<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'study-report.report_download_modal_title' | translate}}</h1>

<div class="content-height" mat-dialog-content>
  <mat-tab-group (selectedIndexChange)="setSelectedTabIndex($event)" mat-align-tabs="center">
    <mat-tab label="{{'study-report.download_configuration' | translate}}">
      <div class="m-3 text-center">
        <p><strong>{{((group.attributes.title) ? group.attributes.title : 'group-detail.no_group_title') | translate}}</strong></p>
        <p>{{((group.attributes.name) ? group.attributes.name : 'group-detail.no_group_name') | translate}} (ID - {{group.id}})</p>
        <div>
          <div class="col-12 mt-2 mb-2">
            <img
              [src]="helper.getAllMediaSupportPath(group.attributes.picture, group.id)"
              (error)="helper.getFallbackImage($event)"
              class="img-thumbnail img-center"
            />
          </div>
          <p class="card-text text-center">
            {{((group.attributes.description) ? group.attributes.description : 'group-detail.no_group_description') | translate}}
          </p>

          <hr />
          <!-- Icons -->
          <div class="container-text-group">
            <div *ngIf="(group.attributes.is_private === 1); else openBlock" class="text-padding">
              <p *ngIf="(group.attributes.accesstype === 'invite')">
                <fa-icon [icon]="faEnvelopeOpenText"></fa-icon>
                {{'group-detail.invitations_only' | translate}}
              </p>
              <p *ngIf="(group.attributes.accesstype === 'password')">
                <fa-icon [icon]="faKey"></fa-icon>
                {{'group-detail.password_only' | translate}}
              </p>
              <p *ngIf="(group.attributes.accesstype !== 'password') && (group.attributes.accesstype !== 'invite')">
                {{'group-detail.private' | translate}}
              </p>
            </div>
            <ng-template #openBlock>
              <div class="text-padding">
                <p>{{'group-detail.public' | translate}}</p>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeMeta'" [(ngModel)]="includeMeta">
            {{'study-report.include_study_meta_data' | translate}}
          </mat-checkbox>
        </p>

        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeDiaryInstance'" [(ngModel)]="includeDiaryInstance">
            {{'study-report.include_diaryInstances' | translate}}
          </mat-checkbox>
        </p>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-template *ngIf="(showDownloadHintSubject | async); then thenShowHint else elseNoHint"></ng-template>
        <ng-template #thenShowHint
          ><span matBadge="!" matBadgeOverlap="false" matBadgeColor="primary"
            >{{'study-report.report_downloads' | translate}}</span
          ></ng-template
        >
        <ng-template #elseNoHint>{{'study-report.report_downloads' | translate}}</ng-template>
      </ng-template>
      <div class="container text-start">
        <br />
        <p>{{'study-report.list_of_reports' | translate}}</p>
        <button [attr.id]="'btnLoadJobResults'" mat-button (click)="loadJobResults()">
          <fa-icon [icon]="faRedo"></fa-icon>
          {{'study-report.reload_reports' | translate}}
        </button>
        <mat-list>
          <div *ngIf="(jobresults$ | async)?.length > 0; then thenShowReports else elseNoReports"></div>
          <ng-template #thenShowReports>
            <app-pagination-list
              #paginator
              (emitPageChange)="updatePagedJobStudies($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="jobresults$ | async"
            ></app-pagination-list>

            <mat-list-item [attr.id]="'jobResult_' + result.id" *ngFor="let result of (pagedJobResults$ | async); trackBy: trackByJobId">
              <fa-icon matListItemIcon [icon]="faFileCsv"></fa-icon>
              <div matListItemTitle>{{result.attributes.result_id}}</div>
              <div matListItemLine>
                <fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at, 'datetime')}}
              </div>
              <div matListItemMeta>
                <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
                  <span class="text-danger">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    {{'study-report.failed' | translate}}
                  </span>
                </div>
                <div *ngIf="result.attributes.finished === 0" mat-line>
                  <span class="text-info">
                    <fa-icon [icon]="faEllipsisH"></fa-icon>
                    {{'study-report.pending' | translate}}
                  </span>
                </div>
                <app-jobresult-download
                  [attr.id]="'jobResultDownload_' + result.id"
                  *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
                  [_result]="result"
                ></app-jobresult-download>
              </div>
            </mat-list-item>
          </ng-template>
          <ng-template #elseNoReports>
            <p>{{'study-report.no_reports' | translate}}</p>
          </ng-template>
        </mat-list>
      </div>
    </mat-tab>
  </mat-tab-group>

  <p *ngIf="(showDownloadHintSubject | async)" class="text-success">{{'study-report.check_downloads' | translate}}</p>
</div>

<div mat-dialog-actions *ngIf="selectedTabIndex === 0" align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'study-report.cancel' | translate}}</button>
  <app-button-feedback
    [attr.id]="'btnGetInstanceReportCSV'"
    [_isDisabled]="submitted"
    (click)="getInstanceReportCSV(group)"
    [_defaultButtonText]="'study-report.download_csv'"
    [_responseText]="downloadCSVReportResponse | async"
  ></app-button-feedback>
</div>
