/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { Router, RouterEvent } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, mergeMap, skip, switchMap, take, takeLast, takeWhile } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { StudyDetailsTranslationsInterface } from '../../../models/interface/study/study-details_translations.interface';
import { StudyTranslationsInterface } from '../../../models/interface/study/study_translations.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { InterventionStore } from 'src/app/store/intervention/component-store/intervention.store';
import { InterventionInterface } from 'src/app/models/interface/intervention.interface';

/**
 * Component:
 * Group configuration page;
 * Can be found: {uri}/groups/{{group_id}}/configuration
 */

@Component({
  selector: 'app-group-configuration',
  templateUrl: './group-configuration.component.html',
  styleUrls: ['./group-configuration.component.scss'],
  providers: [StudyStore, InterventionStore]
})
export class GroupConfigurationComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputPicture') fileInputPicture: ElementRef;

  public studyDetails: StudyDetailsTranslationsInterface;
  public studyMedia: Array<StudyMediaInterface> = [];

  public isLoading$: Observable<boolean>;

  // Data provided by StudyService
  public collaborators: Array<UserInterface>;

  public profile: ProfileInterface;
  public myroleSlug: string;

  // Group creation studyForm
  public groupFormType: UntypedFormGroup;
  public accessType = ['PASSWORD', 'INVITATION'];

  // Calculate dates
  public currentDate = this.helperService.formatDateToYYYY_MM_DD(Date.now());
  public today = new Date();

  // Image upload
  public language: string;
  public url = '';
  public fileData: File = null;
  public previewUrl: any = null;
  public fileUploadProgress: number = null;
  public uploadedFilePath: string = null;
  public files: Array<any> = null;
  public isUploading$: Observable<boolean>;
  public showImageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public selectedMediaSubject: BehaviorSubject<StudyMediaInterface> = new BehaviorSubject<StudyMediaInterface>(null);

  // Form submission
  public submitted = false;
  public isPrivate$: Observable<string>;
  public hasAccesstype$: Observable<string>;
  public role$: Observable<string>;

  public isManager: boolean;
  public isManager$: Observable<boolean>;

  public param = { intervention_name: '...', number_automatic_assignment: 0 };

  public subscriptions: Subscription[] = [];

  public profile$: Observable<ProfileInterface>;

  public updateGroupResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public interventionsSubject: BehaviorSubject<Array<InterventionInterface>> = new BehaviorSubject<Array<InterventionInterface>>([]);

  public interventionControl = new FormControl([]);

  public studyDetail$: Observable<StudyInterface | StudyDetailsTranslationsInterface>;

  public automaticallyAssignedInterventionsSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  private updateStudyResponse$: Observable<any>;
  private uploadStudyMediaResponse$: Observable<any>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private studyMedia$: Observable<Array<StudyMediaInterface>>;

  private isUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPrivateSubject = new BehaviorSubject<string>('0');
  private hasAccesstypeSubject = new BehaviorSubject<string>('PASSWORD');
  private roleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('ecoach');
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Study ID and loading status for this child component
  private studyId: number;
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private router: Router,
    private sharedService: GroupSharedService,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      studyDetail: StudyDetailsTranslationsInterface | StudyInterface;
    }>,
    private studyStore: StudyStore,
    private interventionStore: InterventionStore
  ) {
    this.profile$ = store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isUploading$ = this.isUploadingSubject.asObservable();
    this.isPrivate$ = this.isPrivateSubject.asObservable();
    this.hasAccesstype$ = this.hasAccesstypeSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();

    // Form type contains searchFilter options
    this.groupFormType = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255), Validators.minLength(1)]],
      isPrivate: ['0', Validators.required],
      accesstype: ['', Validators.required],
      password: ['', Validators.maxLength(255)],
      deTitle: ['', [Validators.required, Validators.maxLength(255)]],
      enTitle: ['', [Validators.required, Validators.maxLength(255)]],
      deDescription: ['', [Validators.required, Validators.maxLength(255)]],
      enDescription: ['', [Validators.required, Validators.maxLength(255)]]
    });
    this.groupFormType.controls['accesstype'].setValue(this.accessType[0], { onlySelf: true });
    router.events.subscribe((e: RouterEvent) => {
      this.subscriptions.forEach(s => s.unsubscribe());
    });

    this.updateStudyResponse$ = this.studyStore.updateStudyResponse$;
    this.studyDetail$ = this.studyStore.studyDetail$;
    this.uploadStudyMediaResponse$ = this.studyStore.uploadStudyMediaResponse$;
    this.studyMedia$ = this.studyStore.studyMedia$;
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
  }

  get f() {
    return this.groupFormType.controls;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    // Logged in as eCoach manager
    this.subscriptions.push(
      this.isManagerSubject.subscribe((value: boolean) => {
        this.isManager = value;
      })
    );
    this.isManagerSubject.next(false);

    this.role$ = this.roleSubject.asObservable();

    // Sharing parent values: id and study name
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter(group => group !== null),
          distinctUntilChanged(),
          mergeMap(value => {
            this.isLoadingSubject.next(true);
            if (value.attributes?.automatic_assignments) {
              this.automaticallyAssignedInterventionsSubject.next(
                value.attributes.automatic_assignments.length > 0 ? value.attributes.automatic_assignments[0].interventions_ids : []
              );
            } else {
              this.automaticallyAssignedInterventionsSubject.next([]);
            }
            this.studyId = value.id;
            return this.profile$.pipe(
              filter(user => user !== null),
              take(1)
            );
          })
        )
        .subscribe((result: ProfileInterface) => {
          this.profile = result;
          this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: this.studyId, include: 'roles' } });
          this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.studyId));
        })
    );

    this.subscriptions.push(
      this.collaboratorsOfStudy$?.pipe(filter(collaboratorsOfStudy => !!collaboratorsOfStudy)).subscribe((result: any) => {
        this.collaborators = result.collaborators;
        const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.profile.id.toString());
        if (myself !== undefined) {
          this.myroleSlug = this.helper.getHighestRoleOfCollaborator(myself);
          if (this.myroleSlug.match(/study\.(ecoachmanager|owner)$/)) {
            this.isManagerSubject.next(true);
          }
        }
      })
    );

    this.subscriptions.push(
      this.isManagerSubject.subscribe((value: boolean) => {
        if (value) {
          this.studyStore.getStudyDetailAndTranslation({ studyId: this.studyId });
          this.studyStore.getStudyMedia({ studyId: this.studyId });
          this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.studyId });
        }
      })
    );

    this.subscriptions.push(
      this.studyMedia$.subscribe(media => {
        this.studyMedia = media;
      })
    );
    this.studyDetail$.subscribe((studyDetails: any) => {
      if (studyDetails) {
        this.studyDetails = studyDetails;

        if (studyDetails.attributes?.automatic_assignments) {
          this.automaticallyAssignedInterventionsSubject.next(
            studyDetails.attributes.automatic_assignments.length > 0
              ? studyDetails.attributes.automatic_assignments[0].interventions_ids
              : []
          );
        } else {
          this.automaticallyAssignedInterventionsSubject.next([]);
        }
        const nullOrValue = (element): string => (element ? element : '');
        // Set current study values
        this.groupFormType.controls['name'].patchValue(this.studyDetails.attributes.name, { onlySelf: true });
        this.groupFormType.controls['isPrivate'].patchValue(this.studyDetails.attributes.is_private.toString(), { onlySelf: true });
        this.showIsPrivate(this.studyDetails.attributes.is_private.toString());
        const accesstype = nullOrValue(this.studyDetails.attributes.accesstype);
        if (accesstype !== '') {
          this.groupFormType.controls['accesstype'].patchValue(nullOrValue(this.studyDetails.attributes.accesstype).toUpperCase(), {
            onlySelf: true
          });
          this.groupFormType.controls['password'].patchValue(nullOrValue(this.studyDetails.attributes.password), { onlySelf: true });
        }
        const foundTranslationDE: StudyTranslationsInterface = this.studyDetails.attributes.translations.find(
          (translation: StudyTranslationsInterface) => translation.locale === 'de'
        );
        const foundTranslationENG: StudyTranslationsInterface = this.studyDetails.attributes.translations.find(
          (translation: StudyTranslationsInterface) => translation.locale === 'en'
        );

        if (foundTranslationDE) {
          this.groupFormType.controls['deTitle'].patchValue(foundTranslationDE.title, { onlySelf: true });
          this.groupFormType.controls['deDescription'].patchValue(foundTranslationDE.description, { onlySelf: true });
        }
        if (foundTranslationENG) {
          this.groupFormType.controls['enTitle'].patchValue(foundTranslationENG.title, { onlySelf: true });
          this.groupFormType.controls['enDescription'].patchValue(foundTranslationENG.description, { onlySelf: true });
        }
        this.showImageSubject.next(this.displayImagePreview());
        this.selectedMediaSubject.next(null);
        this.isLoadingSubject.next(false);
      }
    });

    this.subscriptions.push(
      this.sharedService.sourceGroup$.subscribe((value: StudyInterface) => {
        this.param = {
          intervention_name: value.attributes.name,
          number_automatic_assignment: this.automaticallyAssignedInterventionsSubject.value.length
        };
      })
    );

    this.groupFormType.get('isPrivate').valueChanges.subscribe(val => {
      if (this.groupFormType.get('accesstype').value === 'PASSWORD' && val.toString() === '1') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });

    this.groupFormType.get('accesstype').valueChanges.subscribe(val => {
      if (val === 'PASSWORD') {
        this.groupFormType.controls['password'].setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(255)]);
      } else {
        this.groupFormType.controls['password'].clearValidators();
      }
      this.groupFormType.controls['password'].updateValueAndValidity();
    });

    this.allInterventionsOfStudy$.subscribe(interventions => {
      const enabledInterventions = interventions.filter(intervention =>
        this.automaticallyAssignedInterventionsSubject.value.includes(intervention.id)
      );
      this.interventionsSubject.next(interventions);
      this.interventionControl.setValue(enabledInterventions);
    });
  }

  // Group creation studyForm check isPrivate
  public showIsPrivate(value: string): void {
    this.isPrivateSubject.next(value);
  }

  // Group creation studyForm check access type
  public showAccesstype(value: string): void {
    this.hasAccesstypeSubject.next(value);
  }

  // Submit parameters required to create a group
  public updateGroup(): void {
    this.submitted = true;
    if (!this.isGroupFormInvalid() && !this.isFormNameOrPasswordInvalid()) {
      if (this.updateGroupResponse.value === 'DEFAULT') {
        this.updateGroupResponse.next('LOADING');
        const nameParam = this.f.name.value;
        const translations = [];

        const translationGerman = {
          locale: 'de',
          title: this.f.deTitle.value,
          description: this.f.deDescription.value
        };

        const translationEnglish = {
          locale: 'en',
          title: this.f.enTitle.value,
          description: this.f.enDescription.value
        };

        translations.push(translationGerman, translationEnglish);

        const formIsPrivate = isPrivate =>
          ({
            1: true,
            0: false
          }[isPrivate]);
        const isPrivateParam = formIsPrivate(this.f.isPrivate.value);

        const formAccesstype = accessType =>
          ({
            PASSWORD: 'password',
            INVITATION: 'invite'
          }[accessType]);
        const accesstypeParam = formAccesstype(this.f.accesstype.value);

        const interventions_ids = this.interventionControl.value.map(intervention => intervention.id);

        const updateStudyService = (imagePath?: string): Observable<any> => {
          this.studyStore.updateStudy({
            studyId: this.studyId,
            name: nameParam,
            translationContent: translations,
            accesstypeParam: isPrivateParam ? accesstypeParam : undefined,
            password: isPrivateParam && accesstypeParam === 'password' ? this.f.password.value : undefined,
            isPrivate: isPrivateParam,
            picture: imagePath,
            interventions_ids
          });
          return this.updateStudyResponse$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
          );
        };

        this.subscriptions.push(
          of(this.fileInputPicture.nativeElement.files)
            .pipe(
              switchMap(value => {
                if (value.length > 0) {
                  this.studyStore.uploadStudyMedia({ studyId: this.studyDetails.id, fileToUpload: this.files[0] });
                  return this.uploadStudyMediaResponse$.pipe(
                    skip(1),
                    takeWhile(result => !(result.type === HttpEventType.Response), true),
                    mergeMap((result: any) => {
                      this.fileUploadProgress = Math.round((result['loaded'] / result['total']) * 100);
                      return of(result);
                    }),
                    takeLast(1),
                    mergeMap((result: any) => {
                      if (result instanceof HttpResponse) {
                        this.fileUploadProgress = 0;
                        this.isUploadingSubject.next(false);
                        return of(result);
                      } else {
                        return throwError(result);
                      }
                    })
                  );
                } else {
                  return of(this.selectedMediaSubject.value);
                }
              }),
              mergeMap(result => {
                if (result instanceof HttpResponse) {
                  if (result.type) {
                    const studyMediaList = result.body.data;
                    const picturePath = `uploads/studies/${this.studyDetails.id}/${studyMediaList[0].attributes.url}`;
                    return updateStudyService(picturePath);
                  }
                  if (this.selectedMediaSubject.value) {
                    const picturePath = `uploads/studies/${this.studyDetails.id}/${this.selectedMediaSubject.value.attributes.url}`;
                    return updateStudyService(picturePath);
                  } else {
                    return updateStudyService();
                  }
                } else {
                  return updateStudyService();
                }
              })
            )
            .subscribe(
              (result: any) => {
                this.updateGroupResponse.next('SUCCESS');
                setTimeout(() => {
                  this.updateGroupResponse.next('DEFAULT');
                  this.submitted = false;
                  this.studyStore.getStudyDetailAndTranslation({ studyId: this.studyId });
                  this.sharedService.pushGroup(result.body.data);
                }, 2500);
              },
              error => {
                console.error(error);
                this.updateGroupResponse.next('FAILURE');
                setTimeout(() => {
                  this.updateGroupResponse.next('DEFAULT');
                  this.submitted = false;
                }, 2500);
              }
            )
        );
      }
    }
  }

  public processFile(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.uploadedFilePath = this.fileData.name;
    this.files = fileInput.target.files;
    if (this.fileData.type.match(/image\/*/) == null) {
      this.showImageSubject.next('assets/pictures/default_no_image_picture.svg');
      this.selectedMediaSubject.next(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = _event => {
      this.previewUrl = reader.result;
      this.showImageSubject.next(this.previewUrl);
      this.selectedMediaSubject.next(null);
    };
  }

  public displayImagePreview(source?: string): string {
    if (source) {
      return source;
    }
    if (!this.fileData) {
      return 'assets/pictures/default_no_image_picture.svg';
    }
    if (this.fileData.type.match(/image\/*/) == null) {
      return 'assets/pictures/default_no_image_picture.svg';
    }
    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = _event => {
      this.previewUrl = reader.result;
    };
    return this.previewUrl;
  }

  public clearImage(): void {
    this.fileData = null;
    this.previewUrl = null;
    this.fileUploadProgress = null;
    this.uploadedFilePath = null;
    this.files = null;
    this.fileInputPicture.nativeElement.value = '';
    this.showImageSubject.next(this.displayImagePreview());
    this.selectedMediaSubject.next(null);
  }

  public openDialogStudyMediaGallery(): void {
    this.helperDialog
      .openDialogStudyMediaGallery(this.studyMedia, this.studyId)
      .afterClosed()
      .pipe(debounceTime(2000))
      .subscribe(result => {
        if (result) {
          this.setImageFromSelection(result);
        }
      });
  }

  public setImageFromSelection(selectedMedia: StudyMediaInterface): void {
    this.clearImage();
    const selectedMediaPath = this.helper.getStudyMedia(selectedMedia.attributes.url, this.studyId);
    this.showImageSubject.next(this.displayImagePreview(selectedMediaPath));
    this.selectedMediaSubject.next(selectedMedia);
  }

  public isGroupFormInvalid(): boolean {
    const translationInvalid =
      this.f.deTitle.invalid || this.f.deDescription.invalid || this.f.enTitle.invalid || this.f.enDescription.invalid;
    if (this.f.name.invalid) {
      return true;
    }
    if (this.f.isPrivate.value === '0') {
      return translationInvalid;
    }
    if (this.f.accesstype.value === 'PASSWORD') {
      return !this.f.password.invalid ? translationInvalid : true;
    } else {
      return translationInvalid;
    }
  }

  public isFormNameOrPasswordInvalid(): boolean {
    return this.f.name.invalid || this.f.password.invalid;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
