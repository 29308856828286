<div *ngIf="(isLoading$ | async) === false; else elseLoadingBlock" class="container">
  <div class="jumbotron text-center hoverable p-3">
    <div *ngIf="!isDiary">
      <div class="card mb-3">
        <div class="card-body">
          <div class="card-title text-md-left ml-3 mt-3">
            <h4 class="h4 mb-4">{{lesson.attributes.name}}</h4>

            <dl class="row">
              <dt class="col-sm-4">{{'lesson-preview.title' | translate}}:</dt>
              <dd class="col-sm-8"><p>{{lesson.attributes.title}}</p></dd>

              <dt class="col-sm-4">{{'lesson-preview.description' | translate}}:</dt>
              <dd class="col-sm-8"><p>{{lesson.attributes.description}}</p></dd>

              <dt class="col-sm-4">{{'lesson-preview.available_languages' | translate}}:</dt>
              <dd class="col-sm-8">
                <div
                  *ngIf="((lesson.attributes.locales !== null) && (lesson.attributes.locales.length > 0)), then thenLanguageBlock else elseNoLanguageBlock"
                ></div>
                <ng-template #thenLanguageBlock>
                  <div *ngFor="let code of lesson.attributes.locales">{{helper.parseLanguage(code) | translate}}</div>
                </ng-template>
                <ng-template #elseNoLanguageBlock>
                  <p class="text-sm-left">{{'lesson-preview.no_language_available' | translate}}</p>
                </ng-template>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <!-- Lesson elements in JSON -->
    <app-questionnaire-preview
      [_background_color]="backgroundColor"
      [_elementsType]="'questionnaires'"
      [_lesson]="lesson"
      [_studyId]="studyId"
      [_dynamicAnswers]="dynamicAnswers"
      [_isDiary]="false"
      [_intervention]="intervention"
      [_diary]="diary"
    ></app-questionnaire-preview>
  </div>
</div>

<ng-template #elseLoadingBlock>
  <div>
    <app-loader></app-loader>
  </div>
</ng-template>
