<div class="container-fluid">
  <div class="container text-start mb-3">
    <p>{{'instance-deletion.modal_cancel_instance_description' | translate}}</p>
    <div>
      <div class="container text-start mb-3">
        <div>
          <div class="row">
            <div class="col-sm-3">{{'instance-deletion.intervention' | translate}}:</div>
            <div class="col-sm-9">
              {{intervention !== null ? intervention.attributes.title : ('instance-deletion.modal_detail_instance_no_intervention' |
              translate)}}
            </div>

            <div class="col-sm-3">{{'instance-deletion.instance_ecoach' | translate}}:</div>
            <div class="col-sm-9">
              <span *ngFor="let ecoach of instance.attributes.ecoach_id"
                >{{helper.getECoachUsername(ecoach, studyCollaborator) | translate}}
              </span>
            </div>

            <div class="col-sm-3">{{'instance-deletion.instance_patient' | translate}}:</div>
            <div class="col-sm-9">
              {{helper.findArrObjById(instance.attributes.patient_id, studyMembers) ?
              helper.getCodeNameEmail(instance.attributes.patient_id, studyMembers, intervention.attributes.study_id) :
              ('NON_VERIFIED_PATIENT' | translate)}}
            </div>

            <div class="col-sm-3">{{'instance-deletion.instance_created_at' | translate}}:</div>
            <div class="col-sm-9">{{helper.localizeDateString(instance.attributes.created_at)}}</div>

            <div class="col-sm-3">{{'instance-deletion.instance_starting_at' | translate}}:</div>
            <div class="col-sm-9">{{helper.localizeDateTimestamp(instance.attributes.configuration.starting_at, 'datetime')}}</div>

            <div class="col-sm-3">{{'instance-deletion.modal_detail_instance_guidance_support' | translate}}:</div>
            <div class="col-sm-9">
              <div
                *ngIf="instance.attributes.intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
              ></div>
              <ng-template #thenShowGuidedIcon>
                <fa-icon [icon]="faHandsHelping"></fa-icon>
              </ng-template>
              <ng-template #elseShowUnguidedIcon>
                <fa-icon [icon]="faHands"></fa-icon>
              </ng-template>
              {{('instance-deletion.' + instance.attributes.intervention_type.toString().toLowerCase())| translate}}
            </div>

            <div class="col-sm-3">{{'instance-deletion.modal_detail_instance_identifier' | translate}}:</div>
            <div class="col-sm-9">{{('instance-deletion.instance_id' | translate) + ' #' + instance.id}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
