<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-buddy.buddy_instance_update_modal_title' | translate}}</h1>
<div class="content-height" mat-dialog-content>
  <!-- First buddy -->
  <div>
    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_first_buddy' | translate}}</mat-label>
      <mat-select
        (click)="resetFilter()"
        [attr.id]="'selectedBuddy1'"
        (selectionChange)="onChangeSelection1($event)"
        [(value)]="selectedBuddy1"
      >
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
          <input
            [attr.id]="'userSelection'"
            autocomplete="off"
            matInput
            (keyup)="helper.onKeyFilterUser(members, filter['userSelection'], membersSelection1Subject, selectedBuddy1, studyId)"
            [(ngModel)]="filter['userSelection']"
          />
        </mat-form-field>
        <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of (membersSelection1Subject | async)" [value]="user"
          >{{user.attributes.email}}
          <span class="break-word" *ngIf="helper.getStudyCode(studyId, user)">({{helper.getStudyCode(studyId, user)}})</span></mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_first_diary_instance' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedDiaryInstance1'" [(ngModel)]="selectedDiaryInstance1">
        <mat-option [attr.id]="'option_' + diary_instance.id" *ngFor="let diary_instance of diaryInstancesBuddy1" [value]="diary_instance"
          >{{helper.findArrObjById(diary_instance.attributes.diary_id, diaries).attributes.name}} - {{('group-buddy.diary_instance_id' |
          translate) + ' #' + diary_instance.id}}</mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="diaryInstancesBuddy1.length === 0"
        >{{'group-buddy.buddy_diary_instance_required' | translate}}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_first_intervention_instance' | translate}}</mat-label>
      <mat-select
        [attr.id]="'selectedInterventionInstance1'"
        (selectionChange)="onChangeInterventionInstance1($event)"
        [(ngModel)]="selectedInterventionInstance1"
      >
        <mat-option
          [attr.id]="'option_' + intervention_instance.id"
          *ngFor="let intervention_instance of interventionInstancesBuddy1"
          [value]="intervention_instance"
          >{{helper.findArrObjById(intervention_instance.attributes.intervention_id, interventions)?.attributes.title}} -
          {{('group-buddy.intervention_instance_id' | translate) + ' #' + intervention_instance.id}}</mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="interventionInstancesBuddy1.length === 0"
        >{{'group-buddy.buddy_intervention_instance_required' | translate}}</mat-hint
      >
    </mat-form-field>

    <div class="container text-start">
      <button
        *ngIf="selectedDiaryInstance1"
        [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiaryInstance1.id"
        (click)="openDialogDiaryPreview(selectedDiaryInstance1)"
        mat-button
      >
        {{'intervention-diary.diary_show_diary_questionnaire' | translate}}
      </button>
      <button
        *ngIf="selectedDiaryInstance1"
        [attr.id]="'btnOpenDialogAnswersheetPreview_' + selectedDiaryInstance1.id"
        (click)="openDialogAnswersheetPreview(selectedDiaryInstance1)"
        mat-button
      >
        {{'patient-diary.diary_show_diary_answersheet' | translate}}
      </button>
      <button
        *ngIf="selectedInterventionInstance1"
        [attr.id]="'btnOpenDialogInterventionInstanceDetails_' + selectedInterventionInstance1.id"
        (click)="openDialogInterventionInstanceDetails(selectedInterventionInstance1.id)"
        mat-button
        matTooltip="{{'group-buddy.show_intervention_instance_details' | translate}}"
      >
        {{'group-task.task_show_instance_details' | translate}}
      </button>
      <br />
    </div>
  </div>

  <hr />

  <!-- Second buddy -->
  <div>
    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_second_buddy' | translate}}</mat-label>
      <mat-select
        (click)="resetFilter()"
        [attr.id]="'selectedBuddy2'"
        (selectionChange)="onChangeSelection2($event)"
        [(value)]="selectedBuddy2"
      >
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{'organisation-collaborator.filter_selection' | translate}}</mat-label>
          <input
            [attr.id]="'userSelection'"
            autocomplete="off"
            matInput
            (keyup)="helper.onKeyFilterUser(members, filter['userSelection'], membersSelection2Subject, selectedBuddy2, studyId)"
            [(ngModel)]="filter['userSelection']"
          />
        </mat-form-field>
        <mat-option [attr.id]="'option_' + user.id" *ngFor="let user of (membersSelection2Subject | async)" [value]="user"
          >{{user.attributes.email}}
          <span class="break-word" *ngIf="helper.getStudyCode(studyId, user)">({{helper.getStudyCode(studyId, user)}})</span></mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_second_diary_instance' | translate}}</mat-label>
      <mat-select [attr.id]="'selectedDiaryInstance2'" [(ngModel)]="selectedDiaryInstance2">
        <mat-option [attr.id]="'option_' + diary_instance" *ngFor="let diary_instance of diaryInstancesBuddy2" [value]="diary_instance"
          >{{helper.findArrObjById(diary_instance.attributes.diary_id, diaries).attributes.name}} - {{('group-buddy.diary_instance_id' |
          translate) + ' #' + diary_instance.id}}</mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="diaryInstancesBuddy2.length === 0"
        >{{'group-buddy.buddy_diary_instance_required' | translate}}</mat-hint
      >
    </mat-form-field>

    <mat-form-field class="full-width mb-2">
      <mat-label>{{'group-buddy.select_second_intervention_instance' | translate}}</mat-label>
      <mat-select
        [attr.id]="'selectedInterventionInstance2'"
        (selectionChange)="onChangeInterventionInstance2($event)"
        [(ngModel)]="selectedInterventionInstance2"
      >
        <mat-option
          [attr.id]="'option_' + intervention_instance.id"
          *ngFor="let intervention_instance of interventionInstancesBuddy2"
          [value]="intervention_instance"
          >{{helper.findArrObjById(intervention_instance.attributes.intervention_id, interventions)?.attributes.title}} -
          {{('group-buddy.intervention_instance_id' | translate) + ' #' + intervention_instance.id}}</mat-option
        >
      </mat-select>
      <mat-hint class="text-info" *ngIf="interventionInstancesBuddy2.length === 0"
        >{{'group-buddy.buddy_intervention_instance_required' | translate}}</mat-hint
      >
    </mat-form-field>

    <div class="container text-start">
      <button
        *ngIf="selectedDiaryInstance2"
        [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiaryInstance2.id"
        (click)="openDialogDiaryPreview(selectedDiaryInstance2)"
        mat-button
      >
        {{'intervention-diary.diary_show_diary_questionnaire' | translate}}
      </button>
      <button
        *ngIf="selectedDiaryInstance2"
        [attr.id]="'btnOpenDialogAnswersheetPreview_' + selectedDiaryInstance2.id"
        (click)="openDialogAnswersheetPreview(selectedDiaryInstance2)"
        mat-button
      >
        {{'patient-diary.diary_show_diary_answersheet' | translate}}
      </button>
      <button
        *ngIf="selectedInterventionInstance2"
        [attr.id]="'btnOpenDialogInterventionInstanceDetails_' + selectedInterventionInstance2.id"
        (click)="openDialogInterventionInstanceDetails(selectedInterventionInstance2.id)"
        mat-button
        matTooltip="{{'group-buddy.show_intervention_instance_details' | translate}}"
      >
        {{'group-task.task_show_instance_details' | translate}}
      </button>
      <br />
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-buddy.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnUpdateBuddyConnection'"
    (click)="updateBuddyConnection()"
    [_isDisabled]="!isAllSelected()"
    [_defaultButtonText]="'group-buddy.update_buddy'"
    [_responseText]="updateBuddyResponse | async"
  ></app-button-feedback>
</div>
