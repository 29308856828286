<mat-card appearance="outlined" [attr.id]="'interventionInstance_' + instance.id">
  <mat-card-header class="card-header-center">
    <mat-card-title class="mb-1">
      <span *ngIf="instance.attributes.intervention_type === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"></span>
      <ng-template #thenShowGuidedIcon>
        <fa-icon [icon]="faHandsHelping"></fa-icon>
      </ng-template>
      <ng-template #elseShowUnguidedIcon>
        <fa-icon [icon]="faHands"></fa-icon>
      </ng-template>
      <span
        class="break-word font-weight-light"
        matTooltip="{{helper.getCodeNameEmail(instance.attributes.patient_id, users, instance.attributes.study_id)}}"
      >
        {{ getTruncatedUserName(instance.attributes.patient_id, instance.attributes.study_id) }}
      </span>
    </mat-card-title>
    <mat-card-subtitle>
      <span *ngIf="instance.attributes.pending_invitation === 0; then thenShowInstanceState else elseShowPendingInvitation"></span>
      <ng-template #thenShowInstanceState>
        <button
          matTooltip="{{helper.getToolTipByInstanceState(instance.attributes.progress.current_state) | translate}}"
          [class]="helper.getTextColorByInstanceState(instance.attributes.progress.current_state)"
          mat-button
        >
          <fa-icon [icon]="helper.getFaIconByInstanceState(instance.attributes.progress.current_state)"></fa-icon>
          {{('group-instance.state_' + (instance.attributes.progress.current_state).toLowerCase()) | translate}}
        </button>
      </ng-template>
      <ng-template #elseShowPendingInvitation>
        <button color="warn" mat-button matTooltip="{{'group-instance.tooltip_pending_invitation' | translate}}">
          <fa-icon [icon]="faEnvelope"></fa-icon>
          {{'group-instance.state_pending_invitation' | translate}}
        </button>
      </ng-template>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row text-start">
      <div class="col-sm-4 font-weight-bold">{{'intervention-instance.intervention' | translate}}:</div>
      <div class="col-sm-8">{{instance.attributes?.intervention_title}} (ID - {{instance.attributes.intervention_id}})</div>

      <div class="col-sm-4 font-weight-bold">{{'intervention-instance.instance_ecoach' | translate}}:</div>
      <div class="col-sm-8">
        <div *ngFor="let ecoach of instance.attributes.ecoach_id">{{helper.getECoachUsername(ecoach, studyCollaborators) | translate}}</div>
      </div>

      <div class="col-sm-4 font-weight-bold">{{'intervention-instance.instance_patient' | translate}}:</div>
      <div class="col-sm-8">
        <div *ngIf="helper.findArrObjById(instance.attributes.patient_id, users) !== null; then thenShowUser"></div>
        <div
          *ngIf="(helper.findArrObjById(instance.attributes.patient_id, users) === null) && (instance.attributes.progress.current_state === ('awaiting_next_questionnaire') || instance.attributes.progress.current_state === ('active') && (instance.attributes.pending_invitation === 1)); then thenPending"
        ></div>
        <div
          *ngIf="(helper.findArrObjById(instance.attributes.patient_id, users) === null) && !((instance.attributes.progress.current_state === ('awaiting_next_questionnaire') || instance.attributes.progress.current_state === ('active'))); then thenDeleted"
        ></div>

        <ng-template #thenShowUser>
          <div>{{helper.getCodeNameEmail(instance.attributes.patient_id, users, instance.attributes.study_id)}}</div>
        </ng-template>
        <ng-template #thenPending>
          <div>{{'group-instance.state_pending_invitation' | translate}}</div>
        </ng-template>
        <ng-template #thenDeleted>
          <div>{{'intervention-instance.deleted_user' | translate}}</div>
        </ng-template>
      </div>
      <div class="col-sm-4 font-weight-bold">{{'intervention-instance.instance_starting_at' | translate}}:</div>
      <div class="col-sm-8">{{helper.localizeDateTimestamp(instance.attributes.configuration.starting_at, 'datetime')}}</div>
    </div>
    <small>{{('group-instance.instance_id' | translate) + ' #' + instance.id}}</small>

    <div class="my-2">
      <button
        [attr.id]="'btnOpenDialogInterventionInstanceReport_' + instance.id"
        (click)="openDialogInterventionInstanceReport()"
        class="spacing-right mb-2"
        *ngIf="helper.isECoachOfInstance(instance, profile.id) && (instance.attributes.pending_invitation === 0)"
        mat-button
      >
        <fa-icon [icon]="faFilePdf"></fa-icon>
        {{'instance-report.download_instance_report' | translate}}
      </button>
      <button
        [attr.id]="'btnOpenDialogInterventionInstanceReminderDetails_' + instance.id"
        (click)="openDialogInterventionInstanceReminderDetails(hasReminder)"
        matBadge="{{hasReminder.attributes?.performed}}"
        matTooltip="{{hasReminder.attributes?.performed + '/' + hasReminder.attributes?.maximum_reminders}} {{'intervention-instance-reminder.reminders_have_been_sent' | translate}}"
        [matTooltipPosition]="'right'"
        *ngIf="hasReminder() as hasReminder"
        class="mb-2"
        mat-button
      >
        {{'group-instance.show_reminder' | translate}}
      </button>

      <button
        [attr.id]="'btnOpenDialogInterventionInstanceReminderCreation_' + instance.id"
        class="mb-2"
        mat-button
        (click)="openDialogInterventionInstanceReminderCreation()"
        *ngIf="!hasReminder()"
      >
        {{'instance-creation-confirmation.create_intervention_instance_reminder' | translate}}
      </button>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions [align]="'end'">
    <button
      [attr.id]="'btnOpenDialogInterventionInstanceDetails_' + instance.id"
      (click)="openDialogInterventionInstanceDetails()"
      class="my-2"
      mat-button
    >
      <fa-icon [icon]="faInfoCircle"></fa-icon>
      Details
    </button>

    <button
      [attr.id]="'btnOpenDialogInterventionInstanceUpdate_' + instance.id"
      (click)="openDialogInterventionInstanceUpdate()"
      *ngIf="((helper.isECoachOfInstance(instance, profile.id) || (isManager$ | async)) && (instance.attributes.pending_invitation === 0) && !(helper.isState(instance, 'canceled')))"
      class="my-2"
      mat-button
    >
      <fa-icon [icon]="faEdit"></fa-icon>
      {{'intervention-instance.my_instances_update' | translate}}
    </button>

    <!-- Toggle paused if eCoach is manager or in instance and patient is in study -->
    <button
      [attr.id]="'btnUnpause_' + instance.id"
      (click)="unpause()"
      *ngIf="((helper.isECoachOfInstance(instance, profile.id)|| (isManager$ | async)) && (instance.attributes.pending_invitation === 0) && helper.isState(instance, 'paused'))"
      class="text-success my-2"
      mat-button
    >
      <fa-icon [icon]="faPlayCircle"></fa-icon>
      {{'group-instance.my_instances_unpause' | translate}}
    </button>
    <button
      [attr.id]="'btnOpenDialogInterventionInstanceCancel_' + instance.id"
      (click)="openDialogInterventionInstanceDelete()"
      *ngIf="(helper.isECoachOfInstance(instance, profile.id) || (isManager$ | async)) && !(helper.isState(instance, 'canceled'))"
      class="my-2"
      mat-button
    >
      <fa-icon [icon]="faWindowClose"></fa-icon>
      {{'group-instance.my_instances_cancel' | translate}}
    </button>

    <button
      [attr.id]="'btnGetDetailedIntervention_' + instance.id"
      (click)="getDetailedIntervention(instance.attributes.intervention_id)"
      class="my-2"
      mat-button
    >
      {{'patient-instance.to_intervention' | translate}}
    </button>
  </mat-card-actions>
</mat-card>
