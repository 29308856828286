<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{'group-member.participants_from' | translate:param}}</h2>
  </div>
  <div *ngIf="(isManager$ | async)">
    <button [attr.id]="'btnToggleCollapse'" (click)="helper.toggleSubject(isCollapseSubject)" mat-button>
      <span *ngIf="(isCollapse$ | async)"
        ><fa-icon [icon]="faChevronDown"></fa-icon> {{'group-member.participants_management' | translate}}</span
      >
      <span *ngIf="(isCollapse$ | async) === false"
        ><fa-icon [icon]="faChevronUp"></fa-icon> {{'group-member.participants_management' | translate}}</span
      >
    </button>
    <div *ngIf="(isCollapse$ | async)">
      <hr />
      <button
        [attr.id]="'btnGetPatientOverview'"
        (click)="getPatientOverview()"
        mat-button
        class="mb-2"
      >
        <fa-icon [icon]="faPlus"></fa-icon>
        {{'group.invite_member' | translate}}
      </button>
      <div class="col-12">
        <p>{{'group-member.study_invitation_check_description' | translate}}</p>
        <button
          [attr.id]="'btnOpenDialogGroupInvitationCheck'"
          class="largeButton"
          (click)="openDialogGroupInvitationCheck()"
          mat-button
          color="primary"
        >
          {{'group-member.study_invitation_check' | translate}}
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row justify-content-center">
    <div class="col-12 col-md-6 mb-2">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'group-member.search_user' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyMemberFilter()"
          [(ngModel)]="this.filter['searchFilter']"
          [disabled]="(isLoading$ | async)"
          aria-describedby="collaboratorSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button
          [attr.id]="'searchMember'"
          (keyup.enter)="applyMemberFilter()"
          aria-label="Search"
          mat-button mat-button matSuffix
        >
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <app-pagination-list
    #paginator
    (emitPageChange)="updatePagedMembers($event)"
    [_isLoading]="isLoading$ | async"
    [_items]="members$ | async"
  ></app-pagination-list>

  <div *ngIf="(isLoading$ | async) === false" class="row">
    <div *ngFor="let user of pagedMembers$ | async; trackBy: helper.trackByElement" class="col-12 col-md-6">
      <mat-card appearance="outlined" [attr.id]="'member_' + user.id" class="mb-3">
        <mat-card-header class="card-header-center">
          <mat-card-title
            ><fa-icon [icon]="faEnvelope"></fa-icon> {{((user.attributes.email) ? user.attributes.email : ('group-member.no_email' |
            translate))}} {{hasPendingInvitation(user) ? ('(' + ('group-member.state_pending_invitation' | translate) + ')') : ''
            }}</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <p class="mb-1 text-center break-word text-muted">
            {{((helper.getCodeOfUserByStudy(study.id, user) | async) !== null) ? (helper.getCodeOfUserByStudy(study.id, user) | async) :
            ('group-member.has_no_study_code' | translate)}}
            <button
              [attr.id]="'btnopenDialogGroupStudyCodeUpdate_' + user.id"
              (click)="openDialogGroupStudyCodeUpdate(user)"
              *ngIf="isManager$ | async"
              mat-button
            >
              <fa-icon [icon]="faEdit"></fa-icon>
            </button>
          </p>
          <div *ngIf="(user.attributes.is_verified === 1); then thenShowVerified else elseShowNotVerified"></div>
          <div class="mb-2">
            <ng-template #thenShowVerified>
              <p class="mb-1 text-center text-success">
                <fa-icon [icon]="faCheckCircle"></fa-icon>
                {{'group-member.is_verified' | translate}}
              </p>
            </ng-template>
            <ng-template #elseShowNotVerified>
              <p class="mb-1 text-center text-danger">
                <fa-icon [icon]="faTimesCircle"></fa-icon>
                {{'group-member.is_not_verified' | translate}}
              </p>
            </ng-template>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions [align]="'end'">
          <div *ngIf="hasPendingInvitation(user); then thenShowResend else elseShowNoResend"></div>
          <ng-template #thenShowResend>
            <button
              [attr.id]="'btnResendStudyInvitation_' + user.id"
              class="mb-2"
              (click)="resendStudyInvitation(study.id, user.attributes.email, getInvitationByEmail(user.attributes.email).id)"
              mat-button
            >
              {{'group-member.resend_study_invitation' | translate}}
            </button>
          </ng-template>
          <ng-template #elseShowNoResend>
            <button
              [attr.id]="'btnGetDetailedPatient_' + user.id"
              class="mb-2"
              (click)="getDetailedPatient(user.id)"
              *ngIf="helper.findArrObjById(user.id, allMyPatients) !== null"
              mat-button
              type="submit"
            >
              <fa-icon [icon]="faInfoCircle"></fa-icon>
              {{'group-member.participants_patient_details' | translate}}
            </button>
            <button *ngIf="(isManager$ | async)" (click)="openDialogGroupMemberRemove(user)" class="mb-2" mat-button type="button">
              {{'group-member.participants_remove' | translate}}
            </button>
          </ng-template>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="(isLoading$ | async)">
  <app-loader></app-loader>
</div>
