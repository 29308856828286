<div class="container-fluid">
  <app-alert></app-alert>
  <!-- Update instance -->
  <div *ngIf="(unconfirmedSubject | async)" class="container text-start">
    <br />

    <div class="row mb-2">
      <div class="col-sm-3 font-weight-bold"><strong>{{'instance-update-confirmation.instance_patient' | translate}}:</strong></div>
      <div class="col-sm-9">{{helper.getCodeNameEmail(instanceAttributes.patient_id, studyMembers)}}</div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-3 font-weight-bold"><strong>{{'instance-update-confirmation.instance_ecoach' | translate}}:</strong></div>
      <!-- <div class="col-sm-9">{{helper.findArrObjById(myUserId, studyCollaborator)?.attributes.email}} ({{helper.findArrObjById(myUserId, studyCollaborator)?.attributes.name}})</div> -->
      <div *ngIf="instancePayload.data.attributes.ecoach_ids.length !== 0;" class="col-sm-9">
        <span *ngFor="let id of instancePayload.data.attributes.ecoach_ids; first as isFirst"
          ><span *ngIf="!isFirst"> | </span> {{helper.findArrObjById(id, studyCollaborator)?.attributes.email}} ({{helper.findArrObjById(id,
          studyCollaborator)?.attributes.name}})
        </span>
      </div>
      <div *ngIf="instancePayload.data.attributes.ecoach_ids.length === 0;" class="col-sm-9">-</div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-3 font-weight-bold"><strong>{{'instance-update-confirmation.instance_guidance' | translate}}:</strong></div>
      <div class="col-sm-9">
        <div
          *ngIf="instance.attributes.intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
        ></div>
        <ng-template #thenShowGuidedIcon>
          <fa-icon [icon]="faHandsHelping"></fa-icon>
        </ng-template>
        <ng-template #elseShowUnguidedIcon>
          <fa-icon [icon]="faHands"></fa-icon>
        </ng-template>
        {{('instance-update-confirmation.' + instanceAttributes.intervention_type.toString().toLowerCase()) | translate}}
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-3 font-weight-bold"><strong>{{'instance-update-confirmation.instance_starting_at' | translate}}:</strong></div>
      <div class="col-sm-9">{{helper.localizeDateTimestamp(instanceAttributes.configuration.starting_at, 'datetime')}}</div>
    </div>

    <!-- Visualization of the custom_order -->
    <p><strong> {{'instance-update-confirmation.form_create_instance_custom_order' | translate}}: </strong></p>

    <ul *ngIf="formCustomOrder.length > 0; else elseNoLessonSelected" class="list-group">
      <li
        [attr.id]="'questionnaire_' + questionnaire_id"
        *ngFor="let questionnaire_id of formCustomOrder; let i = index"
        class="list-group-item flex-column align-items-start"
      >
        <div class="d-flex justify-content-between">
          <p class="mb-1">
            {{i + 1}}. {{getQuestionnaireById(questionnaire_id).attributes.title}} -
            {{getQuestionnaireById(questionnaire_id).attributes.name}}
          </p>
        </div>

        <!-- Configuration -->
        <div *ngIf="getConfigurationByQuestionnaireId(questionnaire_id) as config" class="row">
          <div class="col-sm-6">
            <p *ngIf="config.feedback_required; else elseFeedbackNotRequired">
              <span>
                <fa-icon [icon]="faComment"></fa-icon>
                {{'instance-update-confirmation.feedback_required' | translate}}</span
              >
            </p>
            <ng-template #elseFeedbackNotRequired>
              <p>
                <span>
                  <fa-icon [icon]="faCommentSlash"></fa-icon>
                  {{'instance-update-confirmation.feedback_not_required' | translate}}</span
                >
              </p>
            </ng-template>
            <small *ngIf="getAlreadyStarted(config.id)" class="text-danger">{{'instance-update-confirmation.is_active' | translate}}</small>
          </div>
          <div class="col-sm-6">
            <p>
              {{'instance-update-confirmation.' + config.unlock_type.toLowerCase() | translate}}
              <span *ngIf="config.unlock_type.toUpperCase() === 'AT_DATE'" class="float-right"
                >{{helper.localizeDateTimestamp(config.unlock_date, 'datetime')}}</span
              >
            </p>
            <small class="form-text text-muted" *ngIf="(showUnlockHint(config.id))">
              {{'instance-update-confirmation.form_create_instance_unlocks_next' | translate}}
            </small>
          </div>
        </div>

        <div *ngIf="helper.hasUnlockDiaries(questionnaire_id, questionnaires)">
          <mat-divider></mat-divider>
          <p class="py-2">{{'instance-creation-form.unlocks_diaries' | translate}}:</p>
          <div class="row">
            <div *ngFor="let diary of helper.findArrObjById(questionnaire_id, questionnaires).attributes.unlock_diaries">
              <div *appVariable="helper.findArrObjById(diary, diaries) as selectedDiary" class="col-12">
                <fa-icon [icon]="faBook"></fa-icon>
                {{selectedDiary !== null ? selectedDiary.attributes.name : ('lesson.diary_not_found' | translate)}}

                <div *ngIf="selectedDiary?.attributes?.questionnaire_id">
                  <!-- <button mat-button (click)="openDialog()">{{'instance-creation-form.show_diary_configuration' | translate}}</button> -->
                  <button
                    [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiary?.attributes?.questionnaire_id"
                    mat-button
                    (click)="openDialogDiaryPreview(selectedDiary.attributes.questionnaire_id, selectedDiary.attributes.study_id, selectedDiary.attributes.page_color)"
                  >
                    <fa-icon [icon]="faEye"></fa-icon> {{'instance-creation-form.show_diary_preview' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *appVariable="helper.findArrObjById(questionnaire_id, questionnaires) as selectedQuestionnaire">
          <div *ngIf="selectedQuestionnaire.attributes?.skills">
            <div *ngIf="selectedQuestionnaire.attributes.skills.length > 0; then thenShowSkills"></div>
            <ng-template #thenShowSkills>
              <div class="button-row">
                <button
                  [attr.id]="'btnOpenDialogSkill_' + skillId"
                  *ngFor="let skillId of selectedQuestionnaire.attributes.skills; last as isLast"
                  [ngClass]="{'spacing-right': !isLast}"
                  class="mb-2"
                  (click)="helperDialog.openDialogSkill(skillId, skills, intervention)"
                  mat-button
                >
                  <span [ngStyle]="helperSkill.getColorStyle(skillId, skills)"
                    ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId, skills) !== null ?
                    helper.findArrObjById(skillId, skills).attributes.title : ('lesson.skill_not_found' | translate)}}</span
                  >
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </li>
    </ul>
    <!-- No custom order set -->
    <ng-template #elseNoLessonSelected>
      <li class="list-group-item text-center">{{'instance-update-confirmation.form_create_instance_custom_order_no_item' | translate}}</li>
    </ng-template>
    <br />
  </div>
</div>
