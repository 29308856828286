/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, mergeMap, skip, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { InterventionInstanceActionTypes } from '../../../store/intervention-instance/intervention-instance.action';
import { PatientSharedService } from '../../../services/shared/patient-shared/patient-shared.service';
import { UserActionTypes } from '../../../store/user/user.action';
import { UserStore } from '../../../store/user/component-store/user.store';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';
import { ReminderActionTypes } from '../../../store/reminder/reminder.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { StudyInterface } from '../../../models/interface/study/study.interface';

/**
 * Component:
 * Patient instances page displaying all assigned interventions to the patient;
 * Can be found: {uri}/patients/{{user_id}}/instances
 */

@Component({
  selector: 'app-patient-instance',
  templateUrl: './patient-instance.component.html',
  styleUrls: ['./patient-instance.component.scss'],
  providers: [UserStore, StudyStore]
})
export class PatientInstanceComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Filter
  public filter = { searchFilter: '', excludeCanceledInstance: true };

  // Study ID and loading status for this child component
  patientId: number;
  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();

  // Data provided by UserService
  public users: Array<UserInterface> = [];
  public collaborators: Array<UserInterface> = [];

  public studies: Array<StudyInterface> = [];

  public interventions: Array<InterventionInterface> = [];

  // Checkbox
  public isCollab: boolean;
  public isCollabSubject = new BehaviorSubject<boolean>(true);

  public pagedInstances$: Observable<Array<InterventionInstanceInterface>>;

  // Confirm instance payload
  public payload: PayloadInterface = null;

  // Translation for study name
  public param = { intervention_name: '...' };

  public instances$: Observable<Array<InterventionInstanceInterface>>;

  public allQuestionnaires$: Observable<Array<LessonInterface>>;

  public isManager$: Observable<boolean>;

  public profile$: Observable<ProfileInterface>;

  public allInterventionInstanceReminders$: Observable<Array<InterventionInstanceReminderInterface>>;

  private collabInterventions$: Observable<Array<InterventionInterface>>;

  private pagedInstancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);

  private searchTextSubject = new BehaviorSubject<string>('');

  private myInstances: Array<InterventionInstanceInterface> = [];

  private allInstancesForECoach$: Observable<Array<InterventionInstanceInterface>>;

  private myMembers$: Observable<Array<UserInterface>>;

  private instancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);

  private allCollaborators$: Observable<Array<UserInterface>>;

  private allStudies$: Observable<Array<StudyInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: PatientSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private userStore: UserStore,
    private studyStore: StudyStore,
    private store: Store<{
      myProfile: ProfileInterface;
      collabInterventions: Array<InterventionInterface>;
      allInstancesForECoach: Array<InterventionInstanceInterface>;
      allQuestionnaires: Array<LessonInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
      allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface>;
    }>
  ) {
    this.instances$ = this.instancesSubject.asObservable();
    this.pagedInstances$ = this.pagedInstancesSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.collabInterventions$ = store.select('collabInterventions');
    this.allInstancesForECoach$ = store.select('allInstancesForECoach');
    this.allInterventionInstanceReminders$ = this.store.select('allInterventionInstanceReminders');
    this.allQuestionnaires$ = this.store.select('allQuestionnaires');
    this.myMembers$ = this.store.select('myMembers');
    this.allCollaborators$ = this.userStore.allCollaborators$;
    this.allStudies$ = this.studyStore.allStudies$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratingStudiesType,
      payload: { include: 'owners,roles' }
    });

    this.store.dispatch({
      type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
      payload: {}
    });

    this.subscriptions.push(
      this.isCollabSubject.subscribe((value: boolean) => {
        this.isCollab = value;
      })
    );

    this.subscriptions.push(
      this.sharedService.sourceId$
        .pipe(
          filter((value: number) => !(value === null || value === undefined)),
          mergeMap((value: number) => {
            this.patientId = value;
            this.store.dispatch({
              type: InterventionActionTypes.getInterventionsOfCollabStudyType,
              payload: {}
            });
            return this.collabInterventions$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          this.interventions = result;
          this.applyInstanceFilter();
        })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.applyInstanceFilter();
      })
    );

    this.sharedService.pushActiveChild('instances');
  }

  public applyInstanceFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInstanceList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInstanceList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.store.dispatch({
      type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
      payload: {}
    });

    const reqs: Array<Observable<any>> = [];
    this.store.dispatch({
      type: InterventionInstanceActionTypes.getAllInstancesForECoachType,
      payload: { patientId: this.patientId }
    });

    return this.allInstancesForECoach$.pipe(
      mergeMap((result: Array<InterventionInstanceInterface>) => {
        this.myInstances = result;
        if (this.filter['excludeCanceledInstance']) {
          this.myInstances = this.myInstances.filter(
            (instance: InterventionInstanceInterface) => instance.attributes.progress.current_state !== 'canceled'
          );
        }
        this.studyStore.getAllStudies({ include: 'collaborators' });
        reqs.push(this.allStudies$.pipe(skip(1), take(1)));
        this.store.dispatch({
          type: UserActionTypes.getMyMembersType,
          payload: {}
        });
        reqs.push(this.myMembers$.pipe(skip(1), take(1)));
        return forkJoin(reqs);
      }),
      mergeMap((results: any) => {
        const studyCheck = this.helperService.getSafe(() => results[0], undefined);
        const userCheck = this.helperService.getSafe(() => results[1], undefined);

        this.studies = typeof studyCheck === 'undefined' ? [] : results[0];
        this.users = typeof userCheck === 'undefined' ? [] : results[1];

        return this.search(this.filter['searchFilter']).pipe(
          mergeMap(() => this.helper.setPagedContent(this.instancesSubject, this.pagedInstancesSubject, setFirst, pageIndex, pageSize))
        );
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadInstanceList().subscribe());
  }

  public updatePagedInterventionInstances(event: any) {
    if (event) {
      this.pagedInstancesSubject.next(event);
    }
  }

  public getStudyById(studyId: number) {
    return this.studies.find((study: StudyInterface) => study.id.toString() === studyId.toString());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.instancesSubject.next(this.filterWithInput(this.filter['searchFilter'].toLowerCase().trim()));
        return of(true);
      })
    );
  }

  // Filter for instance_id and instance_state
  private filterWithInput(value: string): Array<InterventionInstanceInterface> {
    return this.myInstances.filter((element: InterventionInstanceInterface) => {
      let ltInstanceId = '';
      let ltInstanceState = '';

      if (element.attributes.progress.current_state) {
        const stateSubject = new BehaviorSubject<string>('');
        this.translateService
          .get('group-instance.state_' + element.attributes.progress.current_state.toLowerCase())
          .subscribe((translation: string) => {
            stateSubject.next(translation);
          });
        ltInstanceState = stateSubject.value.toLowerCase().trim();
      }
      if (element.id !== null) {
        ltInstanceId = element.id.toString().toLowerCase().trim();
      }
      return ltInstanceId.includes(value) || ltInstanceState.includes(value);
    });
  }
}
