<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{dialogTitle | translate}}</h1>

<div mat-dialog-content [appCustomScroll]="(updateScroll | async)" #appScrollElement="appCustomScroll" #scroll>
  <!-- Select patient -->
  <div *ngIf="assignmentMode === 'studyInvitation'">
    <div *ngIf="!((showButtonAssignment$ | async) === 'confirm')" class="container mb-3">
      <div class="form-group row">
        <div *ngIf="users?.length > 0; then thenShowUsers else elseShowNoUsers"></div>
        <ng-template #thenShowUsers>
          <div class="form-group text-start">
            <strong>{{'group-instance.form_create_select_patient_description' | translate}}</strong>
          </div>
          <mat-form-field class="full-width center-mat-form-field">
            <mat-label>{{'group-instance.selected_user' | translate}}</mat-label>
            <mat-select
              [attr.id]="'selectedUser'"
              (click)="resetFilter()"
              (selectionChange)="showSelected(selectedUser)"
              [(ngModel)]="selectedUser"
              [disabled]="(showButtonAssignment$ | async) !== 'invite'"
            >
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'group-instance.filter_selection' | translate}}</mat-label>
                <input
                  [attr.id]="'userselection'"
                  autocomplete="off"
                  matInput
                  (keyup)="helper.onKeyFilterUser(users, filter['userSelection'], usersSubject, selectedUser)"
                  [(ngModel)]="filter['userSelection']"
                />
              </mat-form-field>
              <mat-option [attr.id]="'option_' + selection.id" *ngFor="let selection of (usersSubject | async)" [value]="selection"
                >{{helper.getCodeNameEmail(selection.id, users)}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template #elseShowNoUsers>
          <div class="col-sm-8 text-start">{{'group-instance.form_create_no_patient_recruit' | translate}}</div>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #accountAssignmentContainer> </ng-template>
  <div *ngIf="(showButtonAssignment$ | async) === 'confirm'">
    <app-alert></app-alert>
  </div>

  <!-- patientStudyInvitation has its own app-email-language-selection -->
  <app-email-language-selection
    *ngIf="assignmentMode !== 'patientStudyInvitation'"
    (emitLanguageSelection)="setSelectedLanguage($event)"
    [_initialLanguage]="selectedLanguage"
  ></app-email-language-selection>

  <!--
  <div class="row my-2">
    <div class="col-12 col-sm-6 my-2">
      <p>{{'group-instance.select_email_language' | translate}}</p>
    </div>
    <div class="col-12 col-sm-6 my-2">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'group-instance.select_language' | translate}}</mat-label>
        <mat-select [(value)]="selectedLanguage">
          <mat-option [value]="option" *ngFor="let option of languages">{{option}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
--></div>
<div mat-dialog-actions [ngSwitch]="assignmentMode" align="end">
  <div *ngSwitchCase="'accountCreation'">
    <button
      [attr.id]="'btnCreateAccountSendInvitation'"
      (click)="createAccountSendInvitation()"
      *ngIf="(showButtonAssignment$ | async) === 'account'"
      [disabled]="(isLoadingButton$ | async) || hasNoStudies() || noStudiesSelected() || hasNoEmail()"
      mat-button
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.create_patient_invite_study' | translate}}
    </button>
    <button
      [attr.id]="'btnShowPreviousComponent'"
      (click)="showPreviousComponent()"
      *ngIf="(showButtonAssignment$ | async) === 'invite' || (showButtonAssignment$ | async) === 'instance' || (showButtonAssignment$ | async) === 'confirmation'"
      [disabled]="isLoadingButton$ | async"
      type="button"
      mat-button
    >
      {{'patient-creation-overview.modal_back' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      (click)="showNextComponent(patientCreationInterventionSelectionComponentClass, 'PatientCreationInterventionSelectionComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'account'"
      [disabled]="(isLoadingButton$ | async) || hasNoStudies() || noStudiesSelected() || hasNoEmail()"
      mat-button
      color="primary"
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.modal_next' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      (click)="showNextComponent(patientCreationInstanceComponentClass, 'PatientCreationInstanceCreationComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'invite'"
      [disabled]="isLoadingButton$ | async"
      mat-button
      color="primary"
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.modal_patient_configure_instance' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      matTooltip="{{'patient-creation-overview.modal_scroll_unlock_button' | translate}}"
      [matTooltipDisabled]="!appScrollElement.isScrollbarVisible()"
      (click)="showNextComponent(instanceCreationConfirmationDialogComponentClass, 'InstanceCreationConfirmationDialogComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'instance'"
      matBadge="!"
      [matBadgePosition]="'above before'"
      [matBadgeHidden]="!appScrollElement.isScrollbarVisible()"
      [disabled]="(isLoadingButton$ | async) || appScrollElement.isScrollbarVisible()"
      mat-button
      color="primary"
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.modal_instance_confirm' | translate}}
    </button>
    <button
      [attr.id]="'btnRegisterAccount'"
      (click)="registerAccount()"
      *ngIf="(showButtonAssignment$ | async) === 'confirmation'"
      mat-button
      color="primary"
      type="button"
      matBadgeSize="small"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
      {{'patient-creation-overview.modal_patient_create_account' | translate}}
    </button>
    <button [attr.id]="'btnCancel'" *ngIf="(showButtonAssignment$ | async) === 'confirm'" mat-button mat-dialog-close>
      {{'patient-creation-overview.modal_close' | translate}}
    </button>
  </div>

  <div *ngSwitchCase="'studyInvitation'">
    <button
      [attr.id]="'btnShowPreviousComponent'"
      (click)="showPreviousComponent()"
      *ngIf="(showButtonAssignment$ | async) === 'instance' || (showButtonAssignment$ | async) === 'confirmation'"
      [disabled]="isLoadingButton$ | async"
      mat-button
    >
      {{'group-instance.modal_back' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      (click)="showNextComponent(patientCreationInstanceComponentClass, 'PatientCreationInstanceCreationComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'invite'"
      [disabled]="!selectedUser || (isLoadingButton$ | async)"
      mat-button
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'group-instance.modal_patient_configure_instance' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      matTooltip="{{'patient-creation-overview.modal_scroll_unlock_button' | translate}}"
      [matTooltipDisabled]="!appScrollElement.isScrollbarVisible()"
      (click)="showNextComponent(instanceCreationConfirmationDialogComponentClass, 'InstanceCreationConfirmationDialogComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'instance'"
      matBadge="!"
      [matBadgePosition]="'above before'"
      [matBadgeHidden]="!appScrollElement.isScrollbarVisible()"
      [disabled]="(isLoadingButton$ | async) || appScrollElement.isScrollbarVisible()"
      mat-button
      color="primary"
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.modal_instance_confirm' | translate}}
    </button>
    <app-button-feedback
      [_setId]="'btnAssignInterventions'"
      *ngIf="(showButtonAssignment$ | async) === 'confirmation'"
      (click)="assignInterventions()"
      [_defaultButtonText]="'group-instance.modal_assign_to_intervention'"
      [_responseText]="assignInterventionResponse | async"
    ></app-button-feedback>
    <button *ngIf="(showButtonAssignment$ | async) === 'confirm'" mat-button mat-dialog-close>
      {{'group-instance.modal_close' | translate}}
    </button>
  </div>

  <div *ngSwitchCase="'patientInterventionAssignment'">
    <button
      [attr.id]="'btnShowPreviousComponent'"
      (click)="showPreviousComponent()"
      *ngIf="(showButtonAssignment$ | async) === 'instance' || (showButtonAssignment$ | async) === 'confirmation'"
      [disabled]="isLoadingButton$ | async"
      mat-button
    >
      {{'patient.modal_back' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      (click)="showNextComponent(patientCreationInstanceComponentClass, 'PatientCreationInstanceCreationComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'invite'"
      [disabled]="isLoadingButton$ | async"
      mat-button
      color="primary"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient.modal_patient_configure_instance' | translate}}
    </button>
    <button
      [attr.id]="'btnShowNextComponent'"
      matTooltip="{{'patient-creation-overview.modal_scroll_unlock_button' | translate}}"
      [matTooltipDisabled]="!appScrollElement.isScrollbarVisible()"
      (click)="showNextComponent(instanceCreationConfirmationDialogComponentClass, 'InstanceCreationConfirmationDialogComponent')"
      *ngIf="(showButtonAssignment$ | async) === 'instance'"
      matBadge="!"
      [matBadgePosition]="'above before'"
      [matBadgeHidden]="!appScrollElement.isScrollbarVisible()"
      [disabled]="(isLoadingButton$ | async) || appScrollElement.isScrollbarVisible()"
      mat-button
      color="primary"
      type="button"
    >
      <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
      >{{'patient-creation-overview.modal_instance_confirm' | translate}}
    </button>
    <app-button-feedback
      [_setId]="'btnAssignInterventions'"
      *ngIf="(showButtonAssignment$ | async) === 'confirmation'"
      (click)="assignInterventions1()"
      [_defaultButtonText]="'patient.modal_assign_to_intervention'"
      [_responseText]="assignInterventionsResponse | async"
    ></app-button-feedback>
    <button [attr.id]="'btnCancel'" *ngIf="(showButtonAssignment$ | async) === 'confirm'" mat-button mat-dialog-close>
      {{'patient.modal_close' | translate}}
    </button>
  </div>

  <div *ngSwitchCase="'patientStudyInvitation'">
    <div *ngIf="!hideButtonsOnInvitation()">
      <app-button-feedback
        [_setId]="'btnSendStudyInvitationOnly'"
        (click)="sendStudyInvitationOnly()"
        *ngIf="(showButtonAssignment$ | async) === 'selection'"
        [_defaultButtonText]="'patient.modal_confirm_invite_patient_to_study_confirm'"
        [_responseText]="sendStudyInvitationOnlyResponse | async"
      ></app-button-feedback>
      <button
        [attr.id]="'btnShowNextComponentOfStudy'"
        (click)="showNextComponent(patientCreationInterventionSelectionComponentClass, 'PatientCreationInterventionSelectionComponent')"
        *ngIf="(showButtonAssignment$ | async) === 'selection'"
        [disabled]="isLoadingButton$ | async"
        mat-button
      >
        <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
        >{{'patient.modal_patient_to_intervention_selection' | translate}}
      </button>
      <button
        [attr.id]="'btnShowPreviousComponentOfStudy'"
        (click)="showPreviousComponent()"
        *ngIf="((showButtonAssignment$ | async) === 'invite' || (showButtonAssignment$ | async) ===  'instance') || (showButtonAssignment$ | async) ===  'confirmation'"
        [disabled]="isLoadingButton$ | async"
        mat-button
      >
        {{'patient.modal_back' | translate}}
      </button>
      <button
        [attr.id]="'btnShowNextComponentOfStudy'"
        (click)="showNextComponent(patientCreationInstanceComponentClass, 'PatientCreationInstanceCreationComponent')"
        *ngIf="(showButtonAssignment$ | async) === 'invite'"
        [disabled]="isLoadingButton$ | async"
        mat-button
      >
        <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
        >{{'patient.modal_patient_to_instance_configuration' | translate}}
      </button>
      <button
        [attr.id]="'btnShowNextComponentOfStudy'"
        matTooltip="{{'patient-creation-overview.modal_scroll_unlock_button' | translate}}"
        [matTooltipDisabled]="!appScrollElement.isScrollbarVisible()"
        (click)="showNextComponent(instanceCreationConfirmationDialogComponentClass, 'InstanceCreationConfirmationDialogComponent')"
        *ngIf="(showButtonAssignment$ | async) === 'instance'"
        matBadge="!"
        [matBadgePosition]="'above before'"
        [matBadgeHidden]="!appScrollElement.isScrollbarVisible()"
        [disabled]="(isLoadingButton$ | async) || appScrollElement.isScrollbarVisible()"
        mat-button
        color="primary"
        type="button"
      >
        <span *ngIf="isLoadingButton$ | async" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span
        >{{'patient-creation-overview.modal_instance_confirm' | translate}}
      </button>
      <app-button-feedback
        [_setId]="'btnSendInvitationAndCreateInstance'"
        (click)="sendInvitationAndCreateInstance()"
        *ngIf="(showButtonAssignment$ | async) === 'confirmation'"
        [_isDisabled]="isLoadingButton$ | async"
        [_defaultButtonText]="'patient.modal_patient_confirm_study_and_instance'"
        [_responseText]="sendInvitationAndCreateInstanceResponse | async"
      ></app-button-feedback>
    </div>
  </div>
</div>
