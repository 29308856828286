<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'group-member.participants_remove_modal_title' | translate:param}}</h1>
<div mat-dialog-content class="mat-typography">
  {{'group-member.participants_remove_modal_body' | translate}}
  <section>
    <p class="break-word">{{'group-member.study_code' | translate}}: {{this.code}}</p>
    <p>{{member.attributes.email}}</p>
  </section>
  <p>{{'group-member.remove_member-note' | translate}}</p>
</div>
<div mat-dialog-actions align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'group-member.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnRemoveMember'"
    (click)="removeMember(member.id)"
    [_defaultButtonText]="'group-member.participants_remove'"
    [_responseText]="removeMemberResponse | async"
  ></app-button-feedback>
</div>
