/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment';
import { faFolder } from '@fortawesome/free-regular-svg-icons/faFolder';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { HelperFeedbackService } from '../../../services/helper/helper-feedback/helper-feedback.service';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { AnswersheetsStore } from '../../../store/answersheet/component-store/answersheet.store';
import { getCollabInterventionById } from '../../../store/intervention/intervention.selector';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { getCollabGroupById } from '../../../store/study/study.selector';
import { StudyActionTypes } from '../../../store/study/study.action';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { RoleInterface } from 'src/app/models/interface/role.interface';

/**
 * Component:
 * Page displays answersheet details and feedback;
 * Can be found: {uri}/feedback-overview/answersheets/{id}
 */

@Component({
  selector: 'app-answersheet-detail',
  templateUrl: './answersheet-detail.component.html',
  styleUrls: ['./answersheet-detail.component.scss'],
  providers: [AnswersheetsStore, InterventionInstanceStore, QuestionnaireStore]
})
export class AnswersheetDetailComponent implements OnInit, OnDestroy {
  @ViewChild('topPage') topPage: ElementRef;

  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faHandsHelping = faHandsHelping;
  faHands = faHands;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faInfoCircle = faInfoCircle;
  faFolder = faFolder;

  public isLoading$: Observable<boolean>;
  public isDiary$: Observable<boolean>;

  public intervention: InterventionInterface;
  public intervention$: Observable<InterventionInterface | undefined>;

  public answersheet: AnswersheetInterface;
  public answersheet$: Observable<AnswersheetInterface | undefined>;

  public instance: InterventionInstanceInterface;
  public instance$: Observable<InterventionInstanceInterface | null>;

  public answersheets: Array<AnswersheetInterface> = [];
  public answersheetsOfInterventionInstance$: Observable<Array<AnswersheetInterface>>;

  public lesson: LessonInterface;
  public questionnaires: Array<LessonInterface> = [];
  public questionnaire$: Observable<LessonInterface>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  public users: Array<UserInterface> = [];

  // Background color
  public backgroundColor;

  // Translation
  public param = { intervention_name: '', collected_at: '', username: '' };

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isDiarySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isECoachManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private group$: Observable<StudyInterface>;

  private myMembers$: Observable<Array<UserInterface>>;

  private subscriptions: Subscription[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private helperService: HelperService,
    private router: Router,
    private store: Store<{
      getCollabInterventionById: InterventionInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
    }>,
    private helperFeedbackService: HelperFeedbackService,
    private helperDialogService: HelperDialogService,
    private answersheetStore: AnswersheetsStore,
    private questionnaireStore: QuestionnaireStore,
    private interventionInstanceStore: InterventionInstanceStore
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isDiary$ = this.isDiarySubject.asObservable();
    this.answersheetsOfInterventionInstance$ = this.answersheetStore.answersheetsOfInterventionInstance$;
    this.instance$ = this.interventionInstanceStore.instance$;
    this.questionnaire$ = this.questionnaireStore.questionnaire$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get helperFeedback() {
    return this.helperFeedbackService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.actRoute.params
        .pipe(
          map((value: Params) => value.id),
          mergeMap((answersheetId: number) => {
            this.answersheetStore.getAnswersheetAsEcoach(answersheetId);
            this.answersheetStore.getAnswersheetAsEM(answersheetId);
            this.answersheet$ = this.answersheetStore.selectAnswersheet(answersheetId);
            return this.answersheet$;
          }),
          filter(value => !!value),
          take(1),
          switchMap((result: any) => {
            this.answersheet = result;

            if (this.answersheet.attributes.intervention_instance_id === null) {
              this.router.navigate(['feedback-overview/answersheets']);
              return throwError('No intervention instance found');
            }

            this.interventionInstanceStore.getInstance(this.answersheet.attributes.intervention_instance_id);
            return this.instance$.pipe(
              filter(value => !!value),
              take(1),
              mergeMap((res: InterventionInstanceInterface) => {
                this.instance = res;
                this.intervention$ = this.store.select(getCollabInterventionById(this.instance.attributes.intervention_id));
                this.store.dispatch({
                  type: InterventionActionTypes.getInterventionsOfCollabStudyType,
                  payload: { typeOfParentStudy: 'study' }
                });
                return this.intervention$.pipe(
                  filter((intervention: InterventionInterface | undefined) => !!intervention),
                  take(1)
                );
              }),
              mergeMap((intervention: InterventionInterface) => {
                this.intervention = intervention;
                this.group$ = this.store.select(getCollabGroupById(this.intervention.attributes.study_id));
                this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: { include: `owners,roles` } });
                return this.group$.pipe(skip(1), take(1));
              }),
              switchMap((group: StudyInterface) => {
                this.isECoachManagerSubject.next(
                  !!group.relationships?.roles?.data.find((value: RoleInterface) =>
                    value.attributes?.slug.match(/study\.(publisher|ecoachmanager|owner)$/)
                  )
                );
                if (this.isECoachManagerSubject.value) {
                  this.answersheetStore.getAnswersheetsOfInterventionInstanceEM(this.answersheet.attributes.intervention_instance_id);
                } else {
                  this.answersheetStore.getAnswersheetsOfInterventionInstance(this.answersheet.attributes.intervention_instance_id);
                }
                if (this.instance.attributes.patient_id !== null) {
                  this.store.dispatch({
                    type: UserActionTypes.getMyMembersType,
                    payload: {}
                  });
                  return this.myMembers$.pipe(skip(1), take(1));
                } else {
                  return of([]);
                }
              }),
              mergeMap((res: any) => {
                this.users = res;
                this.questionnaireStore.getQuestionnairesIntervention({ interventionId: this.instance.attributes.intervention_id });
                return this.questionnairesOfIntervention$.pipe(skip(1), take(1));
              }),
              catchError(error => of(error))
            );
          })
        )
        .subscribe(
          (result: any) => {
            this.questionnaires = result;
            this.lesson = this.questionnaires.find(
              (questionnaire: LessonInterface) => questionnaire.id.toString() === this.answersheet.attributes.questionnaire_id.toString()
            );
            this.backgroundColor = this.lesson.attributes.page_color;
            this.param = {
              intervention_name: this.intervention?.attributes.title,
              collected_at: this.helper.localizeDateTimestamp(this.answersheet.attributes.collected_at, 'datetime'),
              username: this.helper.getCodeNameEmail(this.answersheet.attributes.user_id, this.users)
            };
            this.isLoadingSubject.next(false);
          },
          error => {
            this.instance = null;
            this.intervention = null;
            this.param = null;
            this.questionnaires = [];
            this.isDiarySubject.next(true);
            console.error(error);
            this.isLoadingSubject.next(true);
            this.router.navigate(['feedback-overview/answersheets']);
          }
        )
    );

    this.subscriptions.push(
      this.answersheetsOfInterventionInstance$.subscribe((answersheets: AnswersheetInterface[]) => {
        this.answersheets = answersheets;
      })
    );
  }

  public hasPreviousAnswersheet(answersheetId: number, allAnswersheets: Array<AnswersheetInterface>): boolean {
    const index = allAnswersheets.findIndex((answersheet: AnswersheetInterface) => answersheet.id.toString() === answersheetId.toString());
    return index > 0 && index !== -1;
  }

  public hasNextAnswersheet(answersheetId: number, allAnswersheets: Array<AnswersheetInterface>): boolean {
    const index = allAnswersheets.findIndex((answersheet: AnswersheetInterface) => answersheet.id.toString() === answersheetId.toString());
    return index < allAnswersheets.length - 1 && index !== -1;
  }

  public getAnswersheet(answersheetId: number, allAnswersheets: Array<AnswersheetInterface>, pos: 'next' | 'previous'): void {
    const index = allAnswersheets.findIndex((answersheet: AnswersheetInterface) => answersheet.id.toString() === answersheetId.toString());
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    const tempIndex = pos === 'previous' ? index - 1 : index + 1;
    this.router.navigate([`feedback-overview/answersheets/${allAnswersheets[tempIndex].id}`]);
  }

  public isAllFinishedQuestionnaire(): boolean {
    return this.instance.attributes.progress.finished_questionnaires.length.toString() === this.questionnaires.length.toString();
  }

  public openDialogInterventionInstanceDetails(instanceId: number): void {
    this.helperDialog
      .openDialogInterventionInstanceDetails(instanceId, this.param)
      .afterClosed()
      .pipe(debounceTime(2000))
      .subscribe(() => {});
  }

  public getDetailedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
