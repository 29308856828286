<div class="container-fluid text-center">
  <!-- Welcome - title and change logs -->
  <div class="text-start mt-2">
    <h1>{{ 'home.welcome' | translate }}!</h1>
    <p *ngIf="isFirstTime" [innerHTML]="'home.welcome_first' | translate"></p>
    <hr />
  </div>

  <!-- Dashboard with tasks, activities and conversations -->
  <div class="row mb-4">
    <!-- Tasks and activities window -->
    <div class="col-12 col-md-6">
      <div appearance="outlined" class="mb-4 text-start card-color-messages">
        <h3 class="mx-3 py-3">
          <fa-icon [icon]="faInfoCircle"></fa-icon>
          {{'home.tasks_and_activities' | translate}}
        </h3>

        <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
          <mat-tab>
            <ng-template mat-tab-label>
              <fa-icon [icon]="faClock" class="mat-tab-label-space"></fa-icon>
              {{'home.my_tasks' | translate}}
            </ng-template>
            <div *ngIf="isLoadingActivities$ | async; else elseTasksBlock" class="row justify-content-center spinnerHeight">
              <app-loader></app-loader>
            </div>
            <ng-template #elseTasksBlock>
              <div class="fixHeight overflowList m-2">
                <div *ngIf="tasksSubject.value.length === 0">
                  <p class="text-center white-background m-2">{{'home.no_tasks_found' | translate}}</p>
                </div>
                <div *ngIf="tasksSubject.value.length !== 0">
                  <div *ngFor="let task of pagedTask$ | async; trackBy: trackByActivityId; last as isLast">
                    <div class="row white-background" [attr.id]="'task_' + task.id">
                      <div class="col-12 text-start m-3">
                        <p class="mb-1 px-2">
                          <strong>{{helperActivity.translateActivityToTask(task, task.relationships?.questionnaires.data) | async}}</strong>
                        </p>
                        <p class="mb-1 px-2 break-word">
                          {{'home.task_by' | translate}} {{helper.findArrObjById(task.attributes.user, members) !== null ?
                          helper.getCodeNameEmail(task.attributes.user, members) : ('SYSTEM' | translate)}}
                        </p>
                        <p class="mb-1 px-2">
                          <small class="text-muted"
                            ><fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateString(task.attributes.created_at)}}</small
                          >
                        </p>
                      </div>
                      <div class="col-12 mx-3 mb-3">
                        <button
                          [attr.id]="'btnShowAnswersheet_' + task"
                          (click)="showAnswersheet(task)"
                          *ngIf="task.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                          color="primary"
                          mat-raised-button
                        >
                          <fa-icon [icon]="faPlusCircle"></fa-icon>
                          {{'home.task_provide_feedback' | translate}}
                        </button>
                        <button
                          [attr.id]="'btnOpenDialogInterventionInstanceDetails_' + task"
                          (click)="openDialogInterventionInstanceDetails(task)"
                          *ngIf="task.attributes.action === 'INTERVENTION_INSTANCE_CAN_UNLOCK'"
                          color="primary"
                          mat-button
                        >
                          <fa-icon [icon]="faPlusCircle"></fa-icon>
                          {{'home.task_unlock_lesson' | translate}}
                        </button>
                      </div>
                    </div>

                    <mat-divider *ngIf="!isLast"></mat-divider>
                  </div>
                </div>
              </div>
              <div class="m-2">
                <app-pagination-list
                  (emitPageChange)="updatePagedTasks($event)"
                  [_showEmpty]="false"
                  [_isLoading]="isLoadingActivities$ | async"
                  [_items]="tasksSubject | async"
                ></app-pagination-list>

                <div class="text-center mt-3">
                  <a [attr.id]="'linkTasks'" mat-button routerLink="/tasks">{{'home.show_all_tasks' | translate}}</a>
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <fa-icon [icon]="faClock" class="mat-tab-label-space"></fa-icon>
              {{'home.my_activities' | translate}}
            </ng-template>
            <div *ngIf="isLoadingActivities$ | async; else elseActivitiesBlock" class="spinnerHeight">
              <app-loader></app-loader>
            </div>
            <ng-template #elseActivitiesBlock>
              <div class="fixHeight overflowList m-2">
                <div *ngIf="memberActivitiesSubject.value.length === 0">
                  <p class="text-center white-background m-2">{{'home.no_activities_found' | translate}}</p>
                </div>
                <div *ngIf="memberActivitiesSubject.value.length !== 0">
                  <div *ngFor="let activity of pagedActivity$ | async; trackBy: trackByActivityId; last as isLast">
                    <div class="row white-background" [attr.id]="'activity_' + activity.id">
                      <div class="col-12 text-start m-3">
                        <p class="mb-1 px-2">
                          <strong> {{helperActivity.getActivityText(activity) | async}}</strong>
                          <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                            ><strong
                              >: {{helperActivity.getLessonNameOfActivity(activity, activity.relationships.questionnaires.data)}}</strong
                            ></span
                          >
                        </p>

                        <p class="mb-1 px-2 break-word">
                          {{'home.performed_by' | translate}} {{helper.findArrObjById(activity.attributes.user, members) !== null ?
                          helper.getCodeNameEmail(activity.attributes.user, members) : ('home.system' | translate)}}
                        </p>

                        <p class="mb-1 px-2">
                          <small class="text-muted"
                            ><fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateString(activity.attributes.created_at)}}</small
                          >
                        </p>
                      </div>
                    </div>

                    <mat-divider *ngIf="!isLast"></mat-divider>
                  </div>
                </div>
              </div>
              <div class="m-2">
                <app-pagination-list
                  (emitPageChange)="updatePagedActivities($event)"
                  [_showEmpty]="false"
                  [_isLoading]="isLoadingActivities$ | async"
                  [_items]="memberActivitiesSubject | async"
                ></app-pagination-list>

                <div class="text-center mt-3">
                  <a [attr.id]="'linkActivities'" mat-button routerLink="/activities">{{'home.show_all_activities' | translate}}</a>
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <!-- Conversation window -->
    <div class="col-12 col-md-6">
      <div appearance="outlined" class="mb-4 text-start card-color-messages">
        <h3 class="mx-3 py-3">
          <fa-icon [icon]="faCommentDots"></fa-icon>
          {{'home.message_inbox' | translate}}
        </h3>

        <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
          <mat-tab>
            <ng-template mat-tab-label>
              <fa-icon [icon]="faPen" class="mat-tab-label-space"></fa-icon>
              {{'home.feedback' | translate}}
            </ng-template>
            <div *ngIf="isLoadingFeedback$ | async; else elseFeedbackBlock" class="spinnerHeight">
              <app-loader></app-loader>
            </div>
            <ng-template #elseFeedbackBlock>
              <div class="fixHeightConversation overflowList m-2">
                <div *ngIf="(feedback$ | async)?.length === 0">
                  <div class="text-center white-background m-2">{{'home.no_feedback_found' | translate}}</div>
                </div>
                <div *ngIf="(feedback$ | async)?.length !== 0">
                  <div
                    *ngFor="let feedback of feedback$ | async; trackBy: trackByThreadId; last as isLast"
                    class="row white-background"
                    [attr.id]="'feedback_' + feedback.id"
                  >
                    <div class="col-12 m-3">
                      <p class="mb-1 px-2">
                        {{'home.new_unread_comment_feedback' | translate}}
                        <strong>"{{feedback.attributes.subject}} "</strong>
                      </p>

                      <p class="mb-1 px-2">
                        <small class="text-muted"
                          ><fa-icon [icon]="faClock"></fa-icon> {{'home.received_on' | translate}}
                          {{helper.localizeDateString(feedback.attributes.updated_at)}}</small
                        >
                      </p>
                    </div>
                    <div class="col-12 mx-3 mb-3">
                      <button
                        [attr.id]="'btnGetAnswersheetDetails_' + feedback.id"
                        (click)="getAnswersheetDetails(feedback)"
                        color="primary"
                        mat-raised-button
                      >
                        {{'home.move_to_answersheet' | translate}}
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                      </button>
                    </div>

                    <mat-divider *ngIf="!isLast"></mat-divider>
                  </div>
                </div>
              </div>

              <div class="m-2">
                <div class="text-center mt-3">
                  <a [attr.id]="'linkFeedbackOverview'" mat-button routerLink="/feedback-overview"
                    >{{'home.show_all_feedback' | translate}}</a
                  >
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <fa-icon [icon]="faCommentDots" class="mat-tab-label-space"></fa-icon>
              {{'home.conversations' | translate}}
            </ng-template>
            <div *ngIf="isLoadingConversations$ | async; else elseConversationBlock" class="row justify-content-center spinnerHeight">
              <app-loader></app-loader>
            </div>
            <ng-template #elseConversationBlock>
              <div class="fixHeightConversation overflowList m-2">
                <div *ngIf="(threads$ | async)?.length === 0">
                  <div class="text-center white-background m-2">{{'home.no_conversations_found' | translate}}</div>
                </div>
                <div *ngIf="(threads$ | async)?.length !== 0">
                  <div
                    *ngFor="let thread of threads$ | async; trackBy: trackByThreadId; last as isLast"
                    class="row white-background"
                    [attr.id]="'thread' + thread.id"
                  >
                    <div class="col-12 m-3">
                      <p class="mb-1 px-2"><strong>{{'home.new_unread_messages_from' | translate}}:</strong></p>
                      <p *ngFor="let participant of thread.relationships.participants.data">
                        <em>{{participant.attributes.email}}</em>
                      </p>
                      <p class="mb-1 px-2">
                        <small class="text-muted"
                          ><fa-icon [icon]="faClock"></fa-icon> {{'home.received_on' | translate}}
                          {{helper.localizeDateString(thread.attributes.updated_at)}}</small
                        >
                      </p>
                    </div>

                    <mat-divider *ngIf="!isLast"></mat-divider>
                  </div>
                </div>
              </div>

              <div class="m-2">
                <div class="text-center mt-3">
                  <a [attr.id]="'linkConversations'" mat-button routerLink="/conversations"
                    >{{'home.show_all_conversations' | translate}}</a
                  >
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
