/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { iif, Observable, of, Subscription, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { ActivityInterface } from '../../../models/interface/activity.interface';
import { AnswersheetInterface } from '../../../models/interface/answersheet.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { MessageMessageInterface } from '../../../models/interface/message_message.interface';
import { MessageThreadsInterface } from '../../../models/interface/message_threads.interface';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { HelperActivityService } from '../../../services/helper/helper-activity/helper-activity.service';
import { HelperFeedbackService } from '../../../services/helper/helper-feedback/helper-feedback.service';
import { HelperService } from '../../../services/helper/helper.service';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { getCollabGroupById, getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { UserActionTypes } from '../../../store/user/user.action';
import { MessageStore } from '../../../store/message/component-store/message.store';
import { InstanceUpdateConfirmationComponent } from '../../intervention-instance/instance-update-confirmation/instance-update-confirmation.component';
import { InstanceUpdateFormComponent } from '../../intervention-instance/instance-update-form/instance-update-form.component';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { RoleInterface } from 'src/app/models/interface/role.interface';

@Component({
  selector: 'app-answersheet-feedback',
  templateUrl: './answersheet-feedback.component.html',
  styleUrls: ['./answersheet-feedback.component.scss'],
  providers: [InterventionInstanceStore, MessageStore]
})
export class AnswersheetFeedbackComponent implements OnInit, OnDestroy {
  @ViewChild('instanceUpdateForm') instanceUpdateForms: InstanceUpdateFormComponent;
  @ViewChild('instanceUpdateConfirm') instanceUpdateConfirm: InstanceUpdateConfirmationComponent;

  // Icon
  faCheckCircle = faCheckCircle;
  faCircle = faCircle;
  faEdit = faEdit;
  faClock = faClock;

  public profile: ProfileInterface;

  // Data provided by StudyService
  public studyCollaborators: Array<UserInterface> = [];

  public intervention: InterventionInterface;
  public instance: InterventionInstanceInterface;
  public instance$: Observable<InterventionInstanceInterface | null>;

  public answersheet: AnswersheetInterface;
  public users: Array<UserInterface> = [];
  public lesson: LessonInterface;
  public questionnaires: Array<LessonInterface> = [];

  public thread: MessageThreadsInterface = null;
  public participants: Array<UserInterface> = [];
  public includeMessages: Array<MessageMessageInterface> = [];

  // Form - Create feedback
  public threadForm: UntypedFormGroup;

  // Form - Submit comment
  public submitted = false;
  public commentForm: UntypedFormGroup;
  public defaultSubject = '';

  // Form - Update comment
  public showEdit = false;
  public commentEditForm: UntypedFormGroup;

  public isSubmittedLoading$: Observable<boolean>;
  public showUpdateInstance$: Observable<boolean>;

  // Show instance update
  public confirmedUpdate = false;
  public instancePayload: PayloadInterface;

  // Translation
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public param = { intervention_name: '', collected_at: '', username: '' };

  public isLoading$: Observable<boolean>;

  public profile$: Observable<UserInterface>;
  public createFeedbackResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');
  public submitCommentResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  public submitFeedbackAsECoachResponse$: Observable<any>;
  public activitiesOfInstance$: Observable<Array<ActivityInterface>>;

  public showUpdateInstanceSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private group$: Observable<StudyInterface>;

  private startThreadResponse$: Observable<any>;
  private sendMessageResponse$: Observable<any>;
  private addParticipantResponse$: Observable<any>;
  private updateMessageResponse$: Observable<any>;

  private allThreadsOfInstance$: Observable<any>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isSubmittedLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;
  private myMembers$: Observable<Array<UserInterface>>;

  private isECoachManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService,
    private formBuilder: UntypedFormBuilder,
    private helperFeedbackService: HelperFeedbackService,
    private helperActivityService: HelperActivityService,
    private translateService: TranslateService,
    private interventionInstanceStore: InterventionInstanceStore,
    private messageStore: MessageStore,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
      getCollabGroupById: StudyInterface;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmittedLoading$ = this.isSubmittedLoadingSubject.asObservable();
    this.showUpdateInstance$ = this.showUpdateInstanceSubject.asObservable();
    this.threadForm = this.formBuilder.group({
      subject: ['', Validators.required],
      feedback: ['', Validators.required]
    });
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    });
    this.commentEditForm = this.formBuilder.group({
      commentEdit: ['']
    });
    this.instance$ = this.interventionInstanceStore.instance$;
    this.submitFeedbackAsECoachResponse$ = this.interventionInstanceStore.submitFeedbackAsECoachResponse$;
    this.activitiesOfInstance$ = this.interventionInstanceStore.activitiesOfInstance$;
    this.startThreadResponse$ = this.messageStore.startThreadResponse$;
    this.sendMessageResponse$ = this.messageStore.sendMessageResponse$;
    this.addParticipantResponse$ = this.messageStore.addParticipantResponse$;
    this.updateMessageResponse$ = this.messageStore.updateMessageResponse$;
    this.allThreadsOfInstance$ = this.messageStore.allThreadsOfInstance$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperActivity() {
    return this.helperActivityService;
  }

  public get helperFeedback() {
    return this.helperFeedbackService;
  }

  get f() {
    return this.commentForm.controls;
  }

  get g() {
    return this.threadForm.controls;
  }

  get h() {
    return this.commentEditForm.controls;
  }

  @Input()
  set _answersheet(_answersheet: AnswersheetInterface) {
    if (_answersheet) {
      this.answersheet = _answersheet;
    }
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    if (_instance) {
      this.instance = _instance;
    }
  }

  @Input()
  set _lesson(_lesson: LessonInterface) {
    if (_lesson) {
      this.lesson = _lesson;
    }
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires) {
      this.questionnaires = _questionnaires;
    }
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention) {
      this.intervention = _intervention;
    }
  }

  ngOnInit(): void {
    // Default subject
    this.subscriptions.push(
      this.profile$.pipe(filter(user => user !== null)).subscribe((user: ProfileInterface) => {
        if (user) {
          const interpolateParams = {
            ecoach_firstname: user.attributes.firstname,
            ecoach_lastname: user.attributes.lastname,
            lesson_name: this.lesson.attributes.title ? this.lesson.attributes.title : ''
          };

          this.defaultSubject = this.translateService.instant('answersheet-detail.default_answersheet_title', interpolateParams);
        }
      })
    );
    this.threadForm.controls['subject'].setValue(this.defaultSubject);

    this.group$ = this.store.select(getCollabGroupById(this.intervention.attributes.study_id));
    this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: { include: `owners,roles` } });
    this.subscriptions.push(
      this.group$
        .pipe(
          skip(1),
          take(1),
          switchMap((group: StudyInterface) => {
            this.isECoachManagerSubject.next(
              !!group.relationships?.roles?.data.find((value: RoleInterface) =>
                value.attributes?.slug.match(/study\.(publisher|ecoachmanager|owner)$/)
              )
            );
            this.interventionInstanceStore.getInstance(this.answersheet.attributes.intervention_instance_id);

            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {}
            });

            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: this.intervention?.attributes.study_id, include: 'roles' }
            });
            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention?.attributes.study_id));

            return this.getAllThreadsOfInstance();
          })
        )
        .subscribe(
          (threads: any) => {
            // Thread and messages
            if (threads.length !== 0) {
              this.thread = threads.find(
                (thread: MessageThreadsInterface) => thread.attributes.answersheet_id.toString() === this.answersheet.id.toString()
              );
              if (this.thread) {
                this.includeMessages = this.thread.relationships.messages.data;
                this.participants = this.thread.relationships.participants.data;
                if (this.thread.id) {
                  if (this.isECoachManagerSubject.value) {
                    this.messageStore.getThreadOfInstanceEM({ threadId: this.thread.id });
                  } else {
                    this.messageStore.getThreadDetails({ threadId: this.thread.id });
                  }
                }
              }
            }

            this.isLoadingSubject.next(false);
          },
          error => {
            console.error(error);
            this.isLoadingSubject.next(true);
          }
        )
    );

    this.subscriptions.push(
      this.collaboratorsOfStudy$?.subscribe((collaborators: any) => {
        this.studyCollaborators = collaborators;
      })
    );

    this.subscriptions.push(
      this.myMembers$.subscribe((users: any) => {
        this.users =
          this.instance.attributes.patient_id !== null ? users.filter(user => this.helper.isPatientOfInstance(this.instance, user.id)) : [];
        this.param = {
          intervention_name: this.intervention?.attributes.title ? this.intervention?.attributes.title : '',
          collected_at: this.helper.localizeDateTimestamp(this.answersheet.attributes.collected_at, 'datetime'),
          username: this.helper.getCodeNameEmail(this.answersheet.attributes.user_id, this.users)
        };
      })
    );

    this.subscriptions.push(
      this.instance$
        .pipe(filter((instance: InterventionInstanceInterface) => !!instance))
        .subscribe((instance: InterventionInstanceInterface) => {
          this.instance = instance;
        })
    );
  }

  // Event on update confirmation
  public onConfirmUpdate(): void {
    this.instancePayload = this.instanceUpdateForms.payload;
  }

  public isAllFinishedQuestionnaire(): boolean {
    return this.instance.attributes.progress.finished_questionnaires.length.toString() === this.questionnaires.length.toString();
  }

  public isMyMessage(message: MessageMessageInterface, myUserId: number): boolean {
    return myUserId ? message.relationships.author.data?.id.toString() === myUserId.toString() : false;
  }

  public isParticipant(): Observable<boolean> {
    return this.profile$.pipe(
      filter(user => user !== null),
      switchMap((user: ProfileInterface) => of(user ? !!this.helper.findArrObjById(user.id, this.participants) : false))
    );
  }

  public toggleShowEdit(message: string): void {
    this.commentEditForm.controls['commentEdit'].patchValue(message, { onlySelf: true });
    this.showEdit = !this.showEdit;
  }

  public confirmUpdateSettings(): void {
    if (this.instanceUpdateForms.confirmInstanceSettings()) {
      this.confirmedUpdate = true;
    }
  }

  // Return to instance update modal
  public goBackUpdate(): void {
    this.confirmedUpdate = false;
  }

  public createFeedback(): void {
    // this.g.feedback.value.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' '));
    this.submitted = true;
    this.createFeedbackResponse.next('LOADING');
    if (this.threadForm.invalid) {
      this.submitted = false;
      this.createFeedbackResponse.next('DEFAULT');
      return;
    }
    let threadId;
    this.messageStore.startThreadAsECoach({
      subject: this.g.subject.value,
      answersheetId: this.answersheet.id,
      interventionInstanceId: this.instance.id
    });
    this.subscriptions.push(
      this.startThreadResponse$
        .pipe(
          skip(1),
          take(1),
          mergeMap((resp: any) => {
            if (resp instanceof HttpResponse) {
              threadId = parseInt(resp.headers.get('Location').substring(resp.headers.get('Location').lastIndexOf('/') + 1), 10);
              const participants = [...this.instance.attributes.ecoach_id, this.instance.attributes.patient_id];
              this.messageStore.addParticipant({ threadId, participantsParam: participants });
              return this.addParticipantResponse$.pipe(skip(1), take(1));
            } else {
              return throwError(resp);
            }
          }),
          switchMap((resp: any) => {
            if (resp instanceof HttpResponse) {
              // this.g.feedback.value.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, ' ');
              this.messageStore.sendMessage({ threadId, messageParam: this.g.feedback.value });
              return this.sendMessageResponse$.pipe(skip(1), take(1));
            } else {
              return throwError(resp);
            }
          }),
          switchMap((resp: any) => iif(() => resp instanceof HttpResponse, this.getAllThreadsOfInstance(), throwError(resp)))
        )
        .subscribe(
          (result: any) => {
            this.updateThread(result);
            this.createFeedbackResponse.next('SUCCESS');
            setTimeout(() => {
              this.createFeedbackResponse.next('DEFAULT');
              this.submitted = false;
            }, 2500);
          },
          () => {
            this.createFeedbackResponse.next('FAILURE');
            setTimeout(() => {
              this.createFeedbackResponse.next('DEFAULT');
              this.submitted = false;
            }, 2500);
          }
        )
    );
  }

  public submitFeedback(): void {
    // If unguided - only optional feedback, else guided feedback
    if (
      this.instance.attributes.intervention_type.toLowerCase() === 'unguided' ||
      !this.helperFeedback.isFeedbackRequired(this.answersheet.id, this.instance)
    ) {
      this.createFeedback();
    } else {
      this.submitted = true;
      this.createFeedbackResponse.next('LOADING');
      if (this.threadForm.invalid) {
        this.submitted = false;
        this.createFeedbackResponse.next('DEFAULT');
        return;
      }

      this.interventionInstanceStore.getAllActivitiesOfInstance({ instanceId: this.instance.id });
      this.subscriptions.push(
        this.activitiesOfInstance$
          .pipe(
            skip(1),
            take(1),
            switchMap((result: Array<ActivityInterface>) => {
              const activities: Array<ActivityInterface> = result;
              const foundTask: ActivityInterface = this.helperActivity.findActivityByAnswersheetId(activities, this.answersheet.id);
              this.interventionInstanceStore.submitFeedbackAsECoach({
                instanceId: this.instance.id,
                subject: this.g.subject.value,
                message: this.g.feedback.value,
                answersheetId: parseInt(this.answersheet.id.toString(), 10),
                questionnaireId: this.answersheet.attributes.questionnaire_id,
                activityId: parseInt(foundTask.id.toString(), 10)
              });
              return this.submitFeedbackAsECoachResponse$.pipe(skip(1), take(1));
            }),
            switchMap((resp: any) => iif(() => resp instanceof HttpResponse, this.getAllThreadsOfInstance(), throwError(resp)))
          )
          .subscribe(
            (result: any) => {
              this.updateThread(result);
              this.createFeedbackResponse.next('SUCCESS');
              setTimeout(() => {
                this.createFeedbackResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            },
            () => {
              this.createFeedbackResponse.next('FAILURE');
              setTimeout(() => {
                this.createFeedbackResponse.next('DEFAULT');
                this.submitted = false;
              }, 2500);
            }
          )
      );
    }
  }

  public submitComment(): void {
    if (!this.isSubmittedLoadingSubject.value) {
      if (this.submitCommentResponse.value === 'DEFAULT') {
        this.submitted = true;
        this.isSubmittedLoadingSubject.next(true);
        this.submitCommentResponse.next('LOADING');
        if (this.commentForm.invalid) {
          this.submitted = false;
          this.submitCommentResponse.next('DEFAULT');
          this.isSubmittedLoadingSubject.next(false);
          return;
        }
        this.messageStore.sendMessage({ threadId: this.thread.id, messageParam: this.f.comment.value });
        this.subscriptions.push(
          this.sendMessageResponse$
            .pipe(
              skip(1),
              take(1),
              switchMap((resp: any) => {
                this.commentForm.controls['comment'].reset('', { onlySelf: true });
                return iif(() => resp instanceof HttpResponse, this.getAllThreadsOfInstance(), of(null));
              })
            )
            .subscribe(
              (results: any) => {
                if (results.length > 0) {
                  this.thread = results[0];
                  this.includeMessages = this.thread.relationships.messages.data;
                  this.submitCommentResponse.next('SUCCESS');
                }
              },
              () => {
                this.submitCommentResponse.next('FAILURE');
                setTimeout(() => {
                  this.submitCommentResponse.next('DEFAULT');
                  this.submitted = false;
                  this.isSubmittedLoadingSubject.next(false);
                }, 2500);
              },
              () => {
                this.submitCommentResponse.next('DEFAULT');
                this.submitted = false;
                this.isSubmittedLoadingSubject.next(false);
              }
            )
        );
      }
    }
  }

  public updateInstanceAndProvideFeedback(): void {
    this.submitted = true;
    this.isSubmittedLoadingSubject.next(true);
    if (this.createFeedbackResponse.value === 'DEFAULT') {
      this.createFeedbackResponse.next('LOADING');
      this.instanceUpdateConfirm.updateInterventionInstance(this.instance.id).subscribe((result: any) => {
        if (result instanceof HttpResponse) {
          this.submitFeedback();
        } else {
          this.createFeedbackResponse.next('FAILURE');
        }
        setTimeout(() => {
          this.createFeedbackResponse.next('DEFAULT');
          this.submitted = false;
          this.isSubmittedLoadingSubject.next(false);
        }, 2500);
      });
    }
  }

  public editComment(messageId: number): void {
    if (!this.isSubmittedLoadingSubject.value) {
      this.showEdit = true;
      this.isSubmittedLoadingSubject.next(true);
      this.messageStore.updateMessage({ messageId, message: this.h.commentEdit.value });
      this.subscriptions.push(
        this.updateMessageResponse$
          .pipe(
            skip(1),
            take(1),
            switchMap((result: any) => iif(() => result instanceof HttpResponse, this.getAllThreadsOfInstance(), throwError(of([]))))
          )
          .subscribe(
            (results: any) => {
              if (results.length !== 0) {
                this.thread = results[0];
                this.includeMessages = this.thread.relationships.messages.data;
                setTimeout(() => {
                  this.showEdit = false;
                  this.isSubmittedLoadingSubject.next(false);
                }, 2500);
              }
            },
            () => {
              setTimeout(() => {
                this.showEdit = false;
                this.isSubmittedLoadingSubject.next(false);
              }, 2500);
            }
          )
      );
    }
  }

  public trackByMessage(index: number, element: MessageMessageInterface): string {
    return element.id + element.attributes.body;
  }

  public getAllThreadsOfInstance(): Observable<any> {
    const payload = {
      instanceId: this.answersheet.attributes.intervention_instance_id,
      answersheetId: this.answersheet.id,
      include: 'messages,participants'
    };
    if (this.isECoachManagerSubject.value) {
      this.messageStore.getAllThreadsOfInstanceEM(payload);
    } else {
      this.messageStore.getAllThreadsOfInstance(payload);
    }
    return this.allThreadsOfInstance$.pipe(skip(1), take(1));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private updateThread(messageThreads: any): void {
    const threads = messageThreads;
    if (threads.length > 0) {
      this.thread = threads[0];
      const participants = this.thread.relationships?.participants?.data ? this.thread.relationships?.participants?.data : [];
      const includeMessages = this.thread.relationships?.messages?.data ? this.thread.relationships?.messages?.data : [];
      this.users = [...this.users, ...participants];
      this.includeMessages = includeMessages;
      this.participants = participants;
      this.submitted = false;
    }
    this.interventionInstanceStore.getInstance(this.instance.id);
  }
}
