import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, forkJoin, Observable, switchMap, tap, throwError } from 'rxjs';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { RoleInterface } from '../../../models/interface/role.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { InterventionService } from '../../../services/api/intervention/intervention.service';

export interface InterventionsState {
  intervention: InterventionInterface | null;
  allInterventionsOfSpecificStudy: InterventionInterface[];
  allCollaboratorsOfSpecificStudy: UserInterface[];
  allInterventionsOfStudies: Array<InterventionInterface[]>;
  interventions: InterventionInterface[];
  interventionsRoles: RoleInterface[];
  setActiveInterventionAsPublisherResponse: any;
  deleteInterventionAsECoachManagerResponse: any;
  pendingReviewingInterventions: InterventionInterface[];
  confirmInterventionReviewAsECoachManagerResponse: any;
  exportInterventionCodebookResponse: any;
}

@Injectable()
export class InterventionStore extends ComponentStore<InterventionsState> {
  readonly intervention$: Observable<InterventionInterface | null> = this.select(state => state.intervention, { debounce: true });
  readonly allInterventionsOfSpecificStudy$: Observable<InterventionInterface[]> = this.select(
    state => state.allInterventionsOfSpecificStudy,
    { debounce: true }
  );
  readonly allCollaboratorsOfSpecificStudy$: Observable<UserInterface[]> = this.select(state => state.allCollaboratorsOfSpecificStudy, {
    debounce: true
  });
  readonly allInterventionsOfStudies$: Observable<Array<InterventionInterface[]>> = this.select(state => state.allInterventionsOfStudies, {
    debounce: true
  });
  readonly interventions$: Observable<InterventionInterface[]> = this.select(state => state.interventions, {
    debounce: true
  });
  readonly interventionsRoles$: Observable<RoleInterface[]> = this.select(state => state.interventionsRoles, {
    debounce: true
  });
  readonly setActiveInterventionAsPublisherResponse$: Observable<any> = this.select(
    state => state.setActiveInterventionAsPublisherResponse,
    { debounce: true }
  );
  readonly deleteInterventionAsECoachManagerResponse$: Observable<any> = this.select(
    state => state.deleteInterventionAsECoachManagerResponse,
    { debounce: true }
  );
  readonly pendingReviewingInterventions$: Observable<InterventionInterface[]> = this.select(state => state.pendingReviewingInterventions, {
    debounce: true
  });
  readonly confirmInterventionReviewAsECoachManagerResponse$: Observable<any> = this.select(
    state => state.confirmInterventionReviewAsECoachManagerResponse,
    { debounce: true }
  );
  readonly exportInterventionCodebookResponse$: Observable<any> = this.select(state => state.exportInterventionCodebookResponse, {
    debounce: true
  });

  readonly updateInterventionState = this.updater(
    (
      state,
      payload: {
        intervention?: InterventionInterface | null;
        allInterventionsOfSpecificStudy?: InterventionInterface[];
        allCollaboratorsOfSpecificStudy?: UserInterface[];
        allInterventionsOfStudies?: Array<InterventionInterface[]>;
        interventions?: InterventionInterface[];
        interventionsRoles?: RoleInterface[];
        setActiveInterventionAsPublisherResponse?: any;
        deleteInterventionAsECoachManagerResponse?: any;
        pendingReviewingInterventions?: InterventionInterface[];
        confirmInterventionReviewAsECoachManagerResponse?: any;
        exportInterventionCodebookResponse?: any;
      }
    ) => ({
      intervention: payload.intervention ? payload.intervention : state.intervention,
      allInterventionsOfSpecificStudy: payload.allInterventionsOfSpecificStudy
        ? payload.allInterventionsOfSpecificStudy
        : state.allInterventionsOfSpecificStudy,
      allCollaboratorsOfSpecificStudy: payload.allCollaboratorsOfSpecificStudy
        ? payload.allCollaboratorsOfSpecificStudy
        : state.allCollaboratorsOfSpecificStudy,
      allInterventionsOfStudies: payload.allInterventionsOfStudies ? payload.allInterventionsOfStudies : state.allInterventionsOfStudies,
      interventions: payload.interventions ? payload.interventions : state.interventions,
      interventionsRoles: payload.interventionsRoles ? payload.interventionsRoles : state.interventionsRoles,
      setActiveInterventionAsPublisherResponse: payload.setActiveInterventionAsPublisherResponse
        ? payload.setActiveInterventionAsPublisherResponse
        : state.setActiveInterventionAsPublisherResponse,
      deleteInterventionAsECoachManagerResponse: payload.deleteInterventionAsECoachManagerResponse
        ? payload.deleteInterventionAsECoachManagerResponse
        : state.deleteInterventionAsECoachManagerResponse,
      pendingReviewingInterventions: payload.pendingReviewingInterventions
        ? payload.pendingReviewingInterventions
        : state.pendingReviewingInterventions,
      confirmInterventionReviewAsECoachManagerResponse: payload.confirmInterventionReviewAsECoachManagerResponse
        ? payload.confirmInterventionReviewAsECoachManagerResponse
        : state.confirmInterventionReviewAsECoachManagerResponse,
      exportInterventionCodebookResponse: payload.exportInterventionCodebookResponse
        ? payload.exportInterventionCodebookResponse
        : state.exportInterventionCodebookResponse
    })
  );

  readonly getIntervention = this.effect((interventionId$: Observable<number>) => {
    this.updateInterventionState({ intervention: null });
    return interventionId$.pipe(
      switchMap((id: number) =>
        this.interventionService.getIntervention(id).pipe(
          tap({
            next: (result: any) => this.updateInterventionState({ intervention: result.body.data }),
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        )
      )
    );
  });

  readonly getAllInterventionsOfSpecificStudy = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionService
          .getAllInterventionsOfSpecificStudy(
            payload.studyId,
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            payload.isActive,
            payload.include
          )
          .pipe(
            tap({
              next: (result: any) => {
                this.updateInterventionState({ allInterventionsOfSpecificStudy: result.body.data });
              },
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly getAllInterventionsOfStudies = this.effect((payloads$: Observable<Array<any>>) =>
    payloads$.pipe(
      switchMap((payloads: Array<any>) => {
        const reqs: Array<Observable<any>> = [];
        payloads.forEach(payload => {
          reqs.push(
            this.interventionService.getAllInterventionsOfSpecificStudy(
              payload.studyId,
              payload.type,
              payload.interventionType,
              payload.isShareable,
              payload.isSubscribable,
              payload.isPrivate,
              payload.isActive,
              payload.include
            )
          );
        });
        return forkJoin(reqs).pipe(
          tap({
            next: (results: Array<any>) => {
              const allInterventionsOfStudies = [];
              results.forEach(result => {
                allInterventionsOfStudies.push(result.body.data);
              });
              this.updateInterventionState({ allInterventionsOfStudies });
            },
            error: e => throwError(e)
          }),
          catchError(error => EMPTY)
        );
      })
    )
  );

  readonly setActiveInterventionAsPublisher = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionState({ setActiveInterventionAsPublisherResponse: null });
        return this.interventionService.setActiveInterventionAsPublisher(payload.id, payload.isActive, payload.deactivationText).pipe(
          tap({
            next: (result: any) => this.updateInterventionState({ setActiveInterventionAsPublisherResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionState({ setActiveInterventionAsPublisherResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly confirmInterventionReviewAsECoachManager = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionState({ confirmInterventionReviewAsECoachManagerResponse: null });
        return this.interventionService.confirmInterventionReviewAsECoachManager(payload.id).pipe(
          tap({
            next: (result: any) => this.updateInterventionState({ confirmInterventionReviewAsECoachManagerResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionState({ confirmInterventionReviewAsECoachManagerResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly deleteInterventionAsECoachManager = this.effect((interventionId$: Observable<number>) =>
    interventionId$.pipe(
      switchMap((interventionId: number) => {
        this.updateInterventionState({ deleteInterventionAsECoachManagerResponse: null });
        return this.interventionService.deleteInterventionAsECoachManager(interventionId).pipe(
          tap({
            next: (result: any) => this.updateInterventionState({ deleteInterventionAsECoachManagerResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionState({ deleteInterventionAsECoachManagerResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  readonly getAllPendingReviewingInterventions = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) =>
        this.interventionService
          .getAllPendingReviewingInterventions(
            payload.type,
            payload.interventionType,
            payload.isShareable,
            payload.isSubscribable,
            payload.isPrivate,
            payload.isActive,
            payload.studyId
          )
          .pipe(
            tap({
              next: (result: any) => this.updateInterventionState({ pendingReviewingInterventions: result.body.data }),
              error: e => throwError(e)
            }),
            catchError(error => EMPTY)
          )
      )
    )
  );

  readonly exportCSVInterventionCodebook = this.effect((payload$: Observable<any>) =>
    payload$.pipe(
      switchMap((payload: any) => {
        this.updateInterventionState({ exportInterventionCodebookResponse: null });
        return this.interventionService.exportCSVInterventionCodebook(payload.interventionId, payload.questionnaireId).pipe(
          tap({
            next: (result: any) => this.updateInterventionState({ exportInterventionCodebookResponse: result }),
            error: e => throwError(e)
          }),
          catchError(error => {
            this.updateInterventionState({ exportInterventionCodebookResponse: error });
            return EMPTY;
          })
        );
      })
    )
  );

  constructor(private readonly interventionService: InterventionService) {
    super({
      intervention: null,
      allInterventionsOfSpecificStudy: [],
      allCollaboratorsOfSpecificStudy: [],
      allInterventionsOfStudies: [],
      interventions: [],
      interventionsRoles: [],
      setActiveInterventionAsPublisherResponse: null,
      deleteInterventionAsECoachManagerResponse: null,
      pendingReviewingInterventions: [],
      confirmInterventionReviewAsECoachManagerResponse: null,
      exportInterventionCodebookResponse: null
    });
  }
}
