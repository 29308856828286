import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonFeedbackComponent } from './button-feedback/button-feedback.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AlertComponent } from './alert/alert.component';
import { CardPendingInvitationComponent } from './card-pending-invitation/card-pending-invitation.component';
import { CardSubmittedAnswersheetComponent } from './card-submitted-answersheet/card-submitted-answersheet.component';
import { LoaderComponent } from './loader/loader.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule } from '@angular/forms';
import { PipeModule } from '../pipe/pipe.module';
import { DirectiveModule } from '../directive/directive.module';
import { JobresultDownloadComponent } from './jobresult-download/jobresult-download.component';
import { CardInterventionComponent } from './card-intervention/card-intervention.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardStudyComponent } from './card-study/card-study.component';
import { CardInterventionInstanceComponent } from './card-intervention-instance/card-intervention-instance.component';
import { CardDiaryComponent } from './card-diary/card-diary.component';
import { PaginationListComponent } from './pagination-list/pagination-list.component';
import { CardUserComponent } from './card-user/card-user.component';
import { CardOrganisationComponent } from './card-organisation/card-organisation.component';
import { AccessCodeBatchInvalidationComponent } from './access-code-batch-invalidation/access-code-batch-invalidation.component';
import { EmailLanguageSelectionComponent } from './email-language-selection/email-language-selection.component';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AlertComponent,
    ButtonFeedbackComponent,
    CardSubmittedAnswersheetComponent,
    CardPendingInvitationComponent,
    LoaderComponent,
    JobresultDownloadComponent,
    CardInterventionComponent,
    CardStudyComponent,
    CardInterventionInstanceComponent,
    CardDiaryComponent,
    PaginationListComponent,
    CardUserComponent,
    CardOrganisationComponent,
    AccessCodeBatchInvalidationComponent,
    EmailLanguageSelectionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MaterialModule,
    PipeModule,
    DirectiveModule
  ],
  exports: [
    AlertComponent,
    ButtonFeedbackComponent,
    CardSubmittedAnswersheetComponent,
    CardPendingInvitationComponent,
    LoaderComponent,
    JobresultDownloadComponent,
    CardInterventionComponent,
    CardStudyComponent,
    CardInterventionInstanceComponent,
    CardDiaryComponent,
    PaginationListComponent,
    CardUserComponent,
    CardOrganisationComponent,
    AccessCodeBatchInvalidationComponent,
    EmailLanguageSelectionComponent
  ]
})
export class UtilsModule {}
