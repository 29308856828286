export const environment = {
  production: true,
  backendURL: 'https://buddy.api.aas2.klips.ifp.uni-ulm.de',
  patientURL: 'https://patient.buddy.aas2.klips.ifp.uni-ulm.de',
  firebaseConfig: {
    apiKey: 'AIzaSyA-UTzMnaiVVfc9M6wJJZgC1REHc3z-yz4',
    authDomain: 'aas2-b8c15.firebaseapp.com',
    databaseURL: 'https://aas2-b8c15.firebaseio.com',
    projectId: 'aas2-b8c15',
    storageBucket: '',
    messagingSenderId: '403595521371',
    appId: '1:403595521371:web:925745d4f935d1b4',
  },
};
