/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, mergeMap, skip, take } from 'rxjs/operators';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { Store } from '@ngrx/store';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { InterventionStore } from '../../../store/intervention/component-store/intervention.store';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { BuddyLastActivityInterface } from '../../../models/interface/buddy/buddy-last-activity.interface';
import { ActivityInterface } from '../../../models/interface/activity.interface';

@Component({
  selector: 'app-intervention-buddy',
  templateUrl: './group-buddy.component.html',
  styleUrls: ['./group-buddy.component.css'],
  providers: [BuddyStore, DiaryStore, InterventionStore, InterventionInstanceStore, StudyStore]
})
export class GroupBuddyComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator;

  // Icons
  faBookOpen = faBookOpen;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faPen = faPen;

  public studyId: number;
  public isLoading$: Observable<boolean>;

  public diaryInstances: Array<DiaryInstanceInterface> = [];
  public buddyInstances: Array<BuddyInstanceInterface> = [];

  public inactiveBuddyLastActivities: Array<BuddyLastActivityInterface> = [];
  public activeBuddyLastActivities: Array<BuddyLastActivityInterface> = [];

  public instances: Array<InterventionInstanceInterface> = [];
  public runningInstances: Array<InterventionInstanceInterface> = [];

  public allBuddyActivitiesOfStudy: Array<ActivityInterface> = [];

  // Filter
  public filter = {
    searchFilter: ''
  };

  public days = 21;

  public resultParam;
  public param = { intervention_name: '...', days: this.days };

  // Data provided by StudyService
  public studyMembers: Array<UserInterface>;
  public diaries: Array<DiaryInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];

  public interventionsBuddy: Array<InterventionInterface> = [];
  public profile$: Observable<ProfileInterface>;
  public profile: ProfileInterface;

  public buddies$: Observable<Array<BuddyInstanceInterface>>;
  public pagedBuddies$: Observable<Array<BuddyInstanceInterface>>;
  public isECoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;

  public buddiesOfStudy$: Observable<Array<BuddyInstanceInterface>>;

  public diaryInstances$: Observable<Array<DiaryInstanceInterface>>;

  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  private allMyInstancesOfStudy$: Observable<Array<InterventionInstanceInterface>>;

  private allInactiveBuddyLastActivities$: Observable<Array<BuddyLastActivityInterface>>;

  private allActiveBuddyLastActivities$: Observable<Array<BuddyLastActivityInterface>>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private studyMembers$: Observable<Array<UserInterface>>;

  private getCSVBuddyByLastActivityResponse$: Observable<any>;

  private buddiesSubject: BehaviorSubject<Array<BuddyInstanceInterface>> = new BehaviorSubject([]);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private searchTextSubject = new BehaviorSubject<string>('');

  private pagedBuddiesSubject: BehaviorSubject<Array<BuddyInstanceInterface>> = new BehaviorSubject<Array<BuddyInstanceInterface>>([]);

  private allBuddyActivitiesOfStudy$: Observable<Array<ActivityInterface>>;

  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private buddyStore: BuddyStore,
    private diaryStore: DiaryStore,
    private interventionStore: InterventionStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private studyStore: StudyStore,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.buddies$ = this.buddiesSubject.asObservable();
    this.pagedBuddies$ = this.pagedBuddiesSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();

    this.buddiesOfStudy$ = this.buddyStore.allBuddiesOfStudy$;
    this.allInactiveBuddyLastActivities$ = this.buddyStore.allInactiveBuddyLastActivities$;
    this.allActiveBuddyLastActivities$ = this.buddyStore.allActiveBuddyLastActivities$;
    this.getCSVBuddyByLastActivityResponse$ = this.buddyStore.getCSVBuddyByLastActivityResponse$;
    this.allBuddyActivitiesOfStudy$ = this.buddyStore.allBuddyActivitiesOfStudy$;
    this.diaryInstances$ = this.diaryStore.allDiaryInstances$;
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.allInterventionsOfStudy$ = this.interventionStore.allInterventionsOfSpecificStudy$;
    this.allMyInstancesOfStudy$ = this.interventionInstanceStore.allMyInstancesOfStudy$;
    this.studyMembers$ = this.studyStore.studyMembers$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profile$.pipe(filter(user => user !== null)).subscribe((result: ProfileInterface) => (this.profile = result))
    );

    this.subscriptions.push(
      this.sharedService.isECoach$.subscribe((value: boolean) => {
        this.isECoachSubject.next(value);
      })
    );

    this.subscriptions.push(
      this.allBuddyActivitiesOfStudy$.subscribe((activities: Array<ActivityInterface>) => {
        this.allBuddyActivitiesOfStudy = activities;
      })
    );

    this.subscriptions.push(
      this.allInactiveBuddyLastActivities$.subscribe((activities: Array<BuddyLastActivityInterface>) => {
        this.inactiveBuddyLastActivities = activities;
      })
    );

    this.subscriptions.push(
      this.allActiveBuddyLastActivities$.subscribe((activities: Array<BuddyLastActivityInterface>) => {
        this.activeBuddyLastActivities = activities;
      })
    );

    this.applyBuddyFilter();
  }

  public applyBuddyFilter(): void {
    this.reloadLastActivities();
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadBuddyList(true).subscribe((result: any) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadBuddyList(setFirst?: boolean): Observable<any> {
    const pageIndex = this.paginator?.paginator ? this.paginator.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator.paginator.pageSize : 20;

    return this.sharedService.isManager$.pipe(
      mergeMap((value: boolean) => {
        this.isManagerSubject.next(value);
        return this.sharedService.sourceGroup$;
      }),
      filter((value: StudyInterface) => !!value),
      mergeMap((value: StudyInterface) => {
        this.studyId = value.id;
        this.param = { intervention_name: value.attributes.name, days: this.days };

        // Get buddy update activities of study
        if (this.isManagerSubject.value) {
          this.buddyStore.getAllBuddyActivitiesStudyEM(this.studyId);
        } else {
          this.buddyStore.getAllBuddyActivitiesStudy(this.studyId);
        }

        this.interventionStore.getAllInterventionsOfSpecificStudy({ studyId: this.studyId, isActive: 1 });
        return this.allInterventionsOfStudy$.pipe(skip(1), take(1));
      }),
      mergeMap((interventions: Array<InterventionInterface>) => {
        this.interventionsBuddy = interventions.filter(
          (intervention: InterventionInterface) => intervention.attributes?.buddy_support === 1
        );
        if (this.isManagerSubject.value) {
          this.interventionInstanceStore.getAllInstancesOfStudy({ studyId: this.studyId });
        } else {
          this.interventionInstanceStore.getMyInstancesOfStudy({ studyId: this.studyId });
        }
        return this.allMyInstancesOfStudy$.pipe(skip(1), take(1));
      }),
      mergeMap((res: Array<InterventionInstanceInterface>) => {
        this.instances = res;
        this.runningInstances = this.instances
          .filter((instance: InterventionInstanceInterface) => !instance.attributes.progress.current_state.match(/^(canceled|completed)$/))
          .filter((instance: InterventionInstanceInterface) =>
            this.interventionsBuddy.map(intervention => intervention.id.toString()).includes(instance.attributes.intervention_id.toString())
          );
        this.diaryStore.getDiariesOfStudy({ studyId: this.studyId });
        return this.diariesOfStudy$.pipe(skip(1), take(1));
      }),
      mergeMap((result: any) => {
        this.diaries = result;
        this.store.dispatch({
          type: StudyActionTypes.getCollaboratorsType,
          payload: { studyId: this.studyId, include: 'roles' }
        });
        this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.studyId));
        return this.collaboratorsOfStudy$.pipe(skip(1), take(1));
      }),
      mergeMap((result: any) => {
        this.studyCollaborators = result.collaborators;
        this.diaryStore.getAllInstancesOfDiaryECoach({ studyId: this.studyId });
        return this.diaryInstances$.pipe(skip(1), take(1));
      }),
      mergeMap((result: any) => {
        this.diaryInstances = result.filter(
          (instance: DiaryInstanceInterface) =>
            this.diaries.map((val: DiaryInterface) => val.id.toString()).includes(instance.attributes.diary_id.toString()) &&
            !!instance.attributes.patient_id &&
            this.instances
              .map(interventionInstance => interventionInstance.id.toString())
              .includes(instance.attributes.intervention_instance_id.toString())
        );
        if (this.isManagerSubject.value) {
          this.studyStore.getMembers({ studyId: this.studyId });
        } else {
          this.studyStore.getECoachMembers({ studyId: this.studyId });
        }
        return this.studyMembers$.pipe(skip(1), take(1));
      }),
      mergeMap((result: Array<UserInterface>) => {
        this.studyMembers = result;
        if (this.isManagerSubject.value) {
          this.buddyStore.getAllBuddiesOfStudyEM(this.studyId);
        } else {
          this.buddyStore.getAllBuddiesOfStudy(this.studyId);
        }
        return this.buddiesOfStudy$.pipe(skip(1), take(1));
      }),
      mergeMap((result: any) => {
        this.buddyInstances = result;
        this.buddiesSubject.next(this.buddyInstances);
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize);
      })
    );
  }

  public updatePagedBuddies(event: any) {
    if (event) {
      this.pagedBuddiesSubject.next(event);
    }
  }

  public reloadLastActivities(): void {
    this.param = { intervention_name: '...', days: this.days };
    this.subscriptions.push(
      this.sharedService.sourceGroup$.pipe(filter((value: StudyInterface) => !!value)).subscribe((value: StudyInterface) => {
        this.buddyStore.getBuddyLastActivities({ studyId: value.id, isActive: false, days: this.days });
        this.buddyStore.getBuddyLastActivities({ studyId: value.id, isActive: true, days: this.days });
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadBuddyList().subscribe());
  }

  public openDialogBuddyInstanceDetails(buddyInstance: BuddyInstanceInterface): void {
    this.helperDialog
      .openDialogBuddyInstanceDetails(
        buddyInstance,
        this.studyMembers,
        this.studyCollaborators,
        this.interventionsBuddy,
        this.runningInstances,
        this.diaryInstances,
        this.diaries,
        this.isManagerSubject.value,
        this.studyId
      )
      .afterClosed()
      .pipe(debounceTime(2000))
      .subscribe(result => {});
  }

  public openDialogBuddyInstanceCreate(): void {
    const userIds: Array<number> = this.buddyInstances.flatMap(
      (buddyInstance: BuddyInstanceInterface) => buddyInstance.attributes.buddies_ids
    );
    const membersWithoutBuddies = this.studyMembers.filter((user: UserInterface) => !userIds.includes(user.id));
    this.helperDialog
      .openDialogBuddyInstanceCreate(
        membersWithoutBuddies,
        this.param,
        this.interventionsBuddy,
        this.runningInstances,
        this.diaryInstances,
        this.diaries,
        this.studyId
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadBuddyList()))
      .subscribe(() => {});
  }

  public openDialogBuddyInstanceDelete(buddyInstance: BuddyInstanceInterface): void {
    this.helperDialog
      .openDialogBuddyInstanceDelete(
        buddyInstance,
        this.studyMembers,
        this.param,
        this.interventionsBuddy,
        this.studyCollaborators,
        this.studyId
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadBuddyList()))
      .subscribe(() => {});
  }

  public openDialogBuddyInstanceUpdate(buddyInstance: BuddyInstanceInterface): void {
    const userIds: Array<number> = this.buddyInstances
      .filter((instance: BuddyInstanceInterface) => instance.id.toString() !== buddyInstance.id.toString())
      .flatMap((instance: BuddyInstanceInterface) => instance.attributes.buddies_ids);
    const membersWithoutBuddies = this.studyMembers.filter((user: UserInterface) => !userIds.includes(user.id));
    this.helperDialog
      .openDialogBuddyInstanceUpdate(
        buddyInstance,
        membersWithoutBuddies,
        this.param,
        this.interventionsBuddy,
        this.runningInstances,
        this.diaryInstances,
        this.diaries,
        this.studyCollaborators,
        this.studyId
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadBuddyList()))
      .subscribe(() => {});
  }

  public openDialogBuddyInstanceSwap(): void {
    this.helperDialog
      .openDialogBuddyInstanceSwap(
        this.buddyInstances,
        this.studyMembers,
        this.param,
        this.interventionsBuddy,
        this.runningInstances,
        this.diaryInstances,
        this.diaries,
        this.studyCollaborators,
        this.studyId
      )
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadBuddyList()))
      .subscribe(() => {});
  }

  public openDialogInterventionInstanceDetails(buddy: BuddyInstanceInterface, userId: number): void {
    const interventionInstanceId: number = this.getInterventionInstanceIdOfBuddy(buddy, userId);
    if (interventionInstanceId) {
      this.helperDialog
        .openDialogInterventionInstanceDetails(interventionInstanceId, this.param)
        .afterClosed()
        .subscribe(result => {});
    }
  }

  public openDialogDiaryAnswersheetPreview(buddy: BuddyInstanceInterface, userId: number): void {
    const diaryInstanceId: number = this.getDiaryInstanceIdOfBuddy(buddy, userId);
    if (diaryInstanceId) {
      const foundDiaryInstance: DiaryInstanceInterface = this.diaryInstances.find(
        (diaryInstance: DiaryInstanceInterface) => diaryInstance.id.toString() === diaryInstanceId.toString()
      );
      if (foundDiaryInstance) {
        const foundDiary: DiaryInterface = this.diaries.find(
          (diary: DiaryInterface) => diary.id.toString() === foundDiaryInstance.attributes.diary_id.toString()
        );
        if (foundDiary) {
          this.helperDialog
            .openDialogDiaryAnswersheetPreview(diaryInstanceId, foundDiary)
            .afterClosed()
            .pipe(debounceTime(2000))
            .subscribe(result => {});
        }
      }
    }
  }

  public getInterventionInstanceIdOfBuddy(buddy: BuddyInstanceInterface, userId: number): number {
    const findInterventionInstance: { intervention_instance_id: number; user_id: number } =
      buddy.attributes.intervention_instances_ids.find(
        (value: { intervention_instance_id: number; user_id: number }) => value.user_id.toString() === userId.toString()
      );
    return findInterventionInstance ? findInterventionInstance.intervention_instance_id : null;
  }

  public getDiaryInstanceIdOfBuddy(buddy: BuddyInstanceInterface, userId: number): number {
    const findDiaryInstance: { diary_instance_id: number; user_id: number } = buddy.attributes.diary_instances_ids.find(
      (value: { diary_instance_id: number; user_id: number }) => value.user_id.toString() === userId.toString()
    );
    return findDiaryInstance ? findDiaryInstance.diary_instance_id : null;
  }

  public isGuidingECoach(userId: number, buddy: BuddyInstanceInterface): boolean {
    return !!buddy.attributes.ecoach_id.find((id: number) => id.toString() === userId.toString());
  }

  public trackByBuddy(index: number, element: BuddyInstanceInterface): string {
    return JSON.stringify(element);
  }

  public getLastActivitiesOfInactiveBuddiesSince(buddyId: number, userId: number): BuddyLastActivityInterface | undefined {
    const result = this.inactiveBuddyLastActivities
      .sort((a, b) => a.id - b.id)
      .find(
        (activity: BuddyLastActivityInterface) =>
          activity.attributes.buddy_id?.toString() === buddyId.toString() &&
          (activity.attributes.user_id !== null ? activity.attributes.user_id : '').toString() === userId?.toString()
      );
    return result;
  }

  public getLastActivitiesOfActiveBuddiesSince(buddyId: number, userId: number): BuddyLastActivityInterface | undefined {
    return this.activeBuddyLastActivities
      .sort((a, b) => a.id - b.id)
      .find(
        (activity: BuddyLastActivityInterface) =>
          activity.attributes.buddy_id?.toString() === buddyId.toString() &&
          (activity.attributes.user_id !== null ? activity.attributes.user_id : '').toString() === userId?.toString()
      );
  }

  public generateCSVBuddyReport(): void {
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter((value: StudyInterface) => !!value),
          mergeMap((value: StudyInterface) => {
            this.buddyStore.getCSVBuddyByLastActivity({ studyId: value.id, isActive: false, days: this.days });
            return this.getCSVBuddyByLastActivityResponse$;
          })
        )
        .subscribe()
    );
  }

  public openDialogBuddyActivityReport(studyId?: number, buddyInstance?: BuddyInstanceInterface): void {
    this.helperDialog
      .openDialogBuddyActivityReport(studyId, this.studyMembers, buddyInstance)
      .afterClosed()
      .pipe(mergeMap((value: any) => this.reloadBuddyList()))
      .subscribe(() => {});
  }

  public isValidDate(checkDate: any): boolean {
    return checkDate !== 'N/A' && checkDate !== undefined;
  }

  public performFilterAndPagination(setFirst: boolean, pageIndex: number, pageSize: number): Observable<boolean> {
    return this.helper
      .searchBuddiesByInput(this.filter['searchFilter'], this.searchTextSubject, this.buddyInstances, this.buddiesSubject)
      .pipe(mergeMap(() => this.helper.setPagedContent(this.buddiesSubject, this.pagedBuddiesSubject, setFirst, pageIndex, pageSize)));
  }

  public getSwappingActivitiesOfBuddy(buddyId: number, userId: number): Array<ActivityInterface> {
    return this.helper.getActivityByNewest(
      this.allBuddyActivitiesOfStudy.filter(
        (activity: ActivityInterface) =>
          activity.attributes?.entity_id?.toString() === userId?.toString() &&
          activity.attributes.info?.buddy_id?.toString() === buddyId.toString()
      )
    );
  }

  public getLatestUpdateActivity(buddyId: number, userId: number): string {
    const activities: Array<ActivityInterface> = this.getSwappingActivitiesOfBuddy(buddyId, userId);
    if (activities.length > 0) {
      return this.helper.localizeDateString(activities[0].attributes.created_at);
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
