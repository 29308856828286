import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LessonPreviewComponent } from '../../questionnaire/lesson-preview/lesson-preview.component';

@Component({
  selector: 'app-dialog-intervention-diary-preview',
  templateUrl: './dialog-intervention-diary-preview.component.html',
  styleUrls: ['./dialog-intervention-diary-preview.component.scss']
})
export class DialogInterventionDiaryPreviewComponent implements OnInit, AfterViewInit {
  @ViewChild('lessonPreviewContainer', { read: ViewContainerRef }) lessonPreviewContainer: ViewContainerRef;

  public lessonPreviewComponentClass = LessonPreviewComponent;
  public components;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.components = [];
    if (this.lessonPreviewContainer) {
      this.lessonPreviewContainer.clear();
    }
  }

  ngAfterViewInit(): void {
    const component: LessonPreviewComponent = this.lessonPreviewContainer.createComponent(this.lessonPreviewComponentClass).instance;
    component._questionnaire_id = this.data.questionnaire_id;
    component._isDiary = true;
    component._study_id = this.data.study_id;
    component._diaryDefault = this.data.diaryPageColor;
    component._diary = this.data.diary;
    this.components.push(component);
  }
}
