<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'instance-report.report_download_modal_title' | translate:param}}</h1>

<div class="content-height" mat-dialog-content>
  <mat-tab-group (selectedIndexChange)="setSelectedTabIndex($event)" mat-align-tabs="center">
    <mat-tab label="{{'instance-report.download_configuration' | translate}}">
      <div class="m-3 text-center">
        <p>
          <strong>
            <span *ngIf="instance.attributes.intervention_type === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"></span>
            <ng-template #thenShowGuidedIcon>
              <fa-icon [icon]="faHandsHelping"></fa-icon>
            </ng-template>
            <ng-template #elseShowUnguidedIcon>
              <fa-icon [icon]="faHands"></fa-icon>
            </ng-template>
            {{helper.findArrObjById(instance.attributes.intervention_id, interventions) !== null ?
            helper.findArrObjById(instance.attributes.intervention_id, interventions).attributes.name :
            ('patient-instance.no_intervention_name_found' | translate)}}
          </strong>
        </p>
        <button
          [class]="helper.getTextColorByInstanceState(instance.attributes.progress.current_state)"
          mat-button
          matTooltip="{{helper.getToolTipByInstanceState(instance.attributes.progress.current_state) | translate}}"
        >
          <fa-icon [icon]="helper.getFaIconByInstanceState(instance.attributes.progress.current_state)"></fa-icon>
          {{('patient-instance.state_' + (instance.attributes.progress.current_state).toLowerCase()) | translate}}
        </button>
        <div>
          <div class="row text-start">
            <div class="col-sm-4">{{'patient-instance.instance_ecoach' | translate}}:</div>
            <div class="col-sm-8">
              <div *ngFor="let ecoach of instance.attributes.ecoach_id">
                {{helper.getECoachUsername(ecoach, collaborators) | translate}}
              </div>
            </div>

            <div class="col-sm-4">{{'patient-instance.instance_patient' | translate}}:</div>
            <div class="col-sm-8">
              <div *ngIf="helper.findArrObjById(instance.attributes.patient_id, users) !== null; then thenShowUser"></div>
              <div
                *ngIf="(helper.findArrObjById(instance.attributes.patient_id, users) === null) && (instance.attributes.progress.current_state === ('awaiting_next_questionnaire' || 'active') && (instance.attributes.pending_invitation === 1)); then thenPending"
              ></div>
              <div
                *ngIf="(helper.findArrObjById(instance.attributes.patient_id, users) === null) && (instance.attributes.progress.current_state !== ('awaiting_next_questionnaire' || 'active')); then thenDeleted"
              ></div>

              <ng-template #thenShowUser>
                <div class="break-word">
                  {{helper.getCodeNameEmail(instance.attributes.patient_id, users, instance.attributes.study_id)}}
                </div>
              </ng-template>
              <ng-template #thenUnverified>
                <div>{{'patient-instance.non_verified_patient' | translate}}</div>
              </ng-template>
              <ng-template #thenPending>
                <div>{{'patient-instance.state_pending_invitation' | translate}}</div>
              </ng-template>
              <ng-template #thenDeleted>
                <div>{{'patient-instance.deleted_user' | translate}}</div>
              </ng-template>
            </div>

            <div class="col-sm-4">{{'patient-instance.instance_starting_at' | translate}}:</div>
            <dd class="col-sm-8">{{helper.localizeDateTimestamp(instance.attributes.configuration.starting_at, 'datetime')}}</dd>
          </div>
          <small>{{('group-instance.instance_id' | translate) + ' #' + instance.id}}</small>
        </div>
      </div>

      <div class="mt-3">
        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeUnfinishedQuestionnaires'" [(ngModel)]="includeUnfinishedQuestionnaires">
            {{'instance-report.include_unfinished_questionnaires' | translate}}
          </mat-checkbox>
        </p>
        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeActivities'" [(ngModel)]="includeActivities">
            {{'instance-report.include_activities' | translate}}
          </mat-checkbox>
        </p>
        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeUDiaries'" [(ngModel)]="includeDiaries">
            {{'instance-report.include_diaries' | translate}}
          </mat-checkbox>
        </p>
        <p>
          <mat-checkbox [attr.id]="'checkboxIncludeFeedback'" [(ngModel)]="includeFeedback">
            {{'instance-report.include_feedback' | translate}}
          </mat-checkbox>
        </p>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-template *ngIf="(showDownloadHintSubject | async); then thenShowHint else elseNoHint"></ng-template>
        <ng-template #thenShowHint
          ><span matBadge="!" matBadgeOverlap="false" matBadgeColor="primary"
            >{{'instance-report.report_downloads' | translate}}</span
          ></ng-template
        >
        <ng-template #elseNoHint>{{'instance-report.report_downloads' | translate}}</ng-template>
      </ng-template>
      <div class="container text-start">
        <br />
        <p>{{'instance-report.list_of_reports' | translate}}</p>
        <button [attr.id]="'btnLoadJobResults'" mat-button (click)="loadJobResults()">
          <fa-icon [icon]="faRedo"></fa-icon>
          {{'instance-report.reload_reports' | translate}}
        </button>
        <mat-list>
          <div *ngIf="(jobresults$ | async)?.length > 0; then thenShowReports else elseNoReports"></div>
          <ng-template #thenShowReports>
            <app-pagination-list
              #paginator
              (emitPageChange)="updatePagedJobInstances($event)"
              [_isLoading]="isLoading$ | async"
              [_items]="jobresults$ | async"
            ></app-pagination-list>

            <mat-list-item [attr.id]="'jobResult_' + result.id" *ngFor="let result of (pagedJobResults$ | async); trackBy: trackByJobId">
              <fa-icon matListItemIcon [icon]="faFilePdf"></fa-icon>
              <div matListItemTitle>{{result.attributes.result_id}}</div>
              <div matListItemLine>
                <fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at, 'datetime')}}
              </div>
              <div matListItemMeta>
                <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
                  <span class="text-danger">
                    <fa-icon [icon]="faTimes"></fa-icon>
                    {{'instance-report.failed' | translate}}
                  </span>
                </div>
                <div *ngIf="result.attributes.finished === 0" mat-line>
                  <span class="text-info">
                    <fa-icon [icon]="faEllipsisH"></fa-icon>
                    {{'instance-report.pending' | translate}}
                  </span>
                </div>
                <app-jobresult-download
                  [attr.id]="'jobResultDownload_' + result.id"
                  *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
                  [_result]="result"
                ></app-jobresult-download>
              </div>
            </mat-list-item>
          </ng-template>
          <ng-template #elseNoReports>
            <p class="text-center">{{'instance-report.no_reports' | translate}}</p>
          </ng-template>
        </mat-list>
      </div>
    </mat-tab>
  </mat-tab-group>

  <p *ngIf="(showDownloadHintSubject | async)" class="text-success">{{'study-report.check_downloads' | translate}}</p>
</div>

<div mat-dialog-actions *ngIf="selectedTabIndex === 0" align="end">
  <button [attr.id]="'btnCancel'" mat-button mat-dialog-close>{{'instance-report.cancel' | translate}}</button>
  <app-button-feedback
    [_setId]="'btnGetInstanceReportPDF'"
    [_isDisabled]="submitted"
    (click)="getInstanceReportPDF(instance)"
    [_defaultButtonText]="'instance-report.download_pdf'"
    [_responseText]="downloadPDFReportResponse | async"
  ></app-button-feedback>
</div>
