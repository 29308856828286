<div class="container-fluid">
  <div class="row text-start mt-3">
    <h1 class="col-12 col-sm-6">Informationen zum Datenschutz der eCoach Plattform</h1>
    <a (click)="redirectToLogin()" *ngIf="(isAuthenticated$ | async) === false" mat-button class="col-12 col-sm-6 mb-2" color="primary"
      ><fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'privacy.back_to_login' | translate }}</a
    >
    <hr />
  </div>

  <p>
    Sehr geehrte Nutzer:innen der Plattform eSano,<br />
    <br />
    der Schutz Ihrer personenbezogenen Daten ist uns wichtig. Deshalb informieren wir Sie an dieser Stelle, zu welchem Zweck wir Ihre Daten
    erheben, speichern oder weiterleiten. Sollten Sie die Plattform im Rahmen einer Studie nutzen, können weitere datenschutzrelevante
    Anforderungen in den dazugehörigen Teilnahmeinformationen bzw. Einwilligungserklärungen Geltung finden. Diesen Informationen können Sie
    ggf. auch entnehmen, welche Rechte Ihnen bezüglich Ihrer personenbezogenen Daten zusteht.
  </p>

  <h3>1. Verantwortlicher für die Datenverarbeitung</h3>
  <p>
    Verantwortlicher für die Datenverarbeitung ist genannt gem. Art. 4 Abs. 7<br />
    Datenschutzgrundverordnung (DSGVO) die<br />
    <br />
    Universität Ulm <br />
    89069 Ulm<br />
    Telefon +49 (0)731/50-10<br />
    Telefax +49 (0)731/50-22038<br />
    <br />
    Die Universität Ulm ist eine Körperschaft des öffentlichen Rechts, die durch den Präsidenten Prof. Dr.-Ing. Michael Weber
    <a href="mailto:praesident@uni-ulm.de">praesident@uni-ulm.de</a> oder durch den Kanzler Dieter Kaufmann
    <a href="mailto:kanzler@uni-ulm.de">kanzler@uni-ulm.de</a> vertreten wird. Bei Fragen rund um den Datenschutz wenden Sie sich bitte an
    <a href="mailto:dsb@uni-ulm.de">dsb@uni-ulm.de</a> oder senden einen Brief mit dem Zusatz "Datenschutzbeauftragte" an die o. g.
    Adresse.<br />
    <br />
    Weitere spezifische Informationen bezüglich der jeweiligen Studie, an der Sie ggf. teilnehmen, finden Sie in den zugehörigen
    Teilnahmeinformationen bzw. Einwilligungserklärungen.
  </p>

  <h3>2. Datenkategorien, Zweck und Rechtsgrundlage der Datenverarbeitung</h3>
  <p>
    Das Online-Interventionsprogramm wird auf der passwortgeschützten Online-Plattform eSano der Universität Ulm angeboten. Die Plattform
    eSano wird vom Institut für Datenbanken und Informationssystem (DBIS) und der Abteilung für Klinische Psychologie und Psychotherapie der
    Universität Ulm verwaltet. Die Plattform besteht aus einem Content Management System zur Erstellung von Inhalten, einer
    Nutzer:innen-Plattform und einer Therapeut:innen- Plattform (eCoach-Plattform). Alle Daten, die über die Plattform erhoben werden,
    werden auf den in Deutschland verorteten Servern der <span *ngIf="!isMasterResearch2">STRATO AG</span>
    <span *ngIf="isMasterResearch2">Hetzner Online GmbH</span> verschlüsselt gespeichert. Es werden die folgenden personenbezogenen Daten
    von Ihnen verarbeitet:
  </p>
  <ul>
    <li>E-Mail-Adresse</li>
    <li>Eingaben in Interventionen und Tagebüchern</li>
    <li>Konversationen mit betreuten Nutzer:innen und anderen eCoaches</li>
    <li>
      Nutzungsdaten der Plattform in Hinblick auf das allgemeine Nutzungsverhalten (z.B. Zeitpunkt der Logins) sowie auf
      eCoach-Nutzer:innen-Interaktion
    </li>
  </ul>
  <p>
    Wir verarbeiten personenbezogene Daten unserer Nutzer:innen grundsätzlich nur, soweit dies zur Bereitstellung einer funktionsfähigen
    Webseite sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer Nutzer:innen erfolgt
    regelmäßig nur nach freiwilliger, informierter Einwilligung des Nutzersder Nutzer:innen. Sollten Sie diese Plattform im Rahmen einer
    Studie nutzen, können Sie weitere Details den jeweils zugehörigen Teilnahmeinformationen bzw. der Einwilligungserklärung entnehmen.
    Rechtsgrundlage für die Verarbeitung ist in diesem Fall Ihre Einwilligung zur Teilnahme an der jeweiligen Studie gem. Art. 6 Abs. 1 lit.
    a EU-DSGVO.
  </p>

  <p>
    Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung
    personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
  </p>

  <p>
    Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns
    dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Einige Cookies sind “Session-Cookies.” Solche Cookies werden
    nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst
    löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen. Mit einem modernen Webbrowser können Sie das
    Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem
    Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer
    Website zur Folge haben. Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung
    bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
    Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und
    reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in
    dieser Datenschutzerklärung separat behandelt.
  </p>

  <p>
    In Server-Log-Dateien erheben und speichern wir automatisch Informationen, die Ihr Browser [Anlage: Note] automatisch an uns übermittelt
    und die erforderlich sind, um die ordnungsmäßen Funktionalität der Plattform sowie deren Stabilität und Sicherheit zu gewährleisten
    (Rechtsgrundlage ist dabei Art. 6 Abs. 1 S. 1 lit. f DSGVO):
  </p>

  <ul>
    <li>Besuchte Seite auf unserer Domain</li>
    <li>Browsertyp und Browserversion</li>
    <li>Verwendetes Betriebssystem Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Datum und Uhrzeit der Serveranfrage</li>
    <li>IP-Adresse</li>
  </ul>

  <h3>3. TLS-Verschlüsselung</h3>
  <p>
    Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
    Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Plattform übermitteln, für Dritte nicht mitlesbar. Sie
    erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss- Symbol in der Browserzeile.
  </p>

  <h3>4. Speicherung Ihrer Daten</h3>
  <p>
    Sofern einer Löschung keine sonstigen berechtigten Interessen des für die Verarbeitung Verantwortlichen entgegenstehen, erfolgt die
    Löschung bei Teilnahme an einer Studie oder an einem Forschungsprojekt entsprechend der dortigen Speicherdauer. Sollten Sie die
    Plattform im Rahmen einer Studie nutzen, finden Sie weitere spezifische Informationen in den zugehörigen Teilnahmeinformationen bzw. der
    Einwilligungserklärung. Bitte informieren Sie sich diesbezüglich über die Löschfrist im Rahmen der entsprechenden Studie oder des
    Forschungsprojekts.
  </p>

  <p>
    Ihre Daten werden so lange gespeichert, wie sie für die ordnungsgemäße Funktionalität der Plattform benötigt werden, spätestens bis zur
    Löschung Ihres Accounts. Wird Ihr Account gelöscht, werden Ihre personenbezogenen Daten gelöscht oder der Personenbezug entfernt.
  </p>

  <h3>5. Empfänger Ihrer Daten</h3>
  <p>
    Im Rahmen der Nutzung der Online-Plattform werden Ihre Daten, sofern in den Teilnahmeinformationen bzw. der Einwilligungserklärung der
    jeweiligen Studie, an der Sie ggf. teilnehmen, nicht anders benannt, von der Abteilung für Klinische Psychologie und Psychotherapie der
    Universität Ulm zu wissenschaftlichen Zwecken verwendet.
  </p>

  <h3>6. Datenverarbeitung durch einen Dritten</h3>
  <div *ngIf="isMasterResearch2; then thenShowIsMasterResearch2 else elseShowDefault"></div>
  <ng-template #thenShowIsMasterResearch2>
    <p>
      Für das Hosting der Online-Plattform nutzen wir Server verwaltet von der Firma<br />
      <br />
      ADMIN INTELLIGENCE GmbH<br />
      Sedanstraße 124<br />
      89077 Ulm<br />
      <br />

      ADMIN INTELLIGENCE GmbH nutzt für das Hosting wiederum Server der Firma<br />
      <br />
      Hetzner Online GmbH<br />
      Industriestr. 25<br />
      91710 Gunzenhausen<br />
      <br />

      Ihre eingegebenen Daten werden für uns bei den Firmen ADMIN INTELLIGENCE GmbH und Hetzner Online GmbH verarbeitet. Alle notwendigen
      technischen und organisatorischen Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten vor Verlust und Missbrauch zu schützen, werden
      von uns und in unserem Auftrag von den Firmen ADMIN INTELLIGENCE GmbH und Hetzner Online GmbH getroffen.
    </p>
  </ng-template>
  <ng-template #elseShowDefault>
    <p>
      Für das Hosting der Online-Plattform nutzen wir einen Server der Firma<br />
      <br />
      STRATO AG<br />
      Pascalstraße 10<br />
      10587 Berlin<br />
      <br />

      Ihre eingegebenen Daten werden für uns bei der Firma STRATO AG verarbeitet. Alle notwendigen technischen und organisatorischen
      Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten vor Verlust und Missbrauch zu schützen, werden von uns und in unserem Auftrag
      von der Firma STRATO AG getroffen.
    </p>
  </ng-template>

  <h3>7. Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
  <p>
    Die Einwilligung zur Datenverarbeitung ist freiwillig. Sie haben das Recht, Ihre Einwilligung jederzeit und ohne Angaben von Gründen zu
    widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf erfolgten
    Verarbeitung nicht berührt. Sollten Sie die Plattform im Rahmen einer Studie nutzen, richten Sie Ihren Widerruf als formlose Mitteilung
    an die in Teilnahmeinformationen bzw. der Einwilligungserklärung Ihrer jeweiligen Studie genannte Adresse.
  </p>

  <h3>8. Ihre Rechte als Betroffener</h3>

  <p>Zum Schutz Ihrer personenbezogenen Daten stehen Ihnen folgende Rechte zu:</p>

  <ul>
    <li>Besuchte Seite auf unserer Domain</li>
    <li>Browsertyp und Browserversion</li>
    <li>Verwendetes Betriebssystem Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Datum und Uhrzeit der Serveranfrage</li>
    <li>IP-Adresse</li>

    <li>Ihre Einwilligung widerrufen (Art. 7 Abs. 3 DSGVO),</li>
    <li>Auskunft über die Sie betreffenden personenbezogenen Daten zu erhalten (Art. 15 DSGVO),</li>
    <li>unrichtige Daten berichtigen zu lassen (Art. 16 DSGVO),</li>
    <li>
      unter bestimmten Voraussetzungen die Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen (Art. 17,
      18 DSGVO),
    </li>
    <li>Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen (Art. 21 DSGVO),</li>
    <li>Ihre Daten zu erhalten und an andere von Ihnen bestimmte Stellen übertragen (Art. 20 DSGVO),</li>
    <li>eine Beschwerde einreichen (Art. 77 DSGVO)</li>
  </ul>

  <p>
    Sie haben das Recht, sich an die zuständige Aufsichtsbehörde für den Datenschutz zu wenden, wenn Sie der Ansicht sind, dass die
    Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt. Die für uns zuständige Aufsichtsbehörde ist der Landesbeauftragte
    für Datenschutz und Informationsfreiheit Baden-Württemberg.
  </p>

  <p>
    Weitere Informationen und Ansprechpartner:innen hierzu finden Sie in den Teilnahmeinformationen bzw. der Einwilligungserklärung der
    jeweiligen Studie, an der Sie ggf. teilnehmen.
  </p>
</div>
