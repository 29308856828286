import { Component, Input, OnInit } from '@angular/core';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faBookMedical } from '@fortawesome/free-solid-svg-icons/faBookMedical';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';

@Component({
  selector: 'app-card-diary',
  templateUrl: './card-diary.component.html',
  styleUrls: ['./card-diary.component.scss']
})
export class CardDiaryComponent implements OnInit {
  // Icons
  faBook = faBook;
  faInfoCircle = faInfoCircle;
  faBookMedical = faBookMedical;

  public diary: DiaryInterface;

  public diaryInstance: DiaryInstanceInterface;

  public users: Array<UserInterface> = [];

  constructor(private helperService: HelperService, private helperDialogService: HelperDialogService) {}

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _diary(_diary: DiaryInterface) {
    if (_diary) {
      this.diary = _diary;
    }
  }

  @Input()
  set _diaryInstance(_diaryInstance: DiaryInstanceInterface) {
    if (_diaryInstance) {
      this.diaryInstance = _diaryInstance;
    }
  }

  @Input()
  set _users(_users: Array<UserInterface>) {
    if (_users) {
      this.users = _users;
    }
  }

  ngOnInit(): void {}

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string): void {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor, this.diary)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogAnswersheetPreview(diary_instance_id: number, diary: DiaryInterface): void {
    this.helperDialog
      .openDialogDiaryAnswersheetPreview(diary_instance_id, diary)
      .afterClosed()
      .subscribe(result => {});
  }
}
