<div class="container-fluid text-center">
  <div class="col-sm-8 text-start mb-3">
    <h2>{{'intervention-skill.skill' | translate}}</h2>
    <hr />
  </div>

  <div class="mb-4 text-start">
    <div *ngIf="isLoading$ | async; else elseSkillBlock" class="spinnerPosition">
      <app-loader></app-loader>
    </div>

    <ng-template #elseSkillBlock>
      <div *ngIf="(skillsOfIntervention$ | async)?.length === 0; else elseShowSkillsBlock">
        <p class="centering">{{ 'intervention-skill.no_skills_found' | translate }}</p>
      </div>

      <!-- Table -->
      <ng-template #elseShowSkillsBlock>
        <div #containerRef class="row card-body">
          <div class="col-4"></div>
          <div class="col-4">
            <canvas #canvas id="canvas">{{chart}}</canvas>
          </div>
          <div class="col-4"></div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
