/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { distinctUntilChanged, mergeMap, skip, take, filter } from 'rxjs/operators';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InvitationInterface } from '../../../models/interface/invitation.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { PaginationListComponent } from '../../utils/pagination-list/pagination-list.component';

/**
 * Component:
 * Group members page displaying a list of members of the group;
 * Can be found: {uri}/groups/{{group_id}}/members
 */

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.scss'],
  providers: [InterventionInstanceStore, StudyStore]
})
export class GroupMemberComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: PaginationListComponent;

  // Icons
  faInfoCircle = faInfoCircle;
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faEdit = faEdit;
  faPlus = faPlus;

  public study: StudyInterface;
  public isLoading$: Observable<boolean>;
  public pendingInvitations: Array<InvitationInterface> = [];
  public pendingInvitationsCode: Array<string> = [];

  // Data provided by UserService
  public allMyPatients: Array<UserInterface>;

  public instances: Array<InterventionInstanceInterface> = [];

  // Filter
  public filter = {
    searchFilter: '',
  };

  // Translation for study name
  public param = { intervention_name: '...' };

  public members$: Observable<Array<UserInterface>>;
  public pagedMembers$: Observable<Array<UserInterface>>;
  public isECoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;

  public isCollapse$: Observable<boolean>;

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public selectedLanguage = localStorage.getItem('language');

  private membersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Data provided by StudyService
  private studyMembers: Array<UserInterface>;

  private searchTextSubject = new BehaviorSubject<string>('');
  private pagedMembersSubject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject<Array<UserInterface>>([]);

  private allMyInstancesOfStudy$: Observable<Array<InterventionInstanceInterface>>;
  private myMembers$: Observable<Array<UserInterface>>;
  private studyMembers$: Observable<Array<UserInterface>>;
  private studyInvitationsECoach$: Observable<Array<InvitationInterface>>;

  private addMembersResponse$: Observable<any>;

  private isECoachSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private interventionInstanceStore: InterventionInstanceStore,
    private studyStore: StudyStore,
    private helperDialogService: HelperDialogService,
    private actions$: Actions,
    private store: Store<{ myMembers: Array<UserInterface> }>,
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.members$ = this.membersSubject.asObservable();
    this.pagedMembers$ = this.pagedMembersSubject.asObservable();
    this.isECoach$ = this.isECoachSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.allMyInstancesOfStudy$ = this.interventionInstanceStore.allMyInstancesOfStudy$;
    this.studyMembers$ = this.studyStore.studyMembers$;
    this.addMembersResponse$ = this.studyStore.addMembersResponse$;
    this.studyInvitationsECoach$ = this.studyStore.studyInvitationsECoach$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter(value => value !== null),
          take(1),
          mergeMap((value: StudyInterface) => {
            this.study = value;
            this.param = { intervention_name: value.attributes.name };
            this.interventionInstanceStore.getMyInstancesOfStudy({ studyId: this.study.id, pendingInvitation: true });
            return this.allMyInstancesOfStudy$.pipe(skip(1), take(1));
          }),
          mergeMap((instances: Array<InterventionInstanceInterface>) => {
            this.instances = instances;
            const reqs: Array<Observable<any>> = [];
            this.store.dispatch({
              type: StudyActionTypes.getCollaboratingStudiesType,
              payload: { studyId: this.study.id, include: 'roles' }
            });
            reqs.push(
              this.actions$.pipe(
                ofType(StudyActionTypes.getCollaboratingStudiesSuccessType, StudyActionTypes.getCollaboratingStudiesErrorType),
                take(1),
                mergeMap(
                  (res: any) =>
                    iif(() => res.type === StudyActionTypes.getCollaboratingStudiesSuccessType, of(res), throwError(() => res)),
                ),
              )
            );
            this.studyStore.getECoachMembers({ studyId: this.study.id });
            reqs.push(this.studyMembers$.pipe(skip(1), take(1)));

            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {}
            });
            reqs.push(this.myMembers$.pipe(skip(1), take(1)));
            return forkJoin(reqs);
          }),
          mergeMap((results: any[]) => {
            const study: StudyInterface = results[0]['response']['body']['data'][0];
            this.studyMembers = results[1];
            this.allMyPatients = results[2];
            const myroleSlug = this.helper.getHighestRoleOfStudy(study);
            if (myroleSlug.match(/study\.(ecoach|collaborator|ecoachmanager|owner)$/)) {
              this.isECoachSubject.next(true);
            }

            if (myroleSlug.match(/study\.(ecoachmanager|owner)$/)) {
              this.isManagerSubject.next(true);
            }

            if (this.isManagerSubject.value) {
              this.studyStore.getAllPendingStudyInvitations({ studyId: this.study.id });
            } else {
              this.studyStore.getEcoachStudyInvitations({ studyId: this.study.id });
            }
            return this.studyInvitationsECoach$.pipe(skip(1), take(1));
          }),
        )
        .subscribe({
          next: (result: InvitationInterface[]) => {
            this.pendingInvitations = result;
            this.pendingInvitationsCode = result.map((invitation) => invitation.attributes.code);
            this.applyMemberFilter();
          },
          error: () => this.applyMemberFilter(),
        }),
    );
  }

  getPatientOverview(): void {
    this.router.navigateByUrl('/patients');
  }

  public applyMemberFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadMemberList(true).subscribe((_result: boolean) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadMemberList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator.paginator.pageSize : 20;
    if (this.isManagerSubject.value) {
      this.studyStore.getMembers({ studyId: this.study.id });
      return this.studyMembers$.pipe(
        skip(1),
        take(1),
        mergeMap(result => {
          this.studyMembers = result;
          return this.search(this.filter['searchFilter']).pipe(
            mergeMap(() => this.helper.setPagedContent(this.membersSubject, this.pagedMembersSubject, setFirst, pageIndex, pageSize)),
          );
        })
      );
    } else {
      let myMemberIds: number[] = [];
      this.store.dispatch({
        type: UserActionTypes.getMyMembersType,
        payload: {},
      });
      return this.myMembers$.pipe(
        skip(1),
        take(1),
        mergeMap((result: UserInterface[]) => {
          myMemberIds = result.map((user) => user.id);
          this.studyStore.getECoachMembers({ studyId: this.study.id });
          return this.studyMembers$.pipe(skip(1), take(1));
        }),
        mergeMap((result: UserInterface[]) => {
          this.studyMembers = result.filter((user) => myMemberIds.includes(user.id));
          return this.search(this.filter['searchFilter']).pipe(
            mergeMap(() => this.helper.setPagedContent(this.membersSubject, this.pagedMembersSubject, setFirst, pageIndex, pageSize)),
          );
        }),
      );
    }
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadMemberList().subscribe());
  }

  public resendStudyInvitation(studyId: number, email: string, invitationId: number): void {
    if (this.isManagerSubject.value) {
      this.studyStore.resendStudyInvitationEM({ studyId, invitationsParam: [invitationId] });
    } else {
      const payloadStudy = {
        data: {
          type: 'users',
          attributes: {
            users: [
              {
                email
              }
            ]
          }
        }
      };
      this.studyStore.addMembers({ studyId, payload: payloadStudy, language: this.selectedLanguage });
    }
    this.addMembersResponse$.pipe(skip(1), take(1)).subscribe(() => {});
  }

  public hasPendingInvitation(user: UserInterface): boolean {
    const found: InvitationInterface = this.pendingInvitations.find(
      (invitation: InvitationInterface) => invitation.attributes.email.toString() === user.attributes.email.toString()
    );
    return !!found;
  }

  public getDetailedPatient(id?: number): void {
    this.router.navigateByUrl(`/patients/${id}`);
  }

  public updatePagedMembers(event: any) {
    if (event) {
      this.pagedMembersSubject.next(event);
    }
  }

  public getInvitationByEmail(email: string): InvitationInterface {
    const found = this.pendingInvitations.find(
      (invitation: InvitationInterface) => invitation.attributes.email.toString() === email.toString()
    );
    return found ? found : null;
  }

  public openDialogGroupInvitationCheck(): void {
    if (!this.isLoadingSubject.value) {
      this.helperDialog
        .openDialogGroupInvitationCheck(this.isManagerSubject, this.study)
        .afterClosed()
        .subscribe(_result => {});
    }
  }

  public openDialogGroupStudyCodeUpdate(user: UserInterface): void {
    this.helperDialog
      .openDialogGroupStudyCodeUpdate(user, this.study.id)
      .afterClosed()
      .pipe(mergeMap((_value) => this.reloadMemberList()))
      .subscribe(() => {});
  }

  public openDialogGroupMemberRemove(user: UserInterface): void {
    this.helper
      .getCodeOfUserByStudy(this.study.id, user)
      .pipe(
        mergeMap((value: string) =>
          this.helperDialog
            .openDialogGroupMemberRemove(user, this.isManagerSubject, this.study.id, value ? value : '', this.param)
            .afterClosed()
        ),
        mergeMap((_value) => this.reloadMemberList())
      )
      .subscribe(() => {});
  }

  public trackByUserId(_index: number, element: UserInterface): number {
    return element.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  // Reads search value and runs searchFilter
  private search(name: string): Observable<boolean> {
    this.searchTextSubject.next(name);
    return this.searchTextSubject.pipe(
      distinctUntilChanged(),
      mergeMap(() => {
        this.membersSubject.next(
          this.helper.filterUsersBy(this.studyMembers, this.filter['searchFilter'].toLowerCase().trim(), this.study.id)
        );
        return of(true);
      })
    );
  }
}
