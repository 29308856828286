import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, skip, take, tap } from 'rxjs';
import { AccessCodeBatchInterface } from 'src/app/models/interface/access-code-batch.interface';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StudyStore } from 'src/app/store/study/component-store/study.store';

@Component({
  selector: 'app-access-code-batch-invalidation',
  templateUrl: './access-code-batch-invalidation.component.html',
  styleUrls: ['./access-code-batch-invalidation.component.scss'],
  providers: [StudyStore]
})
export class AccessCodeBatchInvalidationComponent {
  @Output()
  public emitInvalidationPerformed: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faBan = faBan;

  public batch: AccessCodeBatchInterface;

  // Response
  public invalidateAccessCodeBatchResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  private invalidateBatchResponse$: Observable<any>;

  constructor(private studyStore: StudyStore, private helperService: HelperService) {
    this.invalidateBatchResponse$ = this.studyStore.invalidateBatchResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  @Input()
  set _result(_result: AccessCodeBatchInterface) {
    if (_result) {
      this.batch = _result;
    }
  }

  ngOnInit(): void {}

  public invalidateAccessCodeBatch(): void {
    const batchId = this.batch.id;
    this.invalidateAccessCodeBatchResponse.next('LOADING');
    this.studyStore.invalidateAccessCodeBatch({ batchId });
    this.invalidateBatchResponse$.pipe(skip(1), take(1)).subscribe(
      (result: any) => {
        if (result instanceof HttpErrorResponse) {
          this.invalidateAccessCodeBatchResponse.next('FAILURE');
          setTimeout(() => {
            this.invalidateAccessCodeBatchResponse.next('DEFAULT');
          }, 2500);
        } else {
          this.invalidateAccessCodeBatchResponse.next('SUCCESS');
          this.emitInvalidationPerformed.emit(result);
          setTimeout(() => {
            this.invalidateAccessCodeBatchResponse.next('DEFAULT');
          }, 2500);
        }
      },
      () => {
        this.invalidateAccessCodeBatchResponse.next('FAILURE');
        setTimeout(() => {
          this.invalidateAccessCodeBatchResponse.next('DEFAULT');
        }, 2500);
      },
      () => {
        setTimeout(() => {
          this.invalidateAccessCodeBatchResponse.next('DEFAULT');
        }, 2500);
      }
    );
  }
}
