/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { filter, mergeMap, skip, take } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { RequestBodyData } from '../../../models/request-body.data';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { InterventionConfigurationInterface } from '../../../models/interface/intervention-configuration.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { Store } from '@ngrx/store';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { StudyActionTypes } from '../../../store/study/study.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { getCollabInterventionById } from '../../../store/intervention/intervention.selector';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { UserStore } from '../../../store/user/component-store/user.store';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-instance-update-form',
  templateUrl: './instance-update-form.component.html',
  styleUrls: ['./instance-update-form.component.scss'],
  providers: [DiaryStore, InterventionInstanceStore, QuestionnaireStore, SkillStore, UserStore]
})
export class InstanceUpdateFormComponent implements OnInit, OnDestroy {
  // Emit payload of instance to parent component
  @Output()
  confirmedForm = new EventEmitter<PayloadInterface>();

  // Icon
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faHandsHelping = faHandsHelping;
  faHands = faHands;
  faCheck = faCheck;
  faTimes = faTimes;
  faBook = faBook;
  faStar = faStar;

  // Intervention and its default configurations
  public intervention: InterventionInterface;
  public startsAt;

  // Element id
  public elementId: string;

  // Created instance
  public instance: InterventionInstanceInterface;

  // Questionnaires of study
  public questionnaires: Array<LessonInterface> = [];

  // Skills of intervention
  public skills: Array<SkillInterface> = [];

  // Diary
  public diaries: Array<DiaryInterface> = [];
  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  // MyUserId
  public myUserId: number;

  // Form select options
  public studyMembers: Array<UserInterface> = [];
  public studyCollaborator: Array<UserInterface> = [];
  public selectableCollaborators: Array<UserInterface> = [];
  public unlockType: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];
  public unlockTypeFirst: Array<string> = ['ALWAYS', 'AT_DATE'];
  public unlockTypeNext: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE'];
  public unlockTypeNew: Array<string> = ['ALWAYS', 'MANUALLY', 'AFTER_PREVIOUS', 'AT_DATE', 'CONDITIONAL'];
  public positionType: Array<string> = ['-1'];

  // Locked questionnaires (orders cannot be changed)
  public lockedQuestionnaireIds = [];

  public intervention$: Observable<InterventionInterface | undefined>;

  public isLoading: boolean;

  // Instance creation studyForm
  public instanceFormType: UntypedFormGroup;
  public currentDate = this.helperService.formatDateToYYYY_MM_DD_THH_MM(Date.now());

  // Payload for instance
  public payload: PayloadInterface;

  public updateView: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Subject and Observable
  public formCustomOrderSubject = new BehaviorSubject<Array<{ questionnaire: number; enabled: boolean }>>([]);
  public formCustomOrder$ = this.formCustomOrderSubject.asObservable();

  public guidanceType = ['UNGUIDED', 'GUIDED'];

  public updateECoachResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Data from StudyService
  public collaborators: Array<UserInterface>;

  public isManager$: Observable<boolean>;

  // Selected guiding type
  public selectedGuidanceType = 'UNGUIDED';

  public positionSubject: BehaviorSubject<Array<boolean>> = new BehaviorSubject<Array<boolean>>([]);

  public skillsOfIntervention$: Observable<SkillInterface[]>;

  // Ngx datetimepicker
  public showSpinners = false;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  private defaultConfiguration: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };
  private defaultConfigurationReadOnly: {
    questionnaire_configuration: Array<InterventionConfigurationInterface>;
    custom_order: Array<number>;
  };

  private instanceReadOnly: InterventionInstanceInterface;

  private instance$: Observable<InterventionInstanceInterface>;

  private questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  private addEcoachesToInterventionInstanceResponse$: Observable<any>;
  private deleteEcoachesToInterventionInstanceResponse$: Observable<any>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private selectedIsInterventionTypeSubject = new BehaviorSubject<string>('UNGUIDED');

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private helperService: HelperService,
    private interventionInstanceStore: InterventionInstanceStore,
    private diaryStore: DiaryStore,
    private questionnaireStore: QuestionnaireStore,
    private skillStore: SkillStore,
    private userStore: UserStore,
    private store: Store<{
      skills: Array<SkillInterface>;
      getCollabInterventionById: InterventionInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.isManager$ = this.isManagerSubject.asObservable();
    // Form type contains searchFilter options
    this.instanceFormType = this.formBuilder.group({
      collaborator: [null, Validators.required],
      patient: ['', Validators.required],
      intervention_type: [{ value: '', disabled: true }, Validators.required],
      starts_at: ['', Validators.required],
      questionnaire_configuration: this.formBuilder.array([]),
      collaboratorList: new UntypedFormArray([])
    });
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.instance$ = this.interventionInstanceStore.instance$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
    this.addEcoachesToInterventionInstanceResponse$ = this.userStore.addEcoachesToInterventionInstanceResponse$;
    this.deleteEcoachesToInterventionInstanceResponse$ = this.userStore.deleteEcoachesToInterventionInstanceResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  get f() {
    return this.instanceFormType.controls;
  }

  public get fCollaboratorList(): UntypedFormArray {
    return this.instanceFormType.get('collaboratorList') as UntypedFormArray;
  }

  public get fQuestionnaireConfiguration() {
    return this.instanceFormType.get('questionnaire_configuration') as UntypedFormArray;
  }

  @Input()
  set _id(_id) {
    this.elementId = _id;
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    this.intervention = _intervention;
    this.defaultConfiguration = this.intervention.attributes.default_configuration;
    this.defaultConfigurationReadOnly = JSON.parse(JSON.stringify(_intervention.attributes.default_configuration));
  }

  @Input()
  set _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  @Input()
  set _collaborators(_collaborators: Array<UserInterface>) {
    if (_collaborators) {
      this.studyCollaborator = _collaborators;
    }
  }

  @Input()
  set _instance(_instance: InterventionInstanceInterface) {
    this.instance = _instance;
    this.setMinDate();
  }

  @Input()
  set _questionnaires(_questionnaires: Array<LessonInterface>) {
    if (_questionnaires !== undefined) {
      this.questionnaires = _questionnaires;
      this.questionnaires.forEach((questionnaire, index) => {
        this.positionType.push(index.toString());
      });
      this.setInitialQuestionnaireConfigControl(this.questionnaires);
      this.setInstanceQuestionnaireConfig();
    }
  }

  @Input()
  set _myUserId(_myUserId: number) {
    this.myUserId = _myUserId;
  }

  ngOnInit(): void {
    // Role per study
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratorsType,
      payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
    });

    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
    this.subscriptions.push(
      this.collaboratorsOfStudy$.pipe(skip(1), take(1)).subscribe(
        (result: any) => {
          this.collaborators = this.helper.excludeUsersWithOnlyStudyAccess(result.collaborators);
          // Determine my highest role
          const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.myUserId.toString());
          if (myself !== undefined) {
            const myroleSlug = this.helperService.getHighestRoleOfCollaborator(myself);
            this.isManagerSubject.next(!!myroleSlug.match(/study\.(ecoachmanager|owner)$/));
          }

          this.addCheckboxes();
        },
        () => {
          this.isManagerSubject.next(false);
        }
      )
    );

    this.subscriptions.push(
      this.isLoadingSubject.subscribe((value: boolean) => {
        this.isLoading = value;
      })
    );
    this.isLoadingSubject.next(true);
    this.interventionInstanceStore.getInstance(this.instance.id);
    this.subscriptions.push(
      this.instance$
        .pipe(
          filter(value => !!value),
          take(1)
        )
        .subscribe((result: InterventionInstanceInterface) => {
          this.instance = result;
          this.instanceReadOnly = JSON.parse(JSON.stringify(this.instance));
          this.getProgress();
          this.currentDate = this.helperService.formatDateToYYYY_MM_DD_THH_MM(this.instance.attributes.configuration.starting_at * 1000);

          // Set values and date for group creation studyForm
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
        })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.setMinDate();
        this.subscriptions.push(this.setQuestionnaireConfiguration(this.intervention.id));
      })
    );
    this.setMinDate();

    // Diary
    this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
    this.subscriptions.push(
      this.diariesOfStudy$.subscribe((result: any) => {
        this.diaries = result;
      })
    );

    this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
    this.subscriptions.push(
      this.skillsOfIntervention$.subscribe(result => {
        this.skills = result;
      })
    );
  }

  // Set questionnaire configuration
  public setQuestionnaireConfiguration(interventionId: number): Subscription {
    this.questionnaireStore.getQuestionnairesIntervention({ interventionId });
    return this.questionnairesOfIntervention$.pipe(skip(1), take(1)).subscribe((result: any) => {
      this.questionnaires = this.helper.sortLessonsAscending(result);
      this.questionnaires.forEach((questionnaire, index) => {
        this.positionType.push(index.toString());
      });
      this.setInitialQuestionnaireConfigControl(this.questionnaires);
      this.setInstanceQuestionnaireConfig();
      this.positionSubject = new BehaviorSubject<Array<boolean>>(new Array(this.fQuestionnaireConfiguration.length).fill(false));
      this.updateView.next('setQuestionnaireConfiguration');
    });
  }

  resetQuestionnaireConfig() {
    // Custom order from instance
    this.formCustomOrderSubject.next(
      this.instance.attributes.configuration.custom_order
        .map(id => parseInt(id.toString(), 10))
        .map((x: number) => ({ questionnaire: x, enabled: true }))
    );

    // Set collaborator, patient, starts_at and intervention_type from Instance
    const collaborators = this.getMultipleCollaboratorsFromInstance();
    this.instanceFormType.controls['collaborator'].patchValue(collaborators, { onlySelf: true });
    this.instanceFormType.controls['patient'].patchValue(
      this.helperService.findArrObjById(this.instance.attributes.patient_id, this.studyMembers),
      { onlySelf: true }
    );
    this.instanceFormType.controls['starts_at'].patchValue(
      this.helperService.formatDateToYYYY_MM_DD_THH_MM(this.instance.attributes.configuration.starting_at * 1000)
    );
    this.instanceFormType.controls['intervention_type'].patchValue(this.instance.attributes.intervention_type.toUpperCase());

    this.questionnaires.forEach((questionnaire: LessonInterface, i: number) => {
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        index => index.questionnaire.toString() === questionnaire.id.toString()
      );
      const found = foundIndex > -1;

      // If no configuration found in instance then get default configuration of intervention
      let interventionQuestionnaireConfig: InterventionConfigurationInterface = null;
      let instanceQuestionnaireConfig: InterventionInstanceConfigurationInterface = null;
      let unlockType: string;
      let unlockDaysAfterStart: number;
      let feedbackRequired: boolean;
      let unlockDate: Date;

      const hasInstanceConfig =
        this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        ) !== null;
      if (hasInstanceConfig) {
        instanceQuestionnaireConfig = this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, instanceQuestionnaireConfig.unlock_type).toUpperCase();
        unlockDaysAfterStart = this.calculateUnlockDaysAfterStart(questionnaire.id, this.instance);
        feedbackRequired = instanceQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      } else {
        interventionQuestionnaireConfig = this.getDefaultConfigurationOfQuestionnaire(
          questionnaire.id,
          this.defaultConfigurationReadOnly.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, interventionQuestionnaireConfig.unlock_type).toUpperCase();
        unlockDaysAfterStart = interventionQuestionnaireConfig.unlock_days_after_start;
        feedbackRequired = interventionQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      }

      this.editQuestionnaireConfiguration(
        questionnaire,
        i,
        this.formCustomOrderSubject.value,
        unlockType,
        unlockDaysAfterStart,
        unlockDate,
        feedbackRequired
      );

      if (!found) {
        const added = this.formCustomOrderSubject.value;
        added.push({ questionnaire: parseInt(questionnaire.id.toString(), 10), enabled: found });
        this.formCustomOrderSubject.next(added);
      }
    });
    this.sortByDefaultCustomOrderPosition();
    this.updateView.next('resetQuestionnaireConfig');
  }

  // Group creation studyForm check intervention_type
  public showInterventionType(value: string): void {
    if (this.selectedGuidanceType === 'GUIDED' && value === 'UNGUIDED') {
      this.fQuestionnaireConfiguration.controls.forEach(control => {
        if (control.get('unlock_type').value === 'MANUALLY') {
          control.get('unlock_type').patchValue('AFTER_PREVIOUS', { onlySelf: true });
        }
        control.get('feedback_required').patchValue(false, { onlySelf: true });
      });
    }
    this.instanceFormType.controls['intervention_type'].patchValue(this.selectedGuidanceType, { onlySelf: true });
    this.selectedIsInterventionTypeSubject.next(value);
    this.updateView.next('showInterventionType');
  }

  // Submit parameters required to create a group
  public confirmInstanceSettings(): boolean {
    if (this.isInvalidInstanceForm()) {
      const collabIds: Array<number> = this.instanceFormType
        .get('collaboratorList')
        .value.map((v, i) => (v ? this.selectableCollaborators[i].id : null))
        .filter(v => v !== null)
        .map(id => parseInt(id, 10));
      let interventionType = '';

      const startsAt = this.helperService.convertStringToUnixTimestamp(this.f.starts_at.value);

      switch (this.f.intervention_type.value) {
        case 'UNGUIDED': {
          interventionType = 'Unguided';
          break;
        }
        case 'GUIDED': {
          interventionType = 'Guided';
          break;
        }
        default:
          break;
      }

      const customConfig: Array<InterventionInstanceConfigurationInterface> = [];
      const customOrder: Array<number> = this.formCustomOrderSubject.value
        .filter(x => x.enabled)
        .map((x: { questionnaire: number; enabled: boolean }) => x.questionnaire);

      // Configuration
      this.fQuestionnaireConfiguration.controls.forEach(formcontrol => {
        const configBody = {
          id: parseInt(formcontrol.get('questionnaire_id').value, 10),
          unlock_type: formcontrol.get('unlock_type').value.toLowerCase(),
          unlock_date: this.helperService.convertStringToUnixTimestamp(new Date(formcontrol.get('unlock_date').value).toUTCString()),
          feedback_required: formcontrol.get('feedback_required').value,
          condition: this.getDefaultConditional(parseInt(formcontrol.get('questionnaire_id').value, 10)),
          bonus_lesson: this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10))
        };

        if (formcontrol.get('unlock_type').value.toLowerCase() !== 'conditional') {
          delete configBody.condition;
        }

        if (
          formcontrol.get('unlock_type').value.toLowerCase() !== 'conditional' ||
          this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10)) === undefined ||
          this.getDefaultBonusLesson(parseInt(formcontrol.get('questionnaire_id').value, 10)) === null
        ) {
          delete configBody.bonus_lesson;
        }

        // Only add the configuration are needed for the custom_order to custom_config
        if (customOrder.includes(parseInt(formcontrol.get('questionnaire_id').value, 10))) {
          customConfig.push(configBody);
        }
      });

      const payload: PayloadInterface = new RequestBodyData('intervention_instance', {
        ecoach_ids: collabIds,
        patient_id: this.instance.attributes.patient_id,
        intervention_id: this.intervention.id,
        intervention_type: interventionType,
        configuration: {
          questionnaire_configuration: customConfig,
          custom_order: customOrder,
          // custom_order: newCustomOrder,
          starting_at: startsAt
        }
      });
      this.payload = payload;
      this.confirmedForm.emit(payload);
      return true;
    } else {
      return false;
    }
  }

  // TODO Check instance studyForm for inconsistency
  public isInvalidInstanceForm(): boolean {
    return true;
  }

  public replaceCurrentPosition(questionnaireId: string): string {
    const foundIndex = this.formCustomOrderSubject.value
      .filter((x: { questionnaire: number; enabled: boolean }) => x.enabled)
      .map((x: { questionnaire: number; enabled: boolean }) => x.questionnaire)
      .findIndex((x: number) => x.toString() === questionnaireId);

    if (foundIndex.toString() === '-1') {
      return 'instance-update-form.form_create_instance_pos_not_selected';
    } else {
      return 'Position ' + (foundIndex + 1).toString();
    }
  }

  public getOtherCollaborators(studyCollaborators: Array<UserInterface>): Array<UserInterface> {
    const index = studyCollaborators.findIndex(user => user.id === this.myUserId);
    const remainingCollaborators = [];
    studyCollaborators.forEach(user => {
      remainingCollaborators.push(user);
    });
    if (index > -1) {
      remainingCollaborators.splice(index, 1);
    }
    return remainingCollaborators;
  }

  public sortByDefaultCustomOrderPosition(): void {
    this.fQuestionnaireConfiguration.controls.sort((a, b) => {
      if (a.get('current_enabled').value && b.get('current_enabled').value) {
        const indexA = a.get('current_position').value.toString();
        const indexB = b.get('current_position').value.toString();
        return indexA - indexB;
      } else if (!a.get('current_enabled').value && b.get('current_enabled').value) {
        return 1;
      } else if (a.get('current_enabled').value && !b.get('current_enabled').value) {
        return -1;
      } else {
        const questionnaireA = a.get('questionnaire_id').value.toString();
        const questionnaireB = b.get('questionnaire_id').value.toString();
        return (
          this.questionnaires.find(value => value.id.toString() === questionnaireA.toString()).attributes.position -
          this.questionnaires.find(value => value.id.toString() === questionnaireB.toString()).attributes.position
        );
      }
    });
    this.fQuestionnaireConfiguration.controls.forEach((formControl, index) => {
      formControl.get('current_position').patchValue(index.toString(), { onlySelf: true });
    });
    const controls: AbstractControl[] = this.fQuestionnaireConfiguration.controls;
    const formCustom: Array<{ questionnaire: number; enabled: boolean }> = [];
    controls.forEach((abc: AbstractControl) => {
      formCustom.push({ questionnaire: parseInt(abc.get('questionnaire_id').value, 10), enabled: abc.get('current_enabled').value });
    });
    this.formCustomOrderSubject.next(formCustom);
  }

  public sortByCurrentPosition(): void {
    this.fQuestionnaireConfiguration.controls.sort((a, b) => {
      const indexA = a.get('current_position').value.toString();
      const indexB = b.get('current_position').value.toString();
      return indexA - indexB;
    });
  }

  public getProgress(): void {
    this.lockedQuestionnaireIds = this.instance.attributes.progress.finished_questionnaires;
  }

  public lockPosition(formcontrol: AbstractControl): boolean {
    if (formcontrol) {
      const questionnaireId = formcontrol.get('questionnaire_id').value;
      const found = this.lockedQuestionnaireIds.find(id => id.toString() === questionnaireId.toString());
      return found !== undefined;
    }
    return false;
  }

  public isFirstNotLocked(questionnaireId: number): boolean {
    let idNotLocked = null;
    for (const value of this.formCustomOrderSubject.value) {
      const found = this.lockedQuestionnaireIds.find(id => id.toString() === value.toString());
      if (!found) {
        idNotLocked = value;
        break;
      }
    }
    if (idNotLocked) {
      return questionnaireId.toString() === idNotLocked.toString();
    }
    return false;
  }

  public getRecalculatedUnlockDate(questionnaireId: number, unlockDaysAfterStart: number): Date {
    const instanceQuestionnaireConfig = this.getInstanceConfigurationOfQuestionnaire(
      questionnaireId,
      this.instance.attributes.configuration.questionnaire_configuration
    );

    if (
      instanceQuestionnaireConfig !== null &&
      this.helper.convertUnixTimestampToString(instanceQuestionnaireConfig.unlock_date) !== 'NO_TIMESTAMP_FOUND'
    ) {
      return new Date(instanceQuestionnaireConfig.unlock_date * 1000);
    } else {
      return new Date(
        this.helperService.convertStringToUnixTimestamp(this.f['starts_at'].value) + Math.abs(unlockDaysAfterStart) * 24 * 60 * 60 * 1000
      );
    }
  }
  public updateECoaches(): void {
    this.updateECoachResponse.next('LOADING');

    const removeIds = [];
    const addIds = [];
    const oldly = this.instance.attributes.ecoach_id;
    const newly = this.instanceFormType
      .get('collaboratorList')
      .value.map((v, i) => (v ? this.selectableCollaborators[i].id : null))
      .filter(v => v !== null)
      .map(id => parseInt(id, 10));
    oldly.forEach(id => {
      const found = newly.find(foundid => foundid.toString() === id.toString());
      if (found === undefined) {
        removeIds.push(parseInt(id.toString(), 10));
      }
    });
    newly.forEach(id => {
      const found = oldly.find(foundid => foundid.toString() === id.toString());
      if (found === undefined) {
        addIds.push(parseInt(id.toString(), 10));
      }
    });
    const reqs: Array<Observable<any>> = [];

    if (addIds.length !== 0) {
      const payloadAdd: PayloadInterface = new RequestBodyData('intervention_instance', { ecoach_ids: addIds });
      this.userStore.addEcoachesToInterventionInstance({ instanceId: this.instance.id, payload: payloadAdd });
      reqs.push(
        this.addEcoachesToInterventionInstanceResponse$.pipe(
          skip(1),
          take(1),
          mergeMap(result => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
        )
      );
    }
    if (removeIds.length !== 0) {
      const payloadRemove: PayloadInterface = new RequestBodyData('intervention_instance', { ecoach_ids: removeIds });
      this.userStore.deleteEcoachesToInterventionInstance({ instanceId: this.instance.id, payload: payloadRemove });
      reqs.push(
        this.deleteEcoachesToInterventionInstanceResponse$.pipe(
          skip(1),
          take(1),
          mergeMap(result => iif(() => result instanceof HttpResponse, of(result), throwError(result)))
        )
      );
    }

    const resp = reqs.length === 2 ? reqs[0].pipe(mergeMap(() => reqs[1])) : forkJoin(reqs);
    this.subscriptions.push(
      resp.subscribe(
        () => {
          this.updateECoachResponse.next('SUCCESS');
        },
        () => {
          this.updateECoachResponse.next('FAILURE');
          setTimeout(() => {
            this.updateECoachResponse.next('DEFAULT');
          }, 2500);
        },
        () => {
          setTimeout(() => {
            this.updateECoachResponse.next('DEFAULT');
          }, 2500);
        }
      )
    );
  }

  public showUnlockHint(questionnaireId: number): Observable<boolean> {
    return this.updateView.pipe(
      mergeMap(() => {
        const foundControl = this.fQuestionnaireConfiguration.controls.find(
          (formcontrol: AbstractControl) => formcontrol.get('questionnaire_id').value.toString() === questionnaireId.toString()
        );
        if (!foundControl) {
          return of(false);
        }
        // Previous position based on form_custom_order
        const currentPos = this.formCustomOrderSubject.value.findIndex(value => value.toString() === questionnaireId.toString());
        // const previous_pos = current_pos - 1;
        const previousPos = currentPos - 1;
        let foundPredecessor;
        if (previousPos >= 0) {
          foundPredecessor = this.fQuestionnaireConfiguration.controls.find(
            (formcontrol: AbstractControl) =>
              formcontrol.get('questionnaire_id').value.toString() === this.formCustomOrderSubject.value[previousPos].toString()
          );
        }
        return iif(
          () => foundPredecessor,
          of(!!(foundControl.get('unlock_type').value === 'AFTER_PREVIOUS' && foundPredecessor.get('feedback_required').value)),
          of(false)
        );
      })
    );
  }

  getUnlockTypeOfFirst(questionnaireId: number, type: string): string {
    return this.isFirstNotLocked(questionnaireId) && type === 'after_previous' ? 'always' : type;
  }

  getAlreadyStarted(questionnaireId: number): number {
    if (this.instance.attributes.progress.active_questionnaires) {
      return this.instance.attributes.progress.active_questionnaires.find(
        (value: number) => value.toString() === questionnaireId.toString()
      );
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public toggleVerification(index: number) {
    const arr: Array<boolean> = this.positionSubject.value;
    arr[index] = !arr[index];
    this.positionSubject.next(arr);
  }

  getIndexOfElement(arr: Array<any>): Array<number> {
    return arr.map((value, index) => index);
  }

  public lockStartsAt(): boolean {
    return (
      this.instance.attributes.progress.active_questionnaires.length > 0 ||
      this.instance.attributes.progress.finished_questionnaires.length > 0
    );
  }

  public hasDefaultConditional(questionnaireId: number): boolean {
    const config: InterventionConfigurationInterface = this.getDefaultConfigurationOfQuestionnaire(
      questionnaireId,
      this.defaultConfigurationReadOnly.questionnaire_configuration
    );
    return config ? config.unlock_type.toLowerCase() === 'conditional' : false;
  }

  public getDefaultConditional(questionnaireId: number) {
    return (
      this.getDefaultConfigurationOfQuestionnaire(
        questionnaireId,
        this.defaultConfigurationReadOnly.questionnaire_configuration
      ) as InterventionConfigurationInterface
    ).condition;
  }

  public getDefaultBonusLesson(questionnaireId: number) {
    return (
      this.getDefaultConfigurationOfQuestionnaire(
        questionnaireId,
        this.defaultConfigurationReadOnly.questionnaire_configuration
      ) as InterventionConfigurationInterface
    ).bonus_lesson;
  }

  // Adding checkboxes on userForm control
  private addCheckboxes() {
    //this.studyCollaborator
    this.selectableCollaborators = this.collaborators.filter(
      user =>
        !!this.helperService.getHighestRoleOfCollaborator(user).match(/^(study.owner|study.ecoachmanager|study.ecoach)$/) ||
        this.helper.isECoachOfInstance(this.instance, user.id)
    );
    this.selectableCollaborators.map((user, i) => {
      const control = new UntypedFormControl();
      (this.instanceFormType.controls.collaboratorList as UntypedFormArray).push(control);
      if (this.helper.isECoachOfInstance(this.instance, user.id)) {
        this.fCollaboratorList.controls[i].setValue('true');
      }
    });
  }

  // Patch each questionnaire configuration in studyForm
  private editQuestionnaireConfiguration(
    questionnaire: LessonInterface,
    index: number,
    customOrder: Array<{ questionnaire: number; enabled: boolean }>,
    unlockType,
    unlockDaysAfterStart,
    unlockDate,
    feedbackRequired
  ): void {
    // Is lesson enabled - in form_custom_order?
    const found = customOrder.find(
      (x: { questionnaire: number; enabled: boolean }) => x.questionnaire.toString() === questionnaire.id.toString()
    );
    const foundIndex = customOrder.findIndex(
      (x: { questionnaire: number; enabled: boolean }) => x.questionnaire.toString() === questionnaire.id.toString()
    );
    this.fQuestionnaireConfiguration.controls[index].get('questionnaire_id').patchValue(questionnaire.id, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('current_position').patchValue(foundIndex.toString(), { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('current_enabled').patchValue(found ? found.enabled : false, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('unlock_type').patchValue(unlockType, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('unlock_days_after_start').patchValue(unlockDaysAfterStart, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('unlock_date').patchValue(unlockDate, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index].get('feedback_required').patchValue(feedbackRequired, { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index]
      .get('condition')
      .patchValue(this.getDefaultConditional(questionnaire.id), { onlySelf: true });
    this.fQuestionnaireConfiguration.controls[index]
      .get('bonus_lesson')
      .patchValue(this.getDefaultBonusLesson(questionnaire.id), { onlySelf: true });
    this.updateView.next('editQuestionnaireConfiguration');
  }

  private calculateUnlockDaysAfterStart(questionnaireId: number, instance: InterventionInstanceInterface): number {
    const customConfiguration: InterventionInstanceConfigurationInterface = this.helperService.findArrObjById(
      questionnaireId,
      instance.attributes.configuration.questionnaire_configuration
    );
    const startingAtTimestamp = instance.attributes.configuration.starting_at;
    const days =
      customConfiguration.unlock_date === null || customConfiguration.unlock_date - startingAtTimestamp < 0
        ? 0
        : (customConfiguration.unlock_date - startingAtTimestamp) / (24 * 60 * 60);
    return Math.round(days);
  }

  private getDefaultConfigurationOfQuestionnaire(
    questionnaireId: number,
    configuration: Array<InterventionConfigurationInterface>
  ): InterventionConfigurationInterface {
    return configuration.find(
      (questionnaire: InterventionConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
  }

  private getInstanceConfigurationOfQuestionnaire(
    questionnaireId: number,
    configuration: Array<InterventionInstanceConfigurationInterface>
  ): InterventionInstanceConfigurationInterface {
    const found = configuration.find(
      (questionnaire: InterventionInstanceConfigurationInterface) => questionnaire.id.toString() === questionnaireId.toString()
    );
    return found ? found : null;
  }

  private setInitialQuestionnaireConfigControl(questionnaires: Array<LessonInterface>): void {
    this.fQuestionnaireConfiguration.setValue([]);
    this.formCustomOrderSubject.next(
      this.defaultConfiguration.custom_order
        .map(id => parseInt(id.toString(), 10))
        .map((x: number) => ({ questionnaire: x, enabled: true }))
    );
    questionnaires.forEach((questionnaire: LessonInterface) => {
      // Is enabled?
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        index => index.questionnaire.toString() === questionnaire.id.toString()
      );
      const found = foundIndex > -1;
      const currentPosition = found ? foundIndex : this.formCustomOrderSubject.value.length;

      // If no configuration found in instance then get default configuration of intervention
      let interventionQuestionnaireConfig: InterventionConfigurationInterface = null;
      let instanceQuestionnaireConfig: InterventionInstanceConfigurationInterface = null;
      let unlockType: string;
      let unlockDaysAfterStart: number;
      let feedbackRequired: boolean;
      let unlockDate: Date;

      const hasInstanceConfig =
        this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        ) !== null;

      if (hasInstanceConfig) {
        instanceQuestionnaireConfig = this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, instanceQuestionnaireConfig.unlock_type).toUpperCase();
        unlockDaysAfterStart = this.calculateUnlockDaysAfterStart(questionnaire.id, this.instance);
        feedbackRequired = instanceQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      } else {
        interventionQuestionnaireConfig = this.getDefaultConfigurationOfQuestionnaire(
          questionnaire.id,
          this.defaultConfigurationReadOnly.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, interventionQuestionnaireConfig.unlock_type.toUpperCase());
        unlockDaysAfterStart = interventionQuestionnaireConfig.unlock_days_after_start;
        feedbackRequired = interventionQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      }

      this.fQuestionnaireConfiguration.push(
        this.formBuilder.group({
          questionnaire_id: [questionnaire.id],
          current_position: [currentPosition.toString()],
          current_enabled: [!!found, Validators.required],
          unlock_type: [unlockType],
          unlock_days_after_start: [unlockDaysAfterStart],
          unlock_date: [unlockDate],
          feedback_required: [feedbackRequired],
          condition: null,
          bonus_lesson: null
        })
      );

      if (!found) {
        const added = this.formCustomOrderSubject.value;
        added.push({ questionnaire: parseInt(questionnaire.id.toString(), 10), enabled: found });
        this.formCustomOrderSubject.next(added);
      }
    });

    this.sortByDefaultCustomOrderPosition();
    this.updateView.next('setInitialQuestionnaireConfigControl');
  }

  private setInstanceQuestionnaireConfig(): void {
    // Custom order from instance
    this.formCustomOrderSubject.next(
      this.instance.attributes.configuration.custom_order
        .map(id => parseInt(id.toString(), 10))
        .map((x: number) => ({ questionnaire: x, enabled: true }))
    );

    // Set collaborator, patient, starts_at and intervention_type from Instance
    const collaborators = this.getMultipleCollaboratorsFromInstance();
    this.instanceFormType.controls['collaborator'].setValue(collaborators, { onlySelf: true });
    this.instanceFormType.controls['patient'].setValue(
      this.helperService.findArrObjById(this.instance.attributes.patient_id, this.studyMembers),
      { onlySelf: true }
    );
    this.instanceFormType.controls['starts_at'].setValue(
      this.helperService.formatDateToYYYY_MM_DD_THH_MM(this.instance.attributes.configuration.starting_at * 1000)
    );
    this.instanceFormType.controls['intervention_type'].setValue(this.instance.attributes.intervention_type.toUpperCase());

    this.questionnaires.forEach((questionnaire: LessonInterface, i: number) => {
      const foundIndex = this.formCustomOrderSubject.value.findIndex(
        index => index.questionnaire.toString() === questionnaire.id.toString()
      );
      const found = foundIndex > -1;

      // If no configuration found in instance then get default configuration of intervention
      let interventionQuestionnaireConfig: InterventionConfigurationInterface = null;
      let instanceQuestionnaireConfig: InterventionInstanceConfigurationInterface = null;
      let unlockType: string;
      let unlockDaysAfterStart: number;
      let feedbackRequired: boolean;
      let unlockDate: Date;

      const hasInstanceConfig =
        this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        ) !== null;
      if (hasInstanceConfig) {
        instanceQuestionnaireConfig = this.getInstanceConfigurationOfQuestionnaire(
          questionnaire.id,
          this.instance.attributes.configuration.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, instanceQuestionnaireConfig.unlock_type).toUpperCase();
        unlockDaysAfterStart = this.calculateUnlockDaysAfterStart(questionnaire.id, this.instance);
        feedbackRequired = instanceQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      } else {
        interventionQuestionnaireConfig = this.getDefaultConfigurationOfQuestionnaire(
          questionnaire.id,
          this.defaultConfigurationReadOnly.questionnaire_configuration
        );
        unlockType = this.getUnlockTypeOfFirst(questionnaire.id, interventionQuestionnaireConfig.unlock_type).toUpperCase();
        unlockDaysAfterStart = interventionQuestionnaireConfig.unlock_days_after_start;
        feedbackRequired = interventionQuestionnaireConfig.feedback_required;
        unlockDate = this.getRecalculatedUnlockDate(questionnaire.id, unlockDaysAfterStart);
      }

      if (!found) {
        const added = this.formCustomOrderSubject.value;
        added.push({ questionnaire: parseInt(questionnaire.id.toString(), 10), enabled: found });
        this.formCustomOrderSubject.next(added);
      }

      this.editQuestionnaireConfiguration(
        questionnaire,
        i,
        this.formCustomOrderSubject.value,
        unlockType,
        unlockDaysAfterStart,
        unlockDate,
        feedbackRequired
      );
    });
    this.sortByDefaultCustomOrderPosition();
    this.updateView.next('setInstanceQuestionnaireConfig');
  }

  private getMultipleCollaboratorsFromInstance(): Array<UserInterface> {
    const collaborators = [];
    if (Array.isArray(this.instance.attributes.ecoach_id)) {
      this.instance.attributes.ecoach_id.forEach((id: number) => {
        const found = this.helperService.findArrObjById(id, this.studyCollaborator);
        if (found !== null) {
          collaborators.push(found);
        }
      });
    }
    return collaborators;
  }

  private setMinDate(): void {
    if (this.instance.attributes.configuration.starting_at * 1000 > new Date().getTime()) {
      this.minDate = new Date();
    } else {
      this.minDate = new Date(this.instance.attributes.configuration.starting_at * 1000);
    }
  }
}
