<div class="container-fluid text-center">
  <div class="text-start">
    <h1>{{ 'conversation.conversations' | translate }}</h1>
    <hr />
  </div>

  <div *ngIf="(isLoading$ | async) === false; else elseLoading" role="modelmain">
    <div class="messaging container">
      <div class="row inbox_msg">
        <div class="col-12 col-lg-4 inbox_people">
          <div class="button-link-padding">
            <button [attr.id]="'btnOpenDialogConversationCreation'" (click)="openDialogConversationCreate()" mat-button>
              {{'conversation.new_conversation' | translate}}
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>

          <mat-form-field [style.width.%]="100" appearance="outline">
            <mat-label>{{'conversation.thread_search' | translate}}</mat-label>
            <input
              [attr.id]="'search'"
              (keyup.enter)="search(filter['searchFilter'])"
              [(ngModel)]="filter['searchFilter']"
              aria-describedby="threadSearch"
              aria-label="Search"
              matInput
              type="text"
            />
            <button [attr.id]="'searchThread'" (click)="search(filter['searchFilter'])" aria-label="Search" mat-button mat-button matSuffix>
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>

          <div class="inbox_chat">
            <mat-selection-list
              [attr.id]="'selectionListThread'"
              #thread
              [multiple]="false"
              [(ngModel)]="selectedThreadsModel"
              (ngModelChange)="selectThread($event)"
            >
              <mat-list-option
                [attr.id]="'listOption_' + thread.id"
                [matTooltip]="showParticipants(thread)"
                class="text-start"
                *ngFor="let thread of (threads$ | async); trackBy: trackByThreadId"
                lines="3"
                [value]="thread"
              >
                <span
                  *ngIf="(thread.attributes.unread.is_unread) && (thread.attributes.messages > 0)"
                  matListItemTitle
                  class="badge badge-info new_message"
                >
                  <fa-icon [icon]="faEnvelope"></fa-icon> {{'conversation.new_messages' | translate}}: {{thread.attributes.unread.messages}}
                </span>
                <span class="break-word" *ngFor="let participant of thread.relationships.participants.data; last as isLast" mat-line>
                  <fa-icon *ngIf="isThreadOwner(participant.id, thread)" [icon]="faStar"></fa-icon>
                  <ng-template
                    *ngIf="helper.findArrObjById(participant.id, thread.relationships.participants.data); then thenShowFound else elseShowNotFound"
                  ></ng-template>
                  <ng-template #thenShowFound
                    >{{helper.findArrObjById(participant.id, thread.relationships.participants.data)?.attributes.email}}</ng-template
                  >
                  <ng-template #elseShowNotFound>{{'conversation.deleted_user' | translate}}</ng-template>
                  <span *ngIf="!isLast">, </span>
                </span>
                <div mat-line>
                  <small> {{helper.localizeDateString(thread.attributes.updated_at)}} </small>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div class="col-12 col-lg-8 mesgs">
          <div #chat class="msg_history">
            <div *ngIf="(threads$ | async).length > 0; then thenShowThread else elseNoConversation"></div>
            <ng-template #thenShowThread>
              <div *ngIf="!messagesFromDetails.length" class="mt-3">
                <p class="text-muted">{{'conversation.no_messages_please_write' | translate}}</p>
              </div>
              <div *ngFor="let message of messagesFromDetails; let l = last" class="message mt-1 mb-2">
                {{ l === true ? scrollToBottom() : null}}
                <div
                  *ngIf="
                  !isMyMessage((profile$ | async)?.id, message.relationships?.author.data.id);
                  then incoming_msg;
                  else outgoing_msg
                "
                ></div>
                <ng-template #incoming_msg>
                  <div [attr.id]="'message_' + message.id" class="incoming_msg ms-1">
                    <div class="incoming_msg_img">
                      <img
                        class="img-thumbnail"
                        src="{{helper.findArrObjById(message.relationships?.author.data?.id, selectedThread.relationships.participants.data)?.attributes.picture}}"
                        (error)="helper.getFallbackImage($event)"
                      />
                    </div>
                    <div class="received_msg">
                      <div class="received_withd_msg">
                        <p class="text-start incoming_msg_user">
                          <small>
                            <ng-template
                              *ngIf="helper.findArrObjById(message.relationships?.author.data?.id, selectedThread.relationships.participants.data); then thenShowFound else elseShowNotFound"
                            ></ng-template>
                            <ng-template #thenShowFound
                              >{{helper.findArrObjById(message.relationships?.author.data?.id,
                              selectedThread.relationships.participants.data)?.attributes.email}}</ng-template
                            >
                            <ng-template #elseShowNotFound>{{'conversation.deleted_user' | translate}}</ng-template>
                          </small>
                        </p>
                        <p class="text-start">{{ message.attributes.body }}</p>
                        <p class="text-end">
                          <small
                            ><em
                              ><fa-icon [icon]="faClock"></fa-icon> {{
                              helper.localizeDateString(helper.convertUnixTimestampToDate(message.attributes.deliver_at)) }}</em
                            ></small
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #outgoing_msg>
                  <div [attr.id]="'message_' + message.id" class="outgoing_msg me-1">
                    <div class="sent_msg">
                      <p class="text-start">{{ message.attributes.body }}</p>
                      <p class="text-end">
                        <small
                          ><em
                            ><fa-icon [icon]="faClock"></fa-icon> {{
                            helper.localizeDateString(helper.convertUnixTimestampToDate(message.attributes.deliver_at)) }}</em
                          ></small
                        >
                      </p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </ng-template>
            <ng-template #elseNoConversation>
              <p class="text-muted mt-3">{{'conversation.no_conversation_found' | translate}}</p>
            </ng-template>
          </div>
          <div class="type_msg">
            <form [formGroup]="commentForm" class="input_msg_write">
              <div class="form-group">
                <mat-form-field class="mt-3 full-width" appearance="outline">
                  <mat-label>{{'conversation.write_message' | translate}}</mat-label>
                  <textarea [attr.id]="'comment'" formControlName="comment" matInput></textarea>
                </mat-form-field>

                <div class="my-3">
                  <app-button-feedback
                    [_setId]="'btnSubmitComment'"
                    (click)="submitComment()"
                    [_defaultButtonText]="'conversation.send_message'"
                    [_responseText]="submitCommentResponse | async"
                  ></app-button-feedback>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseLoading>
    <app-loader></app-loader>
  </ng-template>
</div>
