/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { filter, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons/faBookOpen';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { getCollabInterventionById } from '../../../store/intervention/intervention.selector';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { InterventionActionTypes } from 'src/app/store/intervention/intervention.action';
import { RoleInterface } from 'src/app/models/interface/role.interface';
import { environment } from 'src/environments/environment';

/**
 * Component:
 * Intervention details page displaying an intervention;
 * Can be found: {uri}/interventions/{{intervention_id}}/
 */

@Component({
  selector: 'app-intervention-detail',
  templateUrl: './intervention-detail.component.html',
  styleUrls: ['./intervention-detail.component.scss'],
  providers: [InterventionSharedService, DiaryStore]
})
export class InterventionDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('matTabGroup') matTabGroup: QueryList<MatTabGroup>;

  // Icons
  faBookOpen = faBookOpen;
  faCopy = faCopy;
  faUsers = faUsers;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faTrash = faTrash;
  faPlus = faPlus;

  private patientInterventionUrl = environment.patientURL + '/lesson-overview/';

  public isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public intervention: InterventionInterface = null;
  public intervention$: Observable<InterventionInterface | undefined>;

  public diaries: DiaryInterface[] = [];

  // Data provided by StudyService
  public collaborators: UserInterface[] = [];

  public profile: ProfileInterface;

  public isECoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isPublisherOrAccess$: Observable<boolean>;
  public isPublisher$: Observable<boolean>;
  public isInterventionActive$: Observable<boolean>;

  public profile$: Observable<ProfileInterface>;

  public diariesOfStudy$: Observable<DiaryInterface[]>;

  private translationSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;
  private inactiveCollabInterventions$: Observable<InterventionInterface[]>;

  private isECoachStudySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPublisherOrAccessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPublisherSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isInterventionActiveSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private diaryStore: DiaryStore,
    private sharedService: InterventionSharedService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollabInterventionById: InterventionInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      inactiveCollabInterventions: InterventionInterface[];
    }>
  ) {
    this.isECoach$ = this.isECoachStudySubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isPublisherOrAccess$ = this.isPublisherOrAccessSubject.asObservable();
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.isInterventionActive$ = this.isInterventionActiveSubject.asObservable();
    this.profile$ = store.select('myProfile');
    this.inactiveCollabInterventions$ = this.store.select('inactiveCollabInterventions');
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;

    this.subscriptions.push(
      this.translateService
        .getTranslation(localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de')
        .subscribe(translations => {
          this.translationSubject.next(translations);
        })
    );
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.getInterventionContent();

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        this.getInterventionContent();
      })
    );
  }

  ngAfterViewInit(): void {
    this.matTabGroup.changes.subscribe((_comps: QueryList<MatTabGroup>) => {
      this.helper.setMatTabActive(this.matTabGroup, this.router.url, this.translationSubject, this.translateService, 'intervention-detail');
    });
  }

  // Display and navigate to child component relative to this path
  public showChildComponent(child: string): void {
    if (child.match(/^(lessons|skills|collaborators|activities|instances|tasks|diaries)$/)) {
      this.router.navigate([child], { relativeTo: this.actRoute });
    }
  }

  // Listens to tab change
  public onChangeTab(event: MatTabChangeEvent): void {
    const tab: MatTab = event.tab;
    const child: string = this.evaluateTab(tab.textLabel, this.translationSubject.value);
    this.showChildComponent(child);
  }

  public getCatalogue(): void {
    this.router.navigateByUrl('/interventions');
  }

  // Get study name
  public getDetailedStudy(studyId: number): void {
    this.router.navigateByUrl(`/groups/${studyId}`);
  }

  copyInterventionUrlToClipboard(interventionId: number): void {
    const url = this.patientInterventionUrl + interventionId;
    navigator.clipboard.writeText(url);
  }

  public openDialogInterventionDelete(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionDelete(intervention)
      .afterClosed()
      .subscribe(result => {
        if (result === 'SUCCESS') {
          this.router.navigateByUrl('/interventions');
        }
      });
  }

  public copiedInterventionBySameUser(intervention: InterventionInterface, userId: number): boolean {
    if (!intervention.attributes.copied_by_user || !userId) {
      return true;
    } else {
      return intervention.attributes.copied_by_user.toString() === userId.toString();
    }
  }

  public openDialogInterventionTestPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionTestPublish(intervention)
      .afterClosed()
      .subscribe(_result => {
        this.getInterventionContent();
      });
  }

  public openDialogInterventionOfficialPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionOfficialPublish(intervention)
      .afterClosed()
      .subscribe(_result => {
        this.getInterventionContent();
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private evaluateTab(label: string, translate: any): string {
    if (translate) {
      if (translate['intervention-detail']['lessons'] === label) {
        return 'lessons';
      }
      if (translate['intervention-detail']['skills'] === label) {
        return 'skills';
      }
      if (translate['intervention-detail']['collaborators'] === label) {
        return 'collaborators';
      }
      if (translate['intervention-detail']['instances'] === label) {
        return 'instances';
      }
      if (translate['intervention-detail']['tasks'] === label) {
        return 'tasks';
      }
      if (translate['intervention-detail']['activities'] === label) {
        return 'activities';
      }
      if (translate['intervention-detail']['diaries'] === label) {
        return 'diaries';
      }
    }
    return '';
  }

  // Get and display detailed study details
  private getInterventionContent(): void {
    let interventionId: number;
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          take(1),
          mergeMap((value: ProfileInterface) => {
            this.profile = value;
            return this.actRoute.params;
          }),
          map(v => parseInt(v.id, 10)),
          switchMap((id: number) => {
            interventionId = id;
            this.store.dispatch({
              type: InterventionActionTypes.getInterventionsOfCollabStudyType,
              payload: { typeOfParentStudy: 'study' }
            });
            return this.inactiveCollabInterventions$.pipe(skip(1), take(1));
          }),
          switchMap((inactiveInterventions: InterventionInterface[]) => {
            const foundInactiveIntervention = inactiveInterventions.find(
              (intervention: InterventionInterface) => intervention.id.toString() === interventionId.toString()
            );
            if (!!foundInactiveIntervention) {
              return of(foundInactiveIntervention);
            }
            this.intervention$ = this.store.select(getCollabInterventionById(interventionId));
            return this.intervention$.pipe(
              filter((intervention: InterventionInterface | undefined) => !!intervention),
              take(1)
            );
          }),
          mergeMap((intervention: InterventionInterface) => {
            this.intervention = intervention;
            if (this.intervention.attributes.is_active === 1) {
              this.isInterventionActiveSubject.next(true);
            }
            this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          }),
          mergeMap((result: DiaryInterface[]) => {
            this.diaries = result;
            this.store.dispatch({
              type: StudyActionTypes.getCollaboratorsType,
              payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
            });
            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
            return this.collaboratorsOfStudy$.pipe(
              filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
              take(1)
            );
          })
        )
        .subscribe({
          next: result => {
            this.collaborators = result.collaborators;
            // Determine my highest role
            const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.profile.id.toString());
            if (myself !== undefined) {
              const myRoleSlug = this.helper.getHighestRoleOfCollaborator(myself);
              const isECoach = !!myRoleSlug.match(/study\.(ecoach|ecoachmanager|owner)$/);
              this.isECoachStudySubject.next(isECoach);
              this.sharedService.pushIsECoach(isECoach);

              const isManager = !!myRoleSlug.match(/study\.(ecoachmanager|owner)$/);
              this.isManagerSubject.next(isManager);

              const isPublisherOrAccess = !!myRoleSlug.match(/study\.(access|publisher)$/);
              this.isPublisherOrAccessSubject.next(isPublisherOrAccess);

              this.isPublisherSubject.next(
                myself.relationships.roles.data.find((value: RoleInterface) => value.attributes.slug === 'study.publisher')
              );
            }
            this.sharedService.pushIntervention(this.intervention);
            this.isLoadingSubject.next(false);
          },
          error: error => {
            throwError(() => error);
            this.router.navigateByUrl('/interventions');
          },
          complete: () => {
            this.isLoadingSubject.next(false);
          }
        })
    );
  }
}
