import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, switchMap, throwError, take, skip, fromEvent } from 'rxjs';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { Chart } from 'chart.js/auto';
import { InteractionItem } from 'chart.js/dist/core/core.interaction';

@Component({
  selector: 'app-intervention-skill',
  templateUrl: './intervention-skill.component.html',
  styleUrls: ['./intervention-skill.component.scss'],
  providers: [DiaryStore, QuestionnaireStore, SkillStore]
})
export class InterventionSkillComponent implements OnInit, OnDestroy {
  public canvas: ElementRef;

  public isLoading$: Observable<boolean>;

  public lessonSkills: Array<{ skillId: number; lessonIds: Array<number> }> = [];

  // Data provided by InterventionSharedService
  public studyId: number;
  public intervention: InterventionInterface;

  public lessons: Array<LessonInterface>;

  public diaries: Array<DiaryInterface>;

  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public questionnairesOfIntervention$: Observable<Array<LessonInterface>>;

  public skills: Array<SkillInterface>;

  public skillsOfIntervention$: Observable<SkillInterface[]>;

  // New Chart
  public chart: any;
  public dataset: { labels: Array<string>; datasets: Array<{ label; data; backgroundColor }> } = { labels: [], datasets: [] };

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: InterventionSharedService,
    private diaryStore: DiaryStore,
    private questionnaireStore: QuestionnaireStore,
    private skillStore: SkillStore,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @ViewChild('canvas', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.canvas = content;
      this.chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
        type: 'doughnut',
        data: this.dataset,
        options: {
          responsive: true
        }
      });
      fromEvent(content.nativeElement, 'click').subscribe((event: any) => {
        const activePoints: Array<InteractionItem> = this.chart.getElementsAtEventForMode(event, 'point', { intersect: true }, true);
        this.onSelectSkill(this.lessonSkills[activePoints[0].index].skillId);
      });
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          switchMap((intervention: InterventionInterface) => {
            this.studyId = intervention.attributes.study_id;
            this.intervention = intervention;
            this.lessonSkills = [];
            if (this.intervention.attributes.gamification.skills_order) {
              this.intervention.attributes.gamification.skills_order.forEach((skillId: number) => {
                this.lessonSkills.push({ skillId, lessonIds: [] });
              });
            }

            this.diaryStore.getDiariesOfStudy({ studyId: this.studyId });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          }),
          switchMap((result: any) => {
            this.diaries = result;
            this.questionnaireStore.getQuestionnairesIntervention({ interventionId: this.intervention.id });
            return this.questionnairesOfIntervention$.pipe(skip(1), take(1));
          }),
          switchMap((result: any) => {
            this.lessons = result;
            this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
            return this.skillsOfIntervention$;
          })
        )
        .subscribe(
          result => {
            this.skills = result;
            this.lessons.forEach((lesson: LessonInterface) => {
              if (lesson.attributes.skills) {
                lesson.attributes.skills.forEach((skillId: number) => {
                  const index = this.lessonSkills.findIndex(
                    (value: { skillId: number; lessonIds: Array<number> }) => value.skillId.toString() === skillId.toString()
                  );
                  if (index > -1) {
                    this.lessonSkills[index].lessonIds.push(lesson.id);
                  }
                });
              }
            });

            const skillDataSet = { label: '', data: [], backgroundColor: [] };
            this.lessonSkills.forEach((value: { skillId: number; lessonIds: Array<number> }) => {
              const foundSkill = this.skills.find((skill: SkillInterface) => skill.id.toString() === value.skillId.toString());
              this.dataset.labels.push(foundSkill.attributes.title);
              skillDataSet.data.push(value.lessonIds.length);
              const color = foundSkill.attributes.color;
              skillDataSet.backgroundColor.push(color ? color : '#C8191C');
            });
            this.dataset.datasets.push(skillDataSet);

            this.lessons = this.helper.sortLessonsAscending(this.lessons);
            this.isLoadingSubject.next(false);
          },
          error => {
            throwError(error);
          }
        )
    );
  }

  public onSelectSkill(skillId): void {
    const dialogRef = this.helperDialog.openDialogSkillByName(skillId, this.skills, undefined, this.intervention);
    if (dialogRef) {
      dialogRef.afterClosed().subscribe(result => {});
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
