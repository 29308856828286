import { Component, OnInit, ViewChild } from '@angular/core';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faBookMedical } from '@fortawesome/free-solid-svg-icons/faBookMedical';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, Subscription, switchMap, skip, take, mergeMap, filter, of } from 'rxjs';
import { DiaryInstanceInterface } from 'src/app/models/interface/diary-instance.interface';
import { DiaryInterface } from 'src/app/models/interface/diary.interface';
import { LessonInterface } from 'src/app/models/interface/lesson.interface';
import { StudyInterface } from 'src/app/models/interface/study/study.interface';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { HelperService } from 'src/app/services/helper/helper.service';
import { GroupSharedService } from 'src/app/services/shared/group-shared/group-shared.service';
import { DiaryStore } from 'src/app/store/diary/component-store/diary.store';
import { DiaryActionTypes } from 'src/app/store/diary/diary.action';
import { StudyStore } from 'src/app/store/study/component-store/study.store';
import { UserActionTypes } from 'src/app/store/user/user.action';

@Component({
  selector: 'app-group-diary',
  templateUrl: './group-diary.component.html',
  styleUrls: ['./group-diary.component.scss'],
  providers: [DiaryStore, StudyStore]
})
export class GroupDiaryComponent implements OnInit {
  @ViewChild('paginator') paginator;

  // Icons
  faBook = faBook;
  faInfoCircle = faInfoCircle;
  faBookMedical = faBookMedical;

  // Study ID and loading status for this child component
  public studyId: number;
  public isLoading$: Observable<boolean>;

  public questionnaires: Array<LessonInterface> = [];

  public diaryInstances: Array<DiaryInstanceInterface> = [];
  public diaryInstances$: Observable<Array<DiaryInstanceInterface>>;
  public diaries: Array<DiaryInterface> = [];
  public diaries$: Observable<Array<DiaryInterface>>;

  public pagedInstanceDiaryPair$: Observable<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>;
  public instanceDiaryPairs$: Observable<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>;

  public studyMembers: Array<UserInterface> = [];

  private myMembers$: Observable<Array<UserInterface>>;
  private studyMembers$: Observable<Array<UserInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private instanceDiaryPair: Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }> = [];

  // Diary instance & diary
  private instanceDiaryPairsSubject: BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>> =
    new BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>([]);

  private pagedInstanceDiaryPairSubject: BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>> =
    new BehaviorSubject<Array<{ diary_instance: DiaryInstanceInterface; diary: DiaryInterface }>>([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private store: Store<{ diaries: Array<DiaryInterface>; myMembers: Array<UserInterface> }>,
    private diaryStore: DiaryStore,
    private studyStore: StudyStore,
    private helperService: HelperService
  ) {
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.instanceDiaryPairs$ = this.instanceDiaryPairsSubject.asObservable();
    this.pagedInstanceDiaryPair$ = this.pagedInstanceDiaryPairSubject.asObservable();
    this.diaries$ = this.store.select('diaries');
    this.diaryInstances$ = this.diaryStore.allDiaryInstances$;
    this.studyMembers$ = this.studyStore.studyMembers$;
    this.myMembers$ = this.store.select('myMembers');
  }

  public get helper() {
    return this.helperService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter((value: StudyInterface) => value !== null),
          switchMap((value: StudyInterface) => {
            this.studyId = value.id;
            this.diaryStore.getAllInstancesOfDiaryECoach({ diaryId: undefined, ecoachId: undefined, patientId: undefined });
            return this.diaryInstances$.pipe(skip(1), take(1));
          }),
          mergeMap((result: any) => {
            this.diaryInstances = result
              .filter((diaryInstance: DiaryInstanceInterface) => diaryInstance.attributes.study_id !== null)
              .filter(
                (diaryInstance: DiaryInstanceInterface) => diaryInstance.attributes?.study_id?.toString() === this.studyId.toString()
              );
            return this.reloadMemberList(this.studyId);
          }),
          mergeMap((result: any) => {
            this.store.dispatch({
              type: DiaryActionTypes.getAllDiariesType,
              payload: {}
            });
            return this.diaries$.pipe(skip(1), take(1));
          })
        )
        .subscribe((result: any) => {
          this.diaries = result.filter((diary: DiaryInterface) => diary.attributes.study_id.toString() === this.studyId.toString());
          this.diaryInstances.forEach((diaryInstance: DiaryInstanceInterface) => {
            const found = this.diaries.find(
              (value: DiaryInterface) => value.id.toString() === diaryInstance.attributes.diary_id.toString()
            );
            this.instanceDiaryPair.push({ diary_instance: diaryInstance, diary: found });
          });
          this.instanceDiaryPairsSubject.next(this.instanceDiaryPair);
          this.isLoadingSubject.next(false);
          const currentPageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
          this.pagedInstanceDiaryPairSubject.next(this.instanceDiaryPairsSubject.value.slice(0, currentPageSize));
        })
    );
  }

  public updatePagedDiaryInstances(event: any) {
    if (event) {
      this.pagedInstanceDiaryPairSubject.next(event);
    }
  }

  public reloadMemberList(studyId: number): Observable<any> {
    return this.sharedService.isManager$.pipe(
      switchMap((isManager: boolean) => {
        if (isManager) {
          this.studyStore.getMembers({ studyId });
          return this.studyMembers$.pipe(
            skip(1),
            take(1),
            mergeMap(result => {
              this.studyMembers = result;
              return of(true);
            })
          );
        } else {
          let myMemberIds: Array<number> = [];
          this.store.dispatch({
            type: UserActionTypes.getMyMembersType,
            payload: {}
          });
          return this.myMembers$.pipe(
            skip(1),
            take(1),
            mergeMap((result: any) => {
              myMemberIds = result.map((user: UserInterface) => user.id);
              this.studyStore.getECoachMembers({ studyId });
              return this.studyMembers$.pipe(skip(1), take(1));
            }),
            mergeMap(result => {
              this.studyMembers = result.filter((user: UserInterface) => myMemberIds.includes(user.id));
              return of(true);
            })
          );
        }
      })
    );
  }
}
