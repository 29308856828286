import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { faFolder } from '@fortawesome/free-solid-svg-icons/faFolder';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons/faHourglassHalf';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, of, switchMap } from 'rxjs';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from 'src/app/models/interface/user.interface';
import { getCollaboratorsByStudyId } from 'src/app/store/study/study.selector';

@Component({
  selector: 'app-card-intervention',
  templateUrl: './card-intervention.component.html',
  styleUrls: ['./card-intervention.component.scss']
})
export class CardInterventionComponent implements OnInit {
  @Output()
  public emitPublishingPerformed: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public emitDeletionPerformed: EventEmitter<any> = new EventEmitter<any>();

  // Icons
  faFolder = faFolder;
  faUsers = faUsers;
  faPlus = faPlus;
  faBook = faBook;
  faHands = faHands;
  faHandsHelping = faHandsHelping;
  faTrash = faTrash;
  faGlobe = faGlobe;
  faHourglassStart = faHourglassStart;
  faHourglassHalf = faHourglassHalf;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  public intervention: InterventionInterface;

  public isPublisher$: Observable<boolean>;
  public isManager$: Observable<boolean>;

  public isCollab = false;

  public studyCollaborators: Array<UserInterface> = [];

  public profile$: Observable<ProfileInterface>;

  public showStudyButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private isPublisherSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private helperService: HelperService,
    private router: Router,
    private helperDialogService: HelperDialogService,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention) {
      this.intervention = _intervention;
    }
  }

  @Input()
  set _isCollab(_isCollab: boolean) {
    if (_isCollab !== undefined) {
      this.isCollab = _isCollab;
    }
  }

  @Input()
  set _isPublisher(_isPublisher: boolean) {
    if (_isPublisher !== undefined) {
      this.isPublisherSubject.next(_isPublisher);
    }
  }

  @Input()
  set _isManager(_isManager: boolean) {
    if (_isManager !== undefined) {
      this.isManagerSubject.next(_isManager);
    }
  }

  @Input()
  set _showStudyButton(_showStudyButton: boolean) {
    if (_showStudyButton !== undefined) {
      this.showStudyButtonSubject.next(_showStudyButton);
    }
  }

  ngOnInit(): void {
    this.store
      .select(getCollaboratorsByStudyId(this.intervention.attributes.study_id))
      .pipe(filter(collaboratorsOfStudy => !!collaboratorsOfStudy))
      .subscribe(result => {
        this.studyCollaborators = result.collaborators;
      });
  }

  public getDetailedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}`);
  }

  public getUnpublishedIntervention(id: number): void {
    this.router.navigateByUrl(`/interventions/${id}/lessons`);
  }

  public getDetailedStudy(id: number): void {
    this.router.navigateByUrl(`groups/${id}`);
  }

  public getDetailedInterventionInstance(id: number): void {
    this.router.navigateByUrl(`interventions/${id}/instances`);
  }

  public openDialogInterventionTestPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionTestPublish(intervention)
      .afterClosed()
      .subscribe(result => {
        this.emitPublishingPerformed.emit(result);
      });
  }

  public openDialogInterventionOfficialPublish(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionOfficialPublish(intervention)
      .afterClosed()
      .subscribe(result => {
        this.emitPublishingPerformed.emit(result);
      });
  }

  public openDialogInterventionDelete(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionDelete(intervention)
      .afterClosed()
      .subscribe(result => {
        this.emitDeletionPerformed.emit(result);
      });
  }

  public openDialogInterventionReview(intervention: InterventionInterface): void {
    this.helperDialog
      .openDialogInterventionReview(intervention)
      .afterClosed()
      .subscribe(result => {
        this.emitDeletionPerformed.emit(result);
      });
  }

  public isUnpublished(): Observable<boolean> {
    return this.profile$.pipe(
      filter(user => !!user),
      switchMap(user =>
        of(
          this.isPublisherSubject.value &&
            (this.intervention.attributes.publishing_state === 'pending' || this.intervention.attributes.publishing_state === 'reviewed') &&
            this.intervention.attributes.is_active === 0 &&
            !this.helper.copiedInterventionBySameUser(this.intervention, user.id)
        )
      )
    );
  }

  public canBeDeleted(): Observable<boolean> {
    return of(this.isManagerSubject.value && (this.intervention.attributes.is_active === 0 || this.intervention.attributes.is_test === 1));
  }

  public canBeReviewed(): Observable<boolean> {
    return this.profile$.pipe(
      filter(user => !!user),
      switchMap(user =>
        of(
          this.isManagerSubject.value &&
            this.intervention.attributes.publishing_state === 'pending' &&
            this.intervention.attributes.is_active === 0 &&
            !this.helper.copiedInterventionBySameUser(this.intervention, user.id)
        )
      )
    );
  }
}
