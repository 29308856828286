<button [attr.id]="'btnDialogClose'" mat-button mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>{{'answersheet-report.report_download_modal_title' | translate:param}}</h1>

<div class="content-height" mat-dialog-content>
  <div class="container text-start">
    <br />
    <p>{{'instance-report.list_of_reports' | translate}}</p>
    <button [attr.id]="'btnLoadJobResults'" mat-button (click)="loadJobResults()">
      <fa-icon [icon]="faRedo"></fa-icon>
      {{'instance-report.reload_reports' | translate}}
    </button>
    <mat-list>
      <div *ngIf="(jobresults$ | async)?.length > 0; then thenShowReports else elseNoReports"></div>
      <ng-template #thenShowReports>
        <app-pagination-list
          #paginator
          (emitPageChange)="updatePagedJobResults($event)"
          [_isLoading]="isLoading$ | async"
          [_items]="jobresults$ | async"
        ></app-pagination-list>

        <mat-list-item [attr.id]="'jobResult_' + result.id" *ngFor="let result of (pagedJobResults$ | async); trackBy: trackByJobId">
          <fa-icon mat-list-icon [icon]="faFilePdf"></fa-icon>
          <div mat-line>{{result.attributes.result_id}}</div>
          <div mat-line>
            <fa-icon [icon]="faClock"></fa-icon> {{helper.localizeDateTimestamp(result.attributes.created_at, 'datetime')}}
          </div>
          <app-jobresult-download
            [attr.id]="'jobResultDownload_' + result.id"
            *ngIf="result.attributes.finished === 1 && result.attributes.successful === 1"
            [_result]="result"
          ></app-jobresult-download>

          <div *ngIf="result.attributes.finished === 1 && result.attributes.successful === 0" mat-line>
            <span class="text-danger">
              <fa-icon [icon]="faTimes"></fa-icon>
              {{'instance-report.failed' | translate}}
            </span>
          </div>
          <div *ngIf="result.attributes.finished === 0" mat-line>
            <span class="text-info">
              <fa-icon [icon]="faEllipsisH"></fa-icon>
              {{'instance-report.pending' | translate}}
            </span>
          </div>
        </mat-list-item>
      </ng-template>
      <ng-template #elseNoReports>
        <p>{{'instance-report.no_reports' | translate}}</p>
      </ng-template>
    </mat-list>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'instance-report.cancel' | translate}}</button>

  <app-button-feedback
    [_setId]="'btnGetAnswersheetReportPDF_' + answersheet.id"
    (click)="getAnswersheetReportPDF(answersheet)"
    [_defaultButtonText]="'answersheet-report.download_pdf'"
    [_responseText]="downloadPDFReportResponse | async"
  ></app-button-feedback>
  <div *ngIf="download$ | async as download" class="mb-3">
    <p class="text-info">{{'instance-report.download_takes_a_while' | translate}} - {{download.progress}} %</p>
    <mat-progress-bar color="accent" [mode]="'buffer'" [value]="download.progress"> </mat-progress-bar>
  </div>
</div>
