<div class="container-fluid">
  <div class="text-center" *ngIf="isLoading$ | async; else elseInstanceDetails">
    <app-loader></app-loader>
  </div>

  <ng-template #elseInstanceDetails>
    <mat-tab-group mat-align-tabs="center" animationDuration="0ms" selectedIndex="{{selectedIndexSubject | async}}" mat-stretch-tabs>
      <mat-tab label="{{'instance-detail.modal_detail_instance_overview' | translate}}">
        <div class="container text-start">
          <br />

          <div>
            <div class="text-spacing mx-auto">
              <div class="text-center">
                <span
                  *ngIf="instance.attributes.pending_invitation === 0; then thenShowInstanceState else elseShowPendingInvitation"
                ></span>
                <ng-template #thenShowInstanceState>
                  <button
                    [attr.id]="'btnGetTextColorByInstanceState'"
                    matTooltip="{{helper.getToolTipByInstanceState(instance.attributes.progress.current_state) | translate}}"
                    [class]="helper.getTextColorByInstanceState(instance.attributes.progress.current_state)"
                    mat-button
                  >
                    <fa-icon [icon]="helper.getFaIconByInstanceState(instance.attributes.progress.current_state)"></fa-icon>
                    {{('intervention-instance.state_' + (instance.attributes.progress.current_state).toLowerCase()) | translate}}
                  </button>
                </ng-template>
                <ng-template #elseShowPendingInvitation>
                  <button
                    [attr.id]="'btnGetTextColorByInstanceState'"
                    color="warn"
                    mat-button
                    matTooltip="{{'intervention-instance.tooltip_pending_invitation' | translate}}"
                  >
                    <fa-icon [icon]="faEnvelope"></fa-icon>
                    {{'intervention-instance.state_pending_invitation' | translate}}
                  </button>
                </ng-template>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4">{{'instance-detail.intervention' | translate}}:</div>
              <div class="col-sm-8">
                {{intervention !== null ? intervention.attributes.title : ('instance-detail.modal_detail_instance_no_intervention' |
                translate)}}
              </div>

              <div class="col-sm-4">{{'instance-detail.instance_ecoach' | translate}}:</div>
              <div class="col-sm-8">
                <div *ngFor="let ecoach of instance.attributes.ecoach_id">
                  {{helper.getECoachUsername(ecoach, studyCollaborator) | translate}}
                </div>
              </div>

              <div class="col-sm-4">{{'instance-detail.instance_patient' | translate}}:</div>
              <div class="col-sm-8">
                <div *ngIf="helper.findArrObjById(instance.attributes.patient_id, studyMembers) !== null; then thenShowUser"></div>
                <div
                  *ngIf="(helper.findArrObjById(instance.attributes.patient_id, studyMembers) === null) && (instance.attributes.progress.current_state === ('awaiting_next_questionnaire') || instance.attributes.progress.current_state === ('active')); then thenUnverified"
                ></div>
                <div
                  *ngIf="(helper.findArrObjById(instance.attributes.patient_id, studyMembers) === null) && !((instance.attributes.progress.current_state === ('awaiting_next_questionnaire') || instance.attributes.progress.current_state === ('active'))); then thenDeleted"
                ></div>

                <ng-template #thenShowUser>
                  <div class="break-word">
                    {{helper.getCodeNameEmail(instance.attributes.patient_id, studyMembers, instance.attributes.study_id)}}
                  </div>
                </ng-template>
                <ng-template #thenUnverified>
                  <div>{{'instance-detail.non_verified_patient' | translate}}</div>
                </ng-template>
                <ng-template #thenDeleted>
                  <div>{{'instance-detail.deleted_user' | translate}}</div>
                </ng-template>
              </div>

              <div class="col-sm-4">{{'instance-detail.instance_created_at' | translate}}:</div>
              <div class="col-sm-8">{{helper.localizeDateString(instance.attributes.created_at)}}</div>

              <div class="col-sm-4">{{'instance-detail.instance_starting_at' | translate}}:</div>
              <div class="col-sm-8">{{helper.localizeDateTimestamp(instance.attributes.configuration.starting_at, 'datetime')}}</div>

              <div class="col-sm-4">{{'instance-detail.modal_detail_instance_guidance_support' | translate}}:</div>
              <div class="col-sm-8">
                <div
                  *ngIf="instance.attributes.intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
                ></div>
                <ng-template #thenShowGuidedIcon>
                  <fa-icon [icon]="faHandsHelping"></fa-icon>
                </ng-template>
                <ng-template #elseShowUnguidedIcon>
                  <fa-icon [icon]="faHands"></fa-icon>
                </ng-template>
                {{('instance-detail.' + instance.attributes.intervention_type.toString().toLowerCase()) | translate}}
              </div>

              <div class="col-sm-4">{{'instance-detail.modal_detail_instance_identifier' | translate}}:</div>
              <div class="col-sm-8">{{('instance-detail.instance_id' | translate) + ' #' + instance.id}}</div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'instance-detail.modal_detail_instance_progress' | translate}}">
        <div class="container text-start">
          <br />

          <div class="row">
            <div class="col-12 col-md-2">{{'instance-detail.instance_state' | translate}}:</div>
            <div class="col-12 col-md-10">
              <div>
                <div class="progress">
                  <div
                    [attr.aria-valuenow]="progress"
                    [ngStyle]="{'width':calculateProgress()}"
                    aria-valuemax="100"
                    aria-valuemin="0"
                    class="progress-bar"
                    role="progressbar"
                  >
                    <span class="progress-bar-styling">{{'instance-detail.modal_detail_instance_progress_bar' | translate:param}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12 col-md-2">{{'instance-detail.modal_detail_instance_custom_order' | translate}}:</div>
            <div class="col-12 col-md-10">
              <mat-card appearance="outlined" class="my-1" *ngFor="let q of instance.attributes.configuration.custom_order; let i = index;">
                <mat-card-content [attr.id]="'questionnaire_' + q">
                  <p>
                    <strong>
                      {{i + 1}} . {{getQuestionnaireById(q) !== null ? getQuestionnaireById(q).attributes.title :
                      ('instance-detail.modal_detail_instance_no_questionnaire_title' | translate)}} - {{getQuestionnaireById(q) !== null ?
                      getQuestionnaireById(q).attributes.name : ('instance-detail.modal_detail_instance_no_questionnaire_name' |
                      translate)}} - (ID {{q}})
                    </strong>
                  </p>
                  <div class="row">
                    <div class="col-12">
                      <p>
                        <span *ngIf="getLessonStatus(q) === 'instance-detail.locked'">
                          <fa-icon [icon]="faLock"></fa-icon>
                          {{getLessonStatus(q) | translate}}
                          <span *ngIf="isCanceled(instance) || isPaused(instance)"
                            >({{('instance-detail.state_' + (instance.attributes.progress.current_state).toLowerCase()) | translate}})
                          </span>
                        </span>
                        <span *ngIf="getLessonStatus(q) === 'instance-detail.unlocked'">
                          <fa-icon [icon]="faLockOpen"></fa-icon>
                          {{getLessonStatus(q) | translate}}
                          <span *ngIf="isActiveQuestionnaireId(instance, q)">{{'instance-detail.in_progress' | translate}}</span>
                          <span *ngIf="isCanceled(instance) || isPaused(instance)"
                            >({{('instance-detail.state_' + (instance.attributes.progress.current_state).toLowerCase()) | translate}})
                          </span>
                        </span>
                        <span *ngIf="getLessonStatus(q) === 'instance-detail.finished'">
                          <fa-icon [icon]="faCheck"></fa-icon>
                          {{getLessonStatus(q) | translate}}
                        </span>
                      </p>
                      <p>
                        <small>
                          <span *ngIf="getQuestionnaireUnlockedSince(q) && !(getLessonStatus(q) === 'instance-detail.locked')">
                            ({{'instance-detail.unlocked_since' | translate}}
                            {{helper.localizeDateString(getQuestionnaireUnlockedSince(q))}})
                          </span>
                        </small>
                      </p>
                    </div>
                    <div class="col-12">
                      <div
                        *ngIf="getQuestionnaireById(q).attributes.unlock_diaries !== null && getQuestionnaireById(q).attributes.unlock_diaries.length > 0; then thenShowDiaries"
                      ></div>
                      <ng-template #thenShowDiaries>
                        <hr />
                        <div *ngFor="let diary of getQuestionnaireById(q).attributes.unlock_diaries">
                          <fa-icon [icon]="faBook"></fa-icon>
                          {{helper.findArrObjById(diary, diaries) !== null ? helper.findArrObjById(diary, diaries).attributes.name :
                          ('lesson.diary_not_found' | translate)}}
                        </div>
                      </ng-template>
                    </div>
                  </div>

                  <div class="row">
                    <div *ngIf="getConfigurationByQuestionnaireId(q) as config" class="col-sm-6">
                      <div [ngSwitch]="config.unlock_type.toUpperCase()">
                        <!-- the same view can be shown in more than one case -->
                        <p *ngSwitchCase="'AT_DATE'">
                          <fa-icon [icon]="faClock"></fa-icon>
                          {{'instance-detail.modal_detail_instance_unlock_at' | translate}}
                          {{helper.localizeDateTimestamp(config.unlock_date, 'datetime')}}
                        </p>
                        <p *ngSwitchDefault>{{'instance-detail.' + config.unlock_type.toLowerCase() | translate}}</p>
                      </div>
                      <p class="small" *ngIf="config.unlock_type.toUpperCase() === 'CONDITIONAL'">{{getCondition(q)}}</p>
                    </div>
                    <div class="col-sm-6">
                      <p *ngIf="getConfigurationByQuestionnaireId(q).feedback_required; else elseFeedbackNotRequired">
                        <span class="font-weight-bold">
                          <fa-icon [icon]="faComment"></fa-icon>
                          {{'instance-detail.feedback_required' | translate}}
                        </span>
                      </p>
                      <ng-template #elseFeedbackNotRequired>
                        <p>
                          <span class="font-weight-light">
                            <fa-icon [icon]="faCommentSlash"></fa-icon>
                            {{'instance-detail.feedback_not_required' | translate}}</span
                          >
                        </p>
                      </ng-template>
                    </div>
                  </div>

                  <div class="row" *appVariable="helper.findArrObjById(q, questionnaires) as lesson">
                    <div *ngIf="lesson.attributes?.hasSkills">
                      <div *ngIf="lesson.attributes?.skills.length > 0; then thenShowSkills"></div>
                      <ng-template #thenShowSkills>
                        <div class="button-row">
                          <button
                            [attr.id]="'btnOpenDialogSkill_' + skillId"
                            *ngFor="let skillId of lesson.attributes.skills; last as isLast"
                            [ngClass]="{'spacing-right': !isLast}"
                            class="mb-2"
                            (click)="helperDialog.openDialogSkill(skillId, skills, intervention)"
                            mat-button
                          >
                            <span [ngStyle]="helperSkill.getColorStyle(skillId, skills)"
                              ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId, skills) !== null ?
                              helper.findArrObjById(skillId, skills).attributes.title : ('lesson.skill_not_found' | translate)}}</span
                            >
                          </button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </mat-card-content>
                <mat-card-actions>
                  <button
                    [attr.id]="'btnOpenDialogInterventionLesson_' + i"
                    (click)="openDialogInterventionLesson(q, intervention.attributes.study_id)"
                    mat-button
                  >
                    <fa-icon [icon]="faEye"></fa-icon>
                  </button>
                  <app-button-feedback
                    [_setId]="'btnSendReminder_' + q"
                    *ngIf="getLessonStatus(q) === 'instance-detail.unlocked' && (!isCanceled(instance))"
                    (click)="sendReminder(instance.attributes.patient_id, q)"
                    [_defaultButtonText]="'instance-detail.send_reminder_mail'"
                    [_responseText]="(sendReminderResponseArray[i]?.sendReminderResponse | async)"
                  ></app-button-feedback>
                  <app-button-feedback
                    [_setId]="'btnUnlockManuallyQuestionnaire_' + q"
                    *ngIf="getConfigurationByQuestionnaireId(q).unlock_type.toUpperCase() === 'MANUALLY' && (!isCanceled(instance)) && (getLessonStatus(q) === 'instance-detail.locked')"
                    (click)="unlockManuallyQuestionnaire(instance, getConfigurationByQuestionnaireId(q).id)"
                    [_defaultButtonText]="'instance-detail.modal_detail_instance_unlock'"
                    [_responseText]="(unlockManuallyResponseArray[i]?.unlockManuallyResponse | async)"
                  ></app-button-feedback>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab *ngIf="hasSkills" label="{{'instance-detail.modal_detail_instance_skills' | translate}}">
        <div class="container text-start">
          <br />
          <div class="row center">
            <canvas #canvas id="canvas">{{chart}}</canvas>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'instance-detail.modal_detail_instance_answersheet' | translate}}">
        <div class="container text-center">
          <br />

          <div>
            <div *ngIf="answersheets.length > 0; then thenShowAnswersheets else elseShowNoAnswersheets"></div>
            <ng-template #thenShowAnswersheets>
              <div *ngFor="let answersheet of answersheets">
                <app-card-submitted-answersheet
                  [_answersheet]="answersheet"
                  [_instance]="instance"
                  [_questionnaires]="questionnaires"
                  [_threads]="threads"
                  [_studyMembers]="studyMembers"
                  [_isGuidingECoach]="helper.isECoachOfInstance(instance, profile.id)"
                ></app-card-submitted-answersheet>
              </div>
            </ng-template>

            <ng-template #elseShowNoAnswersheets>
              <p>{{'instance-detail.modal_detail_instance_no_answersheet' | translate}}</p>
            </ng-template>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{'instance-detail.modal_detail_instance_activity' | translate}}">
        <div class="container text-center">
          <br />
          <div class="mb-4 text-start">
            <div class="card">
              <div *ngIf="(pagedActivity$ | async)?.length === 0" class="card-body">
                <p class="centering">{{'instance-detail.no_activities_found' | translate}}</p>
              </div>
              <div *ngIf="(pagedActivity$ | async).length !== 0" class="list-group">
                <div
                  [attr.id]="'activity_' + activity.id"
                  *ngFor="let activity of pagedActivity$ | async"
                  class="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div class="row justify-content-around mb-3">
                    <div class="col-sm-8">
                      <p class="mb-1">
                        <strong> {{helperActivity.getActivityText(activity) | async}}</strong>
                        <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'"
                          ><strong>: {{helperActivity.getLessonNameOfActivity(activity, questionnaires)}}</strong></span
                        >
                      </p>

                      <p class="mb-1 break-word">
                        {{'instance-detail.performed_by' | translate}} {{helper.findArrObjById(activity.attributes.user, studyMembers) !==
                        null ? helper.getCodeNameEmail(activity.attributes.user, studyMembers, instance.attributes.study_id) : ('SYSTEM' |
                        translate)}}
                      </p>
                    </div>
                    <div class="col-sm-4">
                      <p class="mb-1"><small>{{helper.localizeDateString(activity.attributes.created_at)}}</small></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <app-pagination-list
                (emitPageChange)="updatePagedActivities($event)"
                [_isLoading]="isLoading$ | async"
                [_items]="activitiesSubject | async"
              ></app-pagination-list>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
