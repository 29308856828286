<div class="container-fluid">
  <app-alert></app-alert>
  <!-- Create instance -->
  <div *ngIf="(unconfirmedSubject | async)" class="container text-start">
    <div>
      <dl class="row">
        <dt class="col-sm-3">{{'instance-creation-confirmation.instance_patient' | translate}}:</dt>
        <dd class="col-sm-9">{{helper.getCodeNameEmail(instanceAttributes.patient_id, studyMembers)}}</dd>

        <dt class="col-sm-3">{{'instance-creation-confirmation.instance_ecoach' | translate}}:</dt>
        <dd class="col-sm-9">
          {{helper.findArrObjById(myUserId, studyCollaborator)?.attributes.email}} ({{helper.findArrObjById(myUserId,
          studyCollaborator)?.attributes.name}})
        </dd>

        <dt class="col-sm-3">{{'instance-creation-confirmation.instance_additional_ecoach' | translate}}:</dt>
        <dd *ngIf="instanceAttributes.ecoach_ids.length !== 0;" class="col-sm-9">
          <span *ngFor="let id of instanceAttributes.ecoach_ids; first as isFirst"
            ><span *ngIf="!isFirst"> | </span> {{helper.findArrObjById(id, studyCollaborator)?.attributes.email}}
            ({{helper.findArrObjById(id, studyCollaborator)?.attributes.name}})
          </span>
        </dd>
        <dd *ngIf="instanceAttributes.ecoach_ids.length === 0;" class="col-sm-9">-</dd>

        <dt class="col-sm-3">{{'instance-creation-confirmation.instance_guidance_support' | translate}}:</dt>
        <dd class="col-sm-9">
          <div
            *ngIf="instanceAttributes.intervention_type.toString().toUpperCase() === 'GUIDED'; then thenShowGuidedIcon else elseShowUnguidedIcon"
          ></div>
          <ng-template #thenShowGuidedIcon>
            <fa-icon [icon]="faHandsHelping"></fa-icon>
          </ng-template>
          <ng-template #elseShowUnguidedIcon>
            <fa-icon [icon]="faHands"></fa-icon>
          </ng-template>
          {{('instance-creation-confirmation.' + instanceAttributes.intervention_type.toLowerCase()) | translate}}
        </dd>

        <dt class="col-sm-3">{{'instance-creation-confirmation.instance_starting_at' | translate}}:</dt>
        <dd class="col-sm-9">{{helper.localizeDateTimestamp(instanceAttributes.configuration.starting_at, 'datetime')}}</dd>
      </dl>
    </div>

    <mat-divider class="my-2"></mat-divider>

    <div *ngIf="reminderConfig; then thenShowReminder else elseNoReminder"></div>

    <ng-template #thenShowReminder>
      <dl class="row">
        <dt class="col-sm-3">{{'intervention-instance-reminder.form_create_intervention_instance_reminder_starts_at' | translate}}:</dt>
        <dd class="col-sm-9">{{helper.localizeDateTimestamp(reminderConfig.payload.data.attributes.configuration.start, 'datetime')}}</dd>

        <dt class="col-sm-3">{{'intervention-instance-reminder.max_number_of_repetitions' | translate}}:</dt>
        <dd class="col-sm-9">{{reminderConfig.payload.data.attributes.configuration.max_times}}</dd>

        <dt class="col-sm-3">{{'intervention-instance-reminder.rhythm' | translate}}:</dt>
        <dd class="col-sm-9">
          {{'intervention-instance-reminder.reminder_rhythm_1' | translate}} {{reminderConfig.payload.data.attributes.configuration.pause}}
          {{('intervention-instance-reminder.' + reminderConfig.payload.data.attributes.configuration.time_unit) |
          translate}}{{'intervention-instance-reminder.reminder_rhythm_2' | translate}}
        </dd>
      </dl>

      <p><strong>{{'intervention-instance-reminder.message_preview'| translate}}:</strong></p>
      <p>{{ defaultReminderText[reminderConfig.payload.data.attributes.content.locale]['greeting'] }}</p>
      <p>
        <ng-template
          *ngIf="reminderConfig.payload.data.attributes.content.text; then thenShowCustomMessage else elsShowDefaultMessage"
        ></ng-template>
        <ng-template #thenShowCustomMessage>{{reminderConfig.payload.data.attributes.content.text}}</ng-template>
        <ng-template #elsShowDefaultMessage
          >{{ defaultReminderText[reminderConfig.payload.data.attributes.content.locale]['defaultEnding'] }}</ng-template
        >
      </p>
      <p>{{ defaultReminderText[reminderConfig.payload.data.attributes.content.locale]['ending'] }}</p>
      <p>
        <ng-template
          *ngIf="reminderConfig.payload.data.attributes.content.ending; then thenShowCustomEnding else elsShowDefaultEnding"
        ></ng-template>
        <ng-template #thenShowCustomEnding>{{reminderConfig.payload.data.attributes.content.ending}}</ng-template>
        <ng-template #elsShowDefaultEnding
          >{{ defaultReminderText[reminderConfig.payload.data.attributes.content.locale]['defaultEnding'] }}</ng-template
        >
      </p>
    </ng-template>
    <ng-template #elseNoReminder>
      <p class="text-center text-info my-2">{{'intervention-instance-reminder.no_reminder_found' | translate}}</p>
    </ng-template>
    <div class="row" align="end">
      <div class="col align-self-end">
        <button
          class="float-end"
          [attr.id]="'btnOpenDialogInterventionInstanceReminderCreation'"
          mat-button
          (click)="openDialogInterventionInstanceReminderCreation(reminderConfig)"
        >
          {{'instance-creation-confirmation.create_intervention_instance_reminder' | translate}}
        </button>
        <button class="float-end" [attr.id]="'btnRemoveReminderConfig'" mat-button (click)="removeReminderConfig()">
          {{'instance-creation-confirmation.remove_intervention_instance_reminder' | translate}}
        </button>
      </div>
    </div>

    <mat-divider class="my-2"></mat-divider>

    <!-- Visualization of the custom_order -->
    <div>
      <div class="mb-2">
        <strong> {{'instance-creation-confirmation.form_create_instance_custom_order' | translate}}: </strong>
      </div>

      <ul *ngIf="formCustomOrder.length > 0; else elseNoLessonSelected" class="list-group">
        <li *ngFor="let questionnaire_id of formCustomOrder; let i = index" class="list-group-item flex-column align-items-start">
          <div class="d-flex justify-content-between">
            <p class="mb-1">
              {{i + 1}}. {{getQuestionnaireById(questionnaire_id).attributes.title}} -
              {{getQuestionnaireById(questionnaire_id).attributes.name}}
            </p>
          </div>

          <!-- Configuration -->
          <div *ngIf="getConfigurationByQuestionnaireId(questionnaire_id) as config" class="row">
            <div class="col-sm-6">
              <p *ngIf="config.feedback_required; else elseFeedbackNotRequired">
                <span>
                  <fa-icon [icon]="faComment"></fa-icon>
                  {{'instance-creation-confirmation.feedback_required' | translate}}</span
                >
              </p>
              <ng-template #elseFeedbackNotRequired>
                <p>
                  <span>
                    <fa-icon [icon]="faCommentSlash"></fa-icon>
                    {{'instance-creation-confirmation.feedback_not_required' | translate}}</span
                  >
                </p>
              </ng-template>
            </div>
            <div class="col-sm-6">
              <div *ngIf="config.unlock_type.toUpperCase()  === 'AT_DATE'; then thenAtDate else elseOtherTypes"></div>
              <ng-template #thenAtDate>
                <p>
                  {{'instance-creation-confirmation.modal_detail_instance_unlock_at' | translate}}
                  {{helper.localizeDateTimestamp(config.unlock_date, 'datetime')}}
                </p>
              </ng-template>
              <ng-template #elseOtherTypes>
                <p>{{('instance-creation-confirmation.' + config.unlock_type.toLowerCase()) | translate}}</p>
              </ng-template>
              <small *ngIf="(showUnlockHint(config.id))" class="form-text text-muted">
                {{'instance-creation-confirmation.form_create_instance_unlocks_next' | translate}}
              </small>
            </div>
          </div>

          <div *ngIf="helper.hasUnlockDiaries(questionnaire_id, questionnaires)">
            <mat-divider></mat-divider>
            <p class="py-2">{{'instance-creation-form.unlocks_diaries' | translate}}:</p>
            <div class="row">
              <div *ngFor="let diary of helper.findArrObjById(questionnaire_id, questionnaires).attributes.unlock_diaries">
                <div *appVariable="helper.findArrObjById(diary, diaries) as selectedDiary" class="col-12">
                  <fa-icon [icon]="faBook"></fa-icon>
                  {{selectedDiary !== null ? selectedDiary.attributes.name : ('lesson.diary_not_found' | translate)}}

                  <div *ngIf="selectedDiary?.attributes?.questionnaire_id">
                    <!-- <button mat-button (click)="openDialog()">{{'instance-creation-form.show_diary_configuration' | translate}}</button> -->
                    <button
                      [attr.id]="'btnOpenDialogDiaryPreview_' + selectedDiary.id"
                      mat-button
                      (click)="openDialogDiaryPreview(selectedDiary.attributes.questionnaire_id, selectedDiary.attributes.study_id, selectedDiary.attributes.page_color)"
                    >
                      <fa-icon [icon]="faEye"></fa-icon> {{'instance-creation-form.show_diary_preview' | translate}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *appVariable="helper.findArrObjById(questionnaire_id, questionnaires) as selectedQuestionnaire">
            <div *ngIf="selectedQuestionnaire.attributes?.skills">
              <div *ngIf="selectedQuestionnaire.attributes.skills.length > 0; then thenShowSkills"></div>
              <ng-template #thenShowSkills>
                <div class="button-row">
                  <button
                    [attr.id]="'btnOpenDialogSkill_' + skillId"
                    *ngFor="let skillId of selectedQuestionnaire.attributes.skills; last as isLast"
                    [ngClass]="{'spacing-right': !isLast}"
                    class="mb-2"
                    (click)="helperDialog.openDialogSkill(skillId, skills, intervention)"
                    mat-button
                  >
                    <span [ngStyle]="helperSkill.getColorStyle(skillId, skills)"
                      ><fa-icon [icon]="faSquare"></fa-icon> {{helper.findArrObjById(skillId, skills) !== null ?
                      helper.findArrObjById(skillId, skills).attributes.title : ('lesson.skill_not_found' | translate)}}</span
                    >
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </li>
      </ul>
      <!-- No custom order set -->
      <ng-template #elseNoLessonSelected>
        <li class="list-group-item text-center">
          {{'instance-creation-confirmation.form_create_instance_custom_order_no_item' | translate}}
        </li>
      </ng-template>
    </div>
    <br />
  </div>
</div>
