<div class="container-fluid text-center">
  <div *ngIf="(isLoadingSubject | async) === false; else elseLoading">
    <div class="jumbotron text-center hoverable p-3">
      <div class="mb-3">
        <mat-card appearance="outlined" class="card mx-auto mb-3">
          <mat-card-header class="card-header-center">
            <mat-card-title>
              <fa-icon
                *ngIf="intervention.attributes.publishing_state === 'accepted'"
                [attr.id]="'copyInterventionUrlToClipboard'"
                [icon]="faCopy"
                [style.cursor]="'pointer'"
                (click)="copyInterventionUrlToClipboard(intervention.id)"
                matTooltip="{{ 'intervention-detail.copyInterventionUrlToClipboard' | translate }}"
              ></fa-icon>
              {{ intervention.attributes.title || ('intervention.no_intervention_title' | translate) }}
            </mat-card-title>
            <mat-card-subtitle>
              Name: {{ intervention.attributes.name || ('intervention.no_intervention_name' | translate) }}
              (ID - {{intervention.id}})
            </mat-card-subtitle>
          </mat-card-header>
          <div class="row justify-content-center">
            <img
              [src]="helper.getAllMediaSupportPath(intervention.attributes.picture, intervention.attributes.study_id)"
              (error)="helper.getFallbackImage($event)"
              alt="No image"
              class="img-fluid card-img-top col-6"
              mat-card-image
            />
          </div>
          <mat-card-content>
            <p>
              {{((intervention.attributes.description) ? intervention.attributes.description : ('intervention.no_intervention_description' |
              translate))}}
            </p>

            <div class="row">
              <div class="col-12 col-sm-6 text-end">{{'intervention.available_languages' | translate}}:</div>
              <div class="col-12 col-sm-6 text-start">
                <div *ngFor="let code of intervention.attributes.locales">{{helper.parseLanguage(code) | translate}}</div>
              </div>
            </div>

            <ng-template #elseNoLanguageBlock>
              <p>{{'intervention.no_language_available' | translate}}</p>
            </ng-template>

            <div *ngIf="(intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length === 1)">
              <p>
                <fa-icon [icon]="faBookOpen"></fa-icon>
                {{'intervention.intervention_unlocks_diary' | translate}}
              </p>
            </div>
            <div *ngIf="(intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length > 1)">
              <p>
                <fa-icon [icon]="faBookOpen"></fa-icon>
                {{'intervention.intervention_unlocks_diaries' | translate}}
              </p>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 col-sm-6 text-end">{{'intervention-detail.unlocked_diaries' | translate}}:</div>
              <div class="col-12 col-sm-6 text-start">
                <div
                  *ngIf="((intervention.attributes.unlock_diaries !== null) && (intervention.attributes.unlock_diaries.length > 0)), then thenDiaryBlock else elseNoDiaryBlock"
                ></div>
                <ng-template #thenDiaryBlock>
                  <div *ngFor="let diary_id of intervention.attributes.unlock_diaries">
                    {{helper.findArrObjById(diary_id, diaries).attributes.title}}
                  </div>
                </ng-template>
                <ng-template #elseNoDiaryBlock>
                  <p class="text-sm-left">-</p>
                </ng-template>
              </div>
            </div>

            <div class="row justify-content-center my-2">
              <!-- Unguided/Guided/No information -->
              <div [ngSwitch]="intervention.attributes.intervention_type" class="text-padding col-sm-2">
                <span *ngSwitchCase="'unaccompanied'" class="text-info">
                  <fa-icon [icon]="faHands"></fa-icon>
                  {{'intervention.unguided' | translate}}
                </span>
                <span *ngSwitchCase="'accompanied'" class="text-info">
                  <fa-icon [icon]="faHandsHelping"></fa-icon>
                  {{'intervention.guided' | translate}}
                </span>
                <span *ngSwitchDefault class="text-muted">{{'intervention.no_information' | translate}}</span>
              </div>

              <!-- Inactive/Active -->
              <div class="text-padding col-sm-2">
                <span *ngIf="(intervention.attributes.is_active === 0); else activeBlock" class="text-danger">
                  {{'intervention.inactive' | translate}}
                </span>
                <ng-template #activeBlock>
                  <span class="text-success">{{'intervention.active' | translate}}</span>
                </ng-template>
              </div>

              <div class="text-padding col-sm-2">
                <span *ngIf="(intervention.attributes.is_running === 0); else runningBlock" class="text-danger">
                  {{'intervention.not_running' | translate}}
                </span>
                <ng-template #runningBlock>
                  <span class="text-success">{{'intervention.running' | translate}}</span>
                </ng-template>
              </div>

              <div class="text-padding col-sm-2">
                <span *ngIf="(intervention.attributes.is_test === 0); else isTestBlock" class="text-info">
                  {{'intervention.is_not_test' | translate}}
                </span>
                <ng-template #isTestBlock>
                  <span class="text-success">{{'intervention.is_test' | translate}}</span>
                </ng-template>
              </div>

              <div class="text-padding col-sm-2">
                <span class="text-info">{{'intervention.' + intervention.attributes.publishing_state | translate}}</span>
              </div>
            </div>
            <div class="justify-content-center">
              <mat-divider></mat-divider>
              <button [attr.id]="'btnGetCatalogue'" (click)="getCatalogue()" class="spacing-right my-2" mat-button>
                <fa-icon [icon]="faBookOpen"></fa-icon>
                {{'intervention-detail.to_catalogue' | translate}}
              </button>
              <button
                [attr.id]="'btnGetDetailedStudy'"
                (click)="getDetailedStudy(intervention.attributes.study_id)"
                class="spacing-right my-2"
                mat-button
              >
                <fa-icon [icon]="faUsers"></fa-icon>
                {{'intervention-detail.to_study' | translate}}
              </button>
              <div
                *ngIf="(isPublisher$ | async) && (intervention.attributes.publishing_state === 'pending') && (intervention.attributes.is_active === 0) && !(copiedInterventionBySameUser(intervention, (profile$ | async).id))"
              >
                <button
                  *ngIf="(intervention.attributes.is_test === 1)"
                  (click)="openDialogInterventionTestPublish(intervention)"
                  class="mb-2"
                  mat-raised-button
                >
                  <fa-icon [icon]="faPlus"></fa-icon>
                  {{'group-intervention-review.button_publishing' | translate}}
                </button>
                <button
                  *ngIf="(intervention.attributes.is_test === 0)"
                  (click)="openDialogInterventionOfficialPublish(intervention)"
                  class="mb-2"
                  color="primary"
                  mat-raised-button
                >
                  <fa-icon [icon]="faPlus"></fa-icon>
                  {{'group-intervention-review.button_publishing' | translate}}
                </button>
              </div>
              <div *ngIf="(isManager$ | async) && ((intervention.attributes.is_active === 0) || (intervention.attributes.is_test === 1))">
                <button
                  [attr.id]="'btnOpenDialogInterventionDelete'"
                  (click)="openDialogInterventionDelete(intervention)"
                  class="my-2"
                  mat-button
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                  {{'group-intervention-review.button_delete_intervention' | translate}}
                </button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-tab-group
        (selectedTabChange)="onChangeTab($event)"
        animationDuration="0ms"
        backgroundColor="primary"
        color="accent"
        #matTabGroup
      >
        <mat-tab label="{{'intervention-detail.lessons' | translate}}"></mat-tab>
        <mat-tab label="{{'intervention-detail.skills' | translate}}"></mat-tab>
        <mat-tab
          *ngIf="(isInterventionActive$ | async) && (isPublisherOrAccess$ | async) === false"
          label="{{'intervention-detail.collaborators' | translate}}"
        ></mat-tab>
        <mat-tab
          *ngIf="(isInterventionActive$ | async) && (isPublisherOrAccess$ | async) === false"
          label="{{'intervention-detail.instances' | translate}}"
        ></mat-tab>
        <mat-tab
          *ngIf="(isInterventionActive$ | async) && (isPublisherOrAccess$ | async) === false"
          label="{{'intervention-detail.tasks' | translate}}"
        ></mat-tab>
        <mat-tab
          *ngIf="(isInterventionActive$ | async) && (isPublisherOrAccess$ | async) === false"
          label="{{'intervention-detail.activities' | translate}}"
        ></mat-tab>
        <mat-tab label="{{'intervention-detail.diaries' | translate}}"></mat-tab>
      </mat-tab-group>
      <!-- Child components of study-detail -->
      <div class="card">
        <div class="card-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseLoading>
    <app-loader></app-loader>
  </ng-template>
</div>
