<mat-card
  appearance="outlined"
  [attr.id]="'invitation_' + invitation.id"
  *appVariable="getStudyById(invitation.attributes.model_id) as study"
  class="mx-auto mb-3"
  [ngClass]="{'alert-warning': isExpired(), 'alert-danger': invitation.attributes.is_active === 0}"
>
  <mat-card-header>
    <mat-card-title *ngIf="invitation.attributes.model_type === 'studies'">
      <div *ngIf="studies.length > 0; then thenShowStudyTitle else elseShowSimplifiedTitle"></div>
      <ng-template #elseShowSimplifiedTitle>
        {{'group-member.modal_list_pending_invitations_study_for' | translate}} {{invitation.attributes.email}}
      </ng-template>
      <ng-template #thenShowStudyTitle>
        {{'patient-invitation-study.modal_list_pending_invitations_study_for' | translate}} {{invitation.attributes.email}}
        {{'patient-invitation-study.modal_list_pending_invitations_to_study' | translate}} "{{study?.attributes.name}}"
      </ng-template>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="row mx-2 mb-2">
      <img
        class="col-4"
        [src]="helper.getAllMediaSupportPath(study?.attributes.picture, study?.id)"
        (error)="helper.getFallbackImage($event)"
        alt="No image"
        class="image-parent img-fluid"
      />
      <div class="col-8">
        <p>
          <strong> {{(study?.attributes.title) ? study?.attributes.title : 'patient-invitation-study.no_group_title' |translate}} </strong>
        </p>
        <p><small class="text-muted">({{study?.attributes.name}}) (ID - {{study?.id}})</small></p>
      </div>
    </div>

    <!-- Expiration -->
    <div *ngIf="invitation.attributes.is_active === 1; then thenShowExpirationDate else elseShowInvalidInvitation"></div>
    <ng-template #thenShowExpirationDate>
      <div class="card-text text-sm-left">
        <div *ngIf="isExpired(); then thenShowExpiredRenew else elseShowDate"></div>
        <ng-template #thenShowExpiredRenew>
          <span class="text-danger">{{'group-member.modal_list_pending_invitations_is_expired_renew' | translate}}</span>
        </ng-template>
        <ng-template #elseShowDate>
          <span class="text-info"
            >{{'group-member.modal_list_pending_invitations_is_active_until' | translate}}
            {{helper.localizeDateTimestamp(invitation.attributes.expires_at, 'datetime')}}
          </span>
        </ng-template>
      </div>
    </ng-template>
    <ng-template #elseShowInvalidInvitation>
      <div class="card-text text-sm-left">{{'group-member.modal_list_pending_invitations_invalid_invitation' | translate}}</div>
    </ng-template>

    <section *ngIf="isManager" class="mt-2">
      <mat-form-field appearance="outline">
        <mat-label>{{'group-member.modal_list_pending_invitations_study_code' | translate}}</mat-label>
        <input [attr.id]="'studyCode' + invitation.id" [(ngModel)]="studyCode" matInput type="text" />
      </mat-form-field>
      <app-button-feedback
        [_setId]="'btnAddUpdateStudyCode_' + invitation.id"
        (click)="addUpdateStudyCode(invitation.attributes.model_id, invitation.attributes.email, invitation.id, studyCode)"
        [_defaultButtonText]="'group-member.save'"
        [_responseText]="addUpdateStudyCodeResponse | async"
        [_setIcon]="faSave"
      ></app-button-feedback>
      <app-button-feedback
        [_setId]="'btnDeleteStudyCode_' + invitation.id"
        (click)="deleteStudyCode(invitation.attributes.model_id, invitation.attributes.email, invitation.id)"
        [_defaultButtonText]="'group-member.delete'"
        [_responseText]="deleteStudyCodeResponse | async"
        [_setIcon]="faEraser"
      ></app-button-feedback>
    </section>

    <section *ngIf="isECoach" class="mt-2">
      <mat-form-field appearance="outline">
        <mat-label>{{'group-member.modal_list_pending_invitations_study_code' | translate}}</mat-label>
        <input [attr.id]="'studyCode_' + invitation.id" [(ngModel)]="studyCode" matInput type="text" />
      </mat-form-field>
      <app-button-feedback
        [_setId]="'btnAddUpdateStudyCode_' + invitation.id"
        (click)="addUpdateStudyCode(invitation.attributes.model_id, invitation.attributes.email, invitation.id, studyCode)"
        [_defaultButtonText]="'group-member.save'"
        [_responseText]="addUpdateStudyCodeResponse | async"
        [_setIcon]="faSave"
      ></app-button-feedback>
      <app-button-feedback
        [_setId]="'btnDeleteStudyCode_' + invitation.id"
        (click)="deleteStudyCode(invitation.attributes.model_id, invitation.attributes.email, invitation.id)"
        [_defaultButtonText]="'group-member.delete'"
        [_responseText]="deleteStudyCodeResponse | async"
        [_setIcon]="faEraser"
      ></app-button-feedback>
    </section>
  </mat-card-content>
  <mat-card-actions align="end">
    <app-button-feedback
      [_setId]="'btnResendStudyInvitation_' + invitation.id"
      (click)="resendStudyInvitation(invitation.attributes.model_id, invitation.attributes.email, invitation)"
      [_defaultButtonText]="'group-member.resend_study_invitation'"
      [_responseText]="resendStudyInvitationResponse | async"
      [_setIcon]="faRedo"
    ></app-button-feedback>
    <app-button-feedback
      [_setId]="'btnDeleteStudyInvitation' + invitation.id"
      (click)="deleteStudyInvitation(invitation.attributes.model_id, invitation.attributes.email, invitation.id)"
      *ngIf="isManager || isECoach"
      [_defaultButtonText]="'group-member.delete_study_invitation'"
      [_responseText]="deleteStudyInvitationResponse | async"
      [_setIcon]="faTrash"
    ></app-button-feedback>
  </mat-card-actions>
</mat-card>
