import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VariableDirective } from '../../helpers/directives/variable/variable.directive';
import { OnlyNumberDirective } from 'src/app/helpers/directives/only-numbers/only-numbers.directive';

@NgModule({
  declarations: [VariableDirective, OnlyNumberDirective],
  imports: [CommonModule],
  exports: [VariableDirective, OnlyNumberDirective]
})
export class DirectiveModule {}
