/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons/faCommentSlash';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { AlertService } from '../../../services/alert/alert.service';
import { InterventionInstanceConfigurationInterface } from '../../../models/interface/intervention-instances/intervention-instance.configuration.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { filter, skip, take } from 'rxjs/operators';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { Store } from '@ngrx/store';
import { StudyActionTypes } from '../../../store/study/study.action';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { HttpResponse } from '@angular/common/http';

/**
 * Component:
 * Intervention creation confirmation displaying the configuration before assigning the intervention;
 */

@Component({
  selector: 'app-instance-creation-confirmation',
  templateUrl: './instance-creation-confirmation.component.html',
  styleUrls: ['./instance-creation-confirmation.component.scss'],
  providers: [DiaryStore, InterventionInstanceStore, SkillStore]
})
export class InstanceCreationConfirmationComponent implements OnInit, OnDestroy {
  // Icon
  faComment = faComment;
  faCommentSlash = faCommentSlash;
  faHandsHelping = faHandsHelping;
  faHands = faHands;
  faBook = faBook;
  faEye = faEye;
  faSquare = faSquare;

  public instancePayload: PayloadInterface = null;
  public instanceAttributes: {
    ecoach_ids: Array<number>;
    patient_id: number;
    intervention_id: number;
    intervention_type: string;
    configuration: {
      questionnaire_configuration: Array<InterventionInstanceConfigurationInterface>;
      custom_order: Array<number>;
      starting_at: number;
    };
  };

  // MyUserId
  public myUserId: number;

  public studyMembers: Array<UserInterface> = [];
  public studyCollaborator: Array<UserInterface> = [];

  public formCustomOrder: Array<number> = [];
  public unconfirmedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public intervention: InterventionInterface;
  public questionnaires: Array<LessonInterface> = [];

  public diaries: Array<DiaryInterface> = [];
  public diariesOfStudy$: Observable<Array<DiaryInterface>>;

  public skills: Array<SkillInterface> = [];

  public skillsOfIntervention$: Observable<SkillInterface[]>;

  public reminderConfig: { payload: PayloadInterface; intervention: InterventionInterface };

  public defaultReminderText = {
    de: {
      greeting: 'Guten Tag,',
      defaultText:
        'bislang wurde die Lektion noch nicht abgeschlossen. Wir möchten mit dieser E-Mail nur an die Bearbeitung erinnern. Wir wissen, dass so ein Training manchmal schwer im Alltag unterzubringen ist – gerade in schwierigen Zeiten kann es gar nicht so einfach sein, etwas für sich zu tun. Gerade dann kann es aber hilfreich sein, am Training dranzubleiben. Wir möchten daher gerne noch einmal dazu einladen, sich die Zeit für das Training zu nehmen.',
      ending: 'Mit besten Grüßen,',
      defaultEnding: 'das eSano Team'
    },
    en: {
      greeting: 'Hello,',
      defaultText:
        'So far the lessons have not yet been completed. With this e-mail we only want to remind you of the processing. We know that such a training is sometimes difficult to accommodate in everyday life - especially in difficult times it cannot be that easy to do something for yourself. But then it can be helpful to stick with the training. We would therefore like to invite you once again to make time for training.',
      ending: 'Best wishes,',
      defaultEnding: 'your eSano team'
    }
  };

  private createInstanceAsECoachResponse$: Observable<any>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private positionType: Array<string> = ['-1'];

  private subscriptions: Subscription[] = [];

  constructor(
    private diaryStore: DiaryStore,
    private interventionInstanceStore: InterventionInstanceStore,
    private skillStore: SkillStore,
    private alertService: AlertService,
    private helperService: HelperService,
    private helperSkillService: HelperSkillService,
    private helperDialogService: HelperDialogService,
    private store: Store<{
      skills: Array<SkillInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>
  ) {
    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.createInstanceAsECoachResponse$ = this.interventionInstanceStore.createInstanceAsECoachResponse$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get alert() {
    return this.alertService;
  }

  // Set study and its configuration from parent
  @Input() _intervention(_intervention: InterventionInterface) {
    this.intervention = _intervention;
  }

  // Set members of study from parent
  @Input() _members(_members: Array<UserInterface>) {
    this.studyMembers = _members;
  }

  // Set collaborators of study from parent
  @Input() _collaborators(_collaborators: Array<UserInterface>) {
    this.studyCollaborator = _collaborators;
  }

  @Input() _payload(_payload: PayloadInterface) {
    if (_payload) {
      this.instancePayload = _payload;
      this.instanceAttributes = _payload.data.attributes;
      this.formCustomOrder = this.instanceAttributes.configuration.custom_order;
    }
  }

  @Input() _questionnaires(_questionnaires: Array<LessonInterface>) {
    this.questionnaires = _questionnaires;
    // Indexing position of questionnaire
    this.questionnaires.forEach((questionnaire, index) => {
      this.positionType.push(index.toString());
    });
  }

  @Input() _myUserId(_myUserId: number) {
    this.myUserId = _myUserId;
  }

  ngOnInit(): void {
    this.store.dispatch({
      type: StudyActionTypes.getCollaboratorsType,
      payload: { studyId: this.intervention.attributes.study_id, include: 'roles' }
    });

    this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.intervention.attributes.study_id));
    this.subscriptions.push(
      this.collaboratorsOfStudy$
        .pipe(
          filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
          take(1)
        )
        .subscribe(result => {
          this.studyCollaborator = result.collaborators;
        })
    );
    this.diaryStore.getDiariesOfStudy({ studyId: this.intervention.attributes.study_id });
    this.diariesOfStudy$.subscribe((result: any) => {
      this.diaries = result;
    });

    this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
    this.subscriptions.push(
      this.skillsOfIntervention$.subscribe(result => {
        this.skills = result;
      })
    );
  }

  public getQuestionnaireById(questionnaireId: number): LessonInterface {
    const found = this.questionnaires.find((questionnaire: LessonInterface) => questionnaire.id.toString() === questionnaireId.toString());
    return found !== undefined ? found : null;
  }

  public getConfigurationByQuestionnaireId(questionnaireId: number): InterventionInstanceConfigurationInterface {
    const found = this.instanceAttributes.configuration.questionnaire_configuration.find(
      (config: InterventionInstanceConfigurationInterface) => config.id.toString() === questionnaireId.toString()
    );
    return found !== undefined ? found : null;
  }

  public createInstance(userId?: number): Observable<any> {
    this.instancePayload.data.attributes.ecoach_ids = this.instancePayload.data.attributes.ecoach_ids.filter(
      (id: number) => id.toString() !== this.myUserId.toString()
    );
    if (!!userId) {
      this.instancePayload.data.attributes.patient_id = userId;
    }
    this.interventionInstanceStore.createInstanceAsECoach({ payload: this.instancePayload });
    return this.createInstanceAsECoachResponse$.pipe(
      filter(value => !!value),
      take(1)
    );
  }

  public showUnlockHint(questionnaireId: number): boolean {
    const foundConfig = this.instanceAttributes.configuration.questionnaire_configuration.find(
      (config: InterventionInstanceConfigurationInterface) => config.id.toString() === questionnaireId.toString()
    );
    if (!foundConfig) {
      return false;
    }
    // Previous position based on form_custom_order
    const currentPos = this.instanceAttributes.configuration.custom_order.findIndex(
      value => value.toString() === questionnaireId.toString()
    );
    // const previous_pos = current_pos - 1;
    const previousPos = currentPos - 1;
    let foundPredecessor: InterventionInstanceConfigurationInterface;
    if (previousPos >= 0) {
      foundPredecessor = this.instanceAttributes.configuration.questionnaire_configuration.find(
        (config: InterventionInstanceConfigurationInterface) =>
          config.id.toString() === this.instanceAttributes.configuration.custom_order[previousPos].toString()
      );
    }
    return foundPredecessor ? foundConfig.unlock_type === 'after_previous' && foundPredecessor.feedback_required : false;
  }

  public openDialogDiaryPreview(questionnaireId: number, studyId: number, diaryPageColor: string): void {
    this.helperDialog
      .openDialogDiaryPreview(questionnaireId, studyId, diaryPageColor)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogInterventionInstanceReminderCreation(reminder?: {
    payload: PayloadInterface;
    intervention: InterventionInterface;
  }): void {
    this.helperDialog
      .openDialogCreateInterventionInstanceReminder(undefined, undefined, reminder?.payload)
      .afterClosed()
      .subscribe(result => {
        if (!(result instanceof HttpResponse) && !!result) {
          this.reminderConfig = { payload: result, intervention: this.intervention };
        }
      });
  }

  public removeReminderConfig(): void {
    this.reminderConfig = null;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
