<div class="card my-3">
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-sm-3 mb-2">
        <button id="btnToggleFilter" (click)="helper.toggleSubject(isCollapseSubject)" class="mt-1" mat-raised-button type="submit">
          <fa-icon [icon]="faList"></fa-icon>
          Filter
        </button>
      </div>

      <div class="col-12 col-sm-6 mb-2">
        <mat-form-field [style.width.%]="100" appearance="outline">
          <mat-label>{{'answersheets.thread_search' | translate}}</mat-label>
          <input
            [attr.id]="'search'"
            (keyup.enter)="applyFilter()"
            [(ngModel)]="filter['searchFilter']"
            aria-describedby="interventionSearch"
            aria-label="Search"
            matInput
            type="text"
          />
          <button [attr.id]="'btnSearchAnswersheet'" (click)="applyFilter()" aria-label="Search" mat-button mat-button matSuffix>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <section class="checkbox-center">
      <mat-checkbox [attr.id]="'checkboxPendingActions'" [(ngModel)]="filter['filterPendingActions']" (change)="applyFilter()"
        >{{'answersheets.include_answersheets_pending_actions' | translate}}</mat-checkbox
      >
    </section>

    <app-pagination-list
      #paginator
      (emitPageChange)="updatePagedAnswersheets($event)"
      [_isLoading]="isLoading$ | async"
      [_items]="answersheetsContent$ | async"
    ></app-pagination-list>

    <div class="row justify-content-center">
      <!-- Content of searchFilter - Selections, radio buttons -->
      <div *ngIf="(isCollapse$ | async) === false" class="col-sm-4 text-start">
        <div class="mb-2">
          <mat-checkbox [attr.id]="'checkboxFBRequired'" (change)="applyFilter()" [(ngModel)]="filter['isFBRequired']"
            >{{'answersheets.answersheet_is_feedback_required' | translate }}</mat-checkbox
          >
        </div>

        <mat-divider></mat-divider>
        <div class="my-2">
          <p><strong>{{'answersheets.answersheet_description_sort_by' | translate}}</strong></p>
          <mat-form-field appearance="outline">
            <mat-label>{{'answersheets.sort_by' | translate}}</mat-label>
            <mat-select [attr.id]="'selectCreationDate'" (selectionChange)="applyFilter()" [(value)]="filter['sortCreatedDate']">
              <mat-option [attr.id]="'option_' + type" *ngFor="let type of sortCreation" [value]="type">{{type | translate}} </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-divider></mat-divider>

          <div class="mt-2">
            <label id="filter-radio-group-label"><strong>{{'answersheets.feedback_filtered_by' | translate}}</strong></label>
            <mat-radio-group
              [attr.id]="'radioFBStatus'"
              aria-label="select read status"
              aria-labelledby="filter-radio-group-label"
              class="filter-radio-group"
              [(ngModel)]="filter['feedbackFilter']"
              (change)="applyFilter()"
            >
              <mat-radio-button id="radioItem2" class="filter-radio-button" value="2"
                >{{'answersheets.no_selection' | translate}}</mat-radio-button
              >
              <mat-radio-button id="radioItem1" class="filter-radio-button" value="1"
                >{{'answersheets.unread' | translate}}</mat-radio-button
              >
              <mat-radio-button id="radioItem0" class="filter-radio-button" value="0">{{'answersheets.read' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div *ngIf="isLoading$ | async; else elseFeedbackBlock" class="col-sm">
        <app-loader></app-loader>
      </div>

      <ng-template #elseFeedbackBlock>
        <div class="col-12 col-sm-8">
          <div *ngFor="let answersheet of pagedAnswersheets$ | async; let i = index; trackBy: helper.trackByElement">
            <mat-card appearance="outlined" [attr.id]="'answersheet_' + answersheet.id" class="card mx-auto mb-3">
              <mat-card-header class="card-header-center">
                <mat-card-title
                  >{{'answersheets.answersheet_of_lesson' | translate}} '{{helper.findArrObjById(answersheet.attributes.questionnaire_id,
                  questionnaires)?.attributes.name}}'
                </mat-card-title>
                <mat-card-subtitle>
                  <div
                    *ngIf="helperFeedback.isFeedbackRequired(answersheet.id, getInterventionInstance(answersheet.attributes.intervention_instance_id)); then thenShowIsRequired else elseShowNotRequired"
                  ></div>
                  <ng-template #thenShowIsRequired>
                    <div
                      *ngIf="helperFeedback.hasFeedback(answersheet.id, threads); then thenShowFeedback else elseShowFeedbackRequired"
                    ></div>
                    <ng-template #thenShowFeedback>
                      <div
                        *ngIf="helperFeedback.isRead(answersheet.id, getInterventionInstance(answersheet.attributes.intervention_instance_id), getThreadByAnswersheet(answersheet.id)); then thenIsRead else elseNotRead"
                      ></div>
                      <ng-template #thenIsRead>
                        <p>
                          <span class="text-success font-weight-bold">
                            <fa-icon [icon]="faCheckCircle"></fa-icon>
                            {{'answersheets.feedback_read' | translate}}
                          </span>
                        </p>
                      </ng-template>
                      <ng-template #elseNotRead>
                        <p>
                          <span class="text-info font-weight-bold">
                            <fa-icon [icon]="faCircle"></fa-icon>
                            {{'answersheets.feedback_not_read' | translate}}
                          </span>
                        </p>
                      </ng-template>
                    </ng-template>
                    <ng-template #elseShowFeedbackRequired>
                      <p>
                        <span class="text-danger font-weight-bold">
                          <fa-icon [icon]="faComment"></fa-icon>
                          {{'answersheets.answersheet_feedback_mandatory' | translate}}
                        </span>
                      </p>
                    </ng-template>
                  </ng-template>
                  <ng-template #elseShowNotRequired>
                    <small>
                      <span class="font-weight-light">
                        <fa-icon [icon]="faCommentSlash"></fa-icon>
                        {{'answersheets.answersheet_feedback_optional' | translate}}
                      </span>
                    </small>
                  </ng-template>

                  <p class="text-danger">
                    <span
                      *ngIf="(getThreadByAnswersheet(answersheet.id)?.attributes.unread.is_unread) && (getThreadByAnswersheet(answersheet.id)?.attributes.unread.messages > 0)"
                    >
                      <fa-icon [icon]="faEnvelope"></fa-icon>
                      {{'answersheets.new_messages' | translate}} :
                      {{getThreadByAnswersheet(answersheet.id)?.attributes.unread.messages}}</span
                    >
                  </p>
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <p
                  *ngIf="helper.findArrObjById(getInterventionInstance(answersheet.attributes.intervention_instance_id)?.attributes.patient_id, users) !== null && answersheet.attributes.intervention_instance_id !== null && getInterventionInstance(answersheet.attributes.intervention_instance_id) !== null; else elseNoUserFound"
                  class="mb-1 break-word font-weight-light"
                >
                  {{'answersheets.answersheet_submitted_by' | translate}} {{helper.getCodeNameEmail(answersheet.attributes.user_id, users,
                  getInterventionInstance(answersheet.attributes.intervention_instance_id).attributes.study_id)}}
                </p>
                <ng-template #elseNoUserFound> {{('answersheets.answersheet_no_user_found' | translate)}} </ng-template>
                <p class="mb-1">
                  {{'answersheets.answersheet_collected_at' | translate}}
                  {{helper.localizeDateTimestamp(answersheet.attributes.collected_at, 'datetime')}}
                </p>

                <div *ngIf="(getThreadByAnswersheet(answersheet.id) !== null)" class="card border-info mt-3">
                  <div *appVariable="getThreadByAnswersheet(answersheet.id) as thread" class="card-body text-info text-start">
                    <p class="card-title mb-1">
                      <strong
                        >{{((thread.attributes.subject) ? thread.attributes.subject : 'answersheets.no_intervention_name') |
                        translate}}</strong
                      >
                    </p>
                    <p class="card-text mb-1">
                      {{'answersheets.feedback_created_at' | translate}} {{helper.localizeDateString(thread.attributes.created_at)}}
                    </p>
                    <p class="card-text mb-1">
                      {{'answersheets.feedback_updated_at' | translate}} {{helper.localizeDateString(thread.attributes.updated_at)}}
                    </p>
                  </div>
                </div>
              </mat-card-content>
              <mat-card-actions [align]="'end'">
                <button
                  [attr.id]="'btnGetAnswersheetDetails'"
                  (click)="getAnswersheetDetails(answersheet.id)"
                  class="spacing-right mb-2"
                  mat-button
                >
                  <span *ngIf="!helperFeedback.hasFeedback(answersheet.id, threads); else elseShowFeedback">
                    {{'answersheets.answersheet_see_answers' | translate}}
                  </span>
                  <ng-template #elseShowFeedback>
                    <span>{{'answersheets.show_feedback' | translate}}</span>
                  </ng-template>
                </button>

                <button
                  [attr.id]="'btnOpenDialogInterventionIntDetails_' + answersheet.attributes.intervention_instance_id"
                  (click)="openDialogInterventionInstanceDetails(answersheet.attributes.intervention_instance_id)"
                  *ngIf="answersheet.attributes.intervention_instance_id !== null"
                  class="mb-2"
                  mat-button
                  type="button"
                >
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                  {{'answersheets.modal_detail_instance_details' | translate}}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
