/* eslint-disable @typescript-eslint/naming-convention */
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, Type, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpenText';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import { BehaviorSubject, forkJoin, iif, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { UserInterface } from '../../../models/interface/user.interface';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { OrganisationCollaboratorInterface } from '../../../models/interface/organisation/organisation-collaborator.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { Action, Store } from '@ngrx/store';
import { HelperService } from '../../../services/helper/helper.service';
import { MediaGalleryComponent } from '../../media/media-gallery/media-gallery.component';
import { GetOrganisationCollaboratorsSuccess, OrganisationActionTypes } from '../../../store/organisation/organisation.action';
import { HttpResponse } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { InterventionActionTypes } from '../../../store/intervention/intervention.action';
import { getCollabInterventionsByStudyId } from '../../../store/intervention/intervention.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyMediaInterface } from '../../../models/interface/study/study-media.interface';
import { ReminderStore } from 'src/app/store/reminder/component-store/reminder.store';
import { StudyDetailsTranslationsInterface } from 'src/app/models/interface/study/study-details_translations.interface';

/**
 * Component:
 * Group details page displaying a navigation bar for its child component;
 * Can be found: {uri}/groups/{{group_id}}
 */

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss'],
  providers: [StudyStore, ReminderStore]
})
export class GroupDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('matTabGroup') matTabGroup: QueryList<MatTabGroup>;

  @ViewChild('mediaGalleryContainer', { read: ViewContainerRef }) mediaGalleryContainer: ViewContainerRef;
  public components;

  // Icons
  faEnvelopeOpenText = faEnvelopeOpenText;
  faKey = faKey;
  faCheckSquare = faCheckSquare;
  faFileCsv = faFileCsv;

  public isLoading$: Observable<boolean>;

  // Data provided by StudyService
  public group: StudyInterface;
  public collaborators: Array<UserInterface>;

  public profile: ProfileInterface;

  public isECoach$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public isOwner$: Observable<boolean>;
  public isPublisher$: Observable<boolean>;
  public supportsBuddy$: Observable<boolean>;

  public profile$: Observable<ProfileInterface> = null;

  public studyDetail$: Observable<StudyInterface | StudyDetailsTranslationsInterface>;

  public supportsBuddySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOrganisationCollaboratorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private translationSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private studyMedia$: Observable<Array<StudyMediaInterface>>;

  private getCSVReminderLogsOfStudyResponse$: Observable<any>;

  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isECoachStudySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOwnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isPublisherSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isOrganisationManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private activeChildrouteSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private subscriptions: Subscription[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private studyStore: StudyStore,
    private reminderStore: ReminderStore,
    private actions$: Actions,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollabInterventionsByStudyId: Array<InterventionInterface>;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
    }>,
    private sharedService: GroupSharedService
  ) {
    this.profile$ = store.select('myProfile');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isECoach$ = this.isECoachStudySubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.isOwner$ = this.isOwnerSubject.asObservable();
    this.isPublisher$ = this.isPublisherSubject.asObservable();
    this.supportsBuddy$ = this.supportsBuddySubject.asObservable();
    this.studyDetail$ = this.studyStore.studyDetail$;
    this.studyMedia$ = this.studyStore.studyMedia$;
    this.getCSVReminderLogsOfStudyResponse$ = this.reminderStore.getCSVReminderLogsOfStudyResponse$;
    this.subscriptions.push(
      this.translateService
        .getTranslation(localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de')
        .subscribe(translations => {
          this.translationSubject.next(translations);
        })
    );
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  public get translation() {
    return this.translateService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profile$
        .pipe(
          filter(user => user !== null),
          take(1),
          switchMap((result: ProfileInterface) => {
            this.profile = result;
            return this.actRoute.params;
          }),
          switchMap(param => {
            this.studyStore.getStudyDetail({ studyId: param.id });
            return this.studyDetail$.pipe(skip(1), take(1));
          }),
          switchMap((result: any) => {
            this.group = result;
            if (this.group.attributes.organisation_id !== null) {
              this.store.dispatch({
                type: OrganisationActionTypes.getOrganisationCollaboratorsType,
                payload: {
                  organisationId: parseInt(this.group.attributes.organisation_id.toString(), 10)
                }
              });

              return this.actions$.pipe(
                ofType(
                  OrganisationActionTypes.getOrganisationCollaboratorsSuccessType,
                  OrganisationActionTypes.getOrganisationCollaboratorsErrorType
                ),
                take(1),
                mergeMap((action: Action) => {
                  if (action.type === OrganisationActionTypes.getOrganisationCollaboratorsSuccessType) {
                    const resp: HttpResponse<any> = (action as GetOrganisationCollaboratorsSuccess).response;
                    const collaborators: Array<OrganisationCollaboratorInterface> = resp['response']['body']['data'];
                    const myself: OrganisationCollaboratorInterface = collaborators.find(
                      user => user.id.toString() === this.profile.id.toString()
                    );
                    if (myself) {
                      const myroleSlug = this.helperService.getHighestRoleOfOrganisationCollaborator(myself);
                      if (myroleSlug.match(/organisation\.(access|manager|owner)$/)) {
                        this.isOrganisationCollaboratorSubject.next(true);
                      }
                      if (myroleSlug.match(/organisation\.(manager|owner)$/)) {
                        return of(true);
                      }
                    }
                  }
                  return of(false);
                }),
                catchError(() => of(false))
              );
            } else {
              return of(false);
            }
          }),
          mergeMap((result: boolean) => {
            this.isOrganisationManagerSubject.next(result);
            this.sharedService.pushIsOrganisationManager(result);
            this.sharedService.pushGroup(this.group);

            this.store.dispatch({
              type: InterventionActionTypes.getInterventionsOfCollabStudyType,
              payload: { typeOfParentStudy: 'study' }
            });

            this.allInterventionsOfStudy$ = this.store.select(getCollabInterventionsByStudyId(this.group.id));
            return this.allInterventionsOfStudy$.pipe(skip(1), take(1));
          }),
          mergeMap((interventionsResp: Array<InterventionInterface>) => {
            let interventions: Array<InterventionInterface> = interventionsResp;
            interventions = interventions.filter((intervention: InterventionInterface) => intervention.attributes.buddy_support === 1);
            this.supportsBuddySubject.next(interventions.length > 0);
            return this.getInterventionContent();
          }),
          catchError(error => throwError(error))
        )
        .subscribe(
          () => {
            this.isLoadingSubject.next(false);
          },
          () => {
            this.router.navigateByUrl('/groups');
          }
        )
    );

    this.subscriptions.push(
      this.translateService.onLangChange
        .pipe(
          switchMap((event: LangChangeEvent) => {
            this.isLoadingSubject.next(true);
            return this.translateService.getTranslation(
              localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'de'
            );
          }),
          switchMap(translations => {
            this.translationSubject.next(translations);
            return this.getInterventionContent();
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.sharedService.sourceGroup$.subscribe((group: StudyInterface) => {
        this.group = group;
      })
    );
  }

  ngAfterViewInit(): void {
    const url = this.router.url;
    this.subscriptions.push(
      this.matTabGroup.changes.subscribe((comps: QueryList<MatTabGroup>) => {
        this.helper.setMatTabActive(this.matTabGroup, this.router.url, this.translationSubject, this.translateService, 'group-detail');
      })
    );
  }

  // Display and navigate to child component relative to this path
  public showChildComponent(child: string): void {
    if (this.activeChildrouteSubject.value !== child) {
      if (
        child.match(
          /^(interventions|members|requests|collaborators|activities|instances|tasks|configuration|review|buddies|diaryInstances)$/
        )
      ) {
        this.router.navigate([child], { relativeTo: this.actRoute });
      }
    }
  }

  public initializeMediaGalleryComponent(componentClass: Type<any>, studyId: number): void {
    this.components = [];
    this.mediaGalleryContainer.clear();
    const component: any = this.mediaGalleryContainer.createComponent<MediaGalleryComponent>(componentClass).instance;

    this.studyStore.getStudyMedia({ studyId });
    this.studyMedia$.pipe(skip(1), take(1)).subscribe(result => {
      component._studyMedia = result;
      component._studyId = studyId;
    });
    this.components = [...this.components, component];
  }

  // Listens to tab change
  public onChangeTab(event): void {
    const tab: MatTab = event.tab;
    this.showChildComponent(this.evaluateTab(tab.textLabel, this.translationSubject.value));
  }

  public openDialogStudyReport(): void {
    this.helperDialog
      .openDialogStudyReport(this.group)
      .afterClosed()
      .subscribe(() => {});
  }

  public openDialogCodeBook(): void {
    this.helperDialog
      .openDialogCodeBook(this.group)
      .afterClosed()
      .subscribe(() => {});
  }

  public openDialogAccessCodeBatches(): void {
    this.helperDialog
      .openDialogAccessCodeBatches(this.group, this.collaborators)
      .afterClosed()
      .subscribe(() => {});
  }

  public getDetailedOrganisation(organisationId: number): void {
    this.router.navigateByUrl(`/organisations/${organisationId}`);
  }

  public generateCSVReminderLogsReport(): void {
    this.reminderStore.getReminderLogsOfStudyCSV({ studyId: this.group.id });
    this.getCSVReminderLogsOfStudyResponse$.subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private evaluateTab(label, translate: any): string {
    if (translate) {
      if (translate['group-detail']['interventions'] === label) {
        return 'interventions';
      }
      if (translate['group-detail']['members'] === label) {
        return 'members';
      }
      if (translate['group-detail']['collaborators'] === label) {
        return 'collaborators';
      }
      if (translate['group-detail']['instances'] === label) {
        return 'instances';
      }
      if (translate['group-detail']['tasks'] === label) {
        return 'tasks';
      }
      if (translate['group-detail']['activities'] === label) {
        return 'activities';
      }
      if (translate['group-detail']['diaries'] === label) {
        return 'diaryInstances';
      }
      if (translate['group-detail']['configurations'] === label) {
        return 'configuration';
      }
      if (translate['group-detail']['review'] === label) {
        return 'review';
      }
      if (translate['group-detail']['buddies'] === label) {
        return 'buddies';
      }
    }

    return '';
  }

  // Get and display detailed study details
  private getInterventionContent(): Observable<any> {
    return this.actRoute.params.pipe(
      map(v => v.id),
      take(1),
      switchMap(id => {
        const reqs: Array<Observable<any>> = [];
        this.studyStore.getStudyDetail({ studyId: id });
        reqs.push(this.studyDetail$.pipe(skip(1), take(1)));
        this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: id, include: 'roles' } });
        this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(id));
        reqs.push(
          this.collaboratorsOfStudy$.pipe(
            filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
            take(1)
          )
        );
        return forkJoin(reqs);
      }),
      mergeMap((results: Array<any>) => {
        this.group = results[0];
        this.collaborators = results[1].collaborators;

        // Determine my highest role
        const myself: UserInterface = this.collaborators.find(user => user.id.toString() === this.profile.id.toString());
        if (myself !== undefined) {
          const myroleSlug = this.helper.getHighestRoleOfCollaborator(myself);

          const isECoach = !!myroleSlug.match(/study\.(ecoach|ecoachmanager|owner)$/);
          this.isECoachStudySubject.next(isECoach);
          this.sharedService.pushIsECoach(isECoach);

          const isManager = !!myroleSlug.match(/study\.(ecoachmanager|owner)$/);
          this.isManagerSubject.next(isManager);
          this.sharedService.pushIsManager(isManager);

          this.isPublisherSubject.next(this.helper.hasRoles(myself.relationships?.roles.data, /study\.publisher$/));
          this.sharedService.pushIsPublisher(this.isPublisherSubject.value);

          if (myroleSlug.match(/study\.owner$/)) {
            this.isOwnerSubject.next(true);
          }

          this.sharedService.pushIsCollaborating(true);
        } else {
          this.sharedService.pushIsCollaborating(false);
        }

        this.isLoadingSubject.next(false);
        return of(null);
      }),
      catchError(error => throwError(error))
    );
  }
}
