/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserInterface } from '../../../models/interface/user.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelperService } from '../../../services/helper/helper.service';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { HttpResponse } from '@angular/common/http';
import { HelperDialogService } from 'src/app/services/helper/helper-dialog/helper-dialog.service';

@Component({
  selector: 'app-dialog-buddy-instance-create',
  templateUrl: './dialog-buddy-instance-create.component.html',
  styleUrls: ['./dialog-buddy-instance-create.component.css'],
  providers: [BuddyStore]
})
export class DialogBuddyInstanceCreateComponent implements OnInit, OnDestroy {
  // Icons
  faHands = faHands;
  faHandsHelping = faHandsHelping;

  public progress = 0;

  public members: Array<UserInterface>;
  public membersSelection1Subject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  public membersSelection2Subject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  public interventionInstancesBuddy1: Array<InterventionInstanceInterface> = [];
  public interventionInstancesBuddy2: Array<InterventionInstanceInterface> = [];
  public diaryInstancesBuddy1: Array<DiaryInstanceInterface> = [];
  public diaryInstancesBuddy2: Array<DiaryInstanceInterface> = [];
  public param;
  public interventions: Array<InterventionInterface> = [];
  public studyId: number;
  public diaryInstances: Array<DiaryInstanceInterface>;
  public interventionInstances: Array<InterventionInstanceInterface>;
  public lessonParam1;
  public lessonParam2;

  public diaries: Array<DiaryInterface>;

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];

  // Selected buddies
  public selectedBuddy1: UserInterface;
  public selectedInterventionInstance1: InterventionInstanceInterface;
  public selectedDiaryInstance1: DiaryInstanceInterface;

  public selectedBuddy2: UserInterface;
  public selectedInterventionInstance2: InterventionInstanceInterface;
  public selectedDiaryInstance2: DiaryInstanceInterface;

  // Form submission
  public submitted = false;

  public connectBuddiesResponse$: Observable<any>;
  public createBuddyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Filter
  public filter = {
    userSelection: ''
  };

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private dialogRef: MatDialogRef<DialogBuddyInstanceCreateComponent>,
    private buddyStore: BuddyStore
  ) {
    this.connectBuddiesResponse$ = this.buddyStore.connectBuddiesResponse$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.members = this.data.members;
    this.param = this.data.param;
    this.interventions = this.data.interventions;
    this.interventionInstances = this.data.interventionInstances;
    this.diaryInstances = this.data.diaryInstances;
    this.diaries = this.data.diaries;
    this.studyId = this.data.studyId;

    if (this.members.length > 0) {
      this.membersSelection1Subject.next(this.members);
      this.membersSelection2Subject.next(this.members);
      this.resetSelection1();
      this.resetSelection2();
    }
  }

  public onChangeSelection1(): void {
    if (this.selectedBuddy2) {
      this.membersSelection1Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2.id.toString())
      );
    }
    if (this.selectedBuddy1) {
      this.membersSelection2Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1.id.toString())
      );
    }
    this.resetSelection1();
  }

  public onChangeSelection2(): void {
    if (this.selectedBuddy2) {
      this.membersSelection1Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2.id.toString())
      );
    }
    if (this.selectedBuddy1) {
      this.membersSelection2Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1.id.toString())
      );
    }
    this.resetSelection2();
  }

  public onChangeInterventionInstance1($event): void {
    this.lessonParam1 = this.calculateProgress($event.value);
  }

  public onChangeInterventionInstance2($event): void {
    this.lessonParam2 = this.calculateProgress($event.value);
  }

  public isAllSelected(): boolean {
    return (
      !!this.selectedBuddy1 &&
      !!this.selectedBuddy2 &&
      !!this.selectedInterventionInstance1 &&
      !!this.selectedInterventionInstance2 &&
      !!this.selectedDiaryInstance1 &&
      !!this.selectedDiaryInstance2
    );
  }

  public createBuddyConnection(): void {
    this.submitted = true;
    if (this.isAllSelected() && this.createBuddyResponse.value === 'DEFAULT') {
      this.createBuddyResponse.next('LOADING');
      this.buddyStore.connectBuddies({
        buddyId1: this.selectedBuddy1.id,
        interventionInstanceId1: this.selectedInterventionInstance1.id,
        diaryInstanceId1: this.selectedDiaryInstance1.id,
        buddyId2: this.selectedBuddy2.id,
        interventionInstanceId2: this.selectedInterventionInstance2.id,
        diaryInstanceId2: this.selectedDiaryInstance2.id,
        studyId: this.studyId
      });

      this.subscriptions.push(
        this.connectBuddiesResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((result: any) => {
            if (result instanceof HttpResponse) {
              this.createBuddyResponse.next('SUCCESS');
              setTimeout(() => {
                this.submitted = false;
                this.createBuddyResponse.next('DEFAULT');
                this.dialogRef.close();
              }, 2500);
            } else {
              this.createBuddyResponse.next('FAILURE');
              setTimeout(() => {
                this.submitted = false;
                this.createBuddyResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    } else {
      this.submitted = false;
    }
  }

  public calculateProgress(instance: InterventionInstanceInterface): { lesson_one: number; lesson_two: number } {
    const lessonOne =
      instance.attributes.progress.finished_questionnaires == null ? 0 : instance.attributes.progress.finished_questionnaires.length;
    const lessonTwo = instance.attributes.configuration.custom_order.length;
    return { lesson_one: lessonOne, lesson_two: lessonTwo };
  }

  public openDialogInterventionInstanceDetails(interventionInstanceId: number): void {
    this.helperDialog
      .openDialogInterventionInstanceDetails(interventionInstanceId)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogDiaryPreview(diaryInstance: DiaryInstanceInterface): void {
    const diary = this.helper.findArrObjById(diaryInstance.attributes.diary_id, this.diaries);
    this.helperDialog
      .openDialogDiaryPreview(diary.attributes.questionnaire_id, diary.attributes.study_id, diary.attributes.page_color, diary)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogAnswersheetPreview(diaryInstance: DiaryInstanceInterface): void {
    const diary = this.helper.findArrObjById(diaryInstance.attributes.diary_id, this.diaries);
    this.helperDialog
      .openDialogDiaryAnswersheetPreview(diaryInstance.id, diary)
      .afterClosed()
      .subscribe(result => {});
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    if (this.selectedBuddy2) {
      this.membersSelection1Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2.id.toString())
      );
    }
    if (this.selectedBuddy1) {
      this.membersSelection2Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1.id.toString())
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private resetSelection1(): void {
    if (this.selectedBuddy1) {
      this.interventionInstancesBuddy1 = this.interventionInstances.filter((instance: InterventionInstanceInterface) =>
        this.helper.isPatientOfInstance(instance, this.selectedBuddy1.id)
      );
      this.diaryInstancesBuddy1 = this.diaryInstances.filter(
        (instance: DiaryInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy1.id.toString()
      );
    }
    this.selectedInterventionInstance1 = null;
    this.selectedDiaryInstance1 = null;
  }

  private resetSelection2(): void {
    if (this.selectedBuddy2) {
      this.interventionInstancesBuddy2 = this.interventionInstances.filter((instance: InterventionInstanceInterface) =>
        this.helper.isPatientOfInstance(instance, this.selectedBuddy2.id)
      );
      this.diaryInstancesBuddy2 = this.diaryInstances.filter(
        (instance: DiaryInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy2.id.toString()
      );
    }
    this.selectedInterventionInstance2 = null;
    this.selectedDiaryInstance2 = null;
  }
}
