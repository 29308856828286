/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogBuddyInstanceCreateComponent } from '../dialog-buddy-instance-create/dialog-buddy-instance-create.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserInterface } from '../../../models/interface/user.interface';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { faHands } from '@fortawesome/free-solid-svg-icons/faHands';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons/faHandsHelping';
import { DiaryInstanceInterface } from '../../../models/interface/diary-instance.interface';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { BuddyInstanceInterface } from '../../../models/interface/buddy/buddy-instance.interface';
import { BuddyStore } from '../../../store/buddy/component-store/buddy.store';
import { HttpResponse } from '@angular/common/http';
import { HelperDialogService } from 'src/app/services/helper/helper-dialog/helper-dialog.service';

@Component({
  selector: 'app-dialog-buddy-instance-update',
  templateUrl: './dialog-buddy-instance-update.component.html',
  styleUrls: ['./dialog-buddy-instance-update.component.css'],
  providers: [BuddyStore]
})
export class DialogBuddyInstanceUpdateComponent implements OnInit, OnDestroy {
  // Icons
  faHands = faHands;
  faHandsHelping = faHandsHelping;

  public progress = 0;

  public members: Array<UserInterface>;
  public membersSelection1Subject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  public membersSelection2Subject: BehaviorSubject<Array<UserInterface>> = new BehaviorSubject([]);
  public interventionInstancesBuddy1: Array<InterventionInstanceInterface> = [];
  public interventionInstancesBuddy2: Array<InterventionInstanceInterface> = [];
  public diaryInstancesBuddy1: Array<DiaryInstanceInterface> = [];
  public diaryInstancesBuddy2: Array<DiaryInstanceInterface> = [];
  public param;
  public interventions: Array<InterventionInterface> = [];
  public buddyInstance: BuddyInstanceInterface;
  public diaryInstances: Array<DiaryInstanceInterface>;
  public interventionInstances: Array<InterventionInstanceInterface>;
  public lessonParam1;
  public lessonParam2;

  public diaries: Array<DiaryInterface>;

  // List of roles
  public roles = ['study.ecoachmanager', 'study.ecoach', 'study.access'];

  // Selected buddies
  public selectedBuddy1: UserInterface;
  public selectedInterventionInstance1: InterventionInstanceInterface;
  public selectedDiaryInstance1: DiaryInstanceInterface;

  public selectedBuddy2: UserInterface;
  public selectedInterventionInstance2: InterventionInstanceInterface;
  public selectedDiaryInstance2: DiaryInstanceInterface;

  // Form submission
  submitted = false;

  public updateBuddyResponse$: Observable<any>;
  public updateBuddyResponse: BehaviorSubject<string> = new BehaviorSubject<string>('DEFAULT');

  // Filter
  public filter = {
    userSelection: ''
  };

  public studyId: number;

  // Subscription Handler
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private dialogRef: MatDialogRef<DialogBuddyInstanceCreateComponent>,
    private buddyStore: BuddyStore
  ) {
    this.updateBuddyResponse$ = this.buddyStore.updateBuddyResponse$;
  }

  // Helper function
  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.members = this.data.members;
    this.param = this.data.param;
    this.buddyInstance = this.data.buddyInstance;
    this.interventionInstances = this.data.interventionInstances;
    this.diaryInstances = this.data.diaryInstances;
    this.diaries = this.data.diaries;
    this.interventions = this.data.interventions;
    this.studyId = this.data.studyId;

    if (this.buddyInstance) {
      this.selectedBuddy1 = this.helper.findArrObjById(this.buddyInstance.attributes.buddies_ids[0], this.members);
      this.selectedBuddy2 = this.helper.findArrObjById(this.buddyInstance.attributes.buddies_ids[1], this.members);
      this.membersSelection1Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2?.id.toString())
      );
      this.membersSelection2Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1?.id.toString())
      );

      this.buddyInstance.attributes.intervention_instances_ids.forEach((value: { user_id: number; intervention_instance_id: number }) => {
        const interventionInstance: InterventionInstanceInterface = this.helper.findArrObjById(
          value.intervention_instance_id,
          this.interventionInstances
        );
        if (interventionInstance) {
          if (interventionInstance.attributes.patient_id.toString() === this.selectedBuddy1?.id.toString()) {
            this.selectedInterventionInstance1 = interventionInstance;
            this.lessonParam1 = this.calculateProgress(interventionInstance);
          } else {
            this.selectedInterventionInstance2 = interventionInstance;
            this.lessonParam2 = this.calculateProgress(interventionInstance);
          }
        }
      });

      this.buddyInstance.attributes.diary_instances_ids.forEach((value: { user_id: number; diary_instance_id: number }) => {
        const diaryInstance: DiaryInstanceInterface = this.helper.findArrObjById(value.diary_instance_id, this.diaryInstances);
        if (diaryInstance) {
          if (diaryInstance.attributes.patient_id.toString() === this.selectedBuddy1?.id.toString()) {
            this.selectedDiaryInstance1 = diaryInstance;
          } else {
            this.selectedDiaryInstance2 = diaryInstance;
          }
        }
      });

      this.resetSelection1();
      this.resetSelection2();
    }
  }

  public onChangeSelection1(event): void {
    this.membersSelection1Subject.next(
      this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2?.id.toString())
    );
    this.membersSelection2Subject.next(
      this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1?.id.toString())
    );
    this.resetSelection1();
  }

  public onChangeSelection2(event): void {
    this.membersSelection1Subject.next(
      this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2?.id.toString())
    );
    this.membersSelection2Subject.next(
      this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1?.id.toString())
    );
    this.resetSelection2();
  }

  public onChangeInterventionInstance1($event): void {
    this.lessonParam1 = this.calculateProgress($event.value);
  }

  public onChangeInterventionInstance2($event): void {
    this.lessonParam2 = this.calculateProgress($event.value);
  }

  public isAllSelected(): boolean {
    return (
      !!this.selectedBuddy1 &&
      !!this.selectedBuddy2 &&
      !!this.selectedInterventionInstance1 &&
      !!this.selectedInterventionInstance2 &&
      !!this.selectedDiaryInstance1 &&
      !!this.selectedDiaryInstance2
    );
  }

  public updateBuddyConnection(): void {
    this.submitted = true;
    if (this.isAllSelected() && this.updateBuddyResponse.value === 'DEFAULT') {
      this.updateBuddyResponse.next('LOADING');
      this.buddyStore.updateBuddies({
        buddyId1: this.selectedBuddy1.id,
        interventionInstanceId1: this.selectedInterventionInstance1.id,
        diaryInstanceId1: this.selectedDiaryInstance1.id,
        buddyId2: this.selectedBuddy2.id,
        interventionInstanceId2: this.selectedInterventionInstance2.id,
        diaryInstanceId2: this.selectedDiaryInstance2.id,
        buddyConnectionId: this.buddyInstance.id
      });
      this.subscriptions.push(
        this.updateBuddyResponse$
          .pipe(
            filter(value => !!value),
            take(1)
          )
          .subscribe((result: any) => {
            if (result instanceof HttpResponse) {
              this.updateBuddyResponse.next('SUCCESS');
              setTimeout(() => {
                this.submitted = false;
                this.updateBuddyResponse.next('DEFAULT');
                this.dialogRef.close(result);
              }, 2500);
            } else {
              this.updateBuddyResponse.next('FAILURE');
              setTimeout(() => {
                this.submitted = false;
                this.updateBuddyResponse.next('DEFAULT');
              }, 2500);
            }
          })
      );
    } else {
      this.submitted = false;
    }
  }

  public calculateProgress(instance: InterventionInstanceInterface): { lesson_one: number; lesson_two: number } {
    const lessonOne =
      instance.attributes.progress.finished_questionnaires == null ? 0 : instance.attributes.progress.finished_questionnaires.length;
    const lessonTwo = instance.attributes.configuration.custom_order.length;
    return { lesson_one: lessonOne, lesson_two: lessonTwo };
  }

  public openDialogInterventionInstanceDetails(interventionInstanceId: number): void {
    this.helperDialog
      .openDialogInterventionInstanceDetails(interventionInstanceId)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogDiaryPreview(diaryInstance: DiaryInstanceInterface): void {
    const diary = this.helper.findArrObjById(diaryInstance.attributes.diary_id, this.diaries);
    this.helperDialog
      .openDialogDiaryPreview(diary.attributes.questionnaire_id, diary.attributes.study_id, diary.attributes.page_color, diary)
      .afterClosed()
      .subscribe(result => {});
  }

  public openDialogAnswersheetPreview(diaryInstance: DiaryInstanceInterface): void {
    const diary = this.helper.findArrObjById(diaryInstance.attributes.diary_id, this.diaries);
    this.helperDialog
      .openDialogDiaryAnswersheetPreview(diaryInstance.id, diary)
      .afterClosed()
      .subscribe(result => {});
  }

  public resetFilter(): void {
    this.filter = {
      userSelection: ''
    };
    if (this.selectedBuddy2) {
      this.membersSelection1Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy2?.id.toString())
      );
    }
    if (this.selectedBuddy1) {
      this.membersSelection2Subject.next(
        this.members.filter((user: UserInterface) => user.id.toString() !== this.selectedBuddy1?.id.toString())
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private resetSelection1(): void {
    this.interventionInstancesBuddy1 = this.interventionInstances.filter(
      (instance: InterventionInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy1?.id.toString()
    );
    this.diaryInstancesBuddy1 = this.diaryInstances.filter(
      (instance: DiaryInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy1?.id.toString()
    );
  }

  private resetSelection2(): void {
    this.interventionInstancesBuddy2 = this.interventionInstances.filter(
      (instance: InterventionInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy2?.id.toString()
    );
    this.diaryInstancesBuddy2 = this.diaryInstances.filter(
      (instance: DiaryInstanceInterface) => instance.attributes.patient_id.toString() === this.selectedBuddy2?.id.toString()
    );
  }
}
