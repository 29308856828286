<mat-toolbar *ngIf="(isAuthenticated$ | async) && (isLoginSubject | async) === false" color="primary">
  <mat-toolbar-row>
    <span>
      <button [attr.id]="'btnRedirectToHome'" (click)="redirectToHome()" mat-button>
        <img class="img-size" src="assets/esano-images/eSano_e-coach_2.svg" />
      </button>
    </span>
    <span fxFlex></span>

    <!---
    <span *ngIf="isAdminSubject | async">
      <button
        [attr.id]="'btnRedirectToAdminDashboard'"
        mat-button
        (click)="redirectToAdmin()"
        matTooltip="{{'navbar.admin_dashboard' | translate}}"
      >
        <fa-icon [icon]="faUsersCog"></fa-icon>
      </button>
    </span>
    -->

    <span>
      <button
        [attr.id]="'btnOpenDialogDownloadReport'"
        mat-button
        (click)="openDialogDownloadReport()"
        matTooltip="{{'navbar.show_downloads' | translate}}"
      >
        <fa-icon [icon]="faDownload"></fa-icon>
      </button>
    </span>

    <span>
      <button
        [attr.id]="'btnDisplayAnnouncements'"
        mat-button
        (click)="displayAnnouncements()"
        matTooltip="{{'announcement.show_announcements' | translate}}"
      >
        <fa-icon [icon]="faBullhorn"></fa-icon>
      </button>
    </span>

    <span>
      <button
        [attr.id]="'btnShowAnnouncements'"
        [matMenuTriggerFor]="menuNotification"
        matTooltip="{{'navbar.show_notifications' | translate}}"
        aria-label="Notification icon button with menu"
        mat-button
      >
        <fa-icon [icon]="faBell"></fa-icon>
        <span *ngIf="myNotifications.length !== 0" class="badge badge-danger ml-2">{{myNotifications.length}}</span>
      </button>
      <mat-menu #menuNotification="matMenu">
        <button *ngIf="myNotifications.length === 0" mat-menu-item>{{'navbar.notification_no_news' | translate}}</button>
        <button
          [attr.id]="'btnRedirectToComponent_' + i"
          (click)="redirectToComponent(content)"
          *ngFor="let content of myNotifications; let i = index"
          mat-menu-item
        >
          {{getNotificationTranslation(content) | translate:getNotificationParams(content)}}
        </button>
      </mat-menu>
    </span>

    <span>
      <button [attr.id]="'btnShowLanguages'" [matMenuTriggerFor]="menuLanguage" aria-label="Notification icon button with menu" mat-button>
        <fa-icon [icon]="faLanguage"></fa-icon>
        {{ 'navbar.language' | translate }}
        <fa-icon [icon]="faSortDown"></fa-icon>
      </button>
      <mat-menu #menuLanguage="matMenu">
        <button [attr.id]="'btnSetPlatformLanguageEN'" (click)="setPlatformLanguage('en')" mat-menu-item>
          {{ 'navbar.english' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button [attr.id]="'btnSetPlatformLanguageDE'" (click)="setPlatformLanguage('de')" mat-menu-item>
          {{ 'navbar.german' | translate }}
        </button>
      </mat-menu>
    </span>

    <span>
      <button [attr.id]="'btnShowUsernameMenu'" [matMenuTriggerFor]="menuUser" mat-button>
        <fa-icon [icon]="faUserAlt"></fa-icon>
        {{(profile$ | async)?.attributes?.email}}
        <fa-icon [icon]="faSortDown"></fa-icon>
      </button>
      <mat-menu #menuUser="matMenu">
        <button [attr.id]="'btnToProfile'" [routerLink]="['/myprofile']" mat-menu-item>
          <fa-icon [icon]="faUserCircle"></fa-icon>
          {{ 'navbar.my_account' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button [attr.id]="'btnToFAQ'" [routerLink]="['/faq']" mat-menu-item>
          <fa-icon [icon]="faQuestionCircle"></fa-icon>
          {{ 'navbar.faq' | translate }}
        </button>
        <mat-divider></mat-divider>
        <button [attr.id]="'btnLogOut'" (click)="logout()" [routerLink]="[]" mat-menu-item>
          <fa-icon [icon]="faSignOutAlt"></fa-icon>
          {{ 'navbar.log_out' | translate }}
        </button>
      </mat-menu>
    </span>
  </mat-toolbar-row>
</mat-toolbar>

<nav *ngIf="(isAuthenticated$ | async) && (isLoginSubject | async) === false" class="navbar navbar-expand-md navbar-light mx-2">
  <div class="container--fluid">
    <button
      [attr.id]="'btnToggleNavbar'"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler mr-auto"
      data-bs-target="#navbarSupportedContent"
      data-bs-toggle="collapse"
      type="button"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a [routerLinkActiveOptions]="{exact:true}" [routerLinkActive]="['active']" [routerLink]="['/home']" class="nav-link">
            <fa-icon [icon]="faHome"></fa-icon>
            {{ 'navbar.home' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/interventions']" class="nav-link">
            <fa-icon [icon]="faBookOpen"></fa-icon>
            {{ 'navbar.catalogue' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/groups']" class="nav-link">
            <fa-icon [icon]="faUsers"></fa-icon>
            {{ 'navbar.groups' | translate}}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/patients']" class="nav-link">
            <fa-icon [icon]="faHospitalUser"></fa-icon>
            {{ 'navbar.patients' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/tasks']" class="nav-link">
            <fa-icon [icon]="faTasks"></fa-icon>
            {{ 'navbar.tasks' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/activities']" class="nav-link">
            <fa-icon [icon]="faClock"></fa-icon>
            {{ 'navbar.activities' | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/feedback-overview']" class="nav-link">
            <fa-icon [icon]="faPen"></fa-icon>
            {{ 'navbar.feedback' | translate }}
            <span *ngIf="unreadFeedback > 0" matBadge="!" matBadgeOverlap="false" color="primary" matBadgeSize="small"></span>
          </a>
        </li>
        <li class="nav-item">
          <a [routerLinkActive]="['active']" [routerLink]="['/conversations']" class="nav-link" [routerLinkActiveOptions]="{exact:false}">
            <fa-icon [icon]="faCommentDots"></fa-icon>
            {{ 'navbar.conversations' | translate }}
            <span
              *ngIf="unreadConversation > 0"
              matBadge="{{unreadConversation}}"
              matBadgeOverlap="false"
              color="primary"
              matBadgeSize="small"
            ></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-banner [_showBanner]="showBanner"></app-banner>
