<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h2>{{'group-buddy.buddies' | translate:param}}</h2>
  </div>

  <!-- Management functions - Deactivated -->
  <div *ngIf="(isECoach$ | async) || (isManager$ | async)">
    <button class="mb-3" (click)="helper.toggleSubject(isCollapseSubject)" mat-button>
      <div *ngIf="(isCollapse$ | async); then thenShowDown else elseShowUp"></div>
      <ng-template #thenShowDown>
        <span><fa-icon [icon]="faChevronDown"></fa-icon> {{'group-buddy.buddy_management' | translate}}</span>
      </ng-template>
      <ng-template #elseShowUp>
        <span><fa-icon [icon]="faChevronUp"></fa-icon> {{'group-buddy.buddy_management' | translate}}</span>
      </ng-template>
    </button>
    <div class="row" *ngIf="(isCollapse$ | async)">
      <hr />
      <div class="col-12 col-md-3 mb-2">
        <p>{{'group-buddy.link_buddies_description' | translate}}</p>
        <button
          [attr.id]="'btnOpenDialogBuddyInstanceCreate'"
          class="largeButton"
          [disabled]="(isLoading$ | async)"
          (click)="openDialogBuddyInstanceCreate()"
          mat-button
          color="primary"
        >
          {{'group-buddy.link_buddies_title' | translate}}
        </button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <p>{{'group-buddy.swap_buddies_description' | translate}}</p>
        <button
          [attr.id]="'btnOpenDialogBuddyInstanceSwap'"
          class="largeButton"
          [disabled]="(isLoading$ | async)"
          (click)="openDialogBuddyInstanceSwap()"
          mat-button
          color="primary"
        >
          {{'group-buddy.swap_buddies_title' | translate}}
        </button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <p>{{'group-buddy.csv_buddies_description' | translate:param}}</p>
        <button
          [attr.id]="'btnGenerateCSVBuddyReport'"
          class="largeButton"
          [disabled]="(isLoading$ | async)"
          (click)="generateCSVBuddyReport()"
          mat-button
          color="primary"
        >
          {{'group-buddy.csv_buddies_title' | translate}}
        </button>
      </div>

      <div class="col-12 col-md-3 mb-2">
        <p>{{'group-buddy.csv_buddies_activities_description' | translate}}</p>
        <button
          [attr.id]="'btnOpenDialogBuddyActivityReportStudy'"
          class="largeButton"
          [disabled]="(isLoading$ | async)"
          (click)="openDialogBuddyActivityReport(studyId)"
          mat-button
          color="primary"
        >
          {{'group-buddy.csv_buddies_activities_title' | translate}}
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row justify-content-center">
    <div class="col-12 col-sm-6 mb-2">
      <!-- Search bar -->
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'group-buddy.search_buddy' | translate}}</mat-label>
        <input
          [attr.id]="'search'"
          (keyup.enter)="applyBuddyFilter()"
          [(ngModel)]="filter['searchFilter']"
          [disabled]="(isLoading$ | async)"
          aria-describedby="collaboratorSearch"
          aria-label="Search"
          matInput
          type="text"
        />
        <button [attr.id]="'btnSearchBuddy'" (click)="applyBuddyFilter()" aria-label="Search" mat-button mat-button matSuffix>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="row justify-content-center mt-1">
    <section class="col-12 col-sm-6 mb-2 input-center">
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>{{'group-buddy.inactive_since_days' | translate}}</mat-label>
        <span matPrefix class="mx-2">{{'group-buddy.for_more_than' | translate}} </span>
        <input
          [attr.id]="'lastActivities'"
          (keyup.enter)="reloadLastActivities()"
          [(ngModel)]="days"
          aria-describedby="inactiveSince"
          aria-label="buddyInactivity"
          matInput
          type="number"
          min="0"
        />
        <span matSuffix>{{'group-buddy.days' | translate}} </span>
        <button
          [attr.id]="'btnReloadLastActivities'"
          (click)="reloadLastActivities()"
          aria-label="buddyInactivity"
          mat-button
          mat-button
          matSuffix
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-form-field>
    </section>

    <app-pagination-list
      #paginator
      (emitPageChange)="updatePagedBuddies($event)"
      [_isLoading]="isLoading$ | async"
      [_items]="buddies$ | async"
    ></app-pagination-list>
  </div>

  <!-- List of user of the study -->
  <div *ngIf="(isLoading$ | async) === false" class="row">
    <div *ngFor="let buddy of (pagedBuddies$ | async); trackBy: helper.trackByElement" class="col-12 col-md-6">
      <mat-card appearance="outlined" [attr.id]="'buddy_' + buddy.id" class="mb-3">
        <mat-card-header class="card-header-center">
          <mat-card-title>{{'group-buddy.buddy_instance_id' | translate}} #{{(buddy.id)}}</mat-card-title>
          <mat-card-subtitle>
            <p class="mb-1 text-center">
              {{'group-buddy.guiding_ecoach' | translate}} :<span *ngFor="let ecoach_id of buddy.attributes.ecoach_id">
                {{helper.findArrObjById(ecoach_id, studyCollaborators)?.attributes.email}} ({{helper.findArrObjById(ecoach_id,
                studyCollaborators)?.attributes.name}})</span
              >
            </p>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="row mb-2">
            <div *ngFor="let user_id of buddy.attributes.buddies_ids" class="col-12 col-md-6">
              <div>
                <ng-template
                  *ngIf="!!getLastActivitiesOfInactiveBuddiesSince(buddy.id, user_id); then thenShowInactive else elseShowActive"
                ></ng-template>
                <ng-template #thenShowInactive>
                  <div class="text-danger">
                    <p>
                      <strong
                        >{{helper.findArrObjById(user_id, studyMembers)?.attributes.email}}
                        <span class="break-word" *ngIf="helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))"
                          >({{helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))}})</span
                        ></strong
                      >
                    </p>
                    <p>{{'group-buddy.inactive' | translate}}</p>
                    <p
                      *ngIf="isValidDate(getLastActivitiesOfInactiveBuddiesSince(buddy.id,
                  user_id)?.attributes?.daily_task_timestamps)"
                    >
                      {{'group-buddy.last_known_activity' | translate}}:
                      {{helper.localizeDateTimestamp(getLastActivitiesOfInactiveBuddiesSince(buddy.id,
                      user_id)?.attributes?.daily_task_timestamps, 'datetime')}}
                    </p>
                    <p
                      *ngIf="!isValidDate(getLastActivitiesOfInactiveBuddiesSince(buddy.id,
                  user_id)?.attributes?.daily_task_timestamps)"
                    >
                      {{'group-buddy.no_last_known_activity_timestamp' | translate}}
                    </p>
                  </div>
                </ng-template>
                <ng-template #elseShowActive>
                  <div class="text-success">
                    <p>
                      <strong
                        >{{helper.findArrObjById(user_id, studyMembers)?.attributes.email}}
                        <span class="break-word" *ngIf="helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))"
                          >({{helper.getStudyCode(studyId, helper.findArrObjById(user_id, studyMembers))}})</span
                        ></strong
                      >
                    </p>
                    <p>{{'group-buddy.active' | translate}}</p>
                    <p *ngIf="!!getLastActivitiesOfActiveBuddiesSince(buddy.id,user_id); else elseNoKnownActivity">
                      {{'group-buddy.last_known_activity' | translate}}:
                      {{helper.localizeDateTimestamp(getLastActivitiesOfActiveBuddiesSince(buddy.id,
                      user_id)?.attributes?.daily_task_timestamps, 'datetime')}}
                    </p>
                    <ng-template #elseNoKnownActivity>
                      <p>{{'group-buddy.no_last_known_activity_timestamp' | translate}}</p>
                      <p>{{'group-buddy.no_last_known_activity_swapping' | translate}} {{getLatestUpdateActivity(buddy.id, user_id)}}</p>
                    </ng-template>
                  </div>
                </ng-template>

                <button
                  [attr.id]="'btnOpenDialogInterventionInstanceDetails_' + buddy.id + '_' + user_id"
                  (click)="openDialogInterventionInstanceDetails(buddy, user_id)"
                  *ngIf="helper.filterInterventionInstancesByPatientId(instances, user_id).length > 0"
                  mat-button
                  matTooltip="{{'group-buddy.show_intervention_instance_details' | translate}}"
                >
                  <fa-icon [icon]="faPen"></fa-icon>
                </button>
                <button
                  [attr.id]="'btnOpenDialogBuddyInstanceDiaryDetails_' + buddy.id + '_' + user_id"
                  (click)="openDialogDiaryAnswersheetPreview(buddy, user_id)"
                  *ngIf="helper.filterDiaryInstancesByPatientId(diaryInstances, user_id).length > 0"
                  mat-button
                  matTooltip="{{'group-buddy.show_diary_instance_details' | translate}}"
                >
                  <fa-icon [icon]="faBookOpen"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button
            [attr.id]="'btnOpenDialogBuddyInstanceDetails_' + buddy.id"
            (click)="openDialogBuddyInstanceDetails(buddy)"
            class="mb-1"
            mat-raised-button
          >
            {{'group-buddy.show_buddy_details' | translate}}
          </button>

          <button
            [attr.id]="'btnOpenDialogBuddyInstanceUpdate_' + buddy.id"
            *ngIf="isGuidingECoach(profile.id, buddy)"
            (click)="openDialogBuddyInstanceUpdate(buddy)"
            class="mb-1"
            mat-raised-button
          >
            {{'group-buddy.update_buddy_details' | translate}}
          </button>

          <button
            [attr.id]="'btnOpenDialogBuddyInstanceDelete_' + buddy.id"
            *ngIf="isGuidingECoach(profile.id, buddy)"
            (click)="openDialogBuddyInstanceDelete(buddy)"
            class="mb-1"
            mat-raised-button
          >
            {{'group-buddy.delete_buddy_details' | translate}}
          </button>
          <button
            [attr.id]="'btnOpenDialogBuddyActivityReportBuddy_' + buddy.id"
            [disabled]="(isLoading$ | async)"
            (click)="openDialogBuddyActivityReport(undefined, buddy)"
            class="mb-1"
            mat-raised-button
          >
            {{'group-buddy.csv_buddy_instance_export' | translate}}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <!-- Spinner -->
  <div *ngIf="(isLoading$ | async)">
    <app-loader></app-loader>
  </div>
</div>
