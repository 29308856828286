<ng-container *ngIf="(isLoading$ | async); then thenLoadingBlock else elseFeedbackContent"></ng-container>

<ng-template #thenLoadingBlock>
  <app-loader></app-loader>
</ng-template>

<ng-template #elseFeedbackContent>
  <div class="container text-start mb-3">
    <div class="card">
      <div class="card-body">
        <div *ngIf="thread === null; else elseThreadAvailBlock">
          <div
            *ngIf="helper.isECoachOfInstance(instance, (profile$ | async)?.id); then thenShowForm else elseShowNoFeedbackAvailable"
          ></div>
          <ng-template #thenShowForm>
            <!-- Show feedback form or update instance form -->
            <div *ngIf="(showUpdateInstance$ | async) === false; then thenShowFeedback else elseShowUpdateInstance"></div>
            <ng-template #thenShowFeedback>
              <h4><strong>{{'answersheet-detail.feedback' | translate}}</strong></h4>
              <div>
                {{'answersheet-detail.modal_create_feedback_description' | translate}}
                <div *ngIf="helper.findArrObjById(answersheet.attributes.user_id, users); then thenShowUser else elseNoUser"></div>
                <ng-template #thenShowUser>
                  <p class="font-weight-bold break-word">
                    {{helper.getCodeNameEmail(answersheet.attributes.user_id, users, instance.attributes.study_id)}}
                  </p>
                </ng-template>
                <ng-template #elseNoUser>
                  <p class="font-weight-bold">{{'<' + ('answersheet-detail.deleted_user' | translate) + '>'}}</p>
                </ng-template>
              </div>
              <div>
                <form [formGroup]="threadForm">
                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'answersheet-detail.feedback_name' | translate}}</mat-label>
                    <input formControlName="subject" matInput type="text" />
                    <mat-error *ngIf="submitted && g.subject.errors && g.subject.errors.required">
                      {{'answersheet-detail.modal_create_feedback_subject_required' | translate}}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'answersheet-detail.feedback_comment' | translate}}</mat-label>
                    <textarea formControlName="feedback" matInput rows="3" type="text"></textarea>
                  </mat-form-field>
                </form>
              </div>
            </ng-template>
            <ng-template #elseShowUpdateInstance>
              <!-- Form -->
              <h2 id="updateInstanceHeader">{{'answersheet-detail.modal_update_instance_title' | translate:param}}</h2>
              <div *ngIf="!confirmedUpdate; then thenUpdateBlock else elseUpdateBlock"></div>
              <ng-template #thenUpdateBlock>
                <app-instance-update-form
                  #instanceUpdateForm
                  (confirmedForm)="onConfirmUpdate()"
                  [_collaborators]="[]"
                  [_id]="'updateModal' + instance.id"
                  [_instance]="instance"
                  [_intervention]="intervention"
                  [_members]="users"
                  [_myUserId]="(profile$ | async)?.id"
                  [_questionnaires]="questionnaires"
                ></app-instance-update-form>
              </ng-template>

              <!-- Confirmation -->
              <ng-template #elseUpdateBlock>
                <app-instance-update-confirmation
                  #instanceUpdateConfirm
                  [_collaborators]="studyCollaborators"
                  [_id]="'updateModal' + instance.id"
                  [_instance]="instance"
                  [_intervention]="intervention"
                  [_members]="users"
                  [_myUserId]="(profile$ | async)?.id"
                  [_payload]="instancePayload"
                  [_questionnaires]="questionnaires"
                ></app-instance-update-confirmation>
              </ng-template>
            </ng-template>

            <div class="footer text-end">
              <!-- Skip update instance -->
              <div *ngIf="(showUpdateInstance$ | async) === false; else elseShowUpdate">
                <div class="row mb-1 justify-content-end">
                  <div *ngIf="!isAllFinishedQuestionnaire(); then thenShowFeedbackOptions else elseShowCreateFeedback"></div>
                  <ng-template #thenShowFeedbackOptions>
                    <div class="col">
                      <app-button-feedback
                        [_setId]="'btnSubmitFeedback'"
                        (click)="submitFeedback()"
                        [_isDisabled]="this.threadForm.invalid"
                        [_defaultButtonText]="'answersheet-detail.skip_config_create_feedback'"
                        [_responseText]="createFeedbackResponse | async"
                      ></app-button-feedback>
                      <button
                        [attr.id]="'btnShowInterventionInstanceConfig'"
                        (click)="helper.toggleSubject(showUpdateInstanceSubject)"
                        [disabled]="this.threadForm.invalid"
                        color="primary"
                        mat-button
                        type="button"
                      >
                        {{'answersheet-detail.next_configuration' | translate}}
                      </button>
                    </div>
                  </ng-template>
                  <ng-template #elseShowCreateFeedback>
                    <div class="col">
                      <app-button-feedback
                        [_setId]="'btnSubmitFeedback'"
                        (click)="submitFeedback()"
                        [_defaultButtonText]="'answersheet-detail.create_feedback'"
                        [_isDisabled]="submitted || (answersheet.attributes.user_id === null) || this.threadForm.invalid"
                        [_responseText]="createFeedbackResponse | async"
                      ></app-button-feedback>
                    </div>
                  </ng-template>
                </div>
              </div>
              <ng-template #elseShowUpdate>
                <div *ngIf="!confirmedUpdate; then thenConfirmUpdateSetting else elseUpdateButtonBlock"></div>
                <ng-template #thenConfirmUpdateSetting>
                  <div class="row mb-1 justify-content-end">
                    <div class="col">
                      <button
                        [attr.id]="'btnShowInterventionInstanceConfig'"
                        (click)="helper.toggleSubject(showUpdateInstanceSubject)"
                        color="primary"
                        mat-button
                        type="button"
                      >
                        {{'answersheet-detail.back_to_feedback' | translate}}
                      </button>
                      <button [attr.id]="'btnGoNext'" (click)="confirmUpdateSettings()" color="primary" mat-button type="button">
                        {{'answersheet-detail.next_to_confirm_configuration' | translate}}
                      </button>
                    </div>
                  </div>
                </ng-template>
                <ng-template #elseUpdateButtonBlock>
                  <div class="row mb-1 justify-content-end">
                    <div class="col">
                      <button [attr.id]="'btnGoPrevious'" (click)="goBackUpdate()" color="primary" mat-button type="button">
                        {{'answersheet-detail.back_to_configuration' | translate}}
                      </button>
                      <app-button-feedback
                        [_setId]="'btnSubmitFeedbackUpdateConfig'"
                        (click)="updateInstanceAndProvideFeedback()"
                        [_defaultButtonText]="'answersheet-detail.save_and_create_feedback'"
                        [_isDisabled]="submitted"
                        [_responseText]="createFeedbackResponse | async"
                      ></app-button-feedback>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </ng-template>
          <ng-template #elseShowNoFeedbackAvailable> {{'answersheet-detail.no_feedback_available' | translate}} </ng-template>
        </div>

        <!-- Feedback available -->
        <ng-template #elseThreadAvailBlock>
          <h3 class="mb-2">{{'answersheet-detail.feedback_and_discussion' | translate}}</h3>

          <div class="row" *ngIf="helperFeedback.isFeedbackRequired(answersheet.id, instance)">
            <div *ngIf="helperFeedback.isRead(thread.attributes.answersheet_id, instance, thread); then thenIsRead else elseNotRead"></div>

            <ng-template #thenIsRead>
              <p>
                <span class="text-success font-weight-bold">
                  <fa-icon [icon]="faCheckCircle"></fa-icon>
                  {{'answersheet-detail.feedback_read' | translate}}
                </span>
              </p>
            </ng-template>
            <ng-template #elseNotRead>
              <p>
                <span class="text-info font-weight-bold">
                  <fa-icon [icon]="faCircle"></fa-icon>
                  {{'answersheet-detail.feedback_not_read' | translate}}
                </span>
              </p>
            </ng-template>
          </div>

          <div class="my-3">
            <mat-chip-listbox>
              <mat-chip color="primary" *ngFor="let participant of participants" selected>
                <ng-template *ngIf="participant.attributes.email; then thenShowFound else elseShowNotFound"></ng-template>
                <ng-template #thenShowFound>{{participant.attributes.email}}</ng-template>
                <ng-template #elseShowNotFound>{{'answersheet-detail.deleted_user' | translate}}</ng-template>
              </mat-chip>
            </mat-chip-listbox>
          </div>

          <div *ngFor="let message of includeMessages; let first = first; let i = index; trackBy: trackByMessage">
            <div *ngIf="first; else elseShowComment">
              <mat-card appearance="outlined" [attr.id]="'message_' + message.id" class="card mat-elevation-z0 mx-auto mb-3 text-center">
                <mat-card-content>
                  <p class="small text-muted text-end">
                    <fa-icon [icon]="faClock"></fa-icon> {{'answersheet-detail.feedback_created_by' | translate}}
                    {{(message.relationships.author.data !== null) ? message.relationships.author.data.attributes.email : ''}} -
                    {{helper.localizeDateString(message.attributes.updated_at)}}
                  </p>
                  <p><strong>{{thread.attributes.subject}}</strong></p>
                  <div
                    *ngIf="showEdit && isMyMessage(message, (profile$ | async)?.id) && isParticipant(); then thenShowEditArea else elseShowFeedbackComment"
                  ></div>
                  <ng-template #thenShowEditArea>
                    <form [formGroup]="commentEditForm">
                      <mat-form-field class="full-width" appearance="outline">
                        <textarea [attr.id]="'commentEdit'" formControlName="commentEdit" matInput rows="3" type="text"></textarea>
                      </mat-form-field>
                    </form>
                  </ng-template>
                  <ng-template #elseShowFeedbackComment>
                    <p class="font-weight-light text-start mb-3" [innerHTML]="message.attributes.body"></p>
                  </ng-template>
                </mat-card-content>
                <mat-card-actions [align]="'end'">
                  <div
                    *ngIf="showEdit && isMyMessage(message, (profile$ | async)?.id) && isParticipant(); then thenShowEdit else elseShowNoEdit"
                  ></div>
                  <ng-template #thenShowEdit>
                    <div class="spacing-top">
                      <button [attr.id]="'btnEditComment'" (click)="editComment(message.id)" color="primary" mat-button>
                        {{'answersheet-detail.feedback_save' | translate}}
                      </button>
                      <button
                        [attr.id]="'btnCancelEditComment'"
                        (click)="toggleShowEdit(message.attributes.body)"
                        color="primary"
                        mat-button
                      >
                        {{'answersheet-detail.feedback_cancel' | translate}}
                      </button>
                    </div>
                  </ng-template>
                  <ng-template #elseShowNoEdit>
                    <div *ngIf="isMyMessage(message, (profile$ | async)?.id)" class="spacing-top">
                      <button
                        [attr.id]="'btnProceedEditComment'"
                        (click)="toggleShowEdit(message.attributes.body)"
                        color="primary"
                        mat-button
                      >
                        <fa-icon [icon]="faEdit"></fa-icon>
                        {{'answersheet-detail.feedback_edit' | translate}}
                      </button>
                    </div>
                  </ng-template>
                </mat-card-actions>
              </mat-card>
            </div>
            <ng-template #elseShowComment>
              <div [attr.id]="'message_' + message.id" class="comment comment-border">
                <h5 class="small text-muted text-end">
                  <ng-template *ngIf="message.relationships?.author.data !== null; then thenShowFound else elseShowNotFound"></ng-template>
                  <ng-template #thenShowFound>{{message.relationships?.author.data.attributes.email}}</ng-template>
                  <ng-template #elseShowNotFound>{{'answersheet-detail.deleted_user' | translate}}</ng-template> -
                  {{helper.localizeDateString(message.attributes.updated_at)}}
                </h5>
                <div class="comment-body">
                  <p>
                    {{message.attributes.body}}
                    <br />
                  </p>
                </div>
              </div>
            </ng-template>
          </div>
          <div *ngIf="(isSubmittedLoading$ | async)" class="mb-2 text-center">
            <app-loader></app-loader>
          </div>
          <div *ngIf="isParticipant() | async">
            <form [formGroup]="commentForm">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'answersheet-detail.leave_comment' | translate}}</mat-label>
                <textarea [attr.id]="'commentAdd'" formControlName="comment" matInput rows="3"></textarea>
              </mat-form-field>
            </form>
            <div class="my-2 align-right">
              <app-button-feedback
                [_setId]="'btnSubmitComment'"
                (click)="submitComment()"
                [_defaultButtonText]="'answersheet-detail.comment'"
                [_responseText]="submitCommentResponse | async"
              ></app-button-feedback>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
