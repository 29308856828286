import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class AccessCodeService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API eCoach - Get and verify provided access code
   * Verifies existing access code.
   *
   * @params number studyId - id of the study.
   *         string code - access code.
   * @return Observable<any> - An observable for any response.
   */
  public getAccessCodes(code: string, type: 'xor' | 'aes'): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const payload: PayloadInterface = {
      data: {
        type: 'access_codes',
        attributes: {
          code
        }
      }
    };
    return this.http.request<any>('get', `${this.backendUrl}/api/v1/ecoachmanager/access/codes`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach manager - Create access codes as eCoach manager or owner
   * This function generates multiple access codes from a study.
   *
   * @params number studyId - id of the study.
   *         number number_access_codes - number of generated codes.
   * @return Observable<any> - An observable for any response.
   */
  public generateAccessCodes(
    study_id: number,
    number_access_codes: number,
    valid_for: number | undefined,
    study_codes: Array<string> | undefined,
    description: string | undefined,
  ): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'access_codes',
        attributes: {
          study_id,
          number_access_codes,
          valid_for,
          study_codes,
          description,
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoachmanager/access/codes`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach manager - Get all access code batches of a study
   * This function retrieves multiple all access code batches from a study.
   *
   * @params number studyId - id of the study.
   * @return Observable<any> - An observable for any response.
   */
  public getAccessCodeBatchesStudy(studyId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/access/codes/batches/${studyId}?limit=0`, { observe: 'response' });
  }

  /**
   * AAS2 API eCoach manager - Invalidate access code batches
   * This function invalidates an access code batch.
   *
   * @params number batchId - id of the access code batch.
   * @return Observable<any> - An observable for any response.
   */
  public invalidateAccessCodeBatch(batchId: number): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoachmanager/access/codes/batches/${batchId}/invalidate`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach manager - Get all blocked access codes
   * This function retrieves all blocked access codes.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getBlockedAccessCodes(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/access/codes/blocked?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }
}
