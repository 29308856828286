/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { download } from '../../../helpers/download/download';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * Diary API service that handles diaries and diary instance related requests
 */

@Injectable({
  providedIn: 'root'
})
export class DiaryService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 API ECoach - All instances of a diary
   * This function retrieves all instances of a diary.
   *
   * @params number diary_id - ID of the diary.
   *         number ecoach_id - ID of the user; preferably an eCoach.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfDiary(diaryId: number, ecoachId?: number, patientId?: number): Observable<any> {
    let params = new HttpParams();
    if (ecoachId !== undefined) {
      params = params.set('ecoach_id', ecoachId.toString());
    }
    if (patientId !== undefined) {
      params = params.set('patient_id', patientId.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/${diaryId}/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Update a diary instance
   * This function updates a diary instance.
   * TODO: Payload still not defined
   *
   * @params number instanceId - ID of the diary instance.
   *         number diary_id - ID of the diary.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public updateDiaryInstance(instanceId: number, diaryId: number, patientId: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'diary',
        attributes: {
          diary_id: diaryId,
          patient_id: patientId
        }
      }
    };
    return this.http.patch<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances/${instanceId}`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API API ECoach - Create a new diary instance
   * This function creates a new diary instance.
   *
   * @params number diary_id - ID of the diary.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public createDiaryInstance(diaryId: number, patientId: number): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'diary',
        attributes: {
          diary_id: diaryId,
          patient_id: patientId
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API API ECoach - Deletes a diary instance
   * This function deletes a diary instance.
   *
   * @params number instanceId - ID of the diary instance.
   * @return Observable<any> - An observable for any response.
   */
  public deleteInstance(instanceId: number): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances/${instanceId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API API ECoach - Shows a diary instance
   * This function returns a diary instance.
   *
   * @params number instanceId - ID of the diary instance.
   * @return Observable<any> - An observable for any response.
   */
  public getDiaryInstance(instanceId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances/${instanceId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API API ECoach - Show all Diary instances of the logged in user
   * This function retrieves all instances of the user.
   *
   * @params number instanceId - ID of the diary instance.
   *         number ecoach_id - ID of the user; preferably an eCoach.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAllDiaryInstancesOfUser(diaryId?: number, patientId?: number): Observable<any> {
    const params = this.setHttpParams(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      patientId,
      diaryId,
      undefined
    );
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/diaries/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API API ECoach - All diary instances of an ecoach
   * This function retrieves all instances of a diary.
   *
   * @params number diary_id - ID of the diary.
   *         number ecoach_id - ID of the user; preferably an eCoach.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAllInstancesOfDiaryECoach(diaryId?: number, ecoachId?: number, patientId?: number): Observable<any> {
    const params = this.setHttpParams(undefined, undefined, null, undefined, undefined, undefined, ecoachId, patientId, diaryId, undefined);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API API ECoach - All answersheets of a diary as an ecoach
   * This function retrieves all instances of a diary.
   *
   * @params number diary_id - ID of the diary.
   *         number diary_instance_id - ID of the diary instance.
   *         number ecoach_id - ID of the user; preferably an eCoach.
   *         number patient_id - ID of the user; preferably a study member of the user.
   * @return Observable<any> - An observable for any response.
   */
  public getAllAnswersheetsOfDiaryECoach(
    diaryId: number,
    diaryInstanceId?: number,
    ecoachId?: number,
    patientId?: number
  ): Observable<any> {
    const params = this.setHttpParams(
      undefined,
      undefined,
      null,
      undefined,
      undefined,
      undefined,
      ecoachId,
      patientId,
      undefined,
      diaryInstanceId
    );
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/${diaryId}/answersheets?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API User - Get all diaries
   * This function returns all diaries.
   *
   * @params number is_shareable - A number determines if diary is shareable.
   *         number is_subscribable - A number determines if diary is subscribable.
   *         number is_private - A number determines if diary is private.
   *         number study_id - ID of the study.
   *         number questionnaire_id - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getAllDiaries(
    isShareable?: number,
    isSubscribable?: number,
    isActive?: number,
    isPrivate?: number,
    studyId?: number,
    questionnaireId?: number
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(isShareable, isSubscribable, isActive, isPrivate, studyId, questionnaireId);
    const url = `${this.backendUrl}/api/v1/ecoach/diaries?limit=0`;
    const options = { headers: this.header, observe: 'response', params } as const;
    return this.http.get<any>(url, options);
  }

  /**
   * AAS2 API User - Show a diary
   * This function returns a diary.
   *
   * @params number is_shareable - A number determines if diary is shareable.
   *         number is_subscribable - A number determines if diary is subscribable.
   *         number is_private - A number determines if diary is private.
   *         number study_id - ID of the study.
   *         number questionnaire_id - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getDiary(diaryId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/diaries/${diaryId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Get questionnaire of a diary
   * This function returns the questionnaire of a diary.
   *
   * @params number is_shareable - A number determines if diary is shareable.
   *         number is_subscribable - A number determines if diary is subscribable.
   *         number is_private - A number determines if diary is private.
   *         number study_id - ID of the study.
   *         number questionnaire_id - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getQuestionnaireOfDiary(diaryId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/diaries/${diaryId}/questionnaire`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - Diaries of a study
   * This function returns diaries of a study.
   *
   * @params number is_shareable - A number determines if diary is shareable.
   *         number is_subscribable - A number determines if diary is subscribable.
   *         number is_private - A number determines if diary is private.
   *         number study_id - ID of the study.
   *         number questionnaire_id - ID of the questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public getDiariesOfStudy(
    studyId: number,
    isShareable?: number,
    isSubscribable?: number,
    isActive?: number,
    isPrivate?: number,
    questionnaireId?: number
  ): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    const params = this.setHttpParams(isShareable, isSubscribable, isActive, isPrivate, studyId, questionnaireId);
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/diaries?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get a diary with its translations
   * This function returns all translations of a diary
   *
   * @params number diary_id - ID of the diary.
   * @return Observable<any> - An observable for any response.
   */
  public getDiariesAndTranslation(diaryId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/${diaryId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Get all diaries of an intervention
   * This function returns all diaries of an intervention
   *
   * @params number intervention_id - ID of the intervention.
   * @return Observable<any> - An observable for any response.
   */
  public getDiariesOfIntervention(interventionId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/interventions/${interventionId}/diaries`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get all activities of a diary instance
   * This function returns all user activities of a diary instance only if user is study.ecoach
   *
   * @params number diaryInstanceId - ID of the diary instance.
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfDiaryInstance(diaryInstanceId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/diaries/instances/${diaryInstanceId}/activities`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach Manager - Get all activities of a diary instance
   * This function returns all user activities of a diary instance in a study.
   *
   * @params number diaryInstanceId - ID of the diary instance.
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfDiaryInstanceEM(diaryInstanceId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoachmanager/diaries/instances/${diaryInstanceId}/activities`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API eCoach Manager - Export diary codebook
   * This function returns a codebook of the diary as csv.
   *
   * @params number diaryId  - An identifier of the diary
   * @return Observable<any> - An observable for any response.
   */
  public exportCSVDiaryCodebook(diaryId: number): Observable<any> {
    this.header = this.header.set('Content-Type', '*/*');
    this.header = this.header.set('Accept', '*/*');
    this.header = this.helperService.setLocaleFromStorage(this.header);

    let downloaded = false;
    return this.http
      .get<any>(`${this.backendUrl}/api/v1/ecoachmanager/diaries/${diaryId}/codebook`, {
        headers: this.header,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob' as 'json'
      })
      .pipe(
        download(jsonResponse => {
          const getFileName = (response: HttpResponse<any>) => {
            let fileName: string;
            try {
              const contentDisposition: string = response.headers.get('content-disposition');
              const r = /filename="(.+)"/;
              fileName = r.exec(contentDisposition)[1];
            } catch (error) {
              fileName = `codebook_diary_id${diaryId}-${new Date().toISOString()}.csv`;
            }
            return fileName;
          };

          const filename = `codebook_diary_id-${diaryId}-${new Date().toISOString()}.csv`;
          const binaryData = [];
          binaryData.push(jsonResponse);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/csv' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          if (!downloaded) {
            downloadLink.click();
            URL.revokeObjectURL(downloadLink.href);
            downloaded = true;
          }
        })
      );
  }

  public setHttpParams(
    isShareable?: number,
    isSubscribable?: number,
    isActive?: number,
    isPrivate?: number,
    studyId?: number,
    questionnaireId?: number,
    ecoachId?: number,
    patientId?: number,
    diaryId?: number,
    diaryInstanceId?: number
  ): HttpParams {
    let params = new HttpParams();
    if (isShareable !== undefined) {
      params = params.set('is_shareable', isShareable.toString());
    }
    if (isSubscribable !== undefined) {
      params = params.set('is_subscribable', isSubscribable.toString());
    }
    if (isActive !== undefined && isActive !== null) {
      params = params.set('is_active', isActive.toString());
    } else {
      if (isActive !== null) {
        params = params.set('is_active', '1');
      }
    }
    if (isPrivate !== undefined) {
      params = params.set('is_private', isPrivate.toString());
    }
    if (studyId !== undefined) {
      params = params.set('study_Id', studyId.toString());
    }
    if (questionnaireId !== undefined) {
      params = params.set('questionnaire_id', questionnaireId.toString());
    }
    if (ecoachId !== undefined) {
      params = params.set('ecoach_id', ecoachId.toString());
    }
    if (patientId !== undefined) {
      params = params.set('patient_id', patientId.toString());
    }
    if (diaryId !== undefined) {
      params = params.set('diary_id', diaryId.toString());
    }
    if (diaryInstanceId !== undefined) {
      params = params.set('diary_instance_id', diaryInstanceId.toString());
    }
    return params;
  }
}
