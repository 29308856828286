/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { catchError, filter, mergeMap, skip, take } from 'rxjs/operators';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { InterventionInstanceInterface } from '../../../models/interface/intervention-instances/intervention-instance.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { UserInterface } from '../../../models/interface/user.interface';
import { GroupSharedService } from '../../../services/shared/group-shared/group-shared.service';
import { StudyInterface } from '../../../models/interface/study/study.interface';
import { ProfileInterface } from '../../../models/interface/profile.interface';
import { Store } from '@ngrx/store';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { StudyActionTypes } from '../../../store/study/study.action';
import { UserActionTypes } from '../../../store/user/user.action';
import { InterventionInstanceStore } from '../../../store/intervention-instance/component-store/intervention-instance.store';
import { getCollaboratorsByStudyId } from '../../../store/study/study.selector';
import { StudyStore } from '../../../store/study/component-store/study.store';
import { InterventionInstanceReminderInterface } from '../../../models/interface/intervention-instance-reminder.interface';
import { ReminderActionTypes } from '../../../store/reminder/reminder.action';
import { PaginationListComponent } from '../../utils/pagination-list/pagination-list.component';

/**
 * Component:
 * Group instances page displaying a list of intervention instances of the group;
 * Can be found: {uri}/groups/{{group_id}}/instances
 */

@Component({
  selector: 'app-group-instance',
  templateUrl: './group-instance.component.html',
  styleUrls: ['./group-instance.component.scss'],
  providers: [InterventionInstanceStore, StudyStore]
})
export class GroupInstanceComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: PaginationListComponent;

  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  public isLoading$: Observable<boolean>;

  // Data provided by UserService
  public users: Array<UserInterface> = [];
  public studyCollaborators: Array<UserInterface> = [];

  // Default - Filter
  public filter = { searchFilter: '' };
  public excludeCanceledInstance = true;

  // Translation for study name
  public param = { intervention_name: '...' };

  // Subject and Observable
  public selectedUserSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public instances$: Observable<Array<InterventionInstanceInterface>>;
  public isCollapse$: Observable<boolean>;
  public isManager$: Observable<boolean>;
  public pagedInstances$: Observable<Array<InterventionInstanceInterface>>;
  public profile$: Observable<ProfileInterface> = null;

  public allInterventionInstanceReminders$: Observable<Array<InterventionInstanceReminderInterface>>;

  public study: StudyInterface;

  public isCollapseSubject = new BehaviorSubject<boolean>(true);

  private allMyInstancesOfStudy$: Observable<Array<InterventionInstanceInterface>>;

  private collaboratorsOfStudy$: Observable<{ studyId: number; collaborators: UserInterface[] }>;

  private studyMembers$: Observable<Array<UserInterface>>;

  private groups$: Observable<Array<StudyInterface>>;

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private myInstances: Array<InterventionInstanceInterface> = [];
  private instancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);
  private searchTextSubject = new BehaviorSubject<string>('');

  // Intervention user role
  private profile: ProfileInterface;

  private isManagerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private myMembers$: Observable<Array<UserInterface>>;

  private pagedInstancesSubject: BehaviorSubject<Array<InterventionInstanceInterface>> = new BehaviorSubject<
    Array<InterventionInstanceInterface>
  >([]);

  private subscriptions: Subscription[] = [];

  constructor(
    private sharedService: GroupSharedService,
    private helperService: HelperService,
    private helperDialogService: HelperDialogService,
    private translateService: TranslateService,
    private interventionInstanceStore: InterventionInstanceStore,
    private studyStore: StudyStore,
    private store: Store<{
      myProfile: ProfileInterface;
      getCollaboratorsByStudyId: { studyId: number; collaborators: UserInterface[] };
      myMembers: Array<UserInterface>;
      allInterventionInstanceReminders: Array<InterventionInstanceReminderInterface>;
      collabGroups: Array<StudyInterface>;
    }>
  ) {
    this.profile$ = store.select('myProfile');
    this.allInterventionInstanceReminders$ = this.store.select('allInterventionInstanceReminders');
    this.myMembers$ = this.store.select('myMembers');
    this.groups$ = this.store.select('collabGroups');
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.isManager$ = this.isManagerSubject.asObservable();
    this.instances$ = this.instancesSubject.asObservable();
    this.pagedInstances$ = this.pagedInstancesSubject.asObservable();
    this.allMyInstancesOfStudy$ = this.interventionInstanceStore.allMyInstancesOfStudy$;
    this.studyMembers$ = this.studyStore.studyMembers$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profile$.pipe(filter(user => user !== null)).subscribe((result: ProfileInterface) => {
        this.profile = result;
      }),
    );

    this.subscriptions.push(
      this.sharedService.sourceGroup$
        .pipe(
          filter(value => !!value),
          take(1)
        )
        .subscribe((value: StudyInterface) => {
          this.study = value;
          this.applyInstanceFilter();
          this.param = { intervention_name: value.attributes.name };
        }),
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((_event: LangChangeEvent) => {
        this.applyInstanceFilter();
      })
    );
  }

  public applyInstanceFilter(): void {
    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.reloadInstanceList(true).subscribe((_result) => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  public reloadInstanceList(setFirst?: boolean): Observable<boolean> {
    const pageIndex = this.paginator?.paginator ? this.paginator?.paginator.pageIndex : 0;
    const pageSize = this.paginator?.paginator ? this.paginator?.paginator.pageSize : 20;
    this.store.dispatch({
      type: ReminderActionTypes.getAllInterventionInstanceRemindersOfUserType,
      payload: {}
    });
    const reqs: Array<Observable<any>> = [];
    this.store.dispatch({ type: StudyActionTypes.getCollaboratingStudiesType, payload: { include: 'owners,roles' } });
    return this.groups$.pipe(
      skip(1),
      take(1),
      mergeMap((result: any) => {
        // Get study and primarily roles
        const studies: Array<StudyInterface> = result;
        if (studies.length > 0) {
          const myRoleSlug = this.helper.getHighestRoleOfStudy(studies.find(study => study.id.toString() === this.study.id.toString()));
          if (myRoleSlug.match(/study\.(ecoachmanager|owner)$/)) {
            this.isManagerSubject.next(true);
          }
        }

        if (this.isManagerSubject.value) {
          this.interventionInstanceStore.getAllInstancesOfStudy({ studyId: this.study.id });
        } else {
          this.interventionInstanceStore.getMyInstancesOfStudy({ studyId: this.study.id });
        }

        return this.allMyInstancesOfStudy$.pipe(
          skip(1),
          take(1),
          mergeMap((res: Array<InterventionInstanceInterface>) => {
            this.myInstances = this.excludeCanceledInstance
              ? res.filter((instance: InterventionInstanceInterface) => instance.attributes.progress.current_state !== 'canceled')
              : res;
            if (this.isManagerSubject.value) {
              this.studyStore.getMembers({ studyId: this.study.id });
            } else {
              this.studyStore.getECoachMembers({ studyId: this.study.id });
            }
            reqs.push(this.studyMembers$.pipe(skip(1), take(1)));
            this.store.dispatch({ type: StudyActionTypes.getCollaboratorsType, payload: { studyId: this.study.id, include: 'roles' } });
            this.collaboratorsOfStudy$ = this.store.select(getCollaboratorsByStudyId(this.study.id));
            reqs.push(
              this.collaboratorsOfStudy$.pipe(
                filter(collaboratorsOfStudy => !!collaboratorsOfStudy),
                take(1),
              )
            );

            this.store.dispatch({
              type: UserActionTypes.getMyMembersType,
              payload: {},
            });
            reqs.push(this.myMembers$.pipe(skip(1), take(1)));
            return forkJoin(reqs);
          }),
        );
      }),
      mergeMap((results: any) => {
        this.users = results[0];
        this.studyCollaborators = results[1].collaborators;
        const myMemberIds = results[2].map((user: UserInterface) => user.id);
        if (!this.isManagerSubject.value) {
          this.users = this.users.filter((user: UserInterface) => myMemberIds.includes(user.id));
        }
        this.selectedUserSubject.next(this.users.length > 0 ? this.users[0].id : null);
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize);
      }),
      catchError(() => {
        this.users = [];
        this.studyCollaborators = [];
        return this.performFilterAndPagination(setFirst, pageIndex, pageSize);
      })
    );
  }

  public updateList(): void {
    this.subscriptions.push(this.reloadInstanceList().subscribe());
  }

  public updatePagedInterventionInstances(event: any) {
    if (event) {
      this.pagedInstancesSubject.next(event);
    }
  }

  public openDialogGroupInterventionInstanceCreate(): void {
    this.helperDialog
      .openDialogAccountInterventionInstanceAssignment(
        'studyInvitation',
        undefined,
        this.study,
        this.users.find((user: UserInterface) => user.id.toString() === this.selectedUserSubject.value.toString()),
        this.users,
      )
      .afterClosed()
      .pipe(mergeMap((_value) => this.reloadInstanceList()))
      .subscribe(() => {});
  }

  public openDialogAddCollaboratorToECoachInstancesStudy(): void {
    this.helperDialog
      .openDialogAddCollaboratorToECoachInstancesStudy(this.study.id)
      .afterClosed()
      .pipe(mergeMap((_value) => this.reloadInstanceList()))
      .subscribe(() => {});
  }

  public performFilterAndPagination(setFirst: boolean, pageIndex: number, pageSize: number): Observable<boolean> {
    return this.helper
      .searchInterventionInstanceByInput(
        this.filter['searchFilter'],
        this.searchTextSubject,
        this.myInstances,
        this.instancesSubject,
        this.study.id,
        this.studyCollaborators,
        this.users,
      )
      .pipe(mergeMap(() => this.helper.setPagedContent(this.instancesSubject, this.pagedInstancesSubject, setFirst, pageIndex, pageSize)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
