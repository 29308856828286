/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PayloadInterface } from '../../../models/interface/payload.interface';
import { HelperService } from '../../helper/helper.service';

/**
 * Service:
 * User/Member API service that handles user and member-related operations
 */

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public header: HttpHeaders = new HttpHeaders();
  private backendUrl: string = environment.backendURL;

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.helperService.setLocaleFromStorage(this.header);
  }

  /**
   * AAS2 Admin API - Get All Users
   * This function retrieves all users.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllUsersAdmin(userId?: number, searchterm?: string): Observable<any> {
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('id', userId);
    }
    if (searchterm !== undefined) {
      params = params.set('searchterm', searchterm);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/admin/users?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 Admin API - User Details
   * This function retrieves a user.
   *
   * @params number userId - ID of a user.
   * @return Observable<any> - An observable for any response.
   */
  public getUserDetailsAdmin(userId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/admin/users/${userId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * @DEPRECATED
   * AAS2 API All Editors
   * This function retrieves all eCoaches.
   * @return Observable<any> - An observable for any response.
   */
  public getAllEditors(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/users/editors?limit=0`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API User - User Details
   * This function returns user details.
   *
   * @params number userId - ID of a user.
   * @return Observable<any> - An observable for any response.
   */
  public getUserDetails(userId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/users/${userId}`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Get My Members as ecoach - restricted access ecoach/user
   * This function retrieves all members of the ecoach.
   *
   * @params number userId - ID of a user.
   *         string email - email of a user.
   * @return Observable<any> - An observable for any response.
   */
  public getMyMembers(userId?: number, email?: string): Observable<any> {
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('id', userId.toString());
    }
    if (email !== undefined) {
      params = params.set('email', email);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API ECoach - Get Member Studies as ecoach
   * This function retrieves studies of a member.
   *
   * @params number userId - ID of a user.
   *         string type - A string determines the study type.
   *           - @DEPRECATED typically 'study'
   *           - typically 'studies' or 'organisationgroups'
   *         string include - A string including additional information.
   *           - typically 'questionnaires', 'collaborators', 'owners' or 'members'
   * @return Observable<any> - An observable for any response.
   */
  public getMemberStudies(userId: number, include?: string, type?: string): Observable<any> {
    let params = new HttpParams();
    if (include !== undefined) {
      params = params.set('include', include);
    }
    if (type !== undefined) {
      params = params.set('type', type);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/${userId}/studies?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get Answersheet of a Member as ecoach  - restricted access ecoach/user
   * This function retrieves answer sheets of a member.
   *
   * @params number userId - ID of a user.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetsOfMember(userId: number): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/${userId}/answersheets?limit=0&finished=1`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API User - Get Activities of Study Members
   * This function returns all activities of the study.
   *
   * @params number studyId - ID of a study.
   *         string action - A string of activity's action.
   *         string include - A string including additional information.
   *           - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesStudyOld(studyId: number, currentStudyOnly?: boolean, action?: string, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (currentStudyOnly !== undefined) {
      params = params.set('currentStudyOnly', currentStudyOnly.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include.toString());
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/studies/${studyId}/members/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - All Ecoaches
   * This function retrieves all ecoaches.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllEcoaches(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/users?limit=0`, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Create a patient account as an ecoach
   * This function creates a patient account and register them to one (or more) studies.
   *
   * @params string name - A username of the user.
   *         string email - A valid email address of the user.
   *         string firstname - A first name of the user.
   *         string lastname - A last name of the user.
   * @return Observable<any> - An observable for any response.
   */
  public registerPatientAsECoach(payload: PayloadInterface, language?: string): Observable<any> {
    this.header = language ? this.header.set('Accept-Language', language) : this.helperService.setLocaleFromStorage(this.header);
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/auth/register`, payload, { headers: this.header, observe: 'response' });
  }

  /**
   * AAS2 API ECoach - Add Ecoaches to an Intervention Instance
   * This function adds one or multiple ecoaches to an intervention instance.
   *
   * @params number instance_id - ID of an intervention instance.
   *         PayloadInterface payload - Payload that contains ids of the eCoaches.
   * @return Observable<any> - An observable for any response.
   */
  public addEcoachesToInterventionInstance(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/ecoaches`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Remove Ecoaches to an Intervention Instance
   * This function removes one or multiple ecoaches from the intervention instance.
   *
   * @params number instance_id - ID of an intervention instance.
   *         PayloadInterface payload - Payload that contains ids of the eCoaches.
   * @return Observable<any> - An observable for any response.
   */
  public deleteEcoachesFromInterventionInstance(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoach/interventions/instances/${instanceId}/ecoaches`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Add Ecoaches to an Intervention Instance
   * This function adds one or multiple ecoaches to an intervention instance.
   *
   * @params number instance_id - ID of an intervention instance.
   *         PayloadInterface payload - Payload that contains ids of the eCoaches.
   * @return Observable<any> - An observable for any response.
   */
  public addEcoachesToInterventionInstanceEM(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/ecoaches`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach Manager - Remove Ecoaches to an Intervention Instance
   * This function removes one or multiple ecoaches from the intervention instance.
   *
   * @params number instance_id - ID of an intervention instance.
   *         PayloadInterface payload - Payload that contains ids of the eCoaches.
   * @return Observable<any> - An observable for any response.
   */
  public deleteEcoachesFromInterventionInstanceEM(instanceId: number, payload: PayloadInterface): Observable<any> {
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoachmanager/interventions/instances/${instanceId}/ecoaches`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get Member Activity as ecoach  - restricted access ecoach/user
   * This function returns all member activities of one member related to the ecoach.
   *
   * @params number user_id - ID of a user.
   *         number instance_id - ID of an intervention instance.
   *         Used to retrieve the user activity of a specific intervention instance, such as:
   *        "STUDY_SUBSCRIBED"
   *         string include - A string including additional information.
   *           - typically 'questionnaire'
   * @return Observable<any> - An observable for any response.
   */
  public getAllEcoachMemberActivities(userId: number, instanceId?: number, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (instanceId !== undefined) {
      params = params.set('interventionInstance', instanceId.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/${userId}/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get activities of study member  - restricted access ecoach/user
   * This function returns all activities of all study members related to the ecoach.
   *
   * @params number study_id - ID of a study.
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesStudy(studyId: number, currentStudyOnly: boolean, action?: string, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (currentStudyOnly !== undefined) {
      params = params.set('currentStudyOnly', currentStudyOnly.toString());
    }
    if (include !== undefined) {
      params = params.set('include', include.toString());
    }
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/studies/${studyId}/members/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get Answersheet Details of Member - restricted access ecoach/user
   * This function retrieves answer sheet details of a member.
   *
   * @params number userId - ID of a user.
   *         number answersheet_id - ID of an answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public getAnswersheetDetailsOfEcoachMember(userId: number, answersheetId): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}{{url}}/api/v1/ecoach/members/${userId}/answersheets/${answersheetId}`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Get Activities of my Members as ecoach - restricted access ecoach/user
   * This function returns all activities of members related to the ecoach.
   *
   * @params string action - Filter activites by provided action type.
   *         string include - A string including additional information - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getActivitiesOfMembers(action?: string, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/activities?limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get Activities of my Members as ecoach between unix timestamps  - restricted access ecoach
   * This function returns all activities of members related to the ecoach.
   *
   * @params string action - Filter activites by provided action type.
   *         string include - A string including additional information - typically 'questionnaires'
   * @return Observable<any> - An observable for any response.
   */
  public getIntimeActivitiesOfMembers(start: number, end: number, action?: string, include?: string): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    let params = new HttpParams();
    if (action !== undefined) {
      params = params.set('action', action);
    }
    if (include !== undefined) {
      params = params.set('include', include);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/activities/intimes?start=${start}&end=${end}&limit=0`, {
      headers: this.header,
      observe: 'response',
      params
    });
  }

  /**
   * AAS2 API ECoach - Get invitations of my Members as ecoach - restricted access ecoach/user
   * This function returns all invitations of members related to the ecoach.
   *
   * @params string email - Filter study invitations by provided email address.
   * @return Observable<any> - An observable for any response.
   */
  public getAllInvitationsOfEcoachMembers(email?: string): Observable<any> {
    let params = new HttpParams();
    if (email !== undefined) {
      params = params.set('email', email);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/members/invitations?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API ECoach - Get users registered by ecoach
   * This function returns all users who have been registered by the ecoach.
   *
   * @params number user_id - ID of a user.
   * @return Observable<any> - An observable for any response.
   */
  public getUsersRegisteredByEcoach(userId?: number): Observable<any> {
    let params = new HttpParams();
    if (userId !== undefined) {
      params = params.set('id', userId.toString());
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/ecoach/my/users?limit=0`, { observe: 'response', params });
  }

  /**
   * AAS2 API ECoach - Send reminder email
   * This function sends reminder mail to user on a questionnaire.
   *
   * @params number user_id - ID of a user.
   *         number questionnaire_id - ID of a questionnaire.
   * @return Observable<any> - An observable for any response.
   */
  public sendReminderEmail(userId: number, questionnaireId: number, interventionInstanceId: number, language?: string): Observable<any> {
    this.header = language ? this.header.set('Accept-Language', language) : this.helperService.setLocaleFromStorage(this.header);
    const payload: PayloadInterface = {
      data: {
        type: 'elements/elements',
        attributes: {
          questionnaire_id: questionnaireId,
          intervention_instance_id: interventionInstanceId
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/users/${userId}/email`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete all unverified patient accounts created by the eCoach
   * This function removes all pending unverified accounts created by the eCoach including study invitations and instances.
   *
   * @return Observable<any> - An observable for any response.
   */
  public deleteUnverifiedPatientAccounts(): Observable<any> {
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoach/users/all/nonverified`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Delete an unverified patient account created by the eCoach
   * This function removes a pending unverified account created by the eCoach including study invitations and instances.
   *
   * @params Array<number> user_ids - List of user ids.
   * @return Observable<any> - An observable for any response.
   */
  public deleteUnverifiedPatientAccount(userIds: Array<number>): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'users',
        attributes: {
          users: userIds
        }
      }
    };
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/ecoach/users/nonverified`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Rejects an account deletion request
   * This function rejects a pending account deletion request.
   *
   * @params number requestId - Account deletion request id.
   * @return Observable<any> - An observable for any response.
   */
  public rejectDeletionRequest(requestId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.patch<any>(`${this.backendUrl}/api/v1/admin/users/delete/requests/${requestId}`, null, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Accept account deletion request to pseudonymise data
   * This function accepts a pending account deletion request to pseudonymise data.
   *
   * @params number userId - ID of user.
   * @return Observable<any> - An observable for any response.
   */
  public acceptDeletionRequestPseudonymise(userId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/admin/users/${userId}/pseudonymise`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Accept account deletion request to completely delete data
   * This function accepts a pending account deletion request to completely delete data.
   *
   * @params number userId - ID of user.
   * @return Observable<any> - An observable for any response.
   */
  public acceptDeletionRequestDelete(userId: number): Observable<any> {
    this.header = this.helperService.setLocaleFromStorage(this.header);
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/admin/users/${userId}/delete`, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API ECoach - Send an email reminder to a user with unread feedback
   * This function sends an email to remind the user of an unread feedback.
   *
   * @params number user_id - ID of a user.
   *         number answersheet_id - ID of an answersheet.
   * @return Observable<any> - An observable for any response.
   */
  public sendReminderToUnreadFeedback(userId: number, answersheetId: number, language?: string): Observable<any> {
    this.header = language ? this.header.set('Accept-Language', language) : this.helperService.setLocaleFromStorage(this.header);
    const payload: PayloadInterface = {
      data: {
        type: 'interventions',
        attributes: {
          answersheet_id: answersheetId
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/ecoach/users/${userId}/unread/feedback`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 User API - Get All Collaborators of organisations and studies
   * This function retrieves all users.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getAllCollaboratorsOfUser(roles?: string): Observable<any> {
    let params = new HttpParams();
    if (roles !== undefined) {
      params = params.set('roles', roles);
    }
    return this.http.get<any>(`${this.backendUrl}/api/v1/my/collaborators?limit=0`, { headers: this.header, observe: 'response', params });
  }

  /**
   * AAS2 API Admin - Assign a role to a user
   * This function assigns multiple roles to a user.
   *
   * @params number userId - ID of a user.
   *         Array<string> roleSlugs - List of roles.
   * @return Observable<any> - An observable for any response.
   */
  public assignRolesToUserAdmin(userId: number, roleSlugs: Array<string>): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'roles',
        attributes: {
          user_id: userId,
          role_slugs: roleSlugs
        }
      }
    };
    return this.http.post<any>(`${this.backendUrl}/api/v1/admin/roles`, payload, {
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Remove a role to a user
   * This function removes multiple roles from a user.
   *
   * @params number userId - ID of a user.
   *         Array<string> roleSlugs - List of roles.
   * @return Observable<any> - An observable for any response.
   */
  public removeRolesFromUserAdmin(userId: number, roleSlugs: Array<string>): Observable<any> {
    const payload: PayloadInterface = {
      data: {
        type: 'roles',
        attributes: {
          user_id: userId,
          role_slugs: roleSlugs
        }
      }
    };
    return this.http.request<any>('delete', `${this.backendUrl}/api/v1/admin/roles`, {
      body: payload,
      headers: this.header,
      observe: 'response'
    });
  }

  /**
   * AAS2 API Admin - Get deletion requests
   * This function returns a list of pending deletion requests.
   *
   * @return Observable<any> - An observable for any response.
   */
  public getDeletionRequests(): Observable<any> {
    return this.http.get<any>(`${this.backendUrl}/api/v1/admin/users/delete/requests?limit=0`, {
      headers: this.header,
      observe: 'response'
    });
  }
}
