/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { mergeMap, skip, switchMap, take } from 'rxjs/operators';
import { InterventionSharedService } from '../../../services/shared/intervention-shared/intervention-shared.service';
import { LessonSharedService } from '../../../services/shared/lesson-shared/lesson-shared.service';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { HelperService } from '../../../services/helper/helper.service';
import { LessonPreviewComponent } from '../../questionnaire/lesson-preview/lesson-preview.component';
import { DiaryInterface } from '../../../models/interface/diary.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { SkillInterface } from '../../../models/interface/skill.interface';
import { HelperSkillService } from '../../../services/helper/helper-skill/helper-skill.service';
import { HelperDialogService } from '../../../services/helper/helper-dialog/helper-dialog.service';
import { DiaryStore } from '../../../store/diary/component-store/diary.store';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { environment } from 'src/environments/environment';

/**
 * Component:
 * Intervention lesson page displaying a list of lessons and preview;
 * Can be found: {uri}/intervention/{{intervention_id}}/lessons
 */

@Component({
  selector: 'app-lesson',
  templateUrl: './intervention-lesson.component.html',
  styleUrls: ['./intervention-lesson.component.scss'],
  providers: [DiaryStore, QuestionnaireStore, SkillStore]
})
export class InterventionLessonComponent implements OnInit, OnDestroy {
  @ViewChild('lessonPreviewContainer', { read: ViewContainerRef }) lessonPreviewContainer: ViewContainerRef;

  public components = [];

  // Icons
  faBook = faBook;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faCopy = faCopy;
  faFileCsv = faFileCsv;
  faSquare = faSquare;

  private patientLessonUrl = environment.patientURL + '/lesson/';

  public isLoading$: Observable<boolean>;
  public isLoadingLesson$: Observable<boolean>;

  // Data provided by InterventionSharedService
  public studyId: number;
  public intervention: InterventionInterface;
  public interventionId: number;
  public lessonId: number;

  public questionnaires: LessonInterface[];

  // Data provided by SkillService
  public skills: SkillInterface[];

  public diaries: DiaryInterface[];
  public diariesOfStudy$: Observable<DiaryInterface[]>;

  public questionnairesOfIntervention$: Observable<LessonInterface[]>;

  public isPreview$: Observable<boolean>;
  public isCollapse$: Observable<boolean>;

  // Param for translation
  public param = { intervention_name: '...' };

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isLoadingLessonSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private skillsOfIntervention$: Observable<SkillInterface[]>;

  private subscriptions: Subscription[] = [];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private diaryStore: DiaryStore,
    private questionnaireStore: QuestionnaireStore,
    private skillStore: SkillStore,
    private sharedService: InterventionSharedService,
    private lessonSharedService: LessonSharedService,
    private helperService: HelperService,
    private helperSkillService: HelperSkillService,
    private helperDialogService: HelperDialogService
  ) {
    // Router event loading if child lesson is selected
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.isLoadingLessonSubject.next(true);
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.isLoadingLessonSubject.next(false);
          break;
        }
        default: {
          break;
        }
      }
    });
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingLesson$ = this.isLoadingLessonSubject.asObservable();
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.isPreview$ = this.lessonSharedService.isPreviewSubject.asObservable();

    // Fixing null for interventionId
    const stringUrl = this.actRoute.snapshot['_routerState'].url.toString();
    this.interventionId = parseInt(stringUrl.match(new RegExp('interventions/(.*)/lessons'))[1], 10);

    this.diariesOfStudy$ = this.diaryStore.diariesOfStudy$;
    this.questionnairesOfIntervention$ = this.questionnaireStore.questionnairesOfIntervention$;
    this.skillsOfIntervention$ = this.skillStore.skillsOfIntervention$;
  }

  public get helper() {
    return this.helperService;
  }

  public get helperSkill() {
    return this.helperSkillService;
  }

  public get helperDialog() {
    return this.helperDialogService;
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.lessonSharedService.sourceId$.subscribe((value: number) => {
        this.lessonId = value;
      })
    );

    this.subscriptions.push(
      this.sharedService.intervention$
        .pipe(
          switchMap((intervention: InterventionInterface) => {
            this.studyId = intervention.attributes.study_id;
            this.interventionId = intervention.id;
            this.intervention = intervention;

            this.param = { intervention_name: intervention.attributes.name };
            this.skillStore.getSkillsOfIntervention({ id: this.intervention.id });
            return this.skillsOfIntervention$;
          }),
          switchMap((result: SkillInterface[]) => {
            this.skills = result;
            this.diaryStore.getDiariesOfStudy({ studyId: this.studyId });
            return this.diariesOfStudy$.pipe(skip(1), take(1));
          }),
          mergeMap((result: DiaryInterface[]) => {
            this.diaries = result;
            this.questionnaireStore.getQuestionnairesIntervention({ interventionId: this.intervention.id });
            return this.questionnairesOfIntervention$.pipe(skip(1), take(1));
          })
        )
        .subscribe({
          next: (result: LessonInterface[]) => {
            this.questionnaires = result;
            this.questionnaires = this.helper.sortLessonsAscending(this.questionnaires);
            this.isLoadingSubject.next(false);
          },
          error: (error) => {
            throwError(() => error);
          }
        })
    );
  }

  public generateLessonURLs(): void {
    const questionnaires: LessonInterface[] = JSON.parse(JSON.stringify(this.questionnaires));
    questionnaires.sort((q1, q2) => q1.attributes.position - q2.attributes.position);
    const rows = questionnaires.map((questionnaire) => {
      const position = questionnaire.attributes.position;
      const name = `"${questionnaire.attributes.name.replace(/"/g, '""')}"`;
      const url = `"${this.patientLessonUrl + questionnaire.id}"`
      return `${position};${name};${url}`;
    });
    const header = 'Position;Name;URL';
    // \ufeff is byte order mark to help programs (Excel) identify UTF encoding
    const csv = `\ufeff${header}\r\n${rows.join('\r\n')}`;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    // Create a link to download it
    const link = document.createElement('a');
    link.href = url;
    const filename = `eSano_Lesson-URLs_ID${this.intervention.id}.csv`;
    link.setAttribute('download', filename);
    link.click();
  }

  copyLessonUrlToClipboard(questionnaireId: number): void {
    const url = this.patientLessonUrl + questionnaireId;
    navigator.clipboard.writeText(url);
  }

  public initializeLessonPreviewComponent(questionnaireId: number, studyId: number): void {
    this.components = [];
    this.lessonPreviewContainer.clear();
    // Create the initial component dynamically inside the ng-template
    const component = this.lessonPreviewContainer.createComponent<LessonPreviewComponent>(LessonPreviewComponent).instance;
    component._questionnaire_id = questionnaireId;
    component._study_id = studyId;
    component._intervention = this.intervention;
    this.components.push(component);
  }

  ngOnDestroy(): void {
    this.lessonSharedService.pushIsPreview(false);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.lessonId = null;
  }
}
