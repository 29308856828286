<div class="container-fluid text-center">
  <div class="col-sm-8 text-start">
    <h1>{{ 'activity.activities' | translate }}</h1>
  </div>
  <hr />
  <h4>
    {{ 'activity.overview' | translate }}
    <button id="btnToggleDescription" (click)="helper.toggleSubject(showDescriptionSubject)" aria-label="Information" mat-button>
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </button>
  </h4>

  <div class="card my-3">
    <div class="card-body">
      <div *ngIf="showDescription$ | async" class="row justify-content-center">
        <div class="col-sm-9">
          <p class="text-justify">{{ 'activity.description' | translate }}</p>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-sm-3 mb-2">
          <button id="btnToggleFilter" (click)="helper.toggleSubject(isCollapseSubject)" class="mt-1" mat-raised-button type="submit">
            <fa-icon [icon]="faList"></fa-icon>
            Filter
          </button>
        </div>

        <!-- Select study -->
        <div class="col-12 col-sm-6 mb-2">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'activity.select' | translate}}</mat-label>
            <mat-select (click)="resetFilter()" (selectionChange)="showSelected()" [(value)]="selectedStudy">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'activity.filter_selection' | translate}}</mat-label>
                <input id="searchStudy" autocomplete="off" matInput (keyup)="onKeyFilter()" [(ngModel)]="filter['studyFilter']" />
              </mat-form-field>
              <mat-option *ngFor="let selection of showStudies$ | async" [value]="selection"
                >{{displaySelect(selection) | translate}} <span *ngIf="selection !== undefined">(ID {{selection.id}})</span></mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>{{'activity.search_user' | translate}}</mat-label>
            <input id="searchActivity" (keyup)="keyUp.next($event)" [(ngModel)]="filter['searchFilter']" matInput />
          </mat-form-field>
        </div>

        <div class="col-sm-3 mb-1"></div>
      </div>

      <div *ngIf="isLoading$ | async; else elseActivityBlock" class="spinnerPosition">
        <app-loader></app-loader>
      </div>

      <!-- Main content -->
      <ng-template #elseActivityBlock>
        <div [ngClass]="(isCollapse$ | async) ? 'justify-content-center' : 'row'" class="text-start">
          <div *ngIf="(isCollapse$ | async) === false" class="col-sm-3">
            <p><strong>{{ 'activity.action_filter' | translate }}</strong></p>
            <mat-checkbox id="checkboxStudy" (change)="applyActivityFilter()" [(ngModel)]="filter['checkboxStudy']"
              >{{ 'activity.study_subscribed' | translate }}</mat-checkbox
            >
            <mat-checkbox id="checkboxAnswersheet" (change)="applyActivityFilter()" [(ngModel)]="filter['checkboxAnswersheet']"
              >{{ 'activity.answersheet_submitted' | translate }}</mat-checkbox
            >
          </div>

          <div [ngClass]="(isCollapse$ | async) ? 'justify-content-center' : 'col-sm-9'">
            <div *ngIf="(tableContent$ | async)?.length === 0; else elseShowActivityBlock">
              <p class="centering">{{ 'activity.no_activities' | translate }}</p>
            </div>

            <ng-template #elseShowActivityBlock>
              <div class="mat-elevation-z0 text-start mb-3">
                <table (matSortChange)="onChangeSortTable($event)" [dataSource]="dataSource" mat-table matSort>
                  <ng-container matColumnDef="activity_text">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.activity_text' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>
                      {{helperActivity.getActivityText(activity) | async}}
                      <span *ngIf="activity.attributes.action === 'ANSWERSHEET_SUBMITTED'">
                        {{helperActivity.getLessonNameOfActivity(activity, lessons)}}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="performed_by">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.performed_by' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>
                      {{helper.findArrObjById(activity.attributes.user, members) !== null ?
                      (helper.getCodeNameEmail(activity.attributes.user, members, selectedStudy.id)) : ('activity.system' | translate)}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created_at">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'activity.created_at' | translate}}</th>
                    <td *matCellDef="let activity" mat-cell>{{helper.localizeDateString(activity.attributes.created_at)}}</td>
                  </ng-container>

                  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                </table>
                <mat-paginator
                  (page)="onPageChangeActivity($event)"
                  [length]="(tableContent$ | async)?.length"
                  [showFirstLastButtons]="true"
                  [pageSizeOptions]="[10, 20, 50]"
                  [pageSize]="20"
                ></mat-paginator>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
