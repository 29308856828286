import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { iif, Observable, of } from 'rxjs';
import { skip, switchMap, take } from 'rxjs/operators';
import { InterventionActionTypes } from '../../../app/store/intervention/intervention.action';
import { InterventionInterface } from '../../models/interface/intervention.interface';
import { getCollabInterventionsByStudyId } from '../../store/intervention/intervention.selector';

@Injectable({
  providedIn: 'root'
})
export class InterventionBuddyGuard implements CanActivateChild {
  private allInterventionsOfStudy$: Observable<Array<InterventionInterface>>;

  constructor(private router: Router, private store: Store<{ getCollabInterventionsByStudyId: Array<InterventionInterface> }>) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const studyId = state.url.replace(/\D/g, '');
    if (state.url.includes('buddies')) {
      this.allInterventionsOfStudy$ = this.store.select(getCollabInterventionsByStudyId(parseInt(studyId, 10)));
      this.store.dispatch({ type: InterventionActionTypes.getInterventionsOfCollabStudyType, payload: { typeOfParentStudy: 'study' } });
      return this.allInterventionsOfStudy$.pipe(
        skip(1),
        take(1),
        switchMap((interventionsResp: Array<InterventionInterface>) => {
          const interventions = interventionsResp.filter(
            (intervention: InterventionInterface) => intervention.attributes.buddy_support === 1
          );
          const hasBuddyInterventions = interventions.length > 0;
          return iif(() => hasBuddyInterventions, of(true), of(this.router.parseUrl(`/groups/${studyId}`)));
        })
      );
    } else {
      return of(true);
    }
  }
}
