/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject, distinctUntilChanged, Subscription, take, mergeMap, of, skip, filter } from 'rxjs';
import { AnswersheetAnswerInterface } from '../../../models/interface/answersheet-answer.interface';
import { ConditionalblockInterface } from '../../../models/interface/condition/conditionalblock.interface';
import { QuestionHeadlineInterface } from '../../../models/interface/elements-question/question-headline/question-headline.interface';
import { QuestionMediaInterface } from '../../../models/interface/elements-question/question-media/question-media.interface';
import { QuestionSliderInterface } from '../../../models/interface/elements-question/question-slider/question-slider.interface';
import { QuestionTableInterface } from '../../../models/interface/elements-question/question-table/question-table.interface';
import { QuestionTextAreaInterface } from '../../../models/interface/elements-question/question-text-area/question-text-area.interface';
import { QuestionTextDateInterface } from '../../../models/interface/elements-question/question-text-date/question-text-date.interface';
import { QuestionTextStringInterface } from '../../../models/interface/elements-question/question-text-string/question-text-string.interface';
import { QuestionTextInterface } from '../../../models/interface/elements-question/question-text/question-text.interface';
import { QuestionYesNoInterface } from '../../../models/interface/elements-question/question-yes-no/question-yes-no.interface';
import { ElementBlockInterface } from '../../../models/interface/elements/element-block.interface';
import { ElementInterface } from '../../../models/interface/elements/element.interface';
import { InterventionInterface } from '../../../models/interface/intervention.interface';
import { LessonInterface } from '../../../models/interface/lesson.interface';
import { EvaluationService } from '../../../services/evaluation/evaluation.service';
import { HelperService } from '../../../services/helper/helper.service';
import { ParserService } from '../../../services/parser/parser.service';
import { PageIterationInterface } from '../../../models/interface/elements/page-iteration.interface';
import { ElementsBlockopensComponent } from '../../questionnaire-element/components/elements-blockopens/elements-blockopens.component';
import { ElementsLinesComponent } from '../../questionnaire-element/components/elements-lines/elements-lines.component';
import { ElementsPageComponent } from '../../questionnaire-element/components/elements-page/elements-page.component';
import { ElementsSpacesComponent } from '../../questionnaire-element/components/elements-spaces/elements-spaces.component';
import { QuestionHeadlineComponent } from '../../questionnaire-element/components/question-headline/question-headline.component';
import { QuestionMediaComponent } from '../../questionnaire-element/components/question-media/question-media.component';
import { QuestionMultipleChoiceComponent } from '../../questionnaire-element/components/question-multiple-choice/question-multiple-choice.component';
import { QuestionSingleChoiceComponent } from '../../questionnaire-element/components/question-single-choice/question-single-choice.component';
import { QuestionSliderComponent } from '../../questionnaire-element/components/question-slider/question-slider.component';
import { QuestionTableComponent } from '../../questionnaire-element/components/question-table/question-table.component';
import { QuestionTextAreaComponent } from '../../questionnaire-element/components/question-text-area/question-text-area.component';
import { QuestionTextDateComponent } from '../../questionnaire-element/components/question-text-date/question-text-date.component';
import { QuestionTextStringComponent } from '../../questionnaire-element/components/question-text-string/question-text-string.component';
import { QuestionTextComponent } from '../../questionnaire-element/components/question-text/question-text.component';
import { QuestionYesNoComponent } from '../../questionnaire-element/components/question-yes-no/question-yes-no.component';
import { ProgressBarComponent } from '../../questionnaire-element/progress-bar/progress-bar.component';
import { QuestionnaireStore } from '../../../store/lesson-questionnaire/component-store/lesson-questionnaire.store';
import { SkillStore } from '../../../store/skill/component-store/skill.store';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { LessonLocaleActionTypes } from 'src/app/store/lesson-locale/lesson-locale.action';
import { SkillInterface } from 'src/app/models/interface/skill.interface';
import { DiaryInterface } from 'src/app/models/interface/diary.interface';

@Component({
  selector: 'app-questionnaire-preview',
  templateUrl: './questionnaire-preview.component.html',
  styleUrls: ['./questionnaire-preview.component.scss'],
  providers: [QuestionnaireStore, SkillStore]
})
export class QuestionnairePreviewComponent implements OnInit, OnDestroy {
  @ViewChild('elementsContainer', { read: ViewContainerRef }) elementsContainer: ViewContainerRef;
  @ViewChild('progressBarContainer', { read: ViewContainerRef }) progressBarContainer: ViewContainerRef;

  @ViewChild('topPage') topPage: ElementRef;

  // Icons
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faEye = faEye;

  public nonNestedElements: Array<
    | ElementInterface
    | QuestionHeadlineInterface
    | QuestionMediaInterface
    | QuestionYesNoInterface
    | QuestionTextInterface
    | QuestionTextStringInterface
    | QuestionTextDateInterface
    | QuestionTextAreaInterface
    | QuestionTableInterface
    | QuestionSliderInterface
    | ElementBlockInterface
  > = [];

  public isDiary = false;
  public diaryDefaultImageColor;
  public studyId: number;

  public lesson: LessonInterface;

  // Background color
  public backgroundColor;

  public elements: Array<any> = [];
  public blocks: Array<ConditionalblockInterface> = [];

  // Answers from answersheet
  public answers: Array<AnswersheetAnswerInterface>;

  // Answers from elements
  public dynamicAnswers: Array<AnswersheetAnswerInterface> = [];

  public isCollapse$: Observable<boolean>;
  public isSmallLayout$: Observable<boolean>;

  public intervention: InterventionInterface;

  public currentPageNumber = 0;
  public pageIteration: Array<PageIterationInterface> = [];

  public isCollapseSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public locales: Array<any> = [];

  public selectedLocale: any;

  private numbersOfPages: { pages: Array<any>; pageElements: Array<ElementInterface>; blocks: Array<ConditionalblockInterface> } = {
    pages: [0],
    pageElements: [],
    blocks: null
  };

  private dynamicAnswers$: Observable<Array<AnswersheetAnswerInterface>>;

  private pages: Array<ElementsPageComponent> = [];

  // Tracks list of generated components
  private components: Array<any> = [];

  private progressBarComponent: ProgressBarComponent;

  private isSmallLayoutSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private elementsOfQuestionnaire$: Observable<Array<any>>;
  private elementsOfSkill$: Observable<any>;

  private subscriptions: Subscription[] = [];
  private elementsType: 'questionnaires' | 'skills' = 'questionnaires';
  private skillId: number;

  constructor(
    private helperService: HelperService,
    private evaluationService: EvaluationService,
    private parserService: ParserService,
    private translateService: TranslateService,
    public myElement: ElementRef,
    private questionnaireStore: QuestionnaireStore,
    private skillStore: SkillStore,
    private store: Store<{
      dynamicAnswers: Array<AnswersheetAnswerInterface>;
      lessonLocale: string;
    }>
  ) {
    this.dynamicAnswers$ = store.select('dynamicAnswers');
    this.isCollapse$ = this.isCollapseSubject.asObservable();
    this.isSmallLayout$ = this.isSmallLayoutSubject.asObservable();
    this.elementsOfQuestionnaire$ = this.questionnaireStore.elementsOfQuestionnaire$;
    this.elementsOfSkill$ = this.skillStore.elementsOfSkill$;
  }

  public get helper() {
    return this.helperService;
  }

  @Input()
  set _elementsType(_elementsType: 'questionnaires' | 'skills') {
    if (_elementsType) {
      this.elementsType = _elementsType;
    }
  }

  @Input()
  set _studyId(_studyId: number) {
    if (_studyId) {
      this.studyId = _studyId;
    }
  }

  @Input()
  set _lesson(_lesson: LessonInterface) {
    if (_lesson) {
      this.lesson = _lesson;
      this.locales = this.lesson.attributes?.locales ? this.lesson.attributes.locales : [];
      if (localStorage.getItem('lessonLocale')) {
        this.selectedLocale = localStorage.getItem('lessonLocale');
      } else {
        if (localStorage.getItem('language')) {
          this.selectedLocale = localStorage.getItem('language');
        } else {
          this.selectedLocale = this.locales[0] ? this.locales[0] : '';
        }
      }
    }
  }

  @Input()
  set _skill(_skill: SkillInterface) {
    if (_skill) {
      this.locales = _skill.attributes?.locales ? _skill.attributes.locales : [];
      if (localStorage.getItem('lessonLocale')) {
        this.selectedLocale = localStorage.getItem('lessonLocale');
      } else {
        if (localStorage.getItem('language')) {
          this.selectedLocale = localStorage.getItem('language');
        } else {
          this.selectedLocale = this.locales[0] ? this.locales[0] : '';
        }
      }
    }
  }

  @Input()
  set _diary(_diary: DiaryInterface) {
    if (_diary) {
      this.locales = _diary.attributes?.locales ? _diary.attributes.locales : [];
      if (localStorage.getItem('lessonLocale')) {
        this.selectedLocale = localStorage.getItem('lessonLocale');
      } else {
        if (localStorage.getItem('language')) {
          this.selectedLocale = localStorage.getItem('language');
        } else {
          this.selectedLocale = this.locales[0] ? this.locales[0] : '';
        }
      }
    }
  }

  @Input()
  set _background_color(_backgroundColor) {
    if (_backgroundColor) {
      this.backgroundColor = _backgroundColor;
    }
  }

  @Input()
  set _answers(_answers: Array<AnswersheetAnswerInterface>) {
    if (_answers) {
      this.answers = _answers;
    }
  }

  @Input()
  set _dynamicAnswers(_dynamicAnswers: Array<AnswersheetAnswerInterface>) {
    if (_dynamicAnswers) {
      this.dynamicAnswers = _dynamicAnswers;
    }
  }

  @Input()
  set _isDiary(_isDiary: boolean) {
    if (_isDiary !== undefined) {
      this.isDiary = _isDiary;
    }
  }

  @Input()
  set _diaryDefault(_diaryDefault: string) {
    if (_diaryDefault !== undefined) {
      this.diaryDefaultImageColor = _diaryDefault;
    }
  }

  @Input()
  set _intervention(_intervention: InterventionInterface) {
    if (_intervention !== undefined) {
      this.intervention = _intervention;
    }
  }

  @Input()
  set _skillId(_skillId: number) {
    if (_skillId !== undefined) {
      this.skillId = _skillId;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.getElementsOfItem().subscribe((result: any) => {
        this.identifyBlocksAndConditionals();

        if (!this.answers) {
          this.dynamicAnswers$.pipe(distinctUntilChanged()).subscribe((resp: Array<AnswersheetAnswerInterface>) => {
            this.dynamicAnswers = resp;
            this.calculatePageElements(resp);
            this.components.forEach(element => {
              element._currentPage = this.currentPageNumber;
            });
            if (this.progressBarComponent) {
              this.progressBarComponent._currentPage = this.currentPageNumber;
              this.progressBarComponent._allPages = this.pageIteration;
            }
          });
        } else {
          const _answers = this.parserService.parseAnswersheetToAnswer(this.answers, this.elements);
          this.calculatePageElements(_answers);
        }

        setTimeout(() => {
          this.components = [];
          if (this.elementsContainer) {
            this.elementsContainer.clear();
          }

          if (this.progressBarContainer) {
            this.progressBarContainer.clear();
          }

          this.progressBarComponent = this.progressBarContainer.createComponent<ProgressBarComponent>(ProgressBarComponent).instance;
          this.progressBarComponent._intervention = this.intervention;
          this.progressBarComponent._currentPage = this.currentPageNumber;
          this.progressBarComponent._allPages = this.pageIteration;
          this.progressBarComponent._answers = this.answers;
          this.progressBarComponent._pages = this.pages;

          const _answers: Array<AnswersheetAnswerInterface> = this.answers
            ? this.parserService.parseAnswersheetToAnswer(this.answers, this.elements)
            : this.dynamicAnswers;

          this.nonNestedElements.forEach(element => {
            // Create the initial component dynamically inside the ng-template
            let component;
            if (element.name === 'HEADLINE') {
              component = this.elementsContainer.createComponent<QuestionHeadlineComponent>(QuestionHeadlineComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.name === 'MEDIA') {
              component = this.elementsContainer.createComponent<QuestionMediaComponent>(QuestionMediaComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'MultipleChoice') {
              component = this.elementsContainer.createComponent<QuestionMultipleChoiceComponent>(QuestionMultipleChoiceComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'SingleChoice') {
              component = this.elementsContainer.createComponent<QuestionSingleChoiceComponent>(QuestionSingleChoiceComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'Slider') {
              component = this.elementsContainer.createComponent<QuestionSliderComponent>(QuestionSliderComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'QuestionTable') {
              component = this.elementsContainer.createComponent<QuestionTableComponent>(QuestionTableComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/texts') {
              component = this.elementsContainer.createComponent<QuestionTextComponent>(QuestionTextComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'TextArea') {
              component = this.elementsContainer.createComponent<QuestionTextAreaComponent>(QuestionTextAreaComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'TextDate') {
              component = this.elementsContainer.createComponent<QuestionTextDateComponent>(QuestionTextDateComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'TextString') {
              component = this.elementsContainer.createComponent<QuestionTextStringComponent>(QuestionTextStringComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/questions' && element.questiontype === 'YesNoSwitch') {
              component = this.elementsContainer.createComponent<QuestionYesNoComponent>(QuestionYesNoComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._allElements = this.elements;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/pages') {
              component = this.elementsContainer.createComponent<ElementsPageComponent>(ElementsPageComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component._condition = this.blocks;
              component._element = element;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
              this.pages.push(component);
            }
            if (element.elementtype === 'elements/lines') {
              component = this.elementsContainer.createComponent<ElementsLinesComponent>(ElementsLinesComponent).instance;
              component.condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/spaces') {
              component = this.elementsContainer.createComponent<ElementsSpacesComponent>(ElementsSpacesComponent).instance;
              component.condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._page = this.getPageByElementPosition(0, element.position, _answers);
            }
            if (element.elementtype === 'elements/blockopens') {
              component = this.elementsContainer.createComponent<ElementsBlockopensComponent>(ElementsBlockopensComponent).instance;
              if (this.answers) {
                component._answers = _answers;
              }
              component.condition = this.blocks;
              component._currentPage = this.currentPageNumber;
              component._element = element;
              component._elements = this.elements;
              component._nested = 0;
              component._pageIteration = this.pageIteration;
              component._page = this.getPageByElementPosition(0, element.position, _answers) + 1;
              component._color = this.backgroundColor;
            }
            if (component) {
              this.components = [...this.components, component];
            }
          });
        });
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.pipe(mergeMap((event: LangChangeEvent) => this.getElementsOfItem())).subscribe((result: any) => {})
    );
  }

  public getElementsOfItem(): Observable<any> {
    if (this.elementsType === 'skills') {
      this.skillStore.getSkillElements({ id: this.skillId });
    } else {
      this.questionnaireStore.getElementsOfQuestionnaire({ questionnaireId: this.lesson.id });
    }

    const tempObserv = (
      this.elementsType === 'skills'
        ? this.elementsOfSkill$.pipe(skip(1), take(1))
        : this.elementsOfQuestionnaire$.pipe(filter((value: Array<any>) => value.length > 0))
    ).pipe(
      mergeMap((result: any) => {
        this.elements = result;
        if (!this.isDiary) {
          if (this.lesson) {
            this.backgroundColor = this.lesson.attributes.page_color;
          }
        } else {
          this.backgroundColor = this.diaryDefaultImageColor;
        }
        return of(this.elements);
      })
    );
    return tempObserv;
  }

  public toggleSmallLayout(): void {
    this.isSmallLayoutSubject.next(!this.isSmallLayoutSubject.value);
  }

  // Get all non-encapsulated elements outside of (conditional) blocks
  public getNonEncapsulatedElements(): Array<ElementInterface> {
    const nonEncapElements: Array<ElementInterface> = [];
    for (let i = 0; i < this.elements.length; ) {
      const element = this.elements[i];
      if (element.elementtype === 'elements/blockopens') {
        const loopBlock = this.blocks.find(cond => cond.blockOpen === element.position);
        nonEncapElements.push(element);
        if (loopBlock) {
          i = loopBlock.blockClose - 1;
        }
      } else {
        nonEncapElements.push(element);
        i++;
      }
    }
    return nonEncapElements;
  }

  public getPageByElementPosition(iteration, position, answers: Array<AnswersheetAnswerInterface>): number {
    const nextPageElements: Array<PageIterationInterface> = this.pageIteration.filter(
      (obj: PageIterationInterface) => obj.position > position
    );
    const previousCurrentPageElements: Array<PageIterationInterface> = this.pageIteration.filter(
      (obj: PageIterationInterface) => obj.position < position
    );
    let nextPage: PageIterationInterface;
    if (nextPageElements.length > 0) {
      const nextPageElementPosition = nextPageElements.reduce(
        (minimum: number, obj: PageIterationInterface) => (obj.position < minimum ? obj.position : minimum),
        nextPageElements[0].position
      );
      const allArrMin = this.pageIteration.filter((obj: PageIterationInterface) => obj.position === nextPageElementPosition);
      nextPage = allArrMin.reduce((min, obj) => (min.iteration < obj.iteration ? min : obj));
    }

    let currentPage: PageIterationInterface;
    if (previousCurrentPageElements.length > 0) {
      const currentPageElementPosition = previousCurrentPageElements.reduce(
        (maximum: number, obj: PageIterationInterface) => (obj.position > maximum ? obj.position : maximum),
        previousCurrentPageElements[0].position
      );
      const allArrMax = this.pageIteration.filter((obj: PageIterationInterface) => obj.position === currentPageElementPosition);
      currentPage = allArrMax.reduce((max, obj) => (max.iteration > obj.iteration ? max : obj));
    }
    let evaluationNextPage;
    if (nextPage !== undefined) {
      evaluationNextPage = this.evaluationService.evaluate(this.blocks, nextPage.position, answers);
      // Added page condition
      if (evaluationNextPage && nextPage.evaluatedValue) {
        return nextPage.page - 1;
      }
    }
    let evaluationCurrentPage;
    if (currentPage !== undefined) {
      evaluationCurrentPage = this.evaluationService.evaluate(this.blocks, currentPage.position, answers);
      // Added page condition
      if (evaluationCurrentPage && currentPage.evaluatedValue) {
        return currentPage.page;
      }
    }
    return 0;
  }

  public showTopPage(selectedPage: number): void {
    if (this.topPage) {
      this.topPage.nativeElement.scrollIntoView();
    }
    const navigation: 'next' | 'previous' = this.currentPageNumber < selectedPage ? 'next' : 'previous';
    const foundPageElementIndex: number = this.pageIteration.findIndex(
      (pageIteration: PageIterationInterface) => pageIteration.page.toString() === selectedPage.toString()
    );

    if (foundPageElementIndex > -1) {
      if (this.pageIteration[foundPageElementIndex].evaluatedValue) {
        this.currentPageNumber = selectedPage;
      } else {
        let foundPageElement: PageIterationInterface;
        if (navigation === 'next') {
          foundPageElement = this.pageIteration
            .slice(selectedPage)
            .find((pageIteration: PageIterationInterface) => pageIteration.evaluatedValue);
        } else {
          const index = this.findLastIndex(
            this.pageIteration.slice(0, selectedPage),
            (pageIteration: PageIterationInterface) => pageIteration.evaluatedValue
          );
          if (index > -1) {
            foundPageElement = this.pageIteration[index];
          }
        }
        this.currentPageNumber = foundPageElement ? foundPageElement.page : 0;
      }
    } else {
      this.currentPageNumber = selectedPage;
    }
    this.setPageBackground();
    this.components.forEach(element => {
      element._currentPage = this.currentPageNumber;
    });
    if (this.progressBarComponent) {
      this.progressBarComponent._currentPage = this.currentPageNumber;
      this.progressBarComponent._allPages = this.pageIteration;
    }
  }

  public setPageBackground(): void {
    const pageIterationElement = this.pageIteration.find(
      (value: PageIterationInterface) => value.page.toString() === this.currentPageNumber.toString()
    );
    const pageElementPos = pageIterationElement !== undefined ? pageIterationElement.position : -1;
    const getPageElement = this.elements.find((element: ElementInterface) => element.position.toString() === pageElementPos.toString());
    if (getPageElement !== undefined && getPageElement.color !== '' && this.backgroundColor !== undefined) {
      this.backgroundColor = getPageElement.color;
    } else {
      if (!this.isDiary) {
        if (this.lesson) {
          this.backgroundColor = this.lesson.attributes.page_color;
        }
      } else {
        this.backgroundColor = this.diaryDefaultImageColor;
      }
    }
  }

  public getRepetition(repetition: number, condition: ConditionalblockInterface, answers: Array<AnswersheetAnswerInterface>): number {
    let reps = repetition;
    if (condition.condition.thenBlock.repeat.question) {
      const resultFromDefault = this.elements.find(element => element.position === condition.condition.thenBlock.repeat.data);
      const resultFromAnswer: AnswersheetAnswerInterface = answers.find(
        answer => answer.position === condition.condition.thenBlock.repeat.data
      );
      if (resultFromAnswer !== undefined) {
        if (resultFromAnswer.value) {
          reps = reps * parseInt(resultFromAnswer.value, 10);
        }
      } else {
        reps = reps * parseInt(resultFromDefault.values.min, 10);
      }
    } else {
      reps = reps * condition.condition.thenBlock.repeat.data;
    }
    return reps;
  }

  getBackgroundStyle() {
    if (this.backgroundColor === null || this.backgroundColor === undefined) {
      return null;
    }
    if (this.helper.isHexColor(this.backgroundColor) || this.backgroundColor === '') {
      return { 'background-color': this.backgroundColor };
    } else {
      return {
        'background-image': 'url(' + this.helper.getAllMediaSupportPath(this.backgroundColor, this.studyId) + ')',
        'background-size': 'cover',
        'background-attachment': 'fixed'
      };
    }
  }

  public hasEvaluatedPage(navigation: 'next' | 'previous') {
    if (navigation === 'next') {
      return this.pageIteration.slice(this.currentPageNumber).findIndex((page: PageIterationInterface) => page.evaluatedValue) > -1;
    } else {
      return (
        this.findLastIndex(this.pageIteration.slice(0, this.currentPageNumber), (page: PageIterationInterface) => page.evaluatedValue) > -1
      );
    }
  }

  public getPageNumber() {
    const foundPageIterationIndex: number = this.pageIteration
      .filter((page: PageIterationInterface) => page.evaluatedValue)
      .findIndex((page: PageIterationInterface) => page.page === this.currentPageNumber);
    return foundPageIterationIndex > -1 ? foundPageIterationIndex + 2 : 1;
  }

  public onChange(event?): void {
    localStorage.setItem('lessonLocale', this.selectedLocale);
    this.store.dispatch({
      type: LessonLocaleActionTypes.setLessonLocaleType,
      payload: this.selectedLocale
    });
  }

  public ngOnDestroy(): void {}

  // Get all nested elements regardless of block position to searchFilter elements out that are nested
  // TODO Recalculate page elements in nested condition and loops
  private calculatePageElements(answers: Array<AnswersheetAnswerInterface>): void {
    // Non nested elements in array of arrays
    this.nonNestedElements = this.getNonEncapsulatedElements();

    // All element pages
    const pageElements: Array<ElementInterface> = this.elements.filter(element => element.elementtype === 'elements/pages');
    this.numbersOfPages.pages = Array(pageElements.length + 1)
      .fill(0)
      .map((x, i) => i);
    this.numbersOfPages.blocks = this.blocks;
    this.numbersOfPages.pageElements = pageElements;

    // TODO Need to add those not part of any conditions
    this.pageIteration = [];

    this.numbersOfPages.pageElements.forEach((element: ElementInterface) => {
      // Evaluate page condition;
      let pageCondition = true;
      if (element.condition) {
        if (element.condition.toString() !== '') {
          pageCondition = this.evaluationService.evaluateCondition(element.condition, answers);
        }
      }

      // const evaluation = this.evaluationService.evaluate(this.blocks, element.position, answers) && pageCondition;
      const evaluation = this.evaluationService.evaluate(this.blocks, element.position, answers);
      if (evaluation) {
        let repetition = 1;
        repetition = repetition * this.evaluateLoop(this.blocks, element.position, answers);
        if (repetition.toString() === '1') {
          const pageNumber = 1;
          // Ignore first element which is the page to support old published interventions
          if (element.position !== 1) {
            this.pageIteration.push({
              page: pageNumber + this.pageIteration.length,
              position: element.position,
              iteration: 0,
              evaluatedValue: pageCondition
            });
          }
        } else {
          for (let i = 0; i < repetition; i++) {
            const pageNumber = 1;
            this.pageIteration.push({
              page: pageNumber + this.pageIteration.length,
              position: element.position,
              iteration: i,
              evaluatedValue: pageCondition
            });
          }
        }
      }
      this.numbersOfPages.pages = Array(this.pageIteration.length)
        .fill(0)
        .map((x, i) => i);
    });
  }

  // Get all pairs of blockOpens and blockCloses elements
  private identifyBlocksAndConditionals(): void {
    const blockOpenPosLocal = [];
    const conditionalsLocal = [];
    const conditionalTypeLocal = [];
    this.blocks = [];
    this.elements.forEach(arr => {
      if (arr.elementtype === 'elements/blockopens') {
        blockOpenPosLocal.push(arr.position);
        conditionalsLocal.push(arr.condition);
        conditionalTypeLocal.push(arr.type);
      }
      if (arr.elementtype === 'elements/blockcloses') {
        this.blocks.push({
          blockOpen: blockOpenPosLocal.pop(),
          blockClose: arr.position,
          condition: conditionalsLocal.pop(),
          condition_type: conditionalTypeLocal.pop()
        });
      }
    });
  }

  // Evaluate all conditional elements for this lesson with the given answers
  private evaluateLoop(condition: Array<ConditionalblockInterface>, position: number, answers: Array<AnswersheetAnswerInterface>): number {
    let repetition = 1;
    if (condition.length > 0) {
      condition.forEach((cond: ConditionalblockInterface) => {
        if (cond.blockOpen < position && position < cond.blockClose) {
          if (cond.condition_type.match(/^(conditional|both|none|details)$/)) {
            repetition = this.getRepetition(repetition, cond, answers);
          }
        }
      });
    }
    return repetition;
  }

  private findLastIndex(array: Array<any>, predicate: (value: any, index: number, obj: any[]) => boolean): number {
    let l = array.length;
    while (l--) {
      if (predicate(array[l], l, array)) {
        return l;
      }
    }
    return -1;
  }
}
